
<header class="relative flex flex-1 flex-shrink-0 items-center z-10 py-4">
  <!-- Desktop nav area -->
  <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2 sm:px-4 lg:px-4 gap-4">
    <div class="min-w-0 flex-1 flex items-start" [class.pl-0]="goBackToRoute === null">
      <struct-icon-button class="-ml-2 mr-2" *ngIf="goBackToRoute !== null" [routerLink]="[goBackToRoute]" icon="chevron_left" #tooltip="matTooltip" matTooltip="{{'General.GoBack' | translate}}" [matTooltipPosition]="'below'"></struct-icon-button>
      <div class="flex-col">
        <div class="flex items-center gap-2">
          <h1 *ngIf="title !== null" class="text-2xl font-medium animate-in slide-in-from-bottom-4 duration-800 truncate">{{title}}</h1>
          <!-- <struct-icon-button *ngIf="addShortcut" icon="bookmark_add" iconSize="16" class="ml-1" #tooltip="matTooltip" matTooltip="{{'General.Shortcut' | translate}}" [matTooltipPosition]="'below'"></struct-icon-button> -->
        </div>
        <nav class="flex animate-in slide-in-from-bottom-8 duration-800" aria-label="Breadcrumb" *ngIf="breadcrumb !== null && breadcrumb.items.length > 0">
          <ol role="list" class="flex items-center space-x-2">
              <li *ngFor="let item of breadcrumb.items; let index=index">
                  <div class="flex items-center">
                      <struct-icon icon="chevron_right" size="8" *ngIf="index > 0"></struct-icon>
                      <a *ngIf="item.route === null || item.route === undefined" (click)="breadcrumbClicked(item)" [ngClass]="{'ml-2': index > 0, 'cursor-pointer': item.action}" class="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <span>{{(getLabel(item) | async) ?? '' | translate}}</span>
                      </a>
                      <a *ngIf="item.route !== null && item.route !== undefined" [routerLink]="[item.route]" [ngClass]="{'ml-2': index > 0}" class="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <span>{{(getLabel(item) | async) ?? ''  | translate}}</span>
                      </a>
                  </div>
              </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="md:ml-10 flex flex-shrink-0 items-center space-x-8">
      <ng-content></ng-content>
    </div>
  </div>
</header>
