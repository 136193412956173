// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum StructEntityType {
  Category = "Category",
  Product = "Product",
  Variant = "Variant",
  VariantGroup = "VariantGroup",
  Asset = "Asset",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace StructEntityType {
  export function toString(value: StructEntityType): string {
    return StructEntityType[value];
  }

  export const values = (): StructEntityType[] => [
    StructEntityType.Category,
    StructEntityType.Product,
    StructEntityType.Variant,
    StructEntityType.VariantGroup,
    StructEntityType.Asset,
  ];

  export function parse(value: string, ignoreCase?: boolean): StructEntityType | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'StructEntityType';
  }
}
