import { Observable, interval } from "rxjs";

export interface INotificationData {
  title: Observable<string> | null;
  description: Observable<string> | null;
  hideCloseButton: boolean;
  closeButtonText: string | null;
  duration:number;
}

export class NotificationData implements INotificationData {
    title:Observable<string> | null = null;
    description:Observable<string> | null = null;
    hideCloseButton = false;
    closeButtonText:string |null = null;
    duration = 5000;
    percentageOfDurationGoneBy = 0;
    private durationGone = 0;

    constructor(data?: Partial<INotificationData>){
        Object.assign(this, data);

        const intervalId = setInterval(() => {
          this.durationGone = this.durationGone + 20;
          const pc = this.durationGone / this.duration * 100;
          if(pc > this.percentageOfDurationGoneBy){
            this.percentageOfDurationGoneBy = pc;
          }
          if(this.percentageOfDurationGoneBy >= 100){
            clearInterval(intervalId);
          }
        }, 20);
    }


  }