<!-- <div *ngIf="isAdminInvite">
  You have been invited to become a Struct Admin user. Accept the invite below to get access.
</div>
<div *ngIf="isTenantInvite">
  You have been invited to become a {{getTenantInvite()}} Struct Tenant user. Accept the invite below to get access.
</div> -->

<section class="w-full bg-white h-screen flex items-center justify-center">

<!-- First column -->
<div class="p-4 lg:p-12 bg-white items-center relative @container/workarea max-w-lg w-full">

  <div class="flex flex-col w-full items-center">

    <!-- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-8 h-8 text-blue-200 mb-8">
      <g>
        <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path>
        <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path>
        <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path>
      </g>
    </svg> -->
    <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 text-center">{{"InviteExistingUser.Title" | translate}}</h1>
    <h2 class="text-lg mb-8 text-center" *ngIf="invite | instanceof: AdminInvite : invite.type as adminInvite">{{"InviteExistingUser.AdminInviteSubtitle" | translate:{invitedBy: invite.invitedBy } }}</h2>
    <h2 class="text-lg mb-8 text-center" *ngIf="invite | instanceof: TenantInvite : invite.type as tenantInvite">{{"InviteExistingUser.TenantInviteSubtitle" | translate:{invitedBy: tenantInvite.invitedBy, tenantName: tenantInvite.tenantName } }}</h2>

    <div class="flex justify-evenly items-center gap-2 w-full">
      <button mat-flat-button color="primary" class="w-full" (click)="acceptInvite()">{{"InviteExistingUser.AcceptInvite" | translate}}</button>
      <button mat-flat-button color="error" class="w-full" (click)="declineInvite()">{{"InviteExistingUser.DeclineInvite" | translate}}</button>
    </div>


  </div>
</div>
</section>
