
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateApiKeyCommand } from "@struct/models/struct/app/domain/apiconfiguration/commands/create-api-key-command";
import { UpdateApiKeyCommand } from "@struct/models/struct/app/domain/apiconfiguration/commands/update-api-key-command";
import { ApiKey } from "@struct/models/struct/app/domain/apiconfiguration/api-key";
import { ApiPermission } from "@struct/models/struct/app/domain/apiconfiguration/api-permission";
import { ApiKeySearchModel } from "@struct/models/struct/app/frontendapi/models/apiconfiguration/api-key-search-model";

@Injectable({ providedIn: "root" })
export class ApiConfigurationApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getApiKeySearchModels(): Observable<ApiKeySearchModel[]> {
    return this.http.get<ApiKeySearchModel[]>(`${this.apiUrl}apiConfiguration/apikeys`, this.httpOptions );
  }

  getApiKey(uid: string): Observable<ApiKey> {
    return this.http.get<ApiKey>(`${this.apiUrl}apiConfiguration/apikeys/${uid}`, this.httpOptions );
  }

  createApiKey(cmd: CreateApiKeyCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}apiConfiguration/apikeys`, cmd, this.httpOptions);
  }

  updateApiKey(cmd: UpdateApiKeyCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}apiConfiguration/apikeys`, cmd, this.httpOptions);
  }

  cycleApiKey(uid: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}apiConfiguration/apikeys/${uid}/cyclekey`, null, this.httpOptions);
  }

  deleteApiKey(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}apiConfiguration/${uid}`, this.httpOptions );
  }

  getApiPermissions(): Observable<ApiPermission[]> {
    return this.http.get<ApiPermission[]>(`${this.apiUrl}apiConfiguration/permissions`, this.httpOptions );
  }
}

