
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AttributeValueTranslationCommand } from "@struct/models/struct/app/domain/attributes/commands/translations/attribute-value-translation-command";
import { AttributeValuesTranslationCommand } from "@struct/models/struct/app/domain/attributes/commands/translations/attribute-values-translation-command";
import { AttributeValueHashUpdate } from "@struct/models/struct/app/domain/attributes/attributes/attribute-value-hash-update";
import { TranslatableAttributeReference } from "@struct/models/struct/app/domain/attributes/translatable-attribute-reference";
import { AttributeEditorModel } from "@struct/models/struct/app/domain/dataconfiguration/implementation/properties/attribute-editor-model";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { BulkTranslateModel } from "@struct/models/struct/app/frontendapi/models/aiassistant/bulk-translate-model";

@Injectable({ providedIn: "root" })
export class TranslationsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getTranslatableAttributes(entityType: StructEntityType): Observable<TranslatableAttributeReference[]> {
    return this.http.get<TranslatableAttributeReference[]>(`${this.apiUrl}gettranslatableattributes?entityType=${entityType}`, this.httpOptions );
  }

  updateTranslatableAttributeValues(entityType: StructEntityType, entityId: number, attributeValues: AttributeEditorModel[]): Observable<AttributeValueHashUpdate[]> {
    return this.http.post<AttributeValueHashUpdate[]>(`${this.apiUrl}updatetranslatableattributevalues?entityType=${entityType}&entityId=${entityId}`, attributeValues, this.httpOptions);
  }

  bulkTranslateGlobalListValues(model: BulkTranslateModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}bulktranslategloballistvalues`, model, this.httpOptions);
  }

  bulkTranslate(model: BulkTranslateModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}bulktranslate`, model, this.httpOptions);
  }

  translateAttributeValues(req: AttributeValuesTranslationCommand): Observable<AttributeEditorModel[]> {
    return this.http.post<AttributeEditorModel[]>(`${this.apiUrl}translateattributevalues`, req, this.httpOptions);
  }

  translateAttributeValue(req: AttributeValueTranslationCommand): Observable<AttributeEditorModel> {
    return this.http.post<AttributeEditorModel>(`${this.apiUrl}translateattributevalue`, req, this.httpOptions);
  }

  getTranslatableAttributeValues(entityType: StructEntityType, entityId: number): Observable<AttributeEditorModel[]> {
    return this.http.get<AttributeEditorModel[]>(`${this.apiUrl}gettranslatableattributevalues?entityType=${entityType}&entityId=${entityId}`, this.httpOptions );
  }
}

