// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DisplayType {
  Text = "Text",
  Number = "Number",
  Percentage = "Percentage",
  FixedList = "FixedList",
  Media = "Media",
  Boolean = "Boolean",
  Date = "Date",
  DateTime = "DateTime",
  Culture = "Culture",
  UserUserGroup = "UserUserGroup",
  CommentsCount = "CommentsCount",
  BadgeItem = "BadgeItem",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DisplayType {
  export function toString(value: DisplayType): string {
    return DisplayType[value];
  }

  export const values = (): DisplayType[] => [
    DisplayType.Text,
    DisplayType.Number,
    DisplayType.Percentage,
    DisplayType.FixedList,
    DisplayType.Media,
    DisplayType.Boolean,
    DisplayType.Date,
    DisplayType.DateTime,
    DisplayType.Culture,
    DisplayType.UserUserGroup,
    DisplayType.CommentsCount,
    DisplayType.BadgeItem,
  ];

  export function parse(value: string, ignoreCase?: boolean): DisplayType | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DisplayType';
  }
}
