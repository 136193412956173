import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { PageMenuItem } from './PageMenuItem';
import {camelize} from "@struct/utilities/lib/utility";

@Component({
  selector: 'struct-app-sidebar-page-menu-list',
  templateUrl: './struct-app-sidebar-page-menu-list.component.html',
  styleUrls: ['./struct-app-sidebar-page-menu-list.component.scss'],
})
export class StructAppSidebarPageMenuListComponent {
  @Input() pageMenuItems: (PageMenuItem)[] = [];
  @Input() collapsed = false;

  constructor(private eRef: ElementRef, private router: Router, private cdr: ChangeDetectorRef) {}

  public goToRoute(route: string): void {
    this.router.navigate([route]);
  }

  public isLinkActive(route: string): boolean {
    return this.router.isActive(route, <IsActiveMatchOptions>{
      matrixParams: 'ignored',
      fragment: 'ignored',
      queryParams: 'ignored',
      paths: 'subset',
    });
  }

  protected readonly camelize = camelize;
}


