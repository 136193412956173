import {Component, OnInit} from '@angular/core';
import {IRowAction} from '@struct/ui/struct-table/models/row-action';
import {RowActionResult} from '@struct/ui/struct-table/models/row-action-result';
import {IRowSingleAction} from '@struct/ui/struct-table/models/row-single-action';
import {TableColumn} from '@struct/ui/struct-table/models/table-column';
import {ITableDefinition} from '@struct/ui/struct-table/models/table-definition';
import {Observable, of} from 'rxjs';
import {Row} from '@struct/ui/struct-table/models/row';
import {CommonModule} from '@angular/common';
import {UiModule} from '@struct/ui/ui.module';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {DisplayType, Query} from "@struct/models/struct/shared/search";
//import {OrganizationsApiService} from "@struct/services/account-management";
import { TranslateService } from '@ngx-translate/core';
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition } from '@struct/ui/struct-table';
//import { OrganizationSearchModel } from '@struct/models/struct/accountmanagement/api/models/organizations/organization-search-model';
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from '@struct/ui/struct-dialog/StructDialogConfig';
//import { InviteAdminUserDialogComponent } from '../invite-admin-user-dialog/invite-admin-user-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrganizationSearchModel } from '@struct/models/struct/accountmanagement/api/models/organization/organization-search-model';
import { OrganizationsApiService } from '@struct/services/account-management/organizations-api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'accounts-organizations-list',
    templateUrl: 'organizations-list.component.html',
    standalone: true,
    imports: [CommonModule, UiModule, AccountsHeaderComponent],
  })

  export class OrganizationsListComponent implements OnInit {
    users: OrganizationSearchModel[] | null = null;
    rows: Row[] = [];
    tableDefinition: ITableDefinition | null = null;
    tableScope = 'organizations';
    filter: Query | null = null;
    rowRouteTemplate: string | null = null;
  
    constructor(private organizationApi: OrganizationsApiService, private tr : TranslateService, private dialogService: MatDialog,private router : Router) {}
    
    ngOnInit(): void {
    const rowActions: IRowAction[] = [new EditOrganizationAction()];

    const tableColumns = [
      new TableColumn({id: 'name', title: this.tr.instant('Organizations.Name'), type: DisplayType.Text}),
      new TableColumn({id: 'uid', title: this.tr.instant('Organizations.Uid'), type: DisplayType.Text})
    ];

    const dataProvider = new OrganizationsDataProvider(this.organizationApi);
    this.tableDefinition = new ClientSideSearchTableDefinition('organizations', tableColumns, rowActions, 'uid', 'organizations', '/organizations/:id', dataProvider);

      }
      createOrganization(): void {
        this.router.navigateByUrl('/organizations/create');
      }
  }

  export class EditOrganizationAction implements IRowSingleAction {
    name = 'Edit organization';
    icon = 'edit';
  
    onClick(rowId: string): Observable<RowActionResult> {
      return of(new RowActionResult(false, `/organizations/${rowId}`));
    }
  }

  export class OrganizationsDataProvider implements ClientSideSearchDataProvider {
    constructor(private organizationApi: OrganizationsApiService) {
    }
  
    getData(): Observable<any[]> {
      return this.organizationApi.getOrganizations();
    }
  }