
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Catalogue } from "@struct/models/struct/app/domain/catalogues/catalogue";
import { ProductStructure } from "@struct/models/struct/app/domain/dataconfiguration/product-structure";
import { PublicationBuild } from "@struct/models/struct/app/domain/publications/build/publication-build";
import { PublicationBuildRequest } from "@struct/models/struct/app/domain/publications/build/publication-build-request";
import { PublicationBuildResult } from "@struct/models/struct/app/domain/publications/build/publication-build-result";
import { PIMItem } from "@struct/models/struct/app/domain/publications/pIM-item";
import { PreviewData } from "@struct/models/struct/app/domain/publications/preview-data";
import { PreviewResult } from "@struct/models/struct/app/domain/publications/preview-result";
import { PrintPublication } from "@struct/models/struct/app/domain/publications/print-publication";
import { PublicationTemplate } from "@struct/models/struct/app/domain/publications/publication-template";
import { CategoryReferenceModel } from "@struct/models/struct/app/frontendapi/models/publication/category-reference-model";
import { CopyTemplateModel } from "@struct/models/struct/app/frontendapi/models/publication/copy-template-model";
import { PreviewItemModel } from "@struct/models/struct/app/frontendapi/models/publication/preview-item-model";
import { PrintPublicationReferenceModel } from "@struct/models/struct/app/frontendapi/models/publication/print-publication-reference-model";
import { ValidateTemplateModel } from "@struct/models/struct/app/frontendapi/models/publication/validate-template-model";

@Injectable({ providedIn: "root" })
export class PublicationsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getTemplates(enabledOnly: boolean): Observable<PublicationTemplate[]> {
    return this.http.get<PublicationTemplate[]>(`${this.apiUrl}publications/templates?enabledOnly=${enabledOnly}`, this.httpOptions );
  }

  getTemplate(templateUid: string): Observable<PublicationTemplate> {
    return this.http.get<PublicationTemplate>(`${this.apiUrl}publications/template/${templateUid}`, this.httpOptions );
  }

  deleteTemplate(templateUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/deletetemplate?templateUid=${templateUid}`, null, this.httpOptions);
  }

  createTemplate(model: PublicationTemplate): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/createtemplate`, model, this.httpOptions);
  }

  updateTemplate(model: PublicationTemplate): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/updatetemplate`, model, this.httpOptions);
  }

  copyTemplate(model: CopyTemplateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/copytemplate`, model, this.httpOptions);
  }

  getProductStructures(): Observable<ProductStructure[]> {
    return this.http.get<ProductStructure[]>(`${this.apiUrl}publications/productstructures`, this.httpOptions );
  }

  getProductCatalogues(): Observable<Catalogue[]> {
    return this.http.get<Catalogue[]>(`${this.apiUrl}publications/productcatalogues`, this.httpOptions );
  }

  postStartBuild(model: PublicationBuildRequest): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}publications/startbuild`, model, this.httpOptions);
  }

  downloadBuild(fileResult: PublicationBuild): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/downloadbuild`, fileResult, this.httpOptions);
  }

  getBackgroundTaskFiles(taskUid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}publications/backgroundtaskfiles?taskUid=${taskUid}`, this.httpOptions );
  }

  loadData(model: PreviewData): Observable<PreviewData> {
    return this.http.post<PreviewData>(`${this.apiUrl}publications/loaddata`, model, this.httpOptions);
  }

  loadPreview(model: PreviewData): Observable<PreviewResult> {
    return this.http.post<PreviewResult>(`${this.apiUrl}publications/loadpreview`, model, this.httpOptions);
  }

  customizeBuild(model: PublicationBuildRequest): Observable<PIMItem[]> {
    return this.http.post<PIMItem[]>(`${this.apiUrl}publications/customizebuild`, model, this.httpOptions);
  }

  downloadAsRendered(model: PreviewData): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/downloadasrendered`, model, this.httpOptions);
  }

  downloadFile(build: PublicationBuildResult): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/downloadfile`, build, this.httpOptions);
  }

  validateTemplate(model: ValidateTemplateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/validatetemplate`, model, this.httpOptions);
  }

  getSystemFonts(): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}publications/systemfonts`, this.httpOptions );
  }

  validateLicense(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}publications/validatelicense`, this.httpOptions );
  }

  getPageSizes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}publications/pagesizes`, this.httpOptions );
  }

  previewItem(model: PreviewItemModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/previewitem`, model, this.httpOptions);
  }

  getTemplatesByItem(model: PreviewItemModel): Observable<PublicationTemplate[]> {
    return this.http.post<PublicationTemplate[]>(`${this.apiUrl}publications/templatesbyitem`, model, this.httpOptions);
  }

  getPrintPublicationReferences(): Observable<PrintPublicationReferenceModel[]> {
    return this.http.get<PrintPublicationReferenceModel[]>(`${this.apiUrl}publications/printpublicationreferences`, this.httpOptions );
  }

  getCategoryReferences(): Observable<CategoryReferenceModel[]> {
    return this.http.get<CategoryReferenceModel[]>(`${this.apiUrl}publications/categoryreferences`, this.httpOptions );
  }

  getPrintPublication(uid: string): Observable<PrintPublication> {
    return this.http.get<PrintPublication>(`${this.apiUrl}publications/printpublication/${uid}`, this.httpOptions );
  }

  createPrintPublication(printPublication: PrintPublication): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/createprintpublication`, printPublication, this.httpOptions);
  }

  updatePrintPublication(printPublication: PrintPublication): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/updateprintpublication`, printPublication, this.httpOptions);
  }

  deletePrintPublication(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}publications/deleteprintpublication?uid=${uid}`, this.httpOptions );
  }

  exportTemplate(templateUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}publications/exporttemplate?templateUid=${templateUid}`, null, this.httpOptions);
  }

  importTemplate(file: FormData): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}publications/importtemplate`, file, this.httpOptions);
  }

  getBuildStatus(taskUid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}publications/buildstatus?taskUid=${taskUid}`, this.httpOptions );
  }
}

