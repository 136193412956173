import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'accounts-form-section-title',
  templateUrl: './accounts-form-section-title.component.html',
  styleUrls: ['./accounts-form-section-title.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AccountsFormSectionTitleComponent {
 
}
