// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISignInRequest  {
  email: string;
  password: string;
}

export class SignInRequest  implements ISignInRequest {
  email = "";

  password = "";


  constructor(data?: Partial<ISignInRequest>) {
    Object.assign(this, data);
  }
}