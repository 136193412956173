<struct-simple-select [(ngModel)]="value"
                      [name]="name"
                      bindValue="id"
                      bindLabel="text"
                      (ngModelChange)="valueChanged($event)"
                      [placeholder]="'Shared.EntityTypePicker.EntityType' | translate"
                      [options]="entityTypes"
                      [multiple]="multiple"
                      [disabled]="disabled"
                      [required]="required">
