import { Component, Input } from '@angular/core';
import {isObservable, Observable, of} from 'rxjs';
import { ContentHeaderBreadcrumb, ContentHeaderBreadcrumbItem } from './Breadcrumb';

@Component({
  selector: 'struct-app-content-header',
  templateUrl: './struct-app-content-header.component.html',
  styleUrls: ['./struct-app-content-header.component.scss'],
})
export class StructAppContentHeaderComponent {
  @Input()
  goBackToRoute: string | null = null;

  @Input()
  title: Observable<string> | string | null = null;

  @Input()
  breadcrumb: ContentHeaderBreadcrumb | null = null;

  @Input()
  addShortcut: boolean = false;

  @Input()
  icon: string | null = null;

  @Input()
  iconColor: string | null = null;

  @Input()
  hasTabs: boolean = false;

  breadcrumbClicked(item: ContentHeaderBreadcrumbItem) {
    if (item.action) {
      item.action();
    }
  }

  getLabel(item: ContentHeaderBreadcrumbItem): Observable<string | null>  {
    return isObservable(item.label) ? item.label : of(item.label);

  }
}
