
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MyTenantCloneTenantCommand } from "@struct/models/accountmanagement/domain/mytenants/commands/my-tenant-clone-tenant-command";
import { MyTenantCreateTenantCommand } from "@struct/models/accountmanagement/domain/mytenants/commands/my-tenant-create-tenant-command";
import { MyTenantsModel } from "@struct/models/accountmanagement/domain/mytenants/my-tenants-model";
import { ProvisionStatusMessage } from "@struct/models/accountmanagement/domain/tenants/provision-status-message";
import { MyTenantSetStateCommand } from "@struct/models/struct/accountmanagement/domain/mytenants/commands/my-tenant-set-state-command";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";

@Injectable({ providedIn: "root" })
export class MyTenantApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getTenants(): Observable<MyTenantsModel[]> {
    return this.http.get<MyTenantsModel[]>(`${this.apiUrl}mytenants`, this.httpOptions );
  }

  setState(cmd: MyTenantSetStateCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}mytenants/setstate`, cmd, this.httpOptions);
  }

  cloneTenant(cmd: MyTenantCloneTenantCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}mytenants/clone`, cmd, this.httpOptions);
  }

  getTenantLogs(tenantUid: string): Observable<ProvisionStatusMessage[]> {
    return this.http.get<ProvisionStatusMessage[]>(`${this.apiUrl}mytenants/logs?tenantUid=${tenantUid}`, this.httpOptions );
  }

  createTenant(cmd: MyTenantCreateTenantCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}mytenants/create`, cmd, this.httpOptions);
  }
}

