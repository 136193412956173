// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountRole } from "@struct/models/struct/shared/models/account-role";
import { ApiUser } from "@struct/models/struct/shared/models/api-user";
import { IApiUser } from "@struct/models/struct/shared/models/i-api-user";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAnonymousUser extends ApiUser {
}

export class AnonymousUser extends ApiUser implements IAnonymousUser {
  override email = "";

  override name = "";

  override id = "";

  override uid = "";

  override fullName = "";

  override accountRole: AccountRole = AccountRole.User;


  constructor(data?: Partial<IAnonymousUser>) {
    super(data);
    Object.assign(this, data);
  }
}