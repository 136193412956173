<section class="w-full" [ngClass]="showMarketing ? 'bg-white' : 'bg-gray-50'">

  <!-- Grid -->
  <div class="h-screen w-full grid" [ngClass]="showMarketing ? 'grid-cols-2' : 'grid-cols-1'">

    <!-- First column -->
     <div class="flex flex-col h-full">
      <div class="p-4 flex flex-grow-0 border-b border-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-8 h-8 text-blue-200">
          <g>
            <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path>
            <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path>
            <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path>
          </g>
        </svg>
      </div>

      <div class="flex items-center justify-center flex-grow">

        <div class="p-4 lg:p-12 w-full lg:max-w-[500px] items-center relative @container/workarea bg-white rounded" [ngClass]="showMarketing ? '' : 'shadow-xl'">
          <div class="absolute inset-2 z-10 bg-white/90 flex items-center justify-center" *ngIf="working">
            <struct-spinner></struct-spinner>
          </div>
          <div class="flex flex-col w-full">
            <div *ngIf="!showSuccess">

              <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8">{{"ResetPassword.Title" | translate}}</h1>

              <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate (ngSubmit)="resetPassword()">

                <struct-form-layout>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"ResetPassword.Password" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"ResetPassword.PasswordDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"ResetPassword.Password" | translate}}</mat-label>
                          <input matInput formControlName="password" [type]="passwordVisible ? 'text' : 'password'" />
                          <div matSuffix class="mr-1">
                            <struct-icon-button
                              icon="hide"
                              size="small"
                              iconSize="12"
                              *ngIf="passwordVisible"
                              (click)="togglePasswordVisibility($event)"
                              #tooltip="matTooltip"
                              matTooltip="{{'ResetPassword.HidePassword' | translate}}"
                              [matTooltipPosition]="'below'"
                            ></struct-icon-button>

                            <struct-icon-button
                              icon="view"
                              size="small"
                              iconSize="12"
                              *ngIf="!passwordVisible"
                              (click)="togglePasswordVisibility($event)"
                              #tooltip="matTooltip"
                              matTooltip="{{'ResetPassword.ViewPassword' | translate}}"
                              [matTooltipPosition]="'below'"
                            ></struct-icon-button>
                          </div>
                          <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">{{"ResetPassword.PasswordIsRequired" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="true">
                    <struct-form-row-headline>{{"RegisterUser.ConfirmPassword" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.ConfirmPasswordDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.ConfirmPassword" | translate}}</mat-label>
                          <input matInput formControlName="confirmPassword" [type]="passwordConfirmVisible ? 'text' : 'password'" />
                          <div matSuffix class="mr-1">
                            <struct-icon-button
                              icon="hide"
                              size="small"
                              iconSize="12"
                              *ngIf="passwordConfirmVisible"
                              (click)="togglePasswordConfirmVisibility($event)"
                              #tooltip="matTooltip"
                              matTooltip="{{'ResetPassword.HidePassword' | translate}}"
                              [matTooltipPosition]="'below'"
                            ></struct-icon-button>

                            <struct-icon-button
                              icon="view"
                              size="small"
                              iconSize="12"
                              *ngIf="!passwordConfirmVisible"
                              (click)="togglePasswordConfirmVisibility($event)"
                              #tooltip="matTooltip"
                              matTooltip="{{'ResetPassword.ViewPassword' | translate}}"
                              [matTooltipPosition]="'below'"
                            ></struct-icon-button>
                          </div>
                          <mat-error *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required')">{{"ResetPassword.ConfirmPasswordIsRequired" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                </struct-form-layout>

                <div *ngIf="showError" class="bg-red-100 text-red-500 text-sm mb-4 p-4 flex gap-4 items-center">
                  <div>
                    <struct-icon [useMaterialIcons]="true" icon="error" class="text-red-500"></struct-icon>
                  </div>
                  <div>
                  <div>
                    {{errorMessage | translate}}
                  </div>
                </div>
                </div>

                <div class="flex items-center w-full">
                  <button mat-flat-button color="primary" [disabled]="!resetPasswordForm.valid" type="submit" class="w-full">{{"ResetPassword.Submit" | translate}}</button>
                </div>

              </form>
            </div>
          </div>
        </div>


      </div>

      <div class="p-4 flex flex-grow-0 border-t border-gray-100">
        <div class="">
          <div class="flex gap-4">
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Help" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Privacy" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Terms" | translate}}</a>
          </div>
        </div>
        <div class="ml-auto">
          <a class="text-sm text-gray-500 hover:underline hover:text-blue-500 cursor-pointer" (click)="toggleMarketingColumn()">Hide marketing</a>
        </div>
      </div>
     </div>
    <!-- First column -->

    <!-- Second column -->
    <struct-carousel class="usein flex w-full h-full relative bg-gray-50" *ngIf="showMarketing"
                 [itemCount]="1"
                 [itemsPerPage]="1"
                 [interval]="10000"
                 [gap]="0"
                 [freezePeriod]="5000"
                 [showHandles]="false"
                 [mainColor]="'--color-blue-200'"
                 [secondColor]="'--color-red-500'">
      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-blue-800">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-blue-900 rounded-xl w-48 h-48 animate-in slide-in-from-bottom-4 duration-1000"></div>
            <div class="bg-blue-900 rounded-xl w-48 h-48 animate-in slide-in-from-bottom-8 duration-1000"></div>
            <div class="bg-blue-900 rounded-xl w-48 h-48 animate-in slide-in-from-bottom-12 duration-1000"></div>
            <div class="bg-blue-900 rounded-xl w-48 h-48 animate-in slide-in-from-bottom-16 duration-1000"></div>
          </div>
          <div class="absolute top-1/2 left-1/4 -translate-y-1/2 -translate-x-1/2">
            <div class=" w-24 h-24 bg-blue-950 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl animate-in slide-in-from-bottom-20 duration-1000">
              AI
            </div>
          </div>

        </div>
        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-3xl">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000 mb-8">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>

    </struct-carousel>
  </div>
  <!-- Grid -->
</section>
<!-- Section: Split screen -->

<div *ngIf="showSuccess">
    <div class="card m-20">
        <div class="card-body">
        <h1>Your password has been reset</h1>
        Please <a [routerLink]="['/authentication/login']"> click here to log in. </a>
        </div>
    </div>
</div>