// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum OperationType {
  OverrideValue = "OverrideValue",
  PlusOrMinus = "PlusOrMinus",
  Replace = "Replace",
  Add = "Add",
  Remove = "Remove",
  StringReplace = "StringReplace",
  Multiply = "Multiply",
  Divide = "Divide",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace OperationType {
  export function toString(value: OperationType): string {
    return OperationType[value];
  }

  export const values = (): OperationType[] => [
    OperationType.OverrideValue,
    OperationType.PlusOrMinus,
    OperationType.Replace,
    OperationType.Add,
    OperationType.Remove,
    OperationType.StringReplace,
    OperationType.Multiply,
    OperationType.Divide,
  ];

  export function parse(value: string, ignoreCase?: boolean): OperationType | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'OperationType';
  }
}
