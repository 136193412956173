<div class="z-[2] mt-4 w-16 h-full flex flex-col justify-start items-center @10xl/content-container:hidden cursor-pointer"
  matTooltip="Show/hide meta information" matTooltipPosition="left"
  [ngClass]="showMetaInformation ? 'bg-gray-200' : 'bg-gray-200/50'"
  (click)="showMetaInformation = !showMetaInformation"
>
  <div class="mb-16 mt-2">
    <struct-icon icon="info" *ngIf="!showMetaInformation" class="text-gray-600"></struct-icon>
    <struct-icon icon="remove" *ngIf="showMetaInformation" class="text-gray-600"></struct-icon>
  </div>
  <a class="transform rotate-90 font-medium whitespace-nowrap cursor-pointer flex items-center gap-2 leading-none"><span class="text-gray-400 animate-in slide-in-from-top-16">{{'MetaInformationSidebar.Title' | translate}}</span></a>
</div>

<aside class="w-0 overflow-hidden h-full z-[2] pb-16 @10xl/content-container:pb-20 @10xl/content-container:w-96 @10xl/content-container:flex-col @10xl/content-container:flex @10xl/content-container:order-last"
  [ngClass]="showMetaInformation ? 'flex absolute -left-96 top-4 w-96 bg-gray-200 shadow-2xl' : ''">
    <section class="overflow-y-auto p-4 flex-grow relative @10xl/content-container:pl-0 animate-in slide-in-from-bottom-4">
      <div class="flex space-y-4 flex-col justify-between h-full @10xl/content-container:flex">
          <ng-content class="flex flex-col flex-grow h-full"></ng-content>

          <!-- <section *ngIf="illustration !== null">
              <div class="w-full h-48 block overflow-hidden aspect-w-3 aspect-h-3 border-b border-gray-100 sticky bottom-0">
                  <img [src]="illustration" />
              </div>
          </section> -->
      </div>
    </section>
</aside>
