import { FileHelper } from "../directives/file-helper";

export class AssetRenderingComponent{
    public videoEnter(event: MouseEvent, row: any) {
      const target = event.target as HTMLVideoElement;
      target.play();
      row.videoPlaying = true;
    }
  
    public videoLeave(event: MouseEvent, row: any) {
      const relatedTarget = event.relatedTarget as HTMLElement;
      if(relatedTarget?.id === 'card-image-label' || relatedTarget?.id === 'card-item-checkbox') {
        return;
      }
      const target = event.target as HTMLVideoElement;
      target.pause();
      target.currentTime = 0;
      row.videoPlaying = false;
    }
  
    public isRenderableImage(fileExtension: string | null): boolean {
      return FileHelper.isRenderableImage(fileExtension);
    }
  
    public isRenderableVideo(fileExtension: string | null): boolean {
      return FileHelper.isRenderableVideo(fileExtension);
    }
  
    public isPdf(fileExtension: string | null): boolean {
      return FileHelper.isPdf(fileExtension);
    }
    
    public isOfficeDocument(fileExtension: string | null): boolean {
      return FileHelper.isOfficeDocument(fileExtension);
    }
  
    public isUnknownFile(fileExtension: string | null): boolean {
      return !this.isRenderableImage(fileExtension) && !this.isRenderableVideo(fileExtension) && !this.isPdf(fileExtension) && !this.isOfficeDocument(fileExtension);
    }
  }