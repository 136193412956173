import {
  DateQueryOperator,
  NumberQueryOperator,
  TextQueryOperator
} from "@struct/models/struct/shared/search/datafieldqueries";
import {BooleanOperator} from "@struct/models/struct/shared/search";

const containsAlphaNumeric = (str: string) => /[a-z0-9 ]+$/gi.test(str);

export class QueryOperatorSplit {
  prefix = '';
  suffix = '';
  operator: TextQueryOperator | NumberQueryOperator | null = null;
}

//|  BooleanQuery
export function determineQueryOperator(str: string): TextQueryOperator | NumberQueryOperator | DateQueryOperator  |null {
  const singleChar = str.substring(0, 1);
  const twoChars = str.substring(0, 2);
  const operator = !containsAlphaNumeric(twoChars) ? twoChars : !containsAlphaNumeric(singleChar) ? singleChar : null;
  switch (operator) {
    case "!*":
      return TextQueryOperator.NotContains;
    case "!\"":
      return TextQueryOperator.NotEquals;
    case "\"\"":
      return TextQueryOperator.IsEmpty;
    case "\"":
      return TextQueryOperator.Equals;
    case "*":
      return TextQueryOperator.Contains;
    case "!":
      return TextQueryOperator.NotStartsWith;
    case ">":
      return NumberQueryOperator.LargerThan;
    case "<":
      return NumberQueryOperator.SmallerThan;
    case ">=":
      return NumberQueryOperator.LargerThanOrEqualTo;
    case "<=":
      return NumberQueryOperator.SmallerThanOrEqualTo;
    default:
      return null;

  }
}

export function mapToQueryOperator(operator: string | TextQueryOperator | NumberQueryOperator | DateQueryOperator | BooleanOperator): QueryOperatorSplit | null {
  const queryOperatorSplit = new QueryOperatorSplit();
  if (operator as TextQueryOperator) {
    queryOperatorSplit.operator = operator as TextQueryOperator;
    switch (operator) {
      case queryOperatorSplit.operator ? TextQueryOperator.NotStartsWith : "NotStartsWith": {
        queryOperatorSplit.prefix = "!";
        break;
      }
      case queryOperatorSplit.operator ? TextQueryOperator.Equals : "Equals" : {
        queryOperatorSplit.prefix = "\"";
        queryOperatorSplit.suffix = "\"";
        break;
      }
      case queryOperatorSplit.operator ? TextQueryOperator.NotEquals : "NotEquals" : {
        queryOperatorSplit.prefix = "!\"";
        queryOperatorSplit.suffix = "\"";
        break;
      }
      case queryOperatorSplit.operator ?  TextQueryOperator.IsEmpty : "IsEmpty": {
        queryOperatorSplit.prefix = "\"";
        queryOperatorSplit.suffix = "\"";
        break;
      }
      case queryOperatorSplit.operator ?  TextQueryOperator.IsNotEmpty : "IsNotEmpty": {
        queryOperatorSplit.prefix = "!\"";
        queryOperatorSplit.suffix = "\"";
        break;
      }
      case queryOperatorSplit.operator ? TextQueryOperator.Contains : "Contains": {
        queryOperatorSplit.prefix = "*";
        break;
      }
      case queryOperatorSplit.operator ? TextQueryOperator.NotContains : "NotContains": {
        queryOperatorSplit.prefix = "!*";
        break;
      }
    }
  } else if (operator as NumberQueryOperator) {
    queryOperatorSplit.operator = operator as NumberQueryOperator;
    switch (operator) {
      case queryOperatorSplit.operator ? NumberQueryOperator.LargerThan : "LargerThan": {
        queryOperatorSplit.prefix = ">";
        break;
      }
      case queryOperatorSplit ? NumberQueryOperator.SmallerThan : "SmallerThan": {
        queryOperatorSplit.prefix = "<";
        break;
      }
      case queryOperatorSplit ? NumberQueryOperator.LargerThanOrEqualTo : "LargerThanOrEqualTo": {
        queryOperatorSplit.prefix = ">=";
        break;
      }
      case queryOperatorSplit ? NumberQueryOperator.SmallerThanOrEqualTo : "SmallerThanOrEqualTo": {
        queryOperatorSplit.prefix = "<=";
        break;
      }
    }
  }
  return queryOperatorSplit;
}

