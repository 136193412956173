
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateWebhookCommand } from "@struct/models/struct/app/domain/webhooks/commands/create-webhook-command";
import { UpdateWebhookCommand } from "@struct/models/struct/app/domain/webhooks/commands/update-webhook-command";
import { Webhook } from "@struct/models/struct/app/domain/webhooks/webhook";
import { WebhookEvent } from "@struct/models/struct/app/domain/webhooks/webhook-event";
import { WebhookRequest } from "@struct/models/struct/app/domain/webhooks/webhook-request";
import { WebhookRequestSlim } from "@struct/models/struct/app/domain/webhooks/webhook-request-slim";
import { WebhookRequestsPagedSearchModel } from "@struct/models/struct/app/frontendapi/models/webhook/webhook-requests-paged-search-model";

@Injectable({ providedIn: "root" })
export class WebhookApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getWebhooks(): Observable<Webhook[]> {
    return this.http.get<Webhook[]>(`${this.apiUrl}webhooks`, this.httpOptions );
  }

  getWebhook(uid: string): Observable<Webhook> {
    return this.http.get<Webhook>(`${this.apiUrl}webhooks/${uid}`, this.httpOptions );
  }

  getLatestWebhookRequests(webhookUid: string, page: number, pageSize: number): Observable<WebhookRequestsPagedSearchModel> {
    return this.http.get<WebhookRequestsPagedSearchModel>(`${this.apiUrl}webhooks/${webhookUid}/requests?page=${page}&pageSize=${pageSize}`, this.httpOptions );
  }

  getLatestWebhookRequestsList(webhookUid: string): Observable<WebhookRequestSlim[]> {
    return this.http.get<WebhookRequestSlim[]>(`${this.apiUrl}webhooks/${webhookUid}/requestslist`, this.httpOptions );
  }

  getWebhookRequest(uid: string): Observable<WebhookRequest | null> {
    return this.http.get<WebhookRequest | null>(`${this.apiUrl}webhooks/request/${uid}`, this.httpOptions );
  }

  createWebhook(command: CreateWebhookCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}webhooks`, command, this.httpOptions);
  }

  updateWebhook(command: UpdateWebhookCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}webhooks`, command, this.httpOptions);
  }

  deleteWebhook(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}webhooks/${uid}`, this.httpOptions );
  }

  getWebhookEvents(): Observable<WebhookEvent[] | null> {
    return this.http.get<WebhookEvent[] | null>(`${this.apiUrl}webhooks/events`, this.httpOptions );
  }

  getWebhookEventsRequestDataExamples(requestType: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${this.apiUrl}webhooks/events/request-data/${requestType}/examples`, this.httpOptions );
  }
}

