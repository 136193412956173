
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LanguageModel } from "@struct/models/accountmanagement/domain/models/language-model";
import { CultureModel } from "@struct/models/struct/accountmanagement/domain/models/culture-model";

@Injectable({ providedIn: "root" })
export class LanguageApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getLanguages(): Observable<LanguageModel[]> {
    return this.http.get<LanguageModel[]>(`${this.apiUrl}languages`, this.httpOptions );
  }

  getCultures(): Observable<CultureModel[]> {
    return this.http.get<CultureModel[]>(`${this.apiUrl}cultures`, this.httpOptions );
  }
}

