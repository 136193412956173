import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StructSpinnerService {
    // Observable source
    private _showSpinner = new BehaviorSubject<boolean>(false);
    // Observable stream
    showSpinner$ = this._showSpinner.asObservable();
    // service command
    changeShowSpinner(value : boolean) {
        this._showSpinner.next(value);
    }
}