// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum SubscriptionRole {
  Owner = "Owner",
  Manager = "Manager",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace SubscriptionRole {
  export function toString(value: SubscriptionRole): string {
    return SubscriptionRole[value];
  }

  export const values = (): SubscriptionRole[] => [
    SubscriptionRole.Owner,
    SubscriptionRole.Manager,
  ];

  export function parse(value: string, ignoreCase?: boolean): SubscriptionRole | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'SubscriptionRole';
  }
}
