// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from "angular-auth-oidc-client";



export const environment = {
  appInsights: {
    instrumentationKey: '43e51863-fe36-4665-af4d-f87007e68d47'
  },
  production: false,
  accountManagementApiUrl : "https://api.accounts.structstage.com/",
  accountAppUrl: 'https://accounts.structstage.com',
  tenantAppUrl: 'https://{tenant}.app.structstage.com',
  entraId: {
    clientId: "185210aa-6636-46ac-b1ff-ac713a16bb09",
    redirectUrl: "https://accounts.structstage.com/sign-in-with-microsoft-callback",
    createAccountRedirectUrl: "https://accounts.structstage.com/register-with-microsoft-callback",
    changeSignInRedirectUrl: "https://accounts.structstage.com/change-sign-in-to-microsoft-callback",
    tokenEndpoint: "https://login.microsoftonline.com/fa720019-6ac0-45bf-956a-1b1bac1b1b71/oauth2/v2.0/token",
    tenantId: "fa720019-6ac0-45bf-956a-1b1bac1b1b71",
    scope: "api://185210aa-6636-46ac-b1ff-ac713a16bb09/User.Read"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
