// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IErrorDetailsModel  {
  statusCode: number;
  message: string;
  isDomainException: boolean;
}

export class ErrorDetailsModel  implements IErrorDetailsModel {
  statusCode = 0;

  message = "";

  isDomainException = false;


  constructor(data?: Partial<IErrorDetailsModel>) {
    Object.assign(this, data);
  }
}