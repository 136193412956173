import {Component, OnInit} from '@angular/core';
import {IRowAction} from '@struct/ui/struct-table/models/row-action';
import {RowActionResult} from '@struct/ui/struct-table/models/row-action-result';
import {IRowSingleAction} from '@struct/ui/struct-table/models/row-single-action';
import {TableColumn} from '@struct/ui/struct-table/models/table-column';
import {ITableDefinition} from '@struct/ui/struct-table/models/table-definition';
import {Observable, of} from 'rxjs';
import {Row} from '@struct/ui/struct-table/models/row';
import {CommonModule} from '@angular/common';
import {UiModule} from '@struct/ui/ui.module';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {DisplayType, Query} from "@struct/models/struct/shared/search";
import {UsersApiService} from "@struct/services/account-management";
import { TranslateService } from '@ngx-translate/core';
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition } from '@struct/ui/struct-table';
import { UserSearchModel } from '@struct/models/struct/accountmanagement/api/models/users/user-search-model';
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from '@struct/ui/struct-dialog/StructDialogConfig';
import { InviteAdminUserDialogComponent } from '../invite-admin-user-dialog/invite-admin-user-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'accounts-users-list',
  templateUrl: './users-list.component.html',
  standalone: true,
  imports: [CommonModule, UiModule, AccountsHeaderComponent],
})
export class UsersListComponent implements OnInit {
  users: UserSearchModel[] | null = null;
  rows: Row[] = [];
  tableDefinition: ITableDefinition | null = null;
  tableScope = 'tenants';
  filter: Query | null = null;
  rowRouteTemplate: string | null = null;

  constructor(private userApi: UsersApiService, private tr : TranslateService, private dialogService: MatDialog) {}
  
  ngOnInit(): void {
    const rowActions: IRowAction[] = [new EditUserAction()];
    this.tr
      .get([
        'Users.FirstName',
        'Users.LastName',
        'Users.Role',
        'Users.Organization',
        "Users.TwoFactorEnabled",
        "Users.LockedOut",
        "Users.LockoutEnd",
        "Users.EmailConfirmed",
        "Users.PhoneNumber",
        "Users.PhoneNumberConfirmed",
      ])
      .subscribe(t => {
        const tableColumns = [
          new TableColumn({id: 'id', title: 'Id', type: DisplayType.Text}),
          new TableColumn({id: 'firstName', title: t['Users.FirstName'], type: DisplayType.Text}),
          new TableColumn({id: 'LastName', title: t['Users.LastName'], type: DisplayType.Text}),
          new TableColumn({id: 'role', title: t['Users.Role'], type: DisplayType.Text}),
          new TableColumn({id: 'organization', title: t['Users.Organization'], type: DisplayType.Text}),
          new TableColumn({id: 'twoFactorEnabled', title: t['Users.TwoFactorEnabled'], type: DisplayType.Boolean}),
          new TableColumn({id: 'lockedOut', title: t['Users.LockedOut'], type: DisplayType.Boolean}),
          new TableColumn({id: 'lockoutEnd', title: t['Users.LockoutEnd'], type: DisplayType.Date}),
          new TableColumn({id: 'emailConfirmed', title: t['Users.EmailConfirmed'], type: DisplayType.Boolean}),
          new TableColumn({id: 'phoneNumber', title: t['Users.PhoneNumber'], type: DisplayType.Text}),
          new TableColumn({id: 'phoneNumberConfirmed', title: t['Users.PhoneNumberConfirmed'], type: DisplayType.Boolean})
        ];
        const dataProvider = new UsersDataProvider(this.userApi);
        this.tableDefinition = new ClientSideSearchTableDefinition('users', tableColumns, rowActions, 'id', 'users', '/users/:id', dataProvider);
      });

    }

    inviteAdminUser(): void {
      const dialogConfig = new StructDialogConfig();
      dialogConfig.position = StructDialogPosition.Center;
      dialogConfig.width = StructDialogWidth.Quarter;

      const config = new MatDialogConfig();
      config.width = '500px';
      config.height = '500px';
      this.dialogService.open(InviteAdminUserDialogComponent, config).afterClosed().subscribe(x => console.log(x));
    }
}

export class UsersDataProvider implements ClientSideSearchDataProvider {
  constructor(private userApi: UsersApiService) {
  }

  getData(): Observable<any[]> {
    return this.userApi.getUsers();
  }
}

export class EditUserAction implements IRowSingleAction {
  name = 'Edit user';
  icon = 'edit';

  onClick(rowId: string): Observable<RowActionResult> {
    return of(new RowActionResult(false, `/users/${rowId}`));
  }
}
