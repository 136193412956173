// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum AuthenticationMethod {
  UsernamePassword = "UsernamePassword",
  Microsoft = "Microsoft",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace AuthenticationMethod {
  export function toString(value: AuthenticationMethod): string {
    return AuthenticationMethod[value];
  }

  export const values = (): AuthenticationMethod[] => [
    AuthenticationMethod.UsernamePassword,
    AuthenticationMethod.Microsoft,
  ];

  export function parse(value: string, ignoreCase?: boolean): AuthenticationMethod | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'AuthenticationMethod';
  }
}
