
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateAttributeGroupCommand } from "@struct/models/struct/app/domain/attributes/commands/attributegroups/create-attribute-group-command";
import { SortAttributeGroupsCommand } from "@struct/models/struct/app/domain/attributes/commands/attributegroups/sort-attribute-groups-command";
import { UpdateAttributeGroupCommand } from "@struct/models/struct/app/domain/attributes/commands/attributegroups/update-attribute-group-command";
import { AttributeGroup } from "@struct/models/struct/app/domain/attributes/attribute-group";
import { AttributeRepresentation } from "@struct/models/struct/app/domain/attributes/attribute-representation";
import { AttributeGroupSearchModel } from "@struct/models/struct/app/frontendapi/models/attributegroup/attribute-group-search-model";

@Injectable({ providedIn: "root" })
export class AttributeGroupsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAttributeGroups(languageId: number): Observable<AttributeGroupSearchModel[]> {
    return this.http.get<AttributeGroupSearchModel[]>(`${this.apiUrl}attributegroups?languageId=${languageId}`, this.httpOptions );
  }

  getAttributeGroup(uid: string): Observable<AttributeGroup> {
    return this.http.get<AttributeGroup>(`${this.apiUrl}attributegroups/${uid}`, this.httpOptions );
  }

  getAttributes(uid: string): Observable<AttributeRepresentation[]> {
    return this.http.get<AttributeRepresentation[]>(`${this.apiUrl}attributegroups/${uid}/attributes`, this.httpOptions );
  }

  createAttributeGroup(cmd: CreateAttributeGroupCommand): Observable<AttributeGroup | null> {
    return this.http.post<AttributeGroup | null>(`${this.apiUrl}attributegroups`, cmd, this.httpOptions);
  }

  updateAttributeGroup(cmd: UpdateAttributeGroupCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}attributegroups`, cmd, this.httpOptions);
  }

  deleteAttributeGroup(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}attributegroups/${uid}`, this.httpOptions );
  }

  sortAttributeGroups(command: SortAttributeGroupsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}attributegroups/sorting`, command, this.httpOptions);
  }
}

