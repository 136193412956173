// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountRole } from "@struct/models/struct/shared/models/account-role";
import { IApiUser } from "@struct/models/struct/shared/models/i-api-user";
// eslint-disable-next-line @typescript-eslint/no-empty-interface


export class ApiUser  implements IApiUser {
  email = "";

  name = "";

  id = "";

  uid = "";

  fullName = "";

  accountRole: AccountRole = AccountRole.User;


  constructor(data?: Partial<IApiUser>) {
    Object.assign(this, data);
  }
}