<div class="flex h-full flex-col overflow-hidden absolute top-0 left-0 w-full">
  <!-- Top nav-->
  <header
    class="relative flex h-20 flex-shrink-0 items-center bg-white border-b border-gray-200 z-40"
  >
    <!-- Desktop nav area -->
    <div
      class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2 md:px-4"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <struct-icon-button
          icon="chevron_left"
          #tooltip="matTooltip"
          matTooltip="Go back"
          [matTooltipPosition]="'below'"
        ></struct-icon-button>
        <h1 class="text-4xl font-medium">Nike Air Max 90</h1>
      </div>
      <div class="md:ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
        <div class="flex items-center space-x-4">
          <span class="inline-flex">
            <struct-icon-button
              icon="bookmark_add"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Add bookmark"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <button
              mat-button
              [matMenuTriggerFor]="menu_dimension"
              class="font-medium text-sm"
            >
              Desktop
            </button>
            <mat-menu #menu_dimension="matMenu" class="text-sm">
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="desktop_mac" size="16"></struct-icon>
                <span>Desktop</span>
              </button>
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="tablet_mac" size="16"></struct-icon>
                <span>Tablet</span>
              </button>
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="phone_iphone" size="16"></struct-icon>
                <span>Mobile</span>
              </button>
            </mat-menu>
          </span>

          <span class="inline-flex">
            <button
              mat-button
              [matMenuTriggerFor]="menu_language"
              class="font-medium text-sm"
            >
              English
            </button>
            <mat-menu #menu_language="matMenu" class="text-sm">
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="globe" size="16"></struct-icon>
                <span>English</span>
              </button>
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="globe" size="16"></struct-icon>
                <span>German</span>
              </button>
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="globe" size="16"></struct-icon>
                <span>Danish</span>
              </button>
              <button
                mat-menu-item
                class="text-sm flex items-center h-10 leading-tight"
              >
                <struct-icon icon="globe" size="16"></struct-icon>
                <span>French</span>
              </button>
            </mat-menu>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="translation"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Translate"
              [matTooltipPosition]="'below'"
              (click)="toggleTranslation()"
              [ngClass]="{
                'bg-gray-100 text-blue-500 rounded-full': translationActive
              }"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="actions"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Actions"
              [matTooltipPosition]="'below'"
              (click)="openActionsDialog()"
            ></struct-icon-button>
            <!-- <struct-icon-button icon="apps" #tooltip="matTooltip" matTooltip="Options" [matTooltipPosition]="'below'" [matMenuTriggerFor]="menu_options"></struct-icon-button>
            <mat-menu #menu_options="matMenu" class="text-sm">
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="content_copy" size="16"></struct-icon>
                <span>Copy</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="developer_board" size="16"></struct-icon>
                <span>Change product structure</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="archive" size="16"></struct-icon>
                <span>Archive</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="move_down" size="16"></struct-icon>
                <span>Move</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="delete_outline" size="16"></struct-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="playlist_add" size="16"></struct-icon>
                <span>Create variant</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="auto_fix_high" size="16"></struct-icon>
                <span>Generate variants</span>
              </button>
              <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                <struct-icon icon="sort" size="16"></struct-icon>
                <span>Sort variants</span>
              </button>
            </mat-menu> -->
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="revision_log"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Revision log"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="quick_actions"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Quick actions"
              [matTooltipPosition]="'below'"
              (click)="openQuickActionsDialog()"
            ></struct-icon-button>
          </span>
        </div>
      </div>
    </div>
  </header>

  <!-- Bottom section -->
  <div class="flex min-h-0 flex-1 overflow-hidden">
    <!-- Main area -->
    <main class="relative min-w-0 flex-1 bg-gray-50 lg:flex">
      <!-- Primary column -->
      <section
        aria-labelledby="primary-heading"
        class="flex h-full min-w-0 flex-1 flex-col lg:order-last"
      >
        <div
          class="hidden fixed w-8 mt-20 py-2 lg:flex lg:flex-col justify-center items-center flex-grow-0 z-10"
        >
          <div class="flex flex-col space-y-2 mt-4">
            <a
              href="#section_general_basic-information"
              class="block w-2 h-2 rounded-full bg-gray-200 hover:bg-blue-500 ring-0 ring-white hover:ring-blue-500"
              #tooltip="matTooltip"
              matTooltip="Basic information"
              [matTooltipPosition]="'left'"
            ></a>
            <a
              href="#section_general_delivery-information"
              class="block w-2 h-2 rounded-full bg-gray-200 hover:bg-blue-500 ring-0 ring-white hover:ring-blue-500"
              #tooltip="matTooltip"
              matTooltip="Delivery information"
              [matTooltipPosition]="'left'"
            ></a>
          </div>
        </div>
        <div
          class="relative struct-scrollbar flex-grow overflow-y-auto  [contain:content]">
          <!-- Tabs begin -->
          <mat-tab-group
            animationDuration="0ms"
            class="flex-grow"
            disableRipple
          >
            <mat-tab>
              <ng-template mat-tab-label> General </ng-template>
              <div class="p-2 sm:p-4 lg:p-8">
                <mat-accordion multi>
                  <mat-expansion-panel
                    expanded="true"
                    hideToggle
                    id="section_general_basic-information"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Basic information </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-4">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-12 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Id</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Internal id of the item
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2"
                          >
                            <span class="font-medium text-lg">74</span>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-12 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Primary Name</span>
                                <struct-icon
                                  icon="info_outline"
                                  size="12"
                                  #tooltip="matTooltip"
                                  matTooltip="This value is inherited to all variants of this product"
                                  [matTooltipPosition]="'below'"
                                ></struct-icon>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Primary name"
                                value="Nike Air Max 90"
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="-mt-4 relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >Nike Air Max 90</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Primary name"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Primary name"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Primary name"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Sales channels</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the sales channels this product shall be
                                available in
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Sales channel"
                                value=""
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                      ></span>
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Sales channel"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Sales channel"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Sales channel"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Model nr</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Model nr"
                                value="4445"
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >4445</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Model nr"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Model nr"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Model nr"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Variation definition</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the definition for how variants differ
                                from each other
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Variation definition"
                                value="Clothing w. color / clothe size variations"
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >Clothing w. color / clothe size
                                        variations</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Variation definition"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Variation definition"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Variation definition"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Brand</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Brand"
                                value="Nike"
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >Nike</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Brand"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Brand"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Brand"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Sales Unit</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the unit that sale quantity is measured
                                in
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Sales Unit"
                                value="Pcs"
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >Pcs</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Sales Unit"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Sales Unit"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Sales Unit"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Accounting group</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the appropriate accounting group
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Accounting group"
                                value=""
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                        >Pcs</span
                                      >
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Accounting group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Accounting group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Accounting group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>

                  <mat-expansion-panel
                    expanded="true"
                    hideToggle
                    id="section_general_delivery-information"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Delivery information </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Tax position no.</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add the tax position no.
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Tax position no."
                                value=""
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                      ></span>
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Tax position no."
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Tax position no."
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Tax position no."
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div
                          class="grid gap-4 grid-cols-12"
                          *ngIf="!translationActive"
                        >
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Dangerous goods</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select if this product is labelled as dangerous
                                goods, thus requiring special attention during
                                transport
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                            >
                              <mat-slide-toggle
                                color="primary"
                                checked="true"
                                disabled="false"
                              ></mat-slide-toggle>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Delivery group</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Delivery group"
                                value=""
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                      ></span>
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Delivery group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Delivery group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Delivery group"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Package size</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Set the gross size of the product (width x
                                length x height)
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6 grid grid-cols-12 gap-2"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-span-4">
                                <struct-input
                                  name="Width"
                                  value=""
                                  [required]="false"
                                >
                                  <struct-input-suffix
                                    ><span class=""
                                      >CM</span
                                    ></struct-input-suffix
                                  >
                                </struct-input>
                              </div>
                              <div class="col-span-4">
                                <struct-input
                                  name="Length"
                                  value=""
                                  [required]="false"
                                >
                                  <struct-input-suffix
                                    ><span class=""
                                      >CM</span
                                    ></struct-input-suffix
                                  >
                                </struct-input>
                              </div>
                              <div class="col-span-4">
                                <struct-input
                                  name="Height"
                                  value=""
                                  [required]="false"
                                >
                                  <struct-input-suffix
                                    ><span class=""
                                      >CM</span
                                    ></struct-input-suffix
                                  >
                                </struct-input>
                              </div>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div class="grid grid-cols-12 gap-2">
                                      <div class="col-span-4">
                                        <struct-input
                                          name="Width"
                                          value=""
                                          [required]="false"
                                          disabled="true"
                                        >
                                          <struct-input-suffix
                                            ><span class=""
                                              >CM</span
                                            ></struct-input-suffix
                                          >
                                        </struct-input>
                                      </div>
                                      <div class="col-span-4">
                                        <struct-input
                                          name="Length"
                                          value=""
                                          [required]="false"
                                        >
                                          <struct-input-suffix
                                            ><span class=""
                                              >CM</span
                                            ></struct-input-suffix
                                          >
                                        </struct-input>
                                      </div>
                                      <div class="col-span-4">
                                        <struct-input
                                          name="Height"
                                          value=""
                                          [required]="false"
                                        >
                                          <struct-input-suffix
                                            ><span class=""
                                              >CM</span
                                            ></struct-input-suffix
                                          >
                                        </struct-input>
                                      </div>
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <div class="grid grid-cols-12 gap-2">
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Width"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Length"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Height"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                      </div>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <div class="grid grid-cols-12 gap-2">
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Width"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Length"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Height"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                      </div>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <div class="grid grid-cols-12 gap-2">
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Width"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Length"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                        <div class="col-span-4">
                                          <struct-input
                                            name="Height"
                                            value=""
                                            [required]="false"
                                          >
                                            <struct-input-suffix
                                              ><span class=""
                                                >CM</span
                                              ></struct-input-suffix
                                            >
                                          </struct-input>
                                        </div>
                                      </div>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr class="border-gray-50" />

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Package weight</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                          >
                            <div
                              class="relative col-span-full lg:col-span-9 xl:col-span-6"
                              *ngIf="!translationActive"
                            >
                              <div
                                class="absolute -top-4 h-3 flex items-center justify-evenly w-full px-4"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <div class="flex items-center space-x-2">
                                    <img
                                      src="/assets/flags/uk.png"
                                      class="w-4 h-auto"
                                    />
                                    <span>English</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="globe"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>United Kingdom</span>
                                  </div>
                                  <div class="flex items-center space-x-2">
                                    <struct-icon
                                      icon="desktop_mac"
                                      size="12"
                                      class="w-4 h-auto"
                                    ></struct-icon>
                                    <span>Desktop</span>
                                  </div>
                                </div>
                              </div>
                              <struct-input
                                name="Package weight"
                                value=""
                                [required]="false"
                              ></struct-input>
                            </div>

                            <div
                              class="relative bg-white ring-1 ring-gray-100 rounded-lg overflow-hidden col-span-full"
                              *ngIf="translationActive"
                            >
                              <ul role="list" class="divide-y divide-gray-100">
                                <li
                                  class="flex items-center divide-x divide-gray-100 bg-white"
                                >
                                  <div class="p-2 w-48 text-gray-400 text-xs">
                                    <div class="flex items-center space-x-2">
                                      <img
                                        src="/assets/flags/uk.png"
                                        class="w-4 h-auto"
                                      />
                                      <span>English</span>
                                    </div>
                                  </div>
                                  <div
                                    class="px-2 flex-grow flex items-center justify-between"
                                  >
                                    <div>
                                      <span
                                        class="font-medium text-gray-700 truncate text-lg"
                                      ></span>
                                    </div>

                                    <div class="flex items-center">
                                      <struct-icon-button
                                        icon="expand"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Expand all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="google_translate"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Google translate all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="copy_to_all"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Copy to all"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                      <struct-icon-button
                                        icon="translation_revision"
                                        iconSize="16"
                                        #tooltip="matTooltip"
                                        matTooltip="Show revisions"
                                        [matTooltipPosition]="'below'"
                                      ></struct-icon-button>
                                    </div>
                                  </div>
                                </li>

                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't1'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't1'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/dk.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Danish</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Denmark</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't1'"
                                    >
                                      <struct-input
                                        name="Package weight"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't1'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't2'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't2'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/de.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>Germany</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>

                                    <div
                                      class="p-2 flex-grow"
                                      *ngIf="translationAccOpen == 't2'"
                                    >
                                      <struct-input
                                        name="Package weight"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't2'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li
                                  class="flex flex-col bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                  (click)="translationAccOpen = 't3'"
                                  [ngClass]="{
                                    '!bg-white hover:!bg-white !cursor-default':
                                      translationAccOpen === 't3'
                                  }"
                                >
                                  <div class="flex divide-x divide-gray-100">
                                    <div class="p-2 w-48 text-gray-400 text-xs">
                                      <div class="flex items-center space-x-2">
                                        <img
                                          src="/assets/flags/fr.png"
                                          class="w-4 h-auto"
                                        />
                                        <span>French</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="globe"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>International</span>
                                      </div>
                                      <div class="flex items-center space-x-2">
                                        <struct-icon
                                          icon="desktop_mac"
                                          size="12"
                                          class="w-4 h-auto"
                                        ></struct-icon>
                                        <span>Desktop</span>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow bg-white"
                                      *ngIf="translationAccOpen == 't3'"
                                    >
                                      <struct-input
                                        name="Package weight"
                                        [required]="false"
                                      ></struct-input>
                                      <div class="flex items-center">
                                        <struct-icon-button
                                          icon="google_translate"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Google translate"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="insert_source"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Insert source"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                        <struct-icon-button
                                          icon="translation_revision"
                                          iconSize="16"
                                          #tooltip="matTooltip"
                                          matTooltip="Show revisions"
                                          [matTooltipPosition]="'above'"
                                        ></struct-icon-button>
                                      </div>
                                    </div>
                                    <div
                                      class="p-2 flex-grow flex items-center"
                                      *ngIf="translationAccOpen != 't3'"
                                    >
                                      <span class="text-gray-400"
                                        >no translation</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Marketing">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Descriptions </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-12 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Highlight</span>
                                <struct-icon
                                  icon="info_outline"
                                  size="12"
                                  #tooltip="matTooltip"
                                  matTooltip="This value is inherited to all variants of this product"
                                  [matTooltipPosition]="'below'"
                                ></struct-icon>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select a highlighting for your product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2"
                          >
                            <div *ngIf="!translationActive">
                              <div
                                class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                              >
                                <div class="flex-grow"></div>
                                <div
                                  class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                                >
                                  <span>Desktop</span>
                                  <span>English</span>
                                </div>
                              </div>
                              <struct-input
                                name="Highlight"
                                [required]="false"
                              ></struct-input>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Short description</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add a short description of this product which
                                can be used as a teaser.
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Short description"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Model nr</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Model nr"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Variation definition</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the definition for how variants differ
                                from each other
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Variation definition"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Brand</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Brand"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Sales unit</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the unit that sale quantity is measured
                                in
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Sales unit"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Accounting group</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the appropriate accounting group
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Accounting group"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Technical specifications">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> General </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Brand</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600"></p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Brand"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Sales unit</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the unit that sale quantity is measured
                                in
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Sales unit"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Accounting group</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select the appropriate accounting group
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Accounting group"
                              [required]="false"
                            ></struct-input>
                          </div>

                          <div
                            class="bg-green-50/50 col-span-6 md:col-span-9 lg:col-span-5 relative p-2 rounded"
                            *ngIf="translationActive"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Accounting group"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Images & video">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Images </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Primary image</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                The primary image is the default image for this
                                product and is shown as a representative for the
                                product whenever the product is displayed Black
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <struct-icon-button
                                  icon="edit"
                                  class=""
                                ></struct-icon-button>
                                <struct-icon-button
                                  icon="delete"
                                  class=""
                                ></struct-icon-button>
                                <struct-icon-button
                                  icon="more_vert"
                                  class="ml-auto"
                                ></struct-icon-button>
                              </div>
                            </div>

                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-100 p-12 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-0 h-48"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class="text-gray-400"
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-600"
                                >Add image</span
                              >
                            </button>
                          </div>

                          <div
                            class="bg-green-50/50 col-span-6 md:col-span-9 lg:col-span-5 relative p-2 rounded"
                            *ngIf="translationActive"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Brand"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Extra product images</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add extra images of the product seen from
                                different angles or in different use cases
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/1352ee57-62f9-4b75-82ca-d294e36601c0/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/4bd8911c-21dd-4a75-b7ef-80bb416a3342/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/65c0e8e3-4776-4e1c-8403-0fe857862b8b/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/86f69036-a323-4675-be99-e054b870b252/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/5926d075-5a37-477a-bc16-001f80678e7e/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <div
                              class="relative block w-full rounded-lg border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-48 group"
                            >
                              <div class="aspect-w-3 aspect-h-3">
                                <img
                                  src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/178f8a9b-960b-492f-8a08-7ce536ecb3d3/air-max-90-shoes-qnqLqk.png"
                                  alt=""
                                  class="object-cover"
                                />
                              </div>
                              <div
                                class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 flex items-center justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                              >
                                <div
                                  class="translate-y-4 transition-transform duration-500 group-hover:translate-y-0"
                                >
                                  <struct-icon-button
                                    icon="edit"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="delete"
                                    class=""
                                  ></struct-icon-button>
                                  <struct-icon-button
                                    icon="more_vert"
                                    class="ml-auto"
                                  ></struct-icon-button>
                                </div>
                              </div>
                            </div>

                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-100 p-12 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-offset-0 h-48"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class="text-gray-400"
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-600"
                                >Add image</span
                              >
                            </button>
                          </div>

                          <div
                            class="bg-green-50/50 col-span-6 md:col-span-9 lg:col-span-5 relative p-2 rounded"
                            *ngIf="translationActive"
                          >
                            <div
                              class="absolute -top-2 h-3 flex items-center justify-evenly w-full px-6"
                            >
                              <div class="flex-grow"></div>
                              <div
                                class="flex-grow text-xs text-gray-400 flex items-center space-x-4 justify-end"
                              >
                                <span>Desktop</span>
                                <span>English</span>
                              </div>
                            </div>
                            <struct-input
                              name="Brand"
                              [required]="false"
                            ></struct-input>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>

                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Videos </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Videos</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add videos from Youtube or Vimeo
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add video</span
                              >
                            </button>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Documents">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Documents </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Documents</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add documents to your product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add document</span
                              >
                            </button>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Classifications">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Categories </mat-panel-title>
                      <mat-panel-description>
                        Place the product in one or more categories within your
                        product catalogues
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div
                          class="divide-y divide-gray-100 overflow-hidden rounded bg-white border border-gray-100"
                        >
                          <div class="px-4 py-5 sm:px-6">
                            <h3 class="font-medium text-base">
                              Product catalogues
                            </h3>
                          </div>
                          <div class="p-0 grid grid-cols-6 h-96">
                            <div
                              class="col-span-2 overflow-y-auto border-r border-gray-100"
                            >
                              <div
                                class="h-10 flex items-center px-4 py-2 sm:px-6 border-b border-gray-100 bg-white sticky top-0 z-10"
                              >
                                <h5 class="font-medium text-md text-gray-400">
                                  Catalogues
                                </h5>
                              </div>

                              <ul role="list" class="divide-y divide-gray-100">
                                <li>
                                  <a
                                    href=""
                                    class="block hover:bg-gray-50 border-r-2 border-blue-500"
                                  >
                                    <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center space-x-2">
                                        <div class="flex items-center">
                                          <struct-icon
                                            icon="folder_filled"
                                            size="16"
                                            class="text-yellow-500"
                                          ></struct-icon>
                                        </div>

                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            Internal catalogue
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>

                                <li>
                                  <a href="" class="block hover:bg-gray-50">
                                    <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center space-x-2">
                                        <div class="flex items-center">
                                          <struct-icon
                                            icon="folder_filled"
                                            size="16"
                                            class="text-yellow-500"
                                          ></struct-icon>
                                        </div>

                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            Electronics site
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>

                                <li>
                                  <a href="" class="block hover:bg-gray-50">
                                    <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center space-x-2">
                                        <div class="flex items-center">
                                          <struct-icon
                                            icon="folder_filled"
                                            size="16"
                                            class="text-yellow-500"
                                          ></struct-icon>
                                        </div>

                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            Wine Channel
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div
                              class="col-span-4 overflow-y-auto relative struct-scrollbar"
                            >
                              <div
                                class="h-10 flex items-center px-2 py-2 sm:px-2 border-b border-gray-100 bg-white sticky top-0 z-10"
                              >
                                <form
                                  class="flex w-full lg:ml-0"
                                  action="#"
                                  method="GET"
                                >
                                  <label for="search-field" class="sr-only"
                                    >Search</label
                                  >
                                  <div
                                    class="relative w-full text-gray-400 focus-within:text-gray-600"
                                  >
                                    <div
                                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                                    >
                                      <struct-icon
                                        icon="search"
                                        size="12"
                                        class="text-gray-400"
                                      ></struct-icon>
                                    </div>
                                    <input
                                      id="search-field"
                                      class="block h-full w-full border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md"
                                      placeholder="Search"
                                      type="search"
                                      name="search"
                                    />
                                  </div>
                                </form>
                              </div>
                              <mat-tree
                                [dataSource]="dataSource"
                                [treeControl]="treeControl"
                              >
                                <mat-tree-node
                                  *matTreeNodeDef="let node"
                                  matTreeNodePadding
                                >
                                  <div
                                    class="w-full px-2 flex space-x-2 items-center relative group"
                                  >
                                    <button mat-icon-button disabled></button>
                                    <struct-checkbox></struct-checkbox>
                                    <struct-icon
                                      icon="folder_filled_open"
                                      size="16"
                                      class="text-yellow-500"
                                      *ngIf="treeControl.isExpanded(node)"
                                    ></struct-icon>
                                    <struct-icon
                                      icon="folder_filled"
                                      size="16"
                                      class="text-yellow-500"
                                      *ngIf="!treeControl.isExpanded(node)"
                                    ></struct-icon>
                                    <span class="text-md flex-grow truncate">{{
                                      node.item
                                    }}</span>
                                    <struct-icon-button
                                      icon="more_vert"
                                      [matMenuTriggerFor]="
                                        menu_tree_node_options
                                      "
                                      class="opacity-0 group-hover:opacity-100"
                                    ></struct-icon-button>
                                  </div>
                                </mat-tree-node>

                                <mat-tree-node
                                  *matTreeNodeDef="let node; when: hasChild"
                                  matTreeNodePadding
                                >
                                  <div
                                    class="w-full px-2 flex space-x-2 items-center relative group"
                                  >
                                    <button
                                      mat-icon-button
                                      [attr.aria-label]="'Toggle ' + node.item"
                                      matTreeNodeToggle
                                    >
                                      <struct-icon
                                        icon="minus"
                                        size="12"
                                        class="text-gray-500"
                                        *ngIf="treeControl.isExpanded(node)"
                                      ></struct-icon>
                                      <struct-icon
                                        icon="plus"
                                        size="12"
                                        class="text-gray-500"
                                        *ngIf="!treeControl.isExpanded(node)"
                                      ></struct-icon>
                                    </button>
                                    <a
                                      href="javascript:;"
                                      class="flex-1 flex items-center space-x-2"
                                    >
                                      <struct-checkbox></struct-checkbox>
                                      <struct-icon
                                        icon="folder_filled_open"
                                        size="16"
                                        class="text-yellow-500"
                                        *ngIf="treeControl.isExpanded(node)"
                                      ></struct-icon>
                                      <struct-icon
                                        icon="folder_filled"
                                        size="16"
                                        class="text-yellow-500"
                                        *ngIf="!treeControl.isExpanded(node)"
                                      ></struct-icon>
                                      <span
                                        class="text-md flex-grow truncate"
                                        >{{ node.item }}</span
                                      >
                                      <struct-icon-button
                                        icon="more_vert"
                                        [matMenuTriggerFor]="
                                          menu_tree_node_options
                                        "
                                        class="opacity-0 group-hover:opacity-100"
                                      ></struct-icon-button>
                                    </a>
                                  </div>
                                  <mat-progress-bar
                                    *ngIf="node.isLoading"
                                    mode="indeterminate"
                                    class="struct-tree-progress-bar absolute bottom-0 left-0 w-full"
                                  ></mat-progress-bar>
                                </mat-tree-node>
                              </mat-tree>

                              <mat-menu
                                #menu_tree_node_options="matMenu"
                                class="text-sm"
                              >
                                <button
                                  mat-menu-item
                                  class="text-sm flex items-center h-10 leading-tight"
                                >
                                  <struct-icon
                                    icon="content_copy"
                                    size="16"
                                  ></struct-icon>
                                  <span>Copy</span>
                                </button>

                                <button
                                  mat-menu-item
                                  class="text-sm flex items-center h-10 leading-tight"
                                >
                                  <struct-icon
                                    icon="move_down"
                                    size="16"
                                  ></struct-icon>
                                  <span>Move</span>
                                </button>
                                <button
                                  mat-menu-item
                                  class="text-sm flex items-center h-10 leading-tight"
                                >
                                  <struct-icon
                                    icon="delete"
                                    size="16"
                                  ></struct-icon>
                                  <span>Delete</span>
                                </button>
                              </mat-menu>
                            </div>
                          </div>
                          <div class="px-4 py-4 sm:px-6">
                            <!-- Content goes here -->
                            <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
                          </div>
                        </div>

                        <div
                          class="divide-y divide-gray-100 overflow-hidden rounded bg-white border border-gray-100"
                        >
                          <div class="px-4 py-5 sm:px-6">
                            <h3 class="font-medium text-base">
                              Selected categories
                            </h3>
                          </div>
                          <div
                            class="p-0 h-96 overflow-y-auto struct-scrollbar"
                          >
                            <div
                              class="px-4 py-3 sm:px-6 border-b border-gray-100 bg-white sticky top-0 z-10"
                            >
                              <h5 class="font-medium text-md text-gray-400">
                                Internal catalogue (2)
                              </h5>
                            </div>
                            <ul role="list" class="divide-y divide-gray-100">
                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-3 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                          <p
                                            class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400"
                                          >
                                            Primary
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <div
                              class="px-4 py-3 sm:px-6 border-b border-gray-100 bg-white sticky top-0 z-10"
                            >
                              <h5 class="font-medium text-md text-gray-400">
                                Electronics site (2)
                              </h5>
                            </div>
                            <ul role="list" class="divide-y divide-gray-100">
                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                          <p
                                            class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400"
                                          >
                                            Primary
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                          <p
                                            class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400"
                                          >
                                            Primary
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                          <p
                                            class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400"
                                          >
                                            Primary
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="block hover:bg-gray-50">
                                  <div class="px-4 py-4 sm:px-6">
                                    <div class="flex items-start space-x-2">
                                      <div
                                        class="flex items-center border-r border-gray-100 pr-2 h-14"
                                      >
                                        <struct-icon
                                          icon="folder_filled"
                                          size="16"
                                          class="text-yellow-500"
                                        ></struct-icon>
                                      </div>

                                      <div
                                        class="flex-shrink-0 flex-grow flex flex-col"
                                      >
                                        <div class="flex items-center">
                                          <p
                                            class="truncate text-base font-medium text-gray-600"
                                          >
                                            T-shirts
                                          </p>
                                          <p
                                            class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400"
                                          >
                                            Primary
                                          </p>
                                        </div>
                                        <div
                                          class="mt-1 sm:flex sm:justify-between"
                                        >
                                          <div class="sm:flex">
                                            <p
                                              class="flex space-x-2 items-center text-sm text-gray-500"
                                            >
                                              <span
                                                >Internal catalogue /
                                                clothing</span
                                              >
                                            </p>
                                          </div>
                                          <div
                                            class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0"
                                          >
                                            <button>Set as primary</button>
                                            <button>Remove</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="px-4 py-4 sm:px-6">
                            <!-- Content goes here -->
                            <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                Relations
                <struct-icon
                  icon="warning"
                  size="12"
                  class="ml-1 text-yellow-500"
                  #tooltip="matTooltip"
                  matTooltip="You have changed data on this tab"
                  [matTooltipPosition]="'below'"
                ></struct-icon>
              </ng-template>
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Product relations </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Accessories</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select accessories for this product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add relation</span
                              >
                            </button>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Upsale products</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select complimentary products which are are
                                considered more premium than this product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add relation</span
                              >
                            </button>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Cross sale products</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select products from other categories which
                                would make sense to buy together with this
                                product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add relation</span
                              >
                            </button>
                          </div>
                        </div>

                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Complimentary products</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Select complimentary products which a customer
                                may consider as an alternative to this product
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <button
                              type="button"
                              class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <struct-icon
                                icon="plus_32"
                                size="32"
                                class=""
                              ></struct-icon>
                              <span
                                class="mt-2 block text-sm font-medium text-gray-700"
                                >Add relation</span
                              >
                            </button>
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Variants">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Variants </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <!-- <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3 class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2">
                                <span>Documents</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Add documents to your product
                              </p>
                            </div>
                          </div>
                          <div class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4">
                            <button type="button" class="relative block w-full rounded-lg border-2 border-dashed border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                              <struct-icon icon="plus" size="32" class=""></struct-icon>
                              <span class="mt-2 block text-sm font-medium text-gray-700">Add document</span>
                            </button>
                          </div>

                        </div> -->
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Shopify Connect">
              <div class="space-y-6 sm:px-6 lg:col-span-9 lg:p-8">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel expanded="true" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Shopify data </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form action="#" method="POST">
                      <section class="flex flex-col space-y-2">
                        <div class="grid gap-4 grid-cols-12">
                          <div class="col-span-6 md:col-span-3 lg:col-span-2">
                            <div class="py-2">
                              <h3
                                class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                              >
                                <span>Start full synchronization</span>
                              </h3>
                              <p class="mt-1 text-sm text-gray-600">
                                Synchronize product, variants and inventory to
                                shopify
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-span-6 md:col-span-9 lg:col-span-5 relative p-2 grid grid-cols-4 gap-4"
                          >
                            <struct-button buttonType="submit" color="accent"
                              >Start</struct-button
                            >
                          </div>
                        </div>
                      </section>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
          </mat-tab-group>
          <!-- Tabs end -->
        </div>

        <!-- Bottom action bar begin -->
        <div
          class="grow-0 shrink-0 h-16 lg:h-20 border-t border-gray-200 bg-white flex items-center px-6 z-10 justify-between"
        >
          <div>
            <nav class="flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center space-x-2">
                <li>
                  <div class="flex items-center">
                    <a
                      href="#"
                      class="ml-2 flex items-center text-sm text-gray-500 hover:text-gray-700"
                    >
                      <struct-icon
                        icon="folder_filled"
                        size="8"
                        class="mr-1"
                      ></struct-icon>
                      <span>Internal catalogue</span>
                    </a>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <struct-icon icon="chevron_right" size="12"></struct-icon>
                    <a
                      href="#"
                      class="ml-2 flex items-center text-sm text-gray-500 hover:text-gray-700"
                      aria-current="page"
                    >
                      <struct-icon
                        icon="folder_filled"
                        size="8"
                        class="mr-1"
                      ></struct-icon>
                      <span>Clothing</span>
                    </a>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <struct-icon icon="chevron_right" size="12"></struct-icon>
                    <a
                      href="#"
                      class="ml-2 flex items-center text-sm text-gray-500 hover:text-gray-700"
                      aria-current="page"
                    >
                      <struct-icon
                        icon="folder_filled"
                        size="8"
                        class="mr-1"
                      ></struct-icon>
                      <span>Shoes</span>
                    </a>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <struct-icon icon="chevron_right" size="12"></struct-icon>
                    <a
                      href="#"
                      class="ml-2 text-sm text-gray-500 hover:text-gray-700"
                      aria-current="page"
                      >Nike Air Max 90</a
                    >
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="flex items-center space-x-4">
            <struct-button buttonType="submit" color="accent"
              >Save changes</struct-button
            >
            <struct-button buttonType="submit"
              >Cancel changes (3)</struct-button
            >
          </div>
        </div>
        <!-- Bottom action bar end -->
      </section>

      <!-- Meta information column (hidden on smaller screens) -->
      <aside
        class="hidden flex-col w-96 overflow-hidden border-r border-gray-200 bg-gray-50 lg:flex lg:order-first"
        *ngIf="!translationActive"
        [ngClass]="{ '!w-8 !bg-gray-100': !metaSidebarVisible }"
      >
        <div class="flex flex-col flex-grow" *ngIf="metaSidebarVisible">
          <section class="">
            <div
              class="w-full h-64 block overflow-hidden aspect-w-3 aspect-h-3 border-b border-gray-100"
            >
              <img
                src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png"
                alt=""
                class="object-cover"
              />
            </div>
          </section>
          <section class="overflow-y-auto p-4 flex-1">
            <div class="space-y-6 pb-16">
              <mat-accordion class="expansion-panel__sidebar">
                <mat-expansion-panel
                  class="relative mat-elevation-z0"
                  expanded="true"
                >
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title> Product status </mat-panel-title>
                  </mat-expansion-panel-header>
                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Created by</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <p class="mr-2 text-sm font-medium text-gray-900">
                            Simon Lyder
                          </p>
                          <img
                            src="/assets/images/avatars/avatar-simon.jpg"
                            alt=""
                            class="h-5 w-5 rounded-full"
                          />
                        </div>
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Creation date</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        August 12, 2022
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Last modified</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        December 8, 2022
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Last modified by</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <p class="mr-2 text-sm font-medium text-gray-900">
                            Lasse Nørby
                          </p>
                          <img
                            src="/assets/images/avatars/avatar-lasse.jpg"
                            alt=""
                            class="h-5 w-5 rounded-full"
                          />
                        </div>
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Revision</dt>
                      <dd class="whitespace-nowrap text-gray-900">Rev. 2</dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Attribute Group</dt>
                      <dd class="whitespace-nowrap text-gray-900">General</dd>
                    </div>
                  </dl>
                </mat-expansion-panel>
                <mat-expansion-panel class="relative mat-elevation-z0">
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title> Enrichment insights </mat-panel-title>
                  </mat-expansion-panel-header>
                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div class="py-3">
                      <div
                        class="flex justify-between text-sm font-medium mb-2"
                      >
                        <dt class="text-gray-500">
                          Long description translation
                        </dt>
                        <dd class="whitespace-nowrap text-gray-900">0%</dd>
                      </div>
                      <mat-progress-bar
                        mode="determinate"
                        value="0"
                      ></mat-progress-bar>
                    </div>

                    <div class="py-3">
                      <div
                        class="flex justify-between text-sm font-medium mb-2"
                      >
                        <dt class="text-gray-500">Primary name translation</dt>
                        <dd class="whitespace-nowrap text-gray-900">25%</dd>
                      </div>
                      <mat-progress-bar
                        mode="determinate"
                        value="25"
                      ></mat-progress-bar>
                    </div>

                    <div class="py-3">
                      <div
                        class="flex justify-between text-sm font-medium mb-2"
                      >
                        <dt class="text-gray-500">
                          Short description translation
                        </dt>
                        <dd class="whitespace-nowrap text-gray-900">50%</dd>
                      </div>
                      <mat-progress-bar
                        mode="determinate"
                        value="50"
                      ></mat-progress-bar>
                    </div>
                  </dl>
                </mat-expansion-panel>
                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title> Tasks </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="w-full flex items-center">
                    <div class="grow">
                      <label for="search" class="sr-only">Search</label>
                      <div
                        class="relative text-gray-400 focus-within:text-gray-600"
                      >
                        <div
                          class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                        >
                          <struct-icon icon="search" size="16"></struct-icon>
                        </div>
                        <input
                          id="search-field"
                          class="block h-full w-full bg-transparent border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </div>
                    <struct-icon-button
                      icon="add"
                      iconSize="12"
                      (click)="isMetaDrawerActive = !isMetaDrawerActive"
                    ></struct-icon-button>
                  </div>
                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div
                      class="flex justify-between items-center text-sm font-medium py-2"
                    >
                      <dt class="text-gray-500">
                        <div class="flex justify-between items-center gap-2 cursor-pointer" (click)="isMetaDrawerActive = !isMetaDrawerActive">
                          <struct-icon icon="calendar" size="16"></struct-icon>
                          <span>Oversættelse på segmenter</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <span
                            class="rounded-full w-3 h-3 bg-yellow-500 overflow-hidden"
                            ></span
                          >
                        </div>
                      </dd>
                    </div>

                    <div
                      class="flex justify-between items-center text-sm font-medium py-2"
                    >
                      <dt class="text-gray-500">
                        <div class="flex justify-between items-center gap-2 cursor-pointer" (click)="isMetaDrawerActive = !isMetaDrawerActive">
                          <struct-icon icon="calendar" size="16"></struct-icon>
                          <span>Billeder</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <span
                            class="rounded-full w-3 h-3 bg-red-500 overflow-hidden"
                            ></span
                          >
                        </div>
                      </dd>
                    </div>

                    <div
                      class="flex justify-between items-center text-sm font-medium py-2"
                    >
                      <dt class="text-gray-500">
                        <div class="flex justify-between items-center gap-2">
                          <struct-icon icon="calendar" size="16"></struct-icon>
                          <span>Opsæt beskrivelser</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <span
                            class="rounded-full w-3 h-3 bg-blue-500 overflow-hidden"
                            ></span
                          >
                        </div>
                      </dd>
                    </div>
                  </dl>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </section>
          <div
            class="h-16 lg:h-20 flex items-center justify-end px-4 space-x-4"
          >
            <struct-icon-button
              icon="move_left"
              #tooltip="matTooltip"
              matTooltip="Close meta sidebar"
              [matTooltipPosition]="'below'"
              (click)="metaSidebarVisible = false"
            ></struct-icon-button>
          </div>
        </div>

        <div class="flex flex-col flex-grow" *ngIf="!metaSidebarVisible">
          <div class="flex-grow"></div>
          <div class="h-16 lg:h-20 flex items-center justify-end">
            <struct-icon-button
              icon="arrow_forward"
              #tooltip="matTooltip"
              matTooltip="Open meta sidebar"
              [matTooltipPosition]="'below'"
              (click)="metaSidebarVisible = true"
            ></struct-icon-button>
          </div>
        </div>
      </aside>
      <div
        class="absolute top-0 right-0 bottom-0 left-96 bg-gray-500 bg-opacity-25 transition-opacity"
        *ngIf="isMetaDrawerActive"
        (click)="isMetaDrawerActive = !isMetaDrawerActive"
      ></div>
      <aside
        class="hidden flex-col w-96 overflow-hidden border-r border-gray-200 shadow-lg bg-gray-50 lg:flex absolute z-10 h-full ml-96"
        *ngIf="isMetaDrawerActive"
      >
        <div
          class="flex h-full flex-col flex-grow overflow-y-auto bg-gray-50 py-6"
        >
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                class="text-base font-semibold leading-6 text-gray-900"
                id="slide-over-title"
              >
                Task
              </h2>
              <div class="ml-3 flex h-7 items-center">
                <struct-icon-button
                  icon="remove"
                  #tooltip="matTooltip"
                  matTooltip="Close"
                  [matTooltipPosition]="'below'"
                  (click)="isMetaDrawerActive = !isMetaDrawerActive"
                ></struct-icon-button>
              </div>
            </div>
          </div>
          <div class="relative mt-6 flex-1 px-4">

          </div>
        </div>
      </aside>

      <!-- Translation column (hidden on smaller screens) -->
      <aside
        class="hidden flex-col w-96 overflow-hidden border-l border-gray-200 bg-gray-50 lg:flex lg:order-last"
        *ngIf="translationActive"
      >
        <section class="overflow-y-auto p-4 flex-1">
          <div class="space-y-6 pb-16">
            <div class="flex items-center">
              <struct-icon-button
                icon="view"
                iconSize="16"
                #tooltip="matTooltip"
                matTooltip="View only translatable fields"
                [matTooltipPosition]="'below'"
              ></struct-icon-button>
              <struct-icon-button
                icon="expand"
                iconSize="16"
                #tooltip="matTooltip"
                matTooltip="Expand all"
                [matTooltipPosition]="'below'"
              ></struct-icon-button>
              <struct-icon-button
                icon="google_translate"
                iconSize="16"
                #tooltip="matTooltip"
                matTooltip="Google translate all"
                [matTooltipPosition]="'below'"
              ></struct-icon-button>
              <struct-icon-button
                icon="copy_to_all"
                iconSize="16"
                #tooltip="matTooltip"
                matTooltip="Copy to all"
                [matTooltipPosition]="'below'"
              ></struct-icon-button>
              <struct-icon-button
                icon="translation_revision"
                iconSize="16"
                #tooltip="matTooltip"
                matTooltip="Show revisions"
                [matTooltipPosition]="'below'"
              ></struct-icon-button>
            </div>

            <div class="">
              <h3 class="font-medium text-gray-900 mb-2">Source</h3>
              <div class="overflow-hidden bg-white shadow sm:rounded-md">
                <ul role="list" class="divide-y divide-gray-100">
                  <li>
                    <a href="#" class="block hover:bg-gray-50">
                      <div class="flex items-center px-4 py-2">
                        <div class="flex min-w-0 flex-1 items-center">
                          <div class="flex-shrink-0">
                            <img
                              class="h-6 w-6"
                              src="/assets/flags/uk.png"
                              alt=""
                            />
                          </div>
                          <div class="min-w-0 flex-1 px-4 text-sm">
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="globe"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>England</span>
                            </div>
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="desktop_mac"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>Desktop</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center space-x-2">
                          <struct-icon
                            icon="edit"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="">
              <h3 class="font-medium text-gray-900 mb-2">Translations</h3>
              <div class="overflow-hidden bg-white shadow sm:rounded-md">
                <ul role="list" class="divide-y divide-gray-100">
                  <li>
                    <a href="#" class="block hover:bg-gray-50">
                      <div class="flex items-center px-4 py-2">
                        <div class="flex min-w-0 flex-1 items-center">
                          <div class="flex-shrink-0">
                            <img
                              class="h-6 w-6"
                              src="/assets/flags/dk.png"
                              alt=""
                            />
                          </div>
                          <div class="min-w-0 flex-1 px-4 text-sm">
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="globe"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>Denmark</span>
                            </div>
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="desktop_mac"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>Desktop</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center space-x-2">
                          <struct-icon
                            icon="edit"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                          <struct-icon
                            icon="close"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#" class="block hover:bg-gray-50">
                      <div class="flex items-center px-4 py-2">
                        <div class="flex min-w-0 flex-1 items-center">
                          <div class="flex-shrink-0">
                            <img
                              class="h-6 w-6"
                              src="/assets/flags/de.png"
                              alt=""
                            />
                          </div>
                          <div class="min-w-0 flex-1 px-4 text-sm">
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="desktop_mac"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>Desktop</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center space-x-2">
                          <struct-icon
                            icon="edit"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                          <struct-icon
                            icon="close"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#" class="block hover:bg-gray-50">
                      <div class="flex items-center px-4 py-2">
                        <div class="flex min-w-0 flex-1 items-center">
                          <div class="flex-shrink-0">
                            <img
                              class="h-6 w-6"
                              src="/assets/flags/fr.png"
                              alt=""
                            />
                          </div>
                          <div class="min-w-0 flex-1 px-4 text-sm">
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="globe"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>International</span>
                            </div>
                            <div class="flex items-center space-x-2">
                              <struct-icon
                                icon="desktop_mac"
                                size="12"
                                class="w-4 h-auto"
                              ></struct-icon>
                              <span>Desktop</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center space-x-2">
                          <struct-icon
                            icon="edit"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                          <struct-icon
                            icon="close"
                            size="12"
                            class="w-4 h-auto"
                          ></struct-icon>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#" class="block hover:bg-gray-50">
                      <button
                        type="button"
                        class="px-4 py-2 group flex items-center w-full bg-gray-50 p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <span
                          class="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400"
                        >
                          <struct-icon icon="plus_12" size="12"></struct-icon>
                        </span>
                        <span
                          class="ml-4 text-sm font-medium text-blue-600 group-hover:text-blue-500"
                          >Add new</span
                        >
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div class="h-20 flex items-center justify-end px-6 space-x-4">
          <struct-icon-button
            icon="move_left"
            #tooltip="matTooltip"
            matTooltip="Close meta sidebar"
            [matTooltipPosition]="'below'"
          ></struct-icon-button>
        </div>
      </aside>
    </main>
  </div>
</div>
