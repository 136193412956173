// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantQuota  {
  includedTenants: number;
  extraTenantPrice: Price;
  maxNumTenants: number;
}

export class TenantQuota  implements ITenantQuota {
  includedTenants = 0;

  extraTenantPrice: Price = new Price();

  maxNumTenants = 0;


  constructor(data?: Partial<ITenantQuota>) {
    Object.assign(this, data);
  }
}