import { Component, Input, OnInit } from '@angular/core';
import { StructColorService } from '@struct/ui/color.service';

@Component({
  selector: 'struct-percentage-column',
  templateUrl: './struct-percentage-column.component.html',
  styleUrls: ['./struct-percentage-column.component.scss'],
})
export class StructPercentageColumnComponent implements OnInit {
  @Input() type: number | null = null;
  _value: string | null = null;
  @Input() get value(): string | null {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
    this.setColor();
  }

  color: string = "";

  constructor(private colorService : StructColorService){}

  ngOnInit(): void {
    if (!this.type ) {
      this.type = 1;
    }
  }

  setColor() {
    if (this.value) {
      this.color = this.colorService.getPercentageColor(parseInt(this.value));      
    }
  }
}
