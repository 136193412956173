import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'struct-icon',
  templateUrl: './struct-icon.component.html',
  styleUrls: ['./struct-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructIconComponent {
   @Input() public icon: string | null = null;
   @Input() public size: string;
   @Input() public useMaterialIcons = false;

   constructor(){
    this.size = "16px";
   }
}
