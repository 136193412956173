// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataField } from "@struct/models/struct/shared/search/data-field";
import { DataType } from "@struct/models/struct/shared/search/data-type";
import { DisplayType } from "@struct/models/struct/shared/search/display-type";
import { QueryOperator } from "@struct/models/struct/shared/search/query-operator";
import { QueryType } from "@struct/models/struct/shared/search/query-type";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDynamicCategoryDataField extends DataField {
  rootAttributeUid?: string | null;
}

export class DynamicCategoryDataField extends DataField implements IDynamicCategoryDataField {
  rootAttributeUid: string | null = null;

  override uid = "";

  override name = "";

  override dataFieldType: DataType = DataType.String;

  override displayType: DisplayType = DisplayType.Text;

  override queryType: QueryType = QueryType.Text;

  override supportedQueryOperators: QueryOperator[] = [];

  override options: string[] | null = null;

  override isMultiValue = false;

  override supportsSorting = false;

  override selectable = false;

  override formatPattern = "";

  override isAggregateValue = false;

  override isReferenceValue = false;


  constructor(data?: Partial<IDynamicCategoryDataField>) {
    super(data);
    Object.assign(this, data);
  }
}