// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInviteAdminCommand  {
  userEmail: string;
  invitedBy: string;
  message?: string | null;
}

export class InviteAdminCommand  implements IInviteAdminCommand {
  userEmail = "";

  invitedBy = "";

  message: string | null = null;


  constructor(data?: Partial<IInviteAdminCommand>) {
    Object.assign(this, data);
  }
}