<section class="grid grid-cols-1 md:grid-cols-2 gap-6">
  <div class="divide-y divide-gray-100 overflow-hidden rounded bg-white border border-gray-100">
      <div class="px-4 py-4 sm:px-4">
          <h3 class="font-medium text-base">
              Product catalogues
          </h3>
      </div>
      <div class="p-0 grid grid-cols-6 h-96">
          <div class="col-span-2 overflow-y-auto border-r border-gray-100">
              <div class="h-10 flex items-center px-4 py-2 sm:px-4 border-b border-gray-100 bg-white sticky top-0 z-10">
                  <h5 class="font-medium text-md text-gray-400">
                      Catalogues
                  </h5>
              </div>

              <ul role="list" class="divide-y divide-gray-100">
                  <li>
                      <a href="" class="block bg-gray-50 hover:bg-gray-50 border-r-2 border-blue-500">
                          <div class="px-4 py-3 sm:px-4">
                              <div class="flex items-center gap-3">
                                <struct-icon icon="folder_filled_open" size="24" class="text-blue-400"></struct-icon>
                                <div class="flex flex-col">
                                    <p class="truncate text-base font-medium text-gray-600">
                                        Internal catalogue
                                    </p>
                                    <span class="text-xs text-gray-400">(master)(765)</span>
                                </div>
                              </div>
                          </div>
                      </a>
                  </li>

                  <li>
                      <a href="" class="block hover:bg-gray-50">
                          <div class="px-4 py-3 sm:px-4">
                              <div class="flex items-center gap-3">
                                <struct-icon icon="folder_filled" size="24" class="text-gray-500"></struct-icon>
                                <div class="flex flex-col">
                                    <p class="truncate text-base font-medium text-gray-600">
                                        Electronics site
                                    </p>
                                    <span class="text-xs text-gray-400">(765)</span>
                                </div>
                              </div>
                          </div>
                      </a>
                  </li>

                  <li>
                      <a href="" class="block hover:bg-gray-50">
                          <div class="px-4 py-3 sm:px-4">
                              <div class="flex items-center gap-3">
                                <struct-icon icon="folder_filled" size="24" class="text-gray-500"></struct-icon>
                                <div class="flex flex-col">
                                    <p class="truncate text-base font-medium text-gray-600">
                                        Wine Channel
                                    </p>
                                    <span class="text-xs text-gray-400">(765)</span>
                                </div>
                              </div>
                          </div>
                      </a>
                  </li>

                  <li>
                    <a href="" class="block hover:bg-gray-50">
                        <div class="px-4 py-3 sm:px-4">
                            <div class="flex items-center gap-3">
                              <struct-icon icon="folder_filled" size="24" class="text-gray-500"></struct-icon>
                              <div class="flex flex-col">
                                  <p class="truncate text-base font-medium text-gray-600">
                                      Test Channel
                                  </p>
                                  <span class="text-xs text-gray-400">(765)</span>
                              </div>
                            </div>
                        </div>
                    </a>
                  </li>

                  <li>
                    <a href="" class="block hover:bg-gray-50">
                        <div class="px-4 py-3 sm:px-4">
                            <div class="flex items-center gap-3">
                              <struct-icon icon="folder_filled" size="24" class="text-gray-500"></struct-icon>
                              <div class="flex flex-col">
                                  <p class="truncate text-base font-medium text-gray-600">
                                      IT Channel
                                  </p>
                                  <span class="text-xs text-gray-400">(765)</span>
                              </div>
                            </div>
                        </div>
                    </a>
                  </li>
              </ul>
          </div>
          <div class="col-span-4 flex flex-col h-full overflow-y-auto relative struct-scrollbar">
              <div class="h-10 flex items-center px-2 py-2 sm:px-2 border-b border-gray-100 bg-white sticky top-0 z-10">
                  <form class="flex w-full lg:ml-0" action="#" method="GET">
                      <label for="search-field" class="sr-only">Search</label>
                      <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                              <struct-icon icon="search" size="12" class="text-gray-400"></struct-icon>
                          </div>
                          <input id="search-field" class="block h-full w-full border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md" placeholder="Search" type="search" name="search" />
                      </div>
                  </form>
              </div>
              <div class="flex flex-grow items-center justify-center">
                <struct-spinner></struct-spinner>
              </div>

              <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                      <div class="w-full px-2 flex space-x-2 items-center relative group">
                          <button mat-icon-button disabled></button>
                          <struct-checkbox></struct-checkbox>
                          <struct-icon icon="folder_filled_open" size="16" class="text-yellow-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                          <struct-icon icon="folder_filled" size="16" class="text-yellow-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                          <span class="text-md flex-grow truncate">{{
                              node.item
                              }}</span>
                          <struct-icon-button icon="more_vert" [matMenuTriggerFor]="
menu_tree_node_options
" class="opacity-0 group-hover:opacity-100"></struct-icon-button>
                      </div>
                  </mat-tree-node>

                  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                      <div class="w-full px-2 flex space-x-2 items-center relative group">
                          <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                              <struct-icon icon="minus" size="12" class="text-gray-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                              <struct-icon icon="plus" size="12" class="text-gray-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                          </button>
                          <a href="javascript:;" class="flex-1 flex items-center space-x-2">
                              <struct-checkbox></struct-checkbox>
                              <struct-icon icon="folder_filled_open" size="16" class="text-yellow-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                              <struct-icon icon="folder_filled" size="16" class="text-yellow-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                              <span class="text-md flex-grow truncate">{{ node.item }}</span>
                              <struct-icon-button icon="more_vert" [matMenuTriggerFor]="
menu_tree_node_options
" class="opacity-0 group-hover:opacity-100"></struct-icon-button>
                          </a>
                      </div>
                      <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="struct-tree-progress-bar absolute bottom-0 left-0 w-full"></mat-progress-bar>
                  </mat-tree-node>
              </mat-tree> -->

              <mat-menu #menu_tree_node_options="matMenu" class="text-sm">
                  <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                      <struct-icon icon="content_copy" size="16"></struct-icon>
                      <span>Copy</span>
                  </button>

                  <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                      <struct-icon icon="move_down" size="16"></struct-icon>
                      <span>Move</span>
                  </button>
                  <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
                      <struct-icon icon="delete" size="16"></struct-icon>
                      <span>Delete</span>
                  </button>
              </mat-menu>
          </div>
      </div>
      <div class="px-4 py-4 sm:px-6">
          <!-- Content goes here -->
          <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
      </div>
  </div>

  <div class="divide-y divide-gray-100 overflow-hidden rounded bg-white border border-gray-100">
      <div class="px-4 py-5 sm:px-6">
          <h3 class="font-medium text-base">
              Selected categories
          </h3>
      </div>
      <div class="p-0 h-96 overflow-y-auto struct-scrollbar">
          <div class="px-4 py-3 sm:px-6 border-b border-gray-100 bg-white sticky top-0 z-10">
              <h5 class="font-medium text-md text-gray-400">
                  Internal catalogue (2)
              </h5>
          </div>
          <ul role="list" class="divide-y divide-gray-100">
              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-3">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                      <p class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400">
                                          Primary
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>
          </ul>

          <div class="px-4 py-3 sm:px-6 border-b border-gray-100 bg-white sticky top-0 z-10">
              <h5 class="font-medium text-md text-gray-400">
                  Electronics site (2)
              </h5>
          </div>
          <ul role="list" class="divide-y divide-gray-100">
              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                      <p class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400">
                                          Primary
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                      <p class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400">
                                          Primary
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                      <p class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400">
                                          Primary
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>

              <li>
                  <div class="block hover:bg-gray-50">
                      <div class="px-4 py-4 sm:px-6">
                          <div class="flex items-start space-x-2">
                              <div class="flex items-center border-r border-gray-100 pr-2 h-14">
                                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500"></struct-icon>
                              </div>

                              <div class="flex-shrink-0 flex-grow flex flex-col">
                                  <div class="flex items-center">
                                      <p class="truncate text-base font-medium text-gray-600">
                                          T-shirts
                                      </p>
                                      <p class="inline-flex rounded-full px-3 py-1 text-sm font-medium leading-5 text-green-400">
                                          Primary
                                      </p>
                                  </div>
                                  <div class="mt-1 sm:flex sm:justify-between">
                                      <div class="sm:flex">
                                          <p class="flex space-x-2 items-center text-sm text-gray-500">
                                              <span>Internal catalogue /
                                                  clothing</span>
                                          </p>
                                      </div>
                                      <div class="mt-2 flex items-center space-x-4 text-sm text-gray-500 sm:mt-0">
                                          <button>Set as primary</button>
                                          <button>Remove</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
      <div class="px-4 py-4 sm:px-6">
          <!-- Content goes here -->
          <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
      </div>
  </div>
</section>
