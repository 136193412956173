import { Component } from '@angular/core';
import { StructColumnHeaderBaseComponent } from '../struct-column-header-base';
import { BooleanFieldQuery, BooleanQueryOperator, FieldQuery } from '@struct/models/struct/shared/search/datafieldqueries';

@Component({
  selector: 'struct-boolean-header',
  templateUrl: './struct-boolean-header.component.html',
})
export class StructBooleanHeaderComponent extends StructColumnHeaderBaseComponent {
  getFieldQuery(str : string | null | undefined) : FieldQuery | null {
    if (str === null || str === undefined || str.length === 0) {
      return null;
    }
    
    const cleanValue = this.getCleanSearchValue(str);
    if(cleanValue === null)
    {
      return null;
    }
    return new BooleanFieldQuery({
      fieldUid: this.column.id,
      queryOperator: BooleanQueryOperator.Equals,      
      filterValue: cleanValue
    });
  }

  getUiText(query: FieldQuery) : string{
    const typed = query as BooleanFieldQuery;
    return typed.filterValue?.toString() ?? "";
  }

  getCleanSearchValue(str: string) : boolean | null {

    if(str.toLowerCase() === "t" ||str.toLowerCase() === "tr" ||str.toLowerCase() === "tru" || str.toLowerCase() === "true" || str === "1" || str.toLowerCase() === "y" || str.toLowerCase() === "ye" || str.toLowerCase() === "yes"){
      return true;
    }
    if(str.toLowerCase() === "f" || str.toLowerCase() === "fa" || str.toLowerCase() === "fal" || str.toLowerCase() === "fals" || str.toLowerCase() === "false" || str === "0" || str.toLowerCase() === "n" || str.toLowerCase() === "no"){
      return false;
    }
    return null;
  }
}

