import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'struct-app-content-workarea',
  templateUrl: './struct-app-content-workarea.component.html',
  styleUrls: ['./struct-app-content-workarea.component.scss'],
})
export class StructAppContentWorkareaComponent {
  @HostBinding('class') class = '';
  @Input() cols: number | null = null;
  @Input() noPadding: boolean = false;
  @Input() noBottomPadding: boolean = false;
}
