<div class="flex h-screen flex-col overflow-hidden absolute top-0 left-0 w-full">
  <!-- App nav -->
  <header class="relative flex h-14 flex-shrink-0 items-center bg-gray-900 z-50 px-2 sm:px-4 lg:px-8 sm:pl-0 lg:pl-0"
          [ngClass]="{'!bg-gray-900': quickLaunchActive || quickSearchActive || quickTaskActive}">
    <div class="flex grow h-full px-2 lg:px-0">

      <div class="flex flex-shrink-0 items-center w-28 justify-center border-r border-red-800/50"
      [ngClass]="{'opacity-25 pointer-events-none': quickLaunchActive || quickSearchActive || quickTaskActive }">
        <a href="#">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-6 h-6 text-blue-200">
            <g>
              <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-white"></path>
              <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-white"></path>
              <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-white"></path>
            </g>
          </svg>
        </a>
      </div>
      <nav aria-label="Global" class="hidden h-full lg:flex lg:items-center lg:space-x-6 pl-4"
        [ngClass]="{'opacity-25 pointer-events-none': quickLaunchActive || quickSearchActive || quickTaskActive}">
        <a href="#" class="h-full pt-2 border-b-4 border-transparent flex items-center text-base font-medium text-white/50 hover:text-white">Dashboard</a>

        <a href="#" class="h-full pt-2 border-b-4 border-blue-300 flex items-center text-base font-medium text-white">PIM</a>

        <a href="#" class="h-full pt-2 border-b-4 border-transparent flex items-center text-base font-medium text-white/50 hover:text-white">Media</a>

        <a href="#" class="h-full pt-2 border-b-4 border-transparent flex items-center text-base font-medium text-white/50 hover:text-white">Publications</a>

        <a href="#" class="h-full pt-2 border-b-4 border-transparent flex items-center text-base font-medium text-white/50 hover:text-white">Data suppliers</a>

        <a href="#" class="h-full pt-2 border-b-4 border-transparent flex items-center text-base font-medium text-white/50 hover:text-white">Accounts</a>


      </nav>
      <div class="hidden h-full lg:flex lg:items-center lg:space-x-2 ml-8 pl-4 border-l border-red-800/50">
        <a class="h-full flex items-center px-2"
          [ngClass]="{'bg-gray-800 text-white': quickLaunchActive}" (click)="toggleQuickLaunch()">
          <struct-icon-button icon="apps_24" iconSize="16" class="text-white" #tooltip="matTooltip" matTooltip="Quick launch" [matTooltipPosition]="'below'"></struct-icon-button>
        </a>

        <a class="h-full flex items-center px-2 relative"
          [ngClass]="{'bg-gray-800 text-white': quickTaskActive}" (click)="toggleQuickTask()">
          <struct-icon-button icon="todo_24" iconSize="16" class="text-white" #tooltip="matTooltip" matTooltip="Your todo list" [matTooltipPosition]="'below'"></struct-icon-button>
          <span class="absolute bottom-4 right-4 block h-2 w-2 rounded-full bg-red-300 ring-2 ring-gray-900"></span>
        </a>

        <a class="h-full flex items-center px-2"
          [ngClass]="{'bg-gray-800 text-white': quickSearchActive}" (click)="toggleQuickSearch()">
          <struct-icon-button icon="search_24" iconSize="16" class="text-white" #tooltip="matTooltip" matTooltip="Search" [matTooltipPosition]="'below'"></struct-icon-button>
        </a>
      </div>
    </div>

    <div class="hidden lg:ml-4 lg:flex lg:space-x-4 lg:items-center">
      <span class="inline-flex">
        <struct-icon-button icon="notifications" iconSize="16" class="text-white" #tooltip="matTooltip" matTooltip="Notifications" [matTooltipPosition]="'below'"></struct-icon-button>
      </span>

      <!-- Profile dropdown -->
      <div class="relative flex-shrink-0">
        <span class="inline-flex">
          <struct-icon-button icon="my_profile" iconSize="16" class="text-white" #tooltip="matTooltip" matTooltip="User" [matTooltipPosition]="'below'"></struct-icon-button>
        </span>

      </div>
    </div>

  </header>

  <div class="overlay bg-black/50 z-50 absolute top-14 left-0 w-full h-full" *ngIf="quickLaunchActive || quickSearchActive || quickTaskActive" (click)="closeAllQuickies()">    
  </div>
  
  <div class="quick-launcher bg-gray-800 z-50 absolute top-14 left-0 w-full shadow-lg h-1/2" *ngIf="quickLaunchActive">
    <div class="grid grid-cols-4 gap-0 h-full">
      <div class="block h-full col-span-1 bg-gray-700/50 overflow-hidden">
        <nav class="h-full overflow-y-auto struct-scrollbar-dark" aria-label="Recently edited">
          <h4 class="text-base text-gray-300 font-medium mb-2 px-8 py-4 mt-4">Recently edited</h4>
          <div class="relative">
            <div class="sticky top-0 z-10 bg-gray-700 px-8 py-4 text-sm font-medium text-gray-100 flex items-center space-x-2">
              <struct-icon icon="calendar" size="12"></struct-icon>
              <h3>19.01.2023</h3>
            </div>
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div class="relative">
            <div class="sticky top-0 z-10 bg-gray-700 px-8 py-4 text-sm font-medium text-gray-100 flex items-center space-x-2">
              <struct-icon icon="calendar" size="12"></struct-icon>
              <h3>17.01.2023</h3>
            </div>
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div class="relative">
            <div class="sticky top-0 z-10 bg-gray-700 px-8 py-4 text-sm font-medium text-gray-100 flex items-center space-x-2">
              <struct-icon icon="calendar" size="12"></struct-icon>
              <h3>10.01.2023</h3>
            </div>
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div class="relative">
            <div class="sticky top-0 z-10 bg-gray-700 px-8 py-4 text-sm font-medium text-gray-100 flex items-center space-x-2">
              <struct-icon icon="calendar" size="12"></struct-icon>
              <h3>06.01.2023</h3>
            </div>
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div class="relative">
            <div class="sticky top-0 z-10 bg-gray-700 px-8 py-4 text-sm font-medium text-gray-100 flex items-center space-x-2">
              <struct-icon icon="calendar" size="12"></struct-icon>
              <h3>03.01.2023</h3>
            </div>
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 90</p>
                      <p class="truncate text-sm text-gray-300">Updated 2 fields</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 70</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-8 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-800">
                  <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                  </div>
                  <div class="min-w-0 flex-1">
                    <a href="#" class="focus:outline-none">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium text-white">Nike Air Max 20</p>
                      <p class="truncate text-sm text-gray-300">Updated Name field</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

        </nav>
      </div>
      <div class="col-span-3 flex-1 grow-1 overflow-y-auto p-8 struct-scrollbar-dark relative">
        <div class="absolute top-4 right-8 flex items-center space-x-4">
          <button class="text-sm font-medium text-gray-500 hover:text-gray-300" (click)="toggleKeyboardShortcuts()" *ngIf="!showKeyboardShortcuts">Show shortcuts</button>
          <button class="text-sm font-medium text-gray-500 hover:text-gray-300" (click)="toggleKeyboardShortcuts()" *ngIf="showKeyboardShortcuts">Hide shortcuts</button>
        </div>

        <h6 class="font-medium text-gray-300 mb-2">Product management</h6>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-12">
          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search and manage entities in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="search_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Search</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">s</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="View all your global lists here" [matTooltipPosition]="'below'">
            <struct-icon-button icon="global_lists_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Global lists</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">l</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Create product catalogues and organize your products categories within these" [matTooltipPosition]="'below'">
            <struct-icon-button icon="catalogues_32" iconSize="32" class="rounded-full p-2 bg-blue-600 relative text-blue-50">

            </struct-icon-button>
            <span class="text-sm mt-1">Catalogues</span>
            <!-- <span class="absolute top-1 w-1 h-1 bg-blue-100 rounded-full ring-2 ring-gray-800"></span> -->
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">c</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Manage collections in folders to ensure you are always on top of your collections' structure" [matTooltipPosition]="'below'">
            <struct-icon-button icon="collections_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Collections</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">x</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Import data to PIM from file" [matTooltipPosition]="'below'">
            <struct-icon-button icon="import_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Import</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">i</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Product model setup" [matTooltipPosition]="'below'">
            <struct-icon-button icon="product_model_setup_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Product model setup</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">p</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="PIM settings overview" [matTooltipPosition]="'below'">
            <struct-icon-button icon="settings_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Settings</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">ctrl</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">s</span>
            </div>
          </a>

        </div>
        <hr class="my-4 border-gray-700" />
        <h6 class="font-medium text-gray-300 mb-2">Asset management</h6>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-12">
          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="search_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Search</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">i</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="catalogues_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Catalogues</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">i</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="collections_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Collections</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">i</span>
            </div>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="settings_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-sm mt-1">Settings</span>
            <div class="flex space-x-1 mt-1 absolute bottom-0" *ngIf="showKeyboardShortcuts">
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">shift</span>
              <span class="text-xs mt-0 bg-gray-800 ring-1 ring-gray-700 text-gray-400 rounded-sm px-1">i</span>
            </div>
          </a>

        </div>
        <hr class="my-4 border-gray-700" />
        <h6 class="font-medium text-gray-300 mb-2">Publications</h6>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-12">

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="publications_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Templates</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="catalogues_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Tasks</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="settings_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Settings</span>
          </a>

        </div>
        <hr class="my-4 border-gray-700" />
        <h6 class="font-medium text-gray-300 mb-2">Data Suppliers</h6>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-12">

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="global_lists_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Message board</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="catalogues_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Manage data suppliers</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="settings_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Settings</span>
          </a>

        </div>
        <hr class="my-4 border-gray-700" />
        <h6 class="font-medium text-gray-300 mb-2">Accounts</h6>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 2xl:grid-cols-12">

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="global_lists_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Users</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="catalogues_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Groups</span>
          </a>

          <a href="#" class="text-gray-100 bg-gray-800 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'below'">
            <struct-icon-button icon="settings_32" iconSize="32" class="rounded-full p-2 group-hover:bg-gray-700"></struct-icon-button>
            <span class="text-xs mt-1">Settings</span>
          </a>

        </div>
      </div>
    </div>

  </div>

  <div class="quick-search bg-gray-800 z-50 absolute top-14 left-0 w-full shadow-lg" *ngIf="quickSearchActive">
    <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-0">
      <div class="col-span-1 bg-gray-700/50 p-8">
        <div class="mt-1 flex items-center relative">
          <input [cdkTrapFocusAutoCapture]="quickSearchInputText" [cdkTrapFocus]="quickSearchInputText" [(ngModel)]="quickSearchInputText" type="text" name="search" id="search-field" placeholder="Search for..." (keyup)="onSearchChange($event)" class="block w-full bg-gray-700 outline-none focus:bg-white border-gray-300 pr-12 text-gray-100 focus:text-gray-800 text-xl py-4 px-4 focus:ring-2 focus:ring-blue-300">
          <div class="absolute right-2">
            <mat-spinner class="mr-4" diameter="16" color="primarydark" *ngIf="quickSearchIsLoading"></mat-spinner>
            <struct-icon-button icon="remove" iconSize="16" (click)="quickSearchInputText = ''" *ngIf="quickSearchInputText !== '' && !quickSearchIsLoading"></struct-icon-button>
          </div>
        </div>
        <!-- <label for="search" class="block text-sm font-medium text-gray-400 mt-2">Search for products, variants, assets, users and much more.</label> -->
        <div class="flex items-center justify-between mt-2">
          <button class="text-xs text-gray-500">Advanced search options</button>
          <button class="text-xs text-gray-500" (click)="quickSearchInputText = ''" *ngIf="quickSearchInputText !== ''">Clear search</button>
        </div>

        <nav class="h-full overflow-y-auto struct-scrollbar-dark mt-8" aria-label="Search suggestions">
          <h4 class="text-xl text-gray-400 font-medium mb-2">Suggestions</h4>
          <div class="relative">
            <ul role="list" class="relative z-0 divide-y divide-gray-700">
              <li class="">
                <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                  <div class="min-w-0 flex-1">
                    <a href="javascript:;" class="focus:outline-none" (click)="setSearchText('Nike Air Max 90')">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium">Nike Air Max 90</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                  <div class="min-w-0 flex-1">
                    <a href="javascript:;" class="focus:outline-none" (click)="setSearchText('Superwood SW04')">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium">Superwood SW04</p>
                    </a>
                  </div>
                </div>
              </li>
              <li class="">
                <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                  <div class="min-w-0 flex-1">
                    <a href="javascript:;" class="focus:outline-none" (click)="setSearchText('BOSS Business Wear T-Shirt Round Neck')">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <p class="text-sm font-medium">BOSS Business Wear T-Shirt Round Neck</p>
                    </a>
                  </div>
                </div>
              </li>

            </ul>
          </div>

        </nav>
      </div>
      <div class="col-span-1 lg:col-span-3 p-8" *ngIf="quickSearchInputText === ''">
        <h3 class="text-2xl text-gray-200 font-medium">What are you looking for?</h3>
        <span class="text-sm text-gray-200 font-medium">You can search for products, variants, assets, users and much more.</span>
      </div>
      <div class="col-span-1 lg:col-span-3 p-8" *ngIf="quickSearchInputText !== ''">
        <div *ngIf="quickSearchIsLoading" class="relative">

          <h3 class="text-2xl text-gray-200 font-medium">Hang on!</h3>
          <span class="text-sm text-gray-200 font-medium">We're trying to find "{{quickSearchInputText}}"</span>
        </div>

        <div *ngIf="!quickSearchIsLoading">
          <h3 class="text-2xl text-gray-200 font-medium">Here is what we could find on "{{quickSearchInputText}}"</h3>
          <mat-tab-group animationDuration="0ms" class="flex-grow" disableRipple class="quick-search-tab-group">
            <mat-tab>
              <ng-template mat-tab-label>
                Products
                <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100">8</span>
              </ng-template>
              <nav class="h-full overflow-y-auto struct-scrollbar-dark" aria-label="Search result">
                <div class="relative">
                  <ul role="list" class="relative z-0 divide-y divide-gray-700">
                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Nike Air <span class="text-gray-100">{{quickSearchInputText}}</span> 70</p>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Superwood <span class="text-gray-100">{{quickSearchInputText}}</span> SW04</p>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">BOSS <span class="text-gray-100">{{quickSearchInputText}}</span> T-Shirt</p>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Dell Laptop - <span class="text-gray-100">{{quickSearchInputText}}</span> 2023</p>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Dell Desktop - <span class="text-gray-100">{{quickSearchInputText}}</span> 2023</p>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Dell Server - <span class="text-gray-100">{{quickSearchInputText}}</span> 2023</p>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Struct Publication - <span class="text-gray-100">{{quickSearchInputText}}</span> template</p>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="">
                      <div class="relative flex items-center space-x-3 px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-700 text-gray-400 hover:text-white">
                        <div class="min-w-0 flex-1">
                          <a href="#" class="focus:outline-none flex items-center space-x-2">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <div class="flex-shrink-0">
                              <img class="h-5 w-5 rounded-full" src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/43e6ec65-7ab1-4362-99c4-8ddaf6253221/air-max-90-shoes-qnqLqk.png" alt="">
                            </div>
                            <p class="text-sm font-medium">Wine Rosa <span class="text-gray-100">{{quickSearchInputText}}</span> - 1996</p>
                          </a>
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>

              </nav>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                Variants
                <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100">68</span>
              </ng-template>
            </mat-tab>
            <mat-tab label="Collections">
              <ng-template mat-tab-label>
                Collections
                <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100">1</span>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>


  </div>

  <div class="quick-task bg-gray-800 z-50 absolute top-14 left-0 w-full shadow-lg" *ngIf="quickTaskActive">
    <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 bg-gray-700/50 p-8">

        <div class="flex items-center text-gray-300 px-2">
          <div class="flex-auto font-semibold">January 2023</div>
          <struct-icon-button icon="chevron_left" iconSize="16"></struct-icon-button>
          <struct-icon-button icon="chevron_right" iconSize="16"></struct-icon-button>
        </div>
        <div class="mt-4 grid grid-cols-7 text-xs text-center leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div class="isolate mt-2 grid grid-cols-7 gap-px text-sm">
          <button type="button" class="rounded-tl-lg bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2021-12-27" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">27</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2021-12-28" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">28</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2021-12-29" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">29</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2021-12-30" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">30</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2021-12-31" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">31</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-01" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">1</time>
          </button>
          <button type="button" class="rounded-tr-lg bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-01" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">2</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-02" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">3</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-04" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">4</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-05" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">5</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-06" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">6</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-07" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">7</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-08" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">8</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10 relative">
            <time datetime="2022-01-09" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">9</time>
            <div class="absolute bottom-1 w-full flex space-x-1 items-center justify-center">
              <span class="w-2 h-2 rounded-full bg-gray-500" #tooltip="matTooltip" matTooltip="Send publication template to review" [matTooltipPosition]="'below'"></span>
              <span class="w-2 h-2 rounded-full bg-gray-500" #tooltip="matTooltip" matTooltip="Set filter images on all categories" [matTooltipPosition]="'below'"></span>
            </div>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-10" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">10</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-11" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">11</time>
          </button>
          <button type="button" class="bg-gray-700 py-1.5 font-semibold text-gray-50 hover:bg-gray-700 focus:z-10 relative">
            <time datetime="2022-01-12" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">12</time>

          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-13" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">13</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-14" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">14</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-15" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">15</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-16" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">16</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-17" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">17</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-18" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">18</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-19" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">19</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-20" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">20</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-21" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">21</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10 relative">
            <time datetime="2022-01-22" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">22</time>
            <div class="absolute bottom-1 w-full flex space-x-1 items-center justify-center">
              <span class="w-2 h-2 rounded-full bg-green-500" #tooltip="matTooltip" matTooltip="Manage supplier data" [matTooltipPosition]="'below'"></span>
            </div>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-23" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">23</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-24" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">24</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-25" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">25</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-26" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">26</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-27" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">27</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10 relative">
            <time datetime="2022-01-28" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">28</time>
            <div class="absolute bottom-1 w-full flex space-x-1 items-center justify-center">
              <span class="w-2 h-2 rounded-full bg-green-500" #tooltip="matTooltip" matTooltip="Send API keys to supplier" [matTooltipPosition]="'below'"></span>
            </div>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-29" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">29</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-30" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">30</time>
          </button>
          <button type="button" class="rounded-bl-lg bg-gray-800/50 py-1.5 text-gray-100 hover:bg-gray-700 focus:z-10">
            <time datetime="2022-01-31" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">31</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-01" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">1</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-02" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">2</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-03" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">3</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-04" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">4</time>
          </button>
          <button type="button" class="bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-05" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">5</time>
          </button>
          <button type="button" class="rounded-br-lg bg-gray-800/50 py-1.5 text-gray-700 hover:bg-gray-800/50 focus:z-10">
            <time datetime="2022-02-06" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">6</time>
          </button>
        </div>
        <div class="flex items-center space-x-4 mt-4">
          <button class="w-full text-gray-300 flex items-center py-4 px-4 justify-center border-2 border-gray-700 border-dashed rounded-md hover:border-gray-500"><struct-icon icon="add" size="16" class="mr-2"></struct-icon>Add task</button>
        </div>

      </div>

      <div class="p-8">
        <h4 class="text-gray-300 font-medium mb-4 flex items-center">In progress <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100 ng-star-inserted">6</span></h4>
        <div class="overflow-hidden bg-gray-800/50 shadow sm:rounded-md">
          <ul role="list" class="bg-gray-700/50 divide-y divide-gray-700">
            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Check inventory on discontinued items</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Update descriptions on all items in publications category</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Check inventory on discontinued items</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Update descriptions on all items in publications category</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Check inventory on discontinued items</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Update descriptions on all items in publications category</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-blue-700 px-2 text-xs font-semibold leading-5 text-blue-200">In progress</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

      </div>

      <div class="p-8">
        <h4 class="text-gray-300 font-medium mb-4 flex items-center">Not started <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100 ng-star-inserted">2</span></h4>
        <div class="overflow-hidden bg-gray-800/50 shadow sm:rounded-md">
          <ul role="list" class="bg-gray-700/50 divide-y divide-gray-700">
            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Check inventory on discontinued items</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-green-700 px-2 text-xs font-semibold leading-5 text-green-200">New</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Update descriptions on all items in publications category</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-green-700 px-2 text-xs font-semibold leading-5 text-green-200">New</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

      </div>

      <div class="p-8">
        <h4 class="text-gray-300 font-medium mb-4 flex items-center">Done <span class="ml-2 inline-flex items-center rounded-full bg-gray-700 px-2.5 py-0.5 text-xs font-medium text-gray-100 ng-star-inserted">48</span></h4>
        <div class="overflow-hidden bg-gray-800/50 shadow sm:rounded-md">
          <ul role="list" class="bg-gray-700/50 divide-y divide-gray-700">
            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Check inventory on discontinued items</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-gray-700 px-2 text-xs font-semibold leading-5 text-gray-200">Complete</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a href="#0" class="block hover:bg-gray-700 group">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="truncate font-medium text-sm text-gray-300 group-hover:text-gray-100">Update descriptions on all items in publications category</p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p class="inline-flex rounded-full bg-gray-700 px-2 text-xs font-semibold leading-5 text-gray-200">Complete</p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="multiple" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        Writers
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <struct-icon icon="comments" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                        0
                      </p>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar" size="12" class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"></struct-icon>
                      <p>
                        Deadline
                        <time datetime="2020-01-07">27 Jan 2023</time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>


  <div class="flex h-full">
    <!-- Narrow sidebar -->
    <div class="hidden w-28 overflow-y-auto bg-white md:block border-r border-gray-200">
      <div class="flex w-full flex-col items-center py-2">
        <div class="mt-0 w-full flex-1 space-y-4 px-2">

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="search" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Search</span>
          </a>

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="global_lists" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Global lists</span>
          </a>

          <a href="#" class="text-blue-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="catalogues" iconSize="20" class="rounded-full p-1 bg-blue-50 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Catalogues</span>
          </a>

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="collections" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Collections</span>
          </a>

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="import" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Import</span>
          </a>

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="settings" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Settings</span>
          </a>

          <a href="#" class="text-gray-600 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon-button icon="shopify_connect" iconSize="20" class="rounded-full p-1 group-hover:bg-gray-100"></struct-icon-button>
            <span class="text-xs mt-1">Shopify connect</span>
          </a>




          <!-- Current: "bg-blue-800 text-white", Default: "text-gray-600 hover:bg-blue-50 hover:text-blue-500" -->
          <!-- <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="Search in PIM" [matTooltipPosition]="'right'">
            <struct-icon icon="search" size="16"></struct-icon>
            <span class="text-xs mt-1">Search</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="Global lists" [matTooltipPosition]="'right'">
            <struct-icon icon="global_lists" size="16"></struct-icon>
            <span class="text-xs mt-1">Global lists</span>
          </a>

          <a href="#" class="bg-blue-50 text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" aria-current="page" #tooltip="matTooltip" matTooltip="Product catalogues" [matTooltipPosition]="'right'">
            <struct-icon icon="catalogues" size="16"></struct-icon>
            <span class="text-xs mt-1">Catalogues</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="Collections" [matTooltipPosition]="'right'">
            <struct-icon icon="collections" size="16"></struct-icon>
            <span class="text-xs mt-1">Collections</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="Background tasks" [matTooltipPosition]="'right'">
            <struct-icon icon="background_tasks" size="16"></struct-icon>
            <span class="text-xs mt-1">Background tasks</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="Import & export" [matTooltipPosition]="'right'">
            <struct-icon icon="import" size="16"></struct-icon>
            <span class="text-xs mt-1">Import</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium"  #tooltip="matTooltip" matTooltip="Settings" [matTooltipPosition]="'right'">
            <struct-icon icon="settings" size="16"></struct-icon>
            <span class="text-xs mt-1">Settings</span>
          </a>

          <a href="#" class="text-gray-600 hover:bg-blue-50 hover:text-blue-500 group rounded h-16 justify-center text-center w-full p-3 flex flex-col items-center text-xs font-medium" #tooltip="matTooltip" matTooltip="ShopifyConnect" [matTooltipPosition]="'right'">
            <struct-icon icon="shopify_connect" size="16"></struct-icon>
            <span class="text-xs mt-1">Shopify connect</span>
          </a> -->
        </div>
      </div>
    </div>

    <aside class="hidden flex-col w-96 overflow-hidden border-r border-gray-200 bg-white lg:flex">
      <section *ngIf="activeCatalogue !== ''">
        <header class="relative flex h-20 flex-shrink-0 items-center bg-white border-b border-gray-200 z-40">
          <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2">
            <div class="min-w-0 flex-1 flex items-center pl-2 space-x-2">
              <button mat-icon-button (click)="setActiveCatalogue('', false)">
                <struct-icon icon="grid_view" size="12"></struct-icon>
              </button>

              <struct-icon icon="folder_filled" size="16" ngClass="{{activeCatalogueIsMaster ? 'text-blue-400' : 'text-gray-500'}}"></struct-icon>
              <span class="text-sm font-medium">{{activeCatalogue}}</span>
            </div>
            <div class="md:ml-10 flex flex-shrink-0 items-center">
              <struct-icon-button icon="add" #tooltip="matTooltip" matTooltip="Add category" [matTooltipPosition]="'below'"></struct-icon-button>
              <struct-icon-button icon="more_vert" [matMenuTriggerFor]="menu_tree_node_options"></struct-icon-button>
            </div>
          </div>
        </header>
        <div>
          <form action="#" method="POST">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <div class="w-full px-2 flex space-x-2 items-center relative group">
                <button mat-icon-button disabled></button>
                <!-- <struct-checkbox></struct-checkbox> -->
                <a href="javascript:;" class="flex-1 flex items-center space-x-2">
                  <struct-icon icon="folder_filled_open" size="16" class="text-yellow-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                  <span class="text-sm font-medium flex-grow truncate">{{node.item}}</span>
                  <struct-icon-button icon="more_vert" [matMenuTriggerFor]="menu_tree_node_options" class="opacity-0 group-hover:opacity-100"></struct-icon-button>
                </a>
              </div>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
              <div class="w-full pl-4 pr-2 flex space-x-2 items-center relative group">
                <button mat-icon-button
                        [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                    <struct-icon icon="minus" size="12" class="text-gray-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                    <struct-icon icon="plus" size="12" class="text-gray-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                </button>
                <a href="javascript:;" class="flex-1 flex items-center space-x-2">
                  <!-- <struct-checkbox></struct-checkbox> -->
                  <struct-icon icon="folder_filled_open" size="16" class="text-yellow-500" *ngIf="treeControl.isExpanded(node)"></struct-icon>
                  <struct-icon icon="folder_filled" size="16" class="text-yellow-500" *ngIf="!treeControl.isExpanded(node)"></struct-icon>
                  <span class="text-sm font-medium flex-grow truncate">{{node.item}}</span>
                  <struct-icon-button icon="more_vert" [matMenuTriggerFor]="menu_tree_node_options" class="opacity-0 group-hover:opacity-100"></struct-icon-button>
                </a>


              </div>
              <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="struct-tree-progress-bar absolute bottom-0 left-0 w-full"></mat-progress-bar>
            </mat-tree-node>

          </mat-tree>

          <mat-menu #menu_tree_node_options="matMenu" class="text-sm">


            <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
              <struct-icon icon="content_copy" size="16"></struct-icon>
              <span>Copy</span>
            </button>

            <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
              <struct-icon icon="move_down" size="16"></struct-icon>
              <span>Move</span>
            </button>
            <button mat-menu-item class="text-sm flex items-center h-10 leading-tight">
              <struct-icon icon="delete" size="16"></struct-icon>
              <span>Delete</span>
            </button>

          </mat-menu>
        </form>
        </div>
      </section>

      <section *ngIf="activeCatalogue === ''">
        <header class="relative flex h-20 flex-shrink-0 items-center bg-white border-b  border-gray-200 z-40">
          <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2">
            <div class="min-w-0 flex-1 flex items-center">
              <span class="text-sm font-medium ml-2">Your catalogues</span>
            </div>
            <div class="md:ml-10 flex flex-shrink-0 items-center">
              <struct-icon-button icon="add" size="16" #tooltip="matTooltip" matTooltip="Add catalogue" [matTooltipPosition]="'below'"></struct-icon-button>
              <struct-icon-button icon="more_vert" size="16"></struct-icon-button>
            </div>
          </div>
        </header>
        <div class="grid grid-cols-2 gap-2 p-2">

          <div class="text-gray-700 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative cursor-pointer"
                      #tooltip="matTooltip"
                      matTooltip="Internal catalogue (master)
                      764 products
                      2889 variants"
                      [matTooltipPosition]="'below'"
                      (click)="setActiveCatalogue('Internal catalogue', true)">
            <div class="relative">
              <struct-icon-button icon="folder_filled" iconSize="48" class="w-24 h-24 flex items-center justify-center rounded-full p-4 group-hover:bg-gray-50 text-blue-300"></struct-icon-button>
              <span class="absolute top-16 right-4 px-1 rounded-full bg-gray-100 text-gray-800 text-xs font-medium">764</span>
              <span class="absolute top-8 right-4 p-1 rounded-full bg-gray-100/50 text-blue-700 text-xs font-medium">
                <struct-icon icon="settings" size="12"></struct-icon>
              </span>
            </div>
            <span class="text-xs mt-1">Internal catalogue (master)</span>
        </div>

          <div class="text-gray-700 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative cursor-pointer"
                      #tooltip="matTooltip"
                      matTooltip="Electronics site
                      98 products
                      322 variants"
                      [matTooltipPosition]="'below'"
                      (click)="setActiveCatalogue('Electronics site', false)">
            <div class="relative">
              <struct-icon-button icon="folder_filled" iconSize="48" class="w-24 h-24 flex items-center justify-center rounded-full p-4 group-hover:bg-gray-50 text-gray-500"></struct-icon-button>
              <span class="absolute top-16 right-4 px-1 rounded-full bg-gray-100 text-gray-800 text-xs font-medium">98</span>
            </div>

            <span class="text-xs mt-1">Electronics site</span>
          </div>

          <div class="text-gray-700 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative cursor-pointer"
                      #tooltip="matTooltip"
                      matTooltip="Wine Channel
                      12 products
                      0 variants"
                      [matTooltipPosition]="'below'"
                      (click)="setActiveCatalogue('Wine Channel', false)">
            <div class="relative">
              <struct-icon-button icon="folder_filled" iconSize="48" class="w-24 h-24 flex items-center justify-center rounded-full p-4 group-hover:bg-gray-50 text-gray-500"></struct-icon-button>
              <span class="absolute top-16 right-4 px-1 rounded-full bg-gray-100 text-gray-800 text-xs font-medium">12</span>
            </div>
            <span class="text-xs mt-1">Wine Channel</span>
          </div>


        </div>
        <div class="grid grid-cols-2 gap-2 p-2 mt-4 pt-4 border-t border-gray-100">
          <div class="text-gray-700 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative"
              #tooltip="matTooltip"
              matTooltip="Unclassified
              2 products"
              [matTooltipPosition]="'below'">
            <div class="relative">
              <struct-icon-button icon="folder_filled" iconSize="48" class="w-24 h-24 flex items-center justify-center rounded-full p-4 group-hover:bg-gray-50 text-gray-300"></struct-icon-button>
              <span class="absolute top-16 right-4 px-1 rounded-full bg-gray-100 text-gray-800 text-xs font-medium">2</span>
            </div>
            <span class="text-xs mt-1">Unclassified</span>
          </div>

          <div class="text-gray-700 p-2 pb-4 group justify-center text-center w-full flex flex-col items-center text-xs font-medium relative"
                #tooltip="matTooltip"
                matTooltip="Deleted
                26 products
                8 variants"
                [matTooltipPosition]="'below'">
            <div class="relative">
              <struct-icon-button icon="trash_48" iconSize="48" class="w-24 h-24 flex items-center justify-center rounded-full p-4 group-hover:bg-gray-50 text-gray-500"></struct-icon-button>
              <span class="absolute top-16 right-4 px-1 rounded-full bg-gray-100 text-gray-800 text-xs font-medium">26</span>
            </div>
            <span class="text-xs mt-1">Deleted</span>
          </div>
        </div>
      </section>
    </aside>



    <!-- Content area -->
    <div class="flex flex-1 flex-col overflow-hidden relative">


      <!-- Main content -->
      <div class="flex flex-1 items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto">
          <accounts-product-page></accounts-product-page>
        </main>

      </div>
    </div>
  </div>

</div>
