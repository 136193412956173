<div class="gap-4 md:grid md:grid-cols-3 lg:grid-cols-12 md:gap-4 mt-8 sm:mt-0">
  <div class="md:col-span-1 lg:col-span-4 xl:col-span-2 2xl:col-span-1">
    <div class="px-4 sm:px-0 sticky top-5">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        <ng-content select="accounts-form-section-title"></ng-content>
      </h3>
      <p class="mt-1 text-sm text-gray-600">
          <ng-content select="accounts-form-section-description"></ng-content>
      </p>
    </div>
  </div>
  <div class="mt-5 md:mt-0 md:col-span-2 lg:col-span-8 xl:col-span-10 2xl:col-span-11">
    <div class="sm:rounded sm:overflow-hidden">
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
          <ng-content select="accounts-form-section-content"></ng-content>
      </div>
    </div>
  </div>
</div>