
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExcelColumn } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/excel-column";
import { ExcelDataImportDetails } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/excel-data-import-details";
import { ListAttributeReference } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/list-attribute-reference";
import { ImportRequest } from "@struct/models/struct/app/domain/fileimport/importers/listattributeexcelimporter/import-request";
import { StructEntityField } from "@struct/models/struct/app/domain/fileimport/importers/listattributeexcelimporter/struct-entity-field";
import { UpdateReport } from "@struct/models/struct/app/domain/fileimport/importers/listattributeexcelimporter/update-report";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";

@Injectable({ providedIn: "root" })
export class ListAttributeExcelImportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getListAttributes(entityType: StructEntityType): Observable<ListAttributeReference[]> {
    return this.http.get<ListAttributeReference[]>(`${this.apiUrl}listattributeexcelimport/listattributes?entityType=${entityType}`, this.httpOptions );
  }

  getListAttributeFields(listAttributeUid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}listattributeexcelimport/listattributefields?listAttributeUid=${listAttributeUid}`, this.httpOptions );
  }

  getEntityFields(entityType: StructEntityType): Observable<StructEntityField[]> {
    return this.http.get<StructEntityField[]>(`${this.apiUrl}listattributeexcelimport/entityfields?entityType=${entityType}`, this.httpOptions );
  }

  getExcelColumns(uid: string): Observable<ExcelColumn[]> {
    return this.http.get<ExcelColumn[]>(`${this.apiUrl}listattributeexcelimport/excelcolums?uid=${uid}`, this.httpOptions );
  }

  getUpdateReport(model: ImportRequest): Observable<UpdateReport> {
    return this.http.post<UpdateReport>(`${this.apiUrl}listattributeexcelimport/updatereport`, model, this.httpOptions);
  }

  getExcelDataImportDetails(uid: string): Observable<ExcelDataImportDetails> {
    return this.http.get<ExcelDataImportDetails>(`${this.apiUrl}listattributeexcelimport/exceldataimportdetails?uid=${uid}`, this.httpOptions );
  }

  commitImport(model: ImportRequest): Observable<ExcelDataImportDetails> {
    return this.http.post<ExcelDataImportDetails>(`${this.apiUrl}listattributeexcelimport/commitimport`, model, this.httpOptions);
  }

  downloadFile(dataImportUid: string, fileUid: string): Observable<ExcelDataImportDetails> {
    return this.http.post<ExcelDataImportDetails>(`${this.apiUrl}listattributeexcelimport/downloadfile?dataImportUid=${dataImportUid}&fileUid=${fileUid}`, null, {responseType:'blob' as 'json', withCredentials: true});
  }

  getMedia(dataImportUid: string, mediaName: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}listattributeexcelimport/media?dataImportUid=${dataImportUid}&mediaName=${encodeURIComponent(mediaName)}`, {responseType:'blob' as 'json', withCredentials: true} );
  }

  downloadMedia(dataImportUid: string, mediaName: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}listattributeexcelimport/downloadmedia?dataImportUid=${dataImportUid}&mediaName=${encodeURIComponent(mediaName)}`, null, {responseType:'blob' as 'json', withCredentials: true});
  }
}

