<section class="w-full" [ngClass]="showMarketing ? 'bg-white' : 'bg-gray-50'">

  <!-- Grid -->
  <div class="h-screen w-full grid" [ngClass]="showMarketing ? 'grid-cols-2' : 'grid-cols-1'">
    <!-- <div class="absolute inset-0 bg-[url('/assets/images/login/hero.png')] bg-right-bottom bg-no-repeat"></div> -->

    <!-- First column -->
     <div class="flex flex-col h-full">
      <div class="p-4 flex flex-grow-0 border-b border-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-8 h-8 text-blue-200">
          <g>
            <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path>
            <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path>
            <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path>
          </g>
        </svg>
      </div>

      <div class="flex items-center justify-center flex-grow">

        <div class="p-4 lg:p-12 w-full lg:max-w-[500px] items-center relative @container/workarea bg-white rounded" [ngClass]="showMarketing ? '' : 'shadow-xl'">
          <div class="absolute inset-2 z-10 bg-white/90 flex items-center justify-center" *ngIf="working">
            <struct-spinner></struct-spinner>
          </div>
          <div class="flex flex-col w-full">


            <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8">{{"Login.Title" | translate}}</h1>
            <!-- <h2 class="text-lg font-medium mb-8">{{"Login.Subtitle" | translate}}</h2> -->

            <div *ngIf="authFailed" class="bg-red-50 rounded -mx-4 -mt-4 px-4 py-2 mb-8 text-red-600 animate-in slide-in-from-bottom-4">
              <p class="font-medium">{{"Login.AuthFailed" | translate}}</p>
              <p *ngIf="errorMessageKey !== null">{{"Login." + errorMessageKey | translate}}</p>
            </div>

            <form [formGroup]="loginForm" (submit)="signIn()">

            <struct-form-layout>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline>{{"Login.Email" | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{"Login.EmailDescription" | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="4">
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>{{"Login.Email" | translate}}</mat-label>
                      <input matInput [placeholder]="'Login.Email' | translate"  appearance="outline" name="email" formControlName="email" />
                      <mat-error *ngIf="loginForm.controls['email'].invalid">{{"Login.EmailIsInvalid" | translate}}</mat-error>
                    </mat-form-field>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>

              </struct-form-row>

              <struct-form-row [borderBelow]="true">
                <struct-form-row-headline>{{"Login.Password" | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{"Login.PasswordDescription" | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>{{"Login.Password" | translate}}</mat-label>
                      <input matInput [placeholder]="'Login.Password' | translate"  appearance="outline" type="password" name="password" formControlName="password" autocomplete="on" />
                    </mat-form-field>
                    <div class="flex justify-end w-full mt-2">
                      <a class="text-xs text-gray-300 hover:text-gray-500" [routerLink]="['/forgot-password']">{{"Login.ForgotPassword" | translate}}</a>
                    </div>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>

            </struct-form-layout>
            <div class="flex items-center">
              <button mat-flat-button color="primary" [disabled]="!loginForm.valid" type="submit" class="w-full h-10">{{"Login.Submit" | translate}}</button>


            </div>

            </form>

            <div>
              <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-200"></div>
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="bg-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-1 gap-4">

                <!-- <a href="#" class="flex w-full items-center justify-center gap-3 rounded bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <g fill="currentColor">
                      <path d="M15.671,6.545H8.035V9.818h4.328C11.671,12,9.962,12.727,8,12.727a4.727,4.727,0,1,1,3.035-8.346l2.378-2.265A8,8,0,1,0,8,16C12.411,16,16.4,13.091,15.671,6.545Z" fill="currentColor"></path>
                    </g>
                  </svg>

                  <span class="text-sm font-semibold leading-6">Google</span>
                </a> -->

                <a (click)="signInUsingMicrosoft()" class="flex cursor-pointer w-full items-center justify-center gap-3 rounded bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g fill="currentColor"><rect x="1" y="1" fill="currentColor" width="10" height="10"></rect> <rect x="13" y="1" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 12)" fill="currentColor" width="10" height="10"></rect> <rect x="1" y="13" fill="currentColor" width="10" height="10"></rect> <rect x="13" y="13" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 36)" fill="currentColor" width="10" height="10"></rect></g>
                  </svg>

                  <span class="text-sm font-semibold leading-6">Microsoft</span>
                </a>

              </div>
            </div>






          </div>
        </div>


      </div>

      <div class="p-4 flex flex-grow-0 border-t border-gray-100">
        <div class="">
          <div class="flex gap-4">
            <!-- <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Help" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Privacy" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Terms" | translate}}</a> -->
          </div>
        </div>
        <div class="ml-auto">
          <a class="text-sm text-gray-500 hover:underline hover:text-blue-500 cursor-pointer" (click)="toggleMarketingColumn()">
            <struct-icon icon="hide"></struct-icon>
          </a>
        </div>
      </div>
     </div>
    <!-- First column -->

    <!-- Second column -->

    <struct-carousel class="usein flex w-full h-full relative bg-gray-50" *ngIf="showMarketing"
                 [itemCount]="4"
                 [itemsPerPage]="1"
                 [interval]="10000"
                 [gap]="0"
                 [freezePeriod]="5000"
                 [showHandles]="false"
                 [mainColor]="'--color-blue-200'"
                 [secondColor]="'--color-red-500'">
      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">
        <div class="relative">
          <div class="grid grid-cols-1">
            <img src="./assets/images/sign-in/next-gen-v4.png" class="animate-in slide-in-from-bottom-12 duration-1000" />
          </div>

        </div>
        <div class="absolute bottom-0 left-0 right-0 p-16 flex items-end justify-between bg-gray-50/75">
          <div class="text-gray-900 flex flex-col gap-2 max-w-3xl">
            <p class="font-medium text-lg animate-in slide-in-from-bottom-4 duration-1000 mb-8">Introducing Struct PIM 4</p>
            <h1 class="text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-medium animate-in slide-in-from-bottom-4 duration-1000 tracking-tight">The future of PIM is here</h1>
          </div>

          <div class="">
            <div class="animate-in slide-in-from-bottom-4 duration-1000">
              <a href="https://struct.com/product-updates/a-first-look-at-struct-pim-4/" target="_blank" class="flex items-center gap-2 text-gray-900 hover:text-blue-500 text-xl">
                <span>Read blogpost</span>
                <struct-icon icon="arrow_right"></struct-icon>
              </a>
            </div>
          </div>

        </div>


      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">
        <div class="relative">
          <div class="grid grid-cols-1">
            <img src="./assets/images/sign-in/cutting-edge-tech.png" class="animate-in slide-in-from-bottom-12 duration-1000" />
          </div>

        </div>
        <div class="absolute bottom-0 left-0 right-0 p-16 flex items-end justify-between bg-gray-50/75">
          <div class="text-gray-900 flex flex-col gap-2 max-w-3xl">
            <p class="font-medium text-lg animate-in slide-in-from-bottom-4 duration-1000 mb-8">Introducing Struct PIM 4</p>
            <h1 class="text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-medium animate-in slide-in-from-bottom-4 duration-1000 tracking-tight">New cutting-edge technologies</h1>
          </div>

          <div class="">
            <div class="animate-in slide-in-from-bottom-4 duration-1000">
              <a href="https://struct.com/product-updates/a-first-look-at-struct-pim-4/" target="_blank" class="flex items-center gap-2 text-gray-900 hover:text-blue-500 text-xl">
                <span>Read blogpost</span>
                <struct-icon icon="arrow_right"></struct-icon>
              </a>
            </div>
          </div>

        </div>

      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">
        <div class="relative">
          <div class="grid grid-cols-1">
            <img src="./assets/images/sign-in/multi-tenant.png" class="animate-in slide-in-from-bottom-12 duration-1000" />
          </div>

        </div>

        <div class="absolute bottom-0 left-0 right-0 p-16 flex items-end justify-between bg-gray-50/75">
          <div class="text-gray-900 flex flex-col gap-2 max-w-3xl">
            <p class="font-medium text-lg animate-in slide-in-from-bottom-4 duration-1000 mb-8">Introducing Struct PIM 4</p>
            <h1 class="text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-medium animate-in slide-in-from-bottom-4 duration-1000 tracking-tight">Enhanced multi-tenant cloud architecture</h1>
          </div>

          <div class="">
            <div class="animate-in slide-in-from-bottom-4 duration-1000">
              <a href="https://struct.com/product-updates/a-first-look-at-struct-pim-4/" target="_blank" class="flex items-center gap-2 text-gray-900 hover:text-blue-500 text-xl">
                <span>Read blogpost</span>
                <struct-icon icon="arrow_right"></struct-icon>
              </a>
            </div>
          </div>

        </div>

      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">
        <div class="relative">
          <div class="grid grid-cols-1">
            <img src="./assets/images/sign-in/ui.png" class="animate-in slide-in-from-bottom-12 duration-1000" />
          </div>

        </div>

        <div class="absolute bottom-0 left-0 right-0 p-16 flex items-end justify-between bg-gray-50/75">
          <div class="text-gray-900 flex flex-col gap-2 max-w-3xl">
            <p class="font-medium text-lg animate-in slide-in-from-bottom-4 duration-1000 mb-8">Introducing Struct PIM 4</p>
            <h1 class="text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-medium animate-in slide-in-from-bottom-4 duration-1000 tracking-tight">Best-in-class UI design</h1>
          </div>

          <div class="">
            <div class="animate-in slide-in-from-bottom-4 duration-1000">
              <a href="https://struct.com/product-updates/a-first-look-at-struct-pim-4/" target="_blank" class="flex items-center gap-2 text-gray-900 hover:text-blue-500 text-xl">
                <span>Read blogpost</span>
                <struct-icon icon="arrow_right"></struct-icon>
              </a>
            </div>
          </div>

        </div>
      </figure>

    </struct-carousel>


  </div>
  <!-- Grid -->
</section>
<!-- Section: Split screen -->
