// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionSetup } from "@struct/models/struct/shared/subscription/subscription-setup";
import { SubscriptionStatus } from "@struct/models/struct/shared/subscription/subscription-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateSubscriptionCommand  {
  uid: string;
  name: string;
  status: SubscriptionStatus;
  subscriptionPlanUid: string;
  companyName: string;
  vatNumber: string;
  postal: string;
  city: string;
  address: string;
  address2?: string | null;
  country: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  invoiceEmail: string;
  paymentCurrency: string;
  firstInvoiceDate: Date | string;
  partnerUid?: string | null;
  setup?: SubscriptionSetup | null;
  overrideQuota: boolean;
  overrideOptionalFeatures: boolean;
}

export class UpdateSubscriptionCommand  implements IUpdateSubscriptionCommand {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  status: SubscriptionStatus = SubscriptionStatus.AwaitingApproval;

  subscriptionPlanUid = "00000000-0000-0000-0000-000000000000";

  companyName = "";

  vatNumber = "";

  postal = "";

  city = "";

  address = "";

  address2: string | null = null;

  country = "";

  contactPerson = "";

  contactPhone = "";

  contactEmail = "";

  invoiceEmail = "";

  paymentCurrency = "";

  firstInvoiceDate: Date | string = new Date(0);

  partnerUid: string | null = null;

  setup: SubscriptionSetup | null = null;

  overrideQuota = false;

  overrideOptionalFeatures = false;


  constructor(data?: Partial<IUpdateSubscriptionCommand>) {
    Object.assign(this, data);
  }
}