// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProvisionStatusMessage  {
  timestamp: Date | string;
  message: string;
}

export class ProvisionStatusMessage  implements IProvisionStatusMessage {
  timestamp: Date | string = new Date(0);

  message = "";


  constructor(data?: Partial<IProvisionStatusMessage>) {
    Object.assign(this, data);
  }
}