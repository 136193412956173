import { Component, Input } from '@angular/core';

export interface ConditionSet {
  name: string;
  type: string;
}

export interface Condition {
  name: string;
  type: string;
}

export enum QueryType {
  simple = 0,
  advanced = 1,
}

@Component({
  selector: 'struct-query',
  templateUrl: './struct-query.component.html',
  styleUrls: ['./struct-query.component.scss'],
})
export class StructQueryComponent {
  @Input() type: QueryType | null = 0;

  isRulesCut: boolean = false;

  conditionSets: ConditionSet[] = [
    {
      name: 'Condition set 1',
      type: 'System Id',
    },
    {
      name: 'Condition set 2',
      type: 'TextData',
    },
  ];

  conditions: Condition[] = [
    {
      name: 'user has permission "View background tasks"',
      type: 'System Id',
    },
    {
      name: 'user has permission "Manage collections"',
      type: 'TextData',
    },
    {
      name: 'user has permission "Browse collections"',
      type: 'FixedListData',
    },
  ];

  public toggleRulesCut(): void {
    this.isRulesCut = !this.isRulesCut;
  }

  protected readonly QueryType = QueryType;
}
