// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TextQueryOperator {
  Equals = "Equals",
  StartsWith = "StartsWith",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  Contains = "Contains",
  NotContains = "NotContains",
  NotEquals = "NotEquals",
  NotStartsWith = "NotStartsWith",
  InList = "InList",
  NotInList = "NotInList",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace TextQueryOperator {
  export function toString(value: TextQueryOperator): string {
    return TextQueryOperator[value];
  }

  export const values = (): TextQueryOperator[] => [
    TextQueryOperator.Equals,
    TextQueryOperator.StartsWith,
    TextQueryOperator.IsEmpty,
    TextQueryOperator.IsNotEmpty,
    TextQueryOperator.Contains,
    TextQueryOperator.NotContains,
    TextQueryOperator.NotEquals,
    TextQueryOperator.NotStartsWith,
    TextQueryOperator.InList,
    TextQueryOperator.NotInList,
  ];

  export function parse(value: string, ignoreCase?: boolean): TextQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'TextQueryOperator';
  }
}
