import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgOptimizedImage } from '@angular/common'

// misc imports
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DragToSelectModule } from 'ngx-drag-to-select';

// Material CDK imports
import { CdkMenuModule, CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Material imports
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';

// Struct imports
import { StructIconComponent } from '@struct/ui/struct-icon';
import { StructSignInComponent } from './struct-sign-in/struct-sign-in.component';
import { StructForgotPasswordComponent } from './struct-forgot-password/struct-forgot-password.component';
import { StructAvatarComponent } from '@struct/ui/struct-avatar';
import { StructNotificationService } from '@struct/ui/struct-notification';
import { InfoNotificationComponent } from '@struct/ui/struct-notification';
import { ErrorNotificationComponent } from '@struct/ui/struct-notification';
import { SuccessNotificationComponent } from '@struct/ui/struct-notification';
import { StructDatepickerComponent } from '@struct/ui/struct-form';
import { StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog';
import { StructSpinnerComponent } from '@struct/ui/struct-spinner';
import { StructInputComponent } from '@struct/ui/struct-form';
import { StructTextareaComponent } from '@struct/ui/struct-form';
import { StructButtonComponent } from '@struct/ui/struct-button';
import { StructCheckboxComponent } from '@struct/ui/struct-form';
import { StructInputSuffixComponent } from '@struct/ui/struct-form';
import { StructInputPrefixComponent } from '@struct/ui/struct-form';
import { StructIconButtonComponent } from '@struct/ui/struct-button';
import { StructFabButtonComponent } from '@struct/ui/struct-button';
import { StructMiniFabButtonComponent } from '@struct/ui/struct-button';
import { StructConfirmDialogService } from '@struct/ui/struct-confirm-dialog';
import { StructDialogService } from '@struct/ui/struct-dialog';
import { StructSimpleSelectComponent } from '@struct/ui/struct-form';
import { StructTableComponent } from '@struct/ui/struct-table';
import { StructPaginatorComponent } from '@struct/ui/struct-paginator';
import { StructSelectedItemsActionsComponent } from '@struct/ui/struct-table';
import { StructColumnsDialogComponent } from '@struct/ui/struct-table';
import { StructTextColumnComponent } from '@struct/ui/struct-table';
import { StructNumberColumnComponent } from '@struct/ui/struct-table';
import { StructPercentageColumnComponent } from '@struct/ui/struct-table';
import { StructMediaColumnComponent } from '@struct/ui/struct-table';
import { StructFixedlistColumnComponent } from '@struct/ui/struct-table';
import { StructUserUserGroupColumnComponent } from '@struct/ui/struct-table';
import { StructBooleanColumnComponent } from '@struct/ui/struct-table';
import { StructDateColumnComponent } from '@struct/ui/struct-table';
import { StructBadgeItemColumnComponent } from '@struct/ui/struct-table';
import { StructCommentsColumnComponent } from '@struct/ui/struct-table';
import { StructBooleanHeaderComponent } from '@struct/ui/struct-table';
import { StructTextHeaderComponent } from '@struct/ui/struct-table';
import { StructNumberHeaderComponent } from '@struct/ui/struct-table';
import { StructValuereferenceHeaderComponent } from '@struct/ui/struct-table';
import { StructDateHeaderComponent } from '@struct/ui/struct-table';
import { ResizableDirective } from '@struct/ui/directives';
import { StructAppSidebarComponent, StructAppSidebarPageMenuListComponent } from '@struct/ui/struct-app-sidebar';
import { DynamicDirective } from '@struct/ui/directives';
import { ScrollToBottomDirective } from '@struct/ui/directives';
import { StructAppSidebarMenuComponent } from '@struct/ui/struct-app-sidebar';
import { StructAppSidebarPageMenuComponent } from '@struct/ui/struct-app-sidebar';
import { StructAppContentHeaderComponent } from '@struct/ui/struct-app-content-area';
import { StructAppContentContainerComponent } from '@struct/ui/struct-app-content-area';
import { StructCultureColumnComponent } from '@struct/ui/struct-table';
import { StructCultureHeaderComponent } from '@struct/ui/struct-table';
import { StructTableActionsComponent } from '@struct/ui/struct-table';
import { StructAppContentFooterComponent } from '@struct/ui/struct-app-content-area';
import { StructAppContentComponent } from '@struct/ui/struct-app-content-area';
import { StructAppContentWorkareaComponent } from '@struct/ui/struct-app-content-area';
import { StructAppContentMetabarComponent } from '@struct/ui/struct-app-content-area';
import { StructFormRowComponent } from '@struct/ui/struct-form';
import { StructFormRowHeadlineComponent } from '@struct/ui/struct-form';
import { StructFormRowDescriptionComponent } from '@struct/ui/struct-form';
import { StructFormRowGridComponent } from '@struct/ui/struct-form';
import { StructFormRowGridTileComponent } from '@struct/ui/struct-form';
import { StructAppContentMetabarInfoRowComponent } from '@struct/ui/struct-app-content-area';
import { StructAppContentMetabarInfoComponent } from '@struct/ui/struct-app-content-area';
import { StructFormLayoutComponent } from '@struct/ui/struct-form';
import { StructSortingTableComponent } from '@struct/ui/struct-table/struct-sorting-table/struct-sorting-table.component';
import { StructFlagIconComponent } from './struct-flag-icon/struct-flag-icon.component';
import { StructFlagIconButtonComponent } from './struct-button/struct-flag-icon-button/struct-flag-icon-button.component';
import { StructFiletypeIconComponent } from './struct-filetype-icon/struct-filetype-icon.component';
import { StructAppContentFooterErrorComponent } from './struct-app-content-area/struct-app-content-footer-error/struct-app-content-footer-error.component';
import { StructIconChooserDialogComponent } from '@struct/ui/struct-icon-chooser-dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StructAppContentMetabarDefaultInfoPanelComponent } from './struct-app-content-area/struct-app-content-metabar-default-info-panel/struct-app-content-metabar-default-info-panel.component';
import { StructClassificationPickerComponent } from './struct-classification-picker/struct-classification-picker.component';
import { StructMediaPickerComponent } from './struct-media-picker/struct-media-picker.component';
import { StructMediaEditorDialogComponent } from './struct-media-editor-dialog';

import { StructRelationPickerComponent } from './struct-relation-picker/struct-relation-picker.component';
import { StructFilePickerComponent } from './struct-file-picker/struct-file-picker.component';
import { StructColorPickerComponent } from './struct-form/struct-color-picker/struct-color-picker.component';
import { StructPermissionPickerComponent } from './struct-form/struct-permission-picker/struct-permission-picker.component';
import { StructCulturePickerComponent } from './struct-form/struct-culture-picker/struct-culture-picker.component';
import { StructQueryComponent } from './struct-query/struct-query.component';
import { StructAddButtonComponent } from './struct-button/struct-add-button/struct-add-button.component';
import { StructAppContentHeaderImageComponent } from './struct-app-content-area/struct-app-content-header-image/struct-app-content-header-image.component';
import { StructAppDamHeaderComponent } from './struct-app-content-area/struct-app-dam-header/struct-app-dam-header.component';
import { StructAppDamSimpleHeaderComponent } from './struct-app-content-area/struct-app-dam-simple-header/struct-app-dam-simple-header.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { StructFileDragAndDropDirective } from './directives/struct-file-drag-and-drop.directive';
import { StructMediaPickerReplaceDialogComponent } from './struct-media-picker/struct-media-picker-replace-dialog/struct-media-picker-replace-dialog.component';
import { StructMediaPickerEditDialogComponent } from './struct-media-picker/struct-media-picker-edit-dialog/struct-media-picker-edit-dialog.component';
import { StructMediaPickerDownloadDialogComponent } from './struct-media-picker/struct-media-picker-download-dialog/struct-media-picker-download-dialog.component';
import { StructMediaPickerMaximizeDialogComponent } from './struct-media-picker/struct-media-picker-maximize-dialog/struct-media-picker-maximize-dialog.component';
import { StructMediaPickerAddNewDialogComponent } from './struct-media-picker/struct-media-picker-add-new-dialog/struct-media-picker-add-new-dialog.component';
import { StructLanguagePickerComponent } from './struct-form/struct-language-picker/struct-language-picker.component';
import { StructTranslatableAttributesPickerComponent } from './struct-form/struct-translatable-attributes-picker/struct-translatable-attributes-picker.component';
import { StructUserUserGroupPickerComponent } from './struct-form/struct-user-user-group-picker/struct-user-user-group-picker.component';
import { StructOperationTypePickerComponent } from './struct-form/struct-operation-type-picker/struct-operation-type-picker.component';
import { StructEntityTypePickerComponent } from './struct-form/struct-entity-type-picker/struct-entity-type-picker.component';
import { StructEntitySelectComponent } from './struct-form/struct-entity-select/struct-entity-select.component';
import { StructCarouselComponent } from './struct-carousel/struct-carousel.component';
import { StructIconPickerComponent } from './struct-form/struct-icon-picker/struct-icon-picker.component';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSliderModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DragDropModule,
    CdkMenuModule,
    CdkAccordionModule,
    CdkMenu,
    CdkMenuItem,
    MatTabsModule,
    MatExpansionModule,
    MatTreeModule,
    MatRadioModule,
    MatStepperModule,
    MatListModule,
    CdkTableModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatCardModule,
    MatChipsModule,
    MatStepperModule,
    ScrollingModule,
    DragToSelectModule.forRoot(),
  ],
  providers: [StructNotificationService, StructConfirmDialogService, StructDialogService, MatIconRegistry, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }],
  declarations: [
    AutofocusDirective,
    DynamicDirective,
    ScrollToBottomDirective,
    StructIconComponent,
    StructSignInComponent,
    StructForgotPasswordComponent,
    StructCarouselComponent,
    StructAvatarComponent,
    InfoNotificationComponent,
    ErrorNotificationComponent,
    SuccessNotificationComponent,
    StructDatepickerComponent,
    StructConfirmDialogComponent,
    StructSpinnerComponent,
    StructInputComponent,
    StructTextareaComponent,
    StructButtonComponent,
    StructCheckboxComponent,
    StructInputSuffixComponent,
    StructInputPrefixComponent,
    StructIconButtonComponent,
    StructFabButtonComponent,
    StructMiniFabButtonComponent,
    StructSimpleSelectComponent,
    StructTableComponent,
    StructPaginatorComponent,
    StructSelectedItemsActionsComponent,
    StructColumnsDialogComponent,
    StructTextColumnComponent,
    StructNumberColumnComponent,
    StructPercentageColumnComponent,
    StructMediaColumnComponent,
    StructFixedlistColumnComponent,
    StructUserUserGroupColumnComponent,
    StructBooleanColumnComponent,
    StructDateColumnComponent,
    StructBadgeItemColumnComponent,
    StructCommentsColumnComponent,
    StructBooleanHeaderComponent,
    StructTextHeaderComponent,
    StructNumberHeaderComponent,
    StructValuereferenceHeaderComponent,
    StructDateHeaderComponent,
    ResizableDirective,
    StructAppSidebarComponent,
    StructAppSidebarMenuComponent,
    StructAppSidebarPageMenuComponent,
    StructAppContentHeaderComponent,
    StructAppContentContainerComponent,
    StructCultureColumnComponent,
    StructCultureHeaderComponent,
    StructTableActionsComponent,
    StructAppContentFooterComponent,
    StructAppContentComponent,
    StructAppContentWorkareaComponent,
    StructAppContentMetabarComponent,
    StructFormRowComponent,
    StructFormRowHeadlineComponent,
    StructFormRowDescriptionComponent,
    StructFormRowGridComponent,
    StructFormRowGridTileComponent,
    StructAppContentMetabarInfoRowComponent,
    StructAppContentMetabarInfoComponent,
    StructFormLayoutComponent,
    StructSortingTableComponent,
    StructFlagIconComponent,
    StructFlagIconButtonComponent,
    StructFiletypeIconComponent,
    StructAppSidebarPageMenuListComponent,
    StructAppContentFooterErrorComponent,
    StructIconChooserDialogComponent,
    StructAppContentMetabarDefaultInfoPanelComponent,
    StructColorPickerComponent,
    StructIconPickerComponent,
    StructPermissionPickerComponent,
    StructCulturePickerComponent,
    StructEntityTypePickerComponent,
    StructQueryComponent,
    StructAddButtonComponent,
    StructAppContentHeaderImageComponent,
    StructAppDamHeaderComponent,
    StructAppDamSimpleHeaderComponent,
    StructClassificationPickerComponent,
    StructMediaPickerComponent,
    StructFilePickerComponent,
    StructRelationPickerComponent,
    StructFileDragAndDropDirective,
    StructMediaPickerReplaceDialogComponent,
    StructMediaPickerEditDialogComponent,
    StructMediaPickerDownloadDialogComponent,
    StructMediaPickerMaximizeDialogComponent,
    StructMediaPickerAddNewDialogComponent,
    StructLanguagePickerComponent,
    StructTranslatableAttributesPickerComponent,
    StructUserUserGroupPickerComponent,
    StructOperationTypePickerComponent,
    StructMediaEditorDialogComponent,
    StructEntitySelectComponent,
    StructCarouselComponent,
  ],
  exports: [
    StructFileDragAndDropDirective,
    AutofocusDirective,
    DynamicDirective,
    StructCarouselComponent,
    ScrollToBottomDirective,
    StructQueryComponent,
    StructAddButtonComponent,
    StructIconComponent,
    StructAvatarComponent,
    StructDatepickerComponent,
    StructSpinnerComponent,
    StructInputComponent,
    StructTextareaComponent,
    StructCheckboxComponent,
    StructInputSuffixComponent,
    StructInputPrefixComponent,
    StructFormRowComponent,
    StructFormRowHeadlineComponent,
    StructFormRowDescriptionComponent,
    StructFormRowGridComponent,
    StructFormRowGridTileComponent,
    StructFormLayoutComponent,
    StructButtonComponent,
    StructIconButtonComponent,
    StructSignInComponent,
    StructForgotPasswordComponent,
    StructFabButtonComponent,
    StructMiniFabButtonComponent,
    StructSimpleSelectComponent,
    StructTableComponent,
    StructPaginatorComponent,
    StructSelectedItemsActionsComponent,
    StructConfirmDialogComponent,
    StructAppSidebarComponent,
    StructAppDamHeaderComponent,
    StructAppDamSimpleHeaderComponent,
    StructAppContentHeaderComponent,
    StructAppContentHeaderImageComponent,
    StructAppContentContainerComponent,
    StructAppContentMetabarInfoComponent,
    StructAppContentFooterComponent,
    StructAppContentFooterErrorComponent,
    StructAppContentComponent,
    StructAppContentWorkareaComponent,
    StructAppContentMetabarInfoRowComponent,
    StructAppContentMetabarComponent,
    StructAppSidebarPageMenuListComponent,
    StructTableActionsComponent,
    StructSortingTableComponent,
    StructFlagIconComponent,
    StructFlagIconButtonComponent,
    StructFiletypeIconComponent,
    StructIconChooserDialogComponent,
    StructClassificationPickerComponent,
    StructMediaPickerComponent,
    StructFilePickerComponent,
    StructRelationPickerComponent,
    StructMediaEditorDialogComponent,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    MatSliderModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DragDropModule,
    CdkMenuModule,
    CdkAccordionModule,
    CdkMenu,
    CdkMenuItem,
    MatTabsModule,
    MatExpansionModule,
    MatTreeModule,
    MatRadioModule,
    MatStepperModule,
    MatListModule,
    CdkTableModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    StructAppContentMetabarDefaultInfoPanelComponent,
    StructColorPickerComponent,
    StructIconPickerComponent,
    StructPermissionPickerComponent,
    StructCulturePickerComponent,
    StructAddButtonComponent,
    StructLanguagePickerComponent,
    StructTranslatableAttributesPickerComponent,
    StructUserUserGroupPickerComponent,
    StructOperationTypePickerComponent,
    StructEntityTypePickerComponent,
    StructEntitySelectComponent,
  ],
})
export class UiModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('struct-icon', 'struct-icon');
  }
}
