export class LinkSidebarItem {
  icon: string;
  label: string;
  route: string;
  activeOnRoute: string[];
  useMaterialIcons = false;

  constructor(icon: string, label: string, route: string, useMaterialIcons = false) {
    this.icon = icon;
    this.label = label;
    this.route = route;
    this.activeOnRoute = [route];
    this.useMaterialIcons = useMaterialIcons;
  }
}
