import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { of, switchMap } from 'rxjs';
import { environment } from 'apps/accounts/src/environments/environment';

@Component({
  selector: 'accounts-change-sign-in-to-microsoft-callback',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, RouterModule],
  templateUrl: './change-sign-in-to-microsoft-callback.component.html',
})
export class ChangeSignInToMicrosoftCallbackComponent implements OnInit {
  private tenant: string | null = null;
  errorMsg: string | undefined = undefined;
  loading = true;
  showError = false;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    console.log('ChangeSignInToMicrosoftCallbackComponent');
    this.authService
      .handleEntraIdChangeToMicrosoftCallback()
      .pipe(
        switchMap(authResult => {
          console.log('authResult', authResult);
          if (authResult.success) {
            return this.authService.changeSignInToMicrosoft(authResult.accessToken);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((response) => {
        if(response?.succeeded){
          window.location.href = environment.accountAppUrl;
        }
        else{
          this.showError = true;
          this.errorMsg = response?.errors[0].message;
        }
        this.loading = false;
      });
  }
}
