<div class="struct-media-picker-container" cdkDropList (cdkDropListDropped)="sortDrop($event)" structFileDragAndDrop (fileDropped)="onFileDropped($event)" [cdkContextMenuTriggerFor]="container_context_menu">
	<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($any($event.target).files)" />


  <div class="relative w-full flex-shrink-0 rounded bg-white border-2 border-gray-100 text-center hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 overflow-hidden h-auto group z-10"
  *ngFor="let file of files; let i = index"
  [cdkContextMenuTriggerFor]="media_item_context_menu"
  cdkDrag
  >
  <div class="w-full h-full bg-gray-100 border-2 border-gray-200" *cdkDragPlaceholder></div>

    <div class="absolute top-0 left-0 right-0" *ngIf="file?.progress < 100">
      <div class="w-full bg-gray-200 h-1 dark:bg-gray-700">
        <div class="bg-blue-500 h-1 transition-all" [ngStyle]="{'width': file?.progress + '%'}"></div>
      </div>
    </div>

    <div class="flex items-center justify-center h-48 transition-opacity duration-200" [ngClass]="{'opacity-25': file?.progress < 100}">
      <img [src]="file?.url" alt="" class="w-auto h-auto max-h-full max-w-full" />
    </div>

    <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full px-2 py-2 flex items-end justify-center -space-x-2 bg-gradient-to-t from-white to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <!-- <p class="text-sm">Src: {{ file?.url }}</p> -->
        <struct-icon-button icon="edit" class="" (click)="editFile(i)"></struct-icon-button>
        <struct-icon-button icon="delete" class="" (click)="deleteFile(i)"></struct-icon-button>
        <struct-icon-button icon="more_vert" class="ml-auto" [matMenuTriggerFor]="mediaMoreMenu"></struct-icon-button>
    </div>

    <mat-menu #mediaMoreMenu="matMenu" class="w-96">
      <div class="flex flex-col p-4 bg-gray-100">
        <h3 class="text-lg font-medium truncate">{{ file?.name }}</h3>
        <p class="text-sm">Size: {{ file?.size }}</p>
        <p class="text-sm">Type: {{ file?.type }}</p>
      </div>
      <button mat-menu-item (click)="replaceFile(i)">Replace</button>
      <button mat-menu-item (click)="editFile(i)">Edit</button>
      <button mat-menu-item (click)="deleteFile(i)">Delete</button>
      <button mat-menu-item (click)="goToFile(i)">Go to media</button>
      <button mat-menu-item (click)="maximizeFile(i)">Maximize</button>
      <button mat-menu-item (click)="downloadFile(i)">Download</button>
    </mat-menu>

    <ng-template #media_item_context_menu>
      <div class="struct-context-menu w-72" cdkMenu>
        <div class="flex flex-col p-4 bg-gray-100 -mt-2">
          <h3 class="text-lg font-medium truncate">{{ file?.name }}</h3>
          <p class="text-sm">Size: {{ file?.size }}</p>
          <p class="text-sm">Type: {{ file?.type }}</p>
        </div>
        <button class="struct-context-menu-item" cdkMenuItem (click)="replaceFile(i)">
          <struct-icon icon="swap" size="12"></struct-icon>
          <span>Replace</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem (click)="editFile(i)">
          <struct-icon icon="edit" size="12"></struct-icon>
          <span>Edit</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem (click)="deleteFile(i)">
          <struct-icon icon="remove" size="12"></struct-icon>
          <span>Delete</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem (click)="goToFile(i)">
          <struct-icon icon="link" size="12"></struct-icon>
          <span>Go to file</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem (click)="maximizeFile(i)">
          <struct-icon icon="maximize" size="12"></struct-icon>
          <span>Maximize</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem (click)="downloadFile(i)">
          <struct-icon icon="download" size="12"></struct-icon>
          <span>Download</span>
        </button>
        <hr />
        <button class="struct-context-menu-item" cdkMenuItem>
          <struct-icon icon="cut" size="12"></struct-icon>
          <span>Cut</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem>
          <struct-icon icon="copy" size="12"></struct-icon>
          <span>Copy</span>
        </button>
        <hr />

        <button class="struct-context-menu-item" cdkMenuItem>
          <div class="relative flex items-center">
            <struct-icon icon="clipboard" size="12"></struct-icon>
            <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
          </div>
          <span>Paste above</span>
        </button>
        <button class="struct-context-menu-item" cdkMenuItem>
          <div class="relative flex items-center">
            <struct-icon icon="clipboard" size="12"></struct-icon>
            <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
          </div>
          <span>Paste below</span>
        </button>
      </div>
    </ng-template>
  </div>

  <div class="absolute bottom-2 right-2 w-48 flex flex-col items-center">
    <!-- <struct-icon icon="folder_32" size="32" class="mb-4"></struct-icon> -->
    <h3 class="text-sm font-medium text-gray-300">Drag and drop file here</h3>
    <p class="text-xs text-gray-300">or</p>
    <label for="fileDropRef" class="text-blue-500 font-medium text-xs">Browse for file</label>
  </div>


  <ng-template #container_context_menu>
    <div class="struct-context-menu w-72" cdkMenu>
      <button class="struct-context-menu-item" cdkMenuItem (click)="addNewFile()">
        <div class="relative flex items-center">
          <struct-icon icon="add" size="12"></struct-icon>
        </div>
        <span>Add new</span>
      </button>

      <hr />

      <button class="struct-context-menu-item" cdkMenuItem>
        <div class="relative flex items-center">
          <struct-icon icon="clipboard" size="12"></struct-icon>
          <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
        </div>
        <span>Paste above</span>
      </button>
      <button class="struct-context-menu-item" cdkMenuItem>
        <div class="relative flex items-center">
          <struct-icon icon="clipboard" size="12"></struct-icon>
          <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
        </div>
        <span>Paste below</span>
      </button>
    </div>
  </ng-template>
</div>
