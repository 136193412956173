
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArchiveReason } from "@struct/models/struct/app/domain/archiving/archive-reason";
import { SubRuleEntityType } from "@struct/models/struct/app/domain/businessrules/actions/shared/sub-rule-entity-type";
import { BusinessRuleDataElementReference } from "@struct/models/struct/app/domain/businessrules/attributedatamodels/business-rule-data-element-reference";
import { ConditionableAttributeElement } from "@struct/models/struct/app/domain/businessrules/attributedatamodels/conditionable-attribute-element";
import { EditableAttributeElement } from "@struct/models/struct/app/domain/businessrules/attributedatamodels/editable-attribute-element";
import { ActionEditorModel } from "@struct/models/struct/app/domain/businessrules/base/action-editor-model";
import { ConditionEditorModel } from "@struct/models/struct/app/domain/businessrules/base/condition-editor-model";
import { TriggerEditorModel } from "@struct/models/struct/app/domain/businessrules/base/trigger-editor-model";
import { ActionReference } from "@struct/models/struct/app/domain/businessrules/referencemodels/action-reference";
import { BusinessRuleReference } from "@struct/models/struct/app/domain/businessrules/referencemodels/business-rule-reference";
import { ConditionReference } from "@struct/models/struct/app/domain/businessrules/referencemodels/condition-reference";
import { TriggerReference } from "@struct/models/struct/app/domain/businessrules/referencemodels/trigger-reference";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { BusinessRuleCreateUpdateModel } from "@struct/models/struct/app/frontendapi/models/businessrules/business-rule-create-update-model";

@Injectable({ providedIn: "root" })
export class BusinessRulesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getBusinessRules(): Observable<BusinessRuleReference[]> {
    return this.http.get<BusinessRuleReference[]>(`${this.apiUrl}businessrules`, this.httpOptions );
  }

  getBusinessRule(uid: string): Observable<BusinessRuleCreateUpdateModel> {
    return this.http.get<BusinessRuleCreateUpdateModel>(`${this.apiUrl}businessrules/${uid}`, this.httpOptions );
  }

  getAvailableTriggers(): Observable<TriggerReference[]> {
    return this.http.get<TriggerReference[]>(`${this.apiUrl}businessrules/triggers`, this.httpOptions );
  }

  getAvailableActions(triggerType: string): Observable<ActionReference[]> {
    return this.http.get<ActionReference[]>(`${this.apiUrl}businessrules/triggers/${triggerType}/actions`, this.httpOptions );
  }

  getAvailableConditions(triggerType: string): Observable<ConditionReference[]> {
    return this.http.get<ConditionReference[]>(`${this.apiUrl}businessrules/triggers/${triggerType}/conditions`, this.httpOptions );
  }

  createBusinessRule(model: BusinessRuleCreateUpdateModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}businessrules`, model, this.httpOptions);
  }

  updateBusinessRule(model: BusinessRuleCreateUpdateModel): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}businessrules`, model, this.httpOptions);
  }

  deleteBusinessRule(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}businessrules/${uid}`, this.httpOptions );
  }

  hydrateTrigger(model: TriggerEditorModel): Observable<TriggerEditorModel> {
    return this.http.post<TriggerEditorModel>(`${this.apiUrl}businessrules/triggers/hydrate`, model, this.httpOptions);
  }

  hydrateAction(model: ActionEditorModel): Observable<ActionEditorModel> {
    return this.http.post<ActionEditorModel>(`${this.apiUrl}businessrules/actions/hydrate`, model, this.httpOptions);
  }

  hydrateCondition(model: ConditionEditorModel): Observable<ConditionEditorModel> {
    return this.http.post<ConditionEditorModel>(`${this.apiUrl}businessrules/conditions/hydrate`, model, this.httpOptions);
  }

  getSupportedSubRules(entityType: SubRuleEntityType): Observable<BusinessRuleReference[]> {
    return this.http.get<BusinessRuleReference[]>(`${this.apiUrl}businessrules/${entityType}/subrules`, this.httpOptions );
  }

  getConditionableAttributeDataElements(entityType: StructEntityType): Observable<ConditionableAttributeElement[]> {
    return this.http.get<ConditionableAttributeElement[]>(`${this.apiUrl}businessrules/dataelements/${entityType}/conditionableattributedataelements`, this.httpOptions );
  }

  getReadableAttributeDataElements(entityType: StructEntityType): Observable<BusinessRuleDataElementReference[]> {
    return this.http.get<BusinessRuleDataElementReference[]>(`${this.apiUrl}businessrules/dataelements/${entityType}/readableattributedataelements`, this.httpOptions );
  }

  getCopyableAttributeDataElements(entityType: StructEntityType): Observable<EditableAttributeElement[]> {
    return this.http.get<EditableAttributeElement[]>(`${this.apiUrl}businessrules/dataelements/${entityType}/copyableattributedataelements`, this.httpOptions );
  }

  getEditableAttributeDataElements(entityType: StructEntityType): Observable<EditableAttributeElement[]> {
    return this.http.get<EditableAttributeElement[]>(`${this.apiUrl}businessrules/dataelements/${entityType}/editableableattributedataelements`, this.httpOptions );
  }

  getResetableAttributeDataElements(entityType: StructEntityType): Observable<EditableAttributeElement[]> {
    return this.http.get<EditableAttributeElement[]>(`${this.apiUrl}businessrules/dataelements/${entityType}/resetableattributedataelements`, this.httpOptions );
  }

  getArchiveReasons(): Observable<ArchiveReason[]> {
    return this.http.get<ArchiveReason[]>(`${this.apiUrl}businessrules/archivereasons`, this.httpOptions );
  }
}

