// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILanguageQuota  {
  includedLanguages: number;
  extraLanguagePackSize: number;
  extraLanguagePackPrice: Price;
}

export class LanguageQuota  implements ILanguageQuota {
  includedLanguages = 0;

  extraLanguagePackSize = 0;

  extraLanguagePackPrice: Price = new Price();


  constructor(data?: Partial<ILanguageQuota>) {
    Object.assign(this, data);
  }
}