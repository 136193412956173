/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { map } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'struct-simple-select',
  templateUrl: './struct-simple-select.component.html',  
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StructSimpleSelectComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: StructSimpleSelectComponent
    },
  ],
})
export class StructSimpleSelectComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
  @Input() required: boolean = false;
  @Input({ required: true }) placeholder: string = '';
  @Input({ required: true }) bindValue!: string;
  @Input({ required: true }) bindLabel!: string;
  @Input() multiple: boolean = false;
  @Input() valueIsArray: boolean = false;
  @Input() tabIndex: number = 0;
  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemRemoved = new EventEmitter<any>();

  readonly pageSize = 100;

  private _options: any[] = [];
  @Input()
  get options(): any[] {
    return this._options;
  }
  set options(value: any[]) {
    this._options = value;
    this.filteredOptions = value;
    this.renderedOptions = value.slice(0, this.pageSize);
    this.initializeSelected();
  }

  @ViewChild('defaultTemplate') template: TemplateRef<any> | null = null;
  @ViewChild('selectInput') selectInput!: ElementRef<HTMLInputElement>;
  @ViewChild('loadMoreBtn') loadMoreBtn: HTMLElement | undefined = undefined;
  @ViewChild('matSuffixRef') matSuffixRef: ElementRef<HTMLDivElement> | undefined = undefined;
  showSuffix = true;

  currentTemplate: TemplateRef<any> | null = null;

  @Input() set customTemplate(template: TemplateRef<any>) {
    this.currentTemplate = template;
  }

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectGrid = new FormControl<any[]>([]);
  selectCtrl = new FormControl('');
  filteredOptions: any[] = [];
  renderedOptions: any[] = [];
  selectedOptions: any[] = [];
  get singleSelectedOption(): any | null {
    return this.selectedOptions.length > 0 ? this.selectedOptions[0] : null;
  }
  selectedOptionIds: any[] = [];

  constructor(controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {
    this.selectCtrl.valueChanges
      .pipe(
        map((value: string | null) => {
          return this.search(value ?? '');
        })
      )
      .subscribe(result => {
        this.filteredOptions = result;
        this.renderedOptions = result.slice(0, this.pageSize);
      });

    //We need to replace the original markAllAsTouched function of the form with a new one that also marks the selectGrid as touched, so that the validation works correctly
    if (controlContainer instanceof NgForm) {
      const ngForm = controlContainer as NgForm;
      const originalFn = ngForm.form.markAllAsTouched;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      ngForm.form.markAllAsTouched = function () {
        originalFn.apply(this);
        that.selectGrid.markAsTouched();
        that.touched = true;
      };
    }
  }

  ngOnInit(): void {
    this.selectGrid = new FormControl(this.selectedOptions, { validators: this.required ? Validators.required : null });  
  }

  ngAfterViewInit(): void {
    if (!this.currentTemplate) {
      this.currentTemplate = this.template;
    }

    this.showSuffix = this.matSuffixRef !== undefined && this.matSuffixRef.nativeElement.children.length > 0;
    this.cdr.detectChanges();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(!this.required || this.multiple) {
      return null;
    }
    if (control.value === null || control.value === '' || control.value === '00000000-0000-0000-0000-000000000000') {
      return { required: true };
    }
    if (Array.isArray(control.value) && control.value.length === 0) {
      return { required: true };
    }
    if (Array.isArray(control.value) && control.value.length === 1 && control.value[0] === '') {
      return { required: true };
    }
    if (Array.isArray(control.value) && control.value.length === 1 && control.value[0] === '00000000-0000-0000-0000-000000000000') {
      return { required: true };
    }
    return null;
  }

  initializeSelected(): void {
    if (this.selectedOptionIds.length === 0) {
      return;
    }
    this.selectedOptions = this.options.filter(option => this.selectedOptionIds.includes(option[this.bindValue]));
    this.selectGrid.setValue(this.selectedOptions);
  }

  removeSelectedOption(option: any): void {
    if (this.disabled) {
      return;
    }

    const index = this.selectedOptions.indexOf(option);
    this.selectedOptions.splice(index, 1);
    this.selectedOptionIds.splice(index, 1);
    const newValue = this.getCurrentValue();
    this.onChange(newValue);
    this.selectGrid.setValue(this.selectedOptions);
    this.markAsTouched();
    this.selectCtrl.setValue('');
    this.itemRemoved.emit(option);
  }

  getCurrentValue(): any {
    const newValue = this.multiple || this.valueIsArray ? this.selectedOptionIds : this.selectedOptionIds.length > 0 ? this.selectedOptionIds[0] : null;
    return newValue;
  }

  addSelectedValue(value: any) {
    if (!this.multiple) {
      this.selectedOptions = [];
      this.selectedOptionIds = [];
    }
    this.selectedOptions.push(value);
    this.selectedOptionIds.push(value[this.bindValue]);
    const newValue = this.getCurrentValue();
    this.onChange(newValue);
    this.selectGrid.setValue(this.selectedOptions);
    this.markAsTouched();
    this.selectInput.nativeElement.value = '';
    this.selectCtrl.setValue('');
  }

  selectOption(event: MatAutocompleteSelectedEvent): void {
    if (this.disabled) {
      return;
    }
    if (event.option.value == null) {
      return;
    }
    if (!this.multiple) {
      this.selectedOptions = [];
      this.selectedOptionIds = [];
    }
    this.selectedOptions.push(event.option.value);
    this.selectedOptionIds.push(event.option.value[this.bindValue]);
    const newValue = this.getCurrentValue();
    this.onChange(newValue);
    this.selectGrid.setValue(this.selectedOptions);
    this.markAsTouched();
    this.selectInput.nativeElement.value = '';
    this.selectCtrl.setValue('');
    this.itemSelected.emit(event.option.value);
  }

  inputFocused(): void {
    this.selectInput.nativeElement.value = '';
    this.selectCtrl.setValue('');
  }

  inputBlur(event: FocusEvent): void {
    if (this.loadMoreBtn !== undefined) {
      const tmp = this.loadMoreBtn as any;
      const elementRef = tmp._elementRef.nativeElement;
      if (event.relatedTarget === elementRef) {
        return;
      }
    }

    if (event.target === this.selectInput.nativeElement) {
      return;
    }
    this.selectInput.nativeElement.value = '';
    this.selectCtrl.setValue('');
  }

  search(value: string): any[] {
    if (typeof value === 'object' || value === '') {
      return this.options.filter(option => !this.selectedOptions.includes(option));
    }

    const filterValue = value.toString().toLowerCase();
    const terms = filterValue.split(' ');
    return this.options
    .filter(option => terms.every(term => option[this.bindLabel].toLowerCase().includes(term)) && !this.selectedOptions.includes(option))
    .sort((a, b) => a[this.bindLabel].length - b[this.bindLabel].length);;
  }

  keyDown(event: KeyboardEvent): void {
    if (this.singleSelectedOption !== null && event.key !== 'Backspace' && event.key !== 'Tab') {
      event.preventDefault();
    } else if (this.singleSelectedOption !== null && event.key === 'Backspace') {
      this.removeSelectedOption(this.singleSelectedOption);
    }
  }

  loadMore(): void {
    const start = this.renderedOptions.length;
    const end = start + this.pageSize;
    this.renderedOptions = this.filteredOptions.slice(0, end);
  }

  focusOnInput() {
    this.selectInput.nativeElement.focus();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedOptions, event.previousIndex, event.currentIndex);
    moveItemInArray(this.selectedOptionIds, event.previousIndex, event.currentIndex);
    const newValue = this.getCurrentValue();
    this.onChange(newValue);
  }

  //ControlValueAccessor implementation from here
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (s: any | any[] | null) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(writeValue: any[] | any | null) {
    if (writeValue === null || writeValue === undefined) {
      this.selectedOptionIds = [];
      this.selectedOptions = [];
      return;
    }

    if (!this.valueIsArray && !Array.isArray(writeValue)) {
      writeValue = [writeValue];
    }

    this.selectedOptionIds = writeValue;
    this.initializeSelected();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.selectGrid.disable();
    } else {
      this.selectGrid.enable();
    }
  }
}
