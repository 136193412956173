import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { UiModule } from '@struct/ui/ui.module';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { AccountsFormContentComponent } from '../../accounts-ui/accounts-form/accounts-form-content/accounts-form-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'apps/accounts/src/environments/environment';

@Component({
  selector: 'accounts-change-to-microsoft-dialog',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, AccountsFormComponent, AccountsHeaderComponent, AccountsFormContentComponent, AccountsFormSectionContentComponent],
  templateUrl: './change-to-microsoft-dialog.component.html',
})
export class ChangeToMicrosoftDialogComponent {
  @ViewChild('changePasswordForm') changePasswordForm!: NgForm;
  loaded = true;
  working = false;

  constructor(private dialogRef: MatDialogRef<ChangeToMicrosoftDialogComponent>, private authService: AuthenticationService) {}

  connectToMicrosoftAccount() {
    this.working = true;
    this.authService.redirectToEntraIdLogin(null, environment.entraId.changeSignInRedirectUrl);
  }

  cancel() {
    this.dialogRef.close();
  }
}
