import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'accounts-form',
  templateUrl: './accounts-form.component.html',
  styleUrls: ['./accounts-form.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AccountsFormComponent  {
}
