<ul role="list" class="px-4 flex flex-col space-y-1" [ngClass]="collapsed ? '-mx-2 pt-4' : '-ml-2 -mr-2'">
  <li *ngFor="let pageMenuItem of pageMenuItems">
      <a class="group flex items-center gap-x-3 pl-2 pr-4 py-0 text-sm leading-6 font-medium hover:bg-gray-200/50 cursor-pointer"
       [ngClass]="isLinkActive(pageMenuItem.route) ? 'bg-gray-200 text-blue-700 border-blue-500' : 'border-transparent'"
       (click)="goToRoute(pageMenuItem.route)"
         [attr.data-cy]="camelize(pageMenuItem.label)"
      >

          <struct-icon-button
           [icon]="pageMenuItem.icon"
           [matTooltip]="collapsed ? (pageMenuItem.label | translate) : ''"
           [matTooltipPosition]="'right'"
           [ngClass]="[isLinkActive(pageMenuItem.route) ? 'text-blue-700 group-hover:bg-transparent' : 'text-gray-600', collapsed ? 'group-hover:bg-gray-200' : '']"
           iconSize="16"
           [useMaterialIcons]="pageMenuItem.useMaterialIcons"
           class="w-12 h-12 flex items-center justify-center rounded-full">
          </struct-icon-button>
          <div class="flex flex-col" *ngIf="collapsed === false">
              <span class="text-base font-medium" [translate]="pageMenuItem.label"></span>
          </div>
      </a>
  </li>
</ul>
