import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'struct-app-content-metabar-default-info-panel',
  templateUrl: './struct-app-content-metabar-default-info-panel.component.html',
  styleUrls: ['./struct-app-content-metabar-default-info-panel.component.scss'],
})
export class StructAppContentMetabarDefaultInfoPanelComponent {
  @Input() title: Observable<string> | undefined | null = null;
  @Input() id: string | number | undefined | null = null;
  @Input() createdBy: string | undefined | null = null;
  @Input() created: Date | string | undefined | null = null;
  @Input() lastModifiedBy: string | undefined | null = null;
  @Input() lastModified: Date | string | undefined | null = null;
}
