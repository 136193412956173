// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeProvisioningStatus } from "@struct/models/accountmanagement/domain/tenants/de-provisioning-status";
import { DeploymentInfo } from "@struct/models/accountmanagement/domain/tenants/deployment-info";
import { ProvisioningStatus } from "@struct/models/accountmanagement/domain/tenants/provisioning-status";
import { TenantStatus } from "@struct/models/accountmanagement/domain/tenants/tenant-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenant  {
  uid: string;
  subscriptionUid: string;
  name: string;
  slug: string;
  status: TenantStatus;
  created: Date | string;
  createdBy: string;
  deploymentInfo: DeploymentInfo;
  provisioningStatus?: ProvisioningStatus | null;
  deProvisioningStatus?: DeProvisioningStatus | null;
  isEarlyAdopter: boolean;
}

export class Tenant  implements ITenant {
  uid = "00000000-0000-0000-0000-000000000000";

  subscriptionUid = "00000000-0000-0000-0000-000000000000";

  name = "";

  slug = "";

  status: TenantStatus = TenantStatus.AwaitingProvisioning;

  created: Date | string = new Date(0);

  createdBy = "";

  deploymentInfo: DeploymentInfo = new DeploymentInfo();

  provisioningStatus: ProvisioningStatus | null = null;

  deProvisioningStatus: DeProvisioningStatus | null = null;

  isEarlyAdopter = false;


  constructor(data?: Partial<ITenant>) {
    Object.assign(this, data);
  }
}