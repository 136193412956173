<mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
  <div class="flex flex-col p-5 pb-10 relative">

    <div class="flex items-center">
      <div class="flex-shrink-0">

        <svg class="h-8 w-8 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="currentColor">
            <path d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12,12-5.373,12-12C23.981,5.381,18.619,.019,12,0Zm7.207,7.707l-9,9c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-4-4c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l3.293,3.293L17.793,6.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Z"></path>
          </g>
        </svg>
      </div>
      <div class="ml-3">
        <h3 class="text-xl font-medium text-red-600">{{data.title | async}}</h3>
      </div>
    </div>
    <div>
      <div *ngIf="data.description !== null" class="mt-4 text-red-800">
        <p>{{data.description | async}}</p>
      </div>
    </div>
    <button class="absolute top-2 right-2 text-red-600" *ngIf="!data.hideCloseButton" (click)="snackbarRef.dismiss()">
      <span class="sr-only">{{data.closeButtonText}}</span>
      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
    </button>
  </div>
