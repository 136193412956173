<mat-form-field appearance="outline" class="w-full" subscriptSizing="dynamic">
  <mat-label>{{name}}</mat-label>
  <input #input name="{{name}}-{{random}}" required matInput [ngModel]="value" [disabled]="disabled" [required]="required" placeholder="{{placeholder}}" (ngModelChange)="onNgModelChange($event)" #elm="ngModel" [matDatepicker]="picker" (focus)="picker.open()">
  <mat-datepicker-toggle matSuffix [for]="picker">
    <struct-icon icon="calendar_date" size="16" matDatepickerToggleIcon></struct-icon>
  </mat-datepicker-toggle>
  <mat-error *ngIf="required && elm.invalid && value?.toString()?.length === 0">{{name}} is required</mat-error>
  <mat-hint align="start" *ngIf="hintStart !== null">{{hintStart}}</mat-hint>
  <mat-hint align="end" *ngIf="hintEnd !== null">{{hintEnd}}</mat-hint>
  <!-- <div matPrefix>
    <ng-content select="struct-input-prefix"></ng-content>
  </div> -->
  <mat-datepicker #picker (closed)="input.blur()"></mat-datepicker>
</mat-form-field>

<!-- <div class="struct-datepicker relative">
  <input [(ngModel)]="date" (ngModelChange)="changeValue()" class="struct-form-control" [ngClass]="{'struct-datepicker-disabled' : disabled === true}" matInput [matDatepicker]="picker" disabled>
  <div class="absolute top-0 right-0">
    <span *ngIf="disabled === false" class="clear-wrapper mr-1" title="Clear" (click)="clearDate()"><span class="clear">×</span></span>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="{{disabled}}"></mat-datepicker>
  </div>
  <small class="ml-4">mm/dd/yyyy</small>
</div> -->
