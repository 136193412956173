
<header class="relative w-full flex flex-1 flex-shrink-0 items-center">
  <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 items-center md:justify-between py-2 px-2 sm:px-4 lg:px-4 gap-4 h-full">
    <div class="min-w-0 flex-1 flex items-center h-full" [class.pl-0]="goBackToRoute === null">
      <struct-icon-button class="-ml-2 mr-2" *ngIf="goBackToRoute !== null" [routerLink]="[goBackToRoute]" icon="chevron_left" #tooltip="matTooltip" matTooltip="Go back" [matTooltipPosition]="'below'"></struct-icon-button>
      <div class="flex flex-col h-full">
        <div class="struct-accounts-header-title-wrap flex-grow-0">
          <h1 class="struct-accounts-header-title"><ng-content></ng-content></h1>
        </div>
      </div>
    </div>
    <div class="mt-4 flex md:mt-0 md:ml-4" *ngIf="headerButtonText !== null">
      <struct-button [color]="'primary'" (click)="headerButtonClicked.emit()" *ngIf="!headerButtonIsDelete">
        <struct-icon icon="add" size="12"></struct-icon>
        {{headerButtonText}}
      </struct-button>

      <struct-button [color]="'danger'" (click)="headerButtonClicked.emit()" *ngIf="headerButtonIsDelete">
        <struct-icon icon="remove" size="12"></struct-icon>
        {{headerButtonText}}
      </struct-button>
    </div>
  </div>
</header>
