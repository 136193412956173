import { Subscription } from "@struct/models/struct/shared/subscription/subscription";
import { SubscriptionPlan } from "@struct/models/struct/shared/subscription/subscription-plan";
import { SubscriptionStatus } from "@struct/models/struct/shared/subscription/subscription-status";


export class SubscriptionModel
{
    uid : string | null = null;
    name : string | null = null;
    created :  Date| string | number  = '';
    status : SubscriptionStatus| null = null;
    subscriptionPlan : SubscriptionPlan | null = null;

    constructor (entity : Subscription, plan : SubscriptionPlan){
        this.uid = entity.uid;
        this.name = entity.name;
        this.status = entity.status;
        this.subscriptionPlan = plan;
    }
}
