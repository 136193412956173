import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog/struct-confirm-dialog.component';
import { StructSpinnerService } from '@struct/ui/struct-spinner/struct-spinner.service';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, map, of, takeUntil } from 'rxjs';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { SubscriptionDetailsComponent } from '../subscription-details/subscription-details.component';
import {SubscriptionApiService} from "@struct/services/account-management";
import { Subscription } from '@struct/models/struct/shared/subscription/subscription';
import { UpdateSubscriptionCommand } from '@struct/models/struct/shared/subscription/commands/update-subscription-command';

@Component({
  selector: 'accounts-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, AccountsHeaderComponent, SubscriptionDetailsComponent],
})
export class EditSubscriptionComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();
  subscription: Subscription | null = null;
  subscriptionName: string | null = null;
  showError = false;
  @ViewChild('subscriptionForm') subscriptionForm!: NgForm;

  constructor(private subscriptionApi: SubscriptionApiService, private currentRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, private spinnerService: StructSpinnerService) {}

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.spinnerService.changeShowSpinner(true);
    const uid = this.currentRoute.snapshot.paramMap.get('uid');
    if (uid !== null) {
      this.subscriptionApi
        .getSubscription(uid)
        .pipe(
          takeUntil(this.destroyed$),
          map(data => {
            this.subscription = data;
            this.spinnerService.changeShowSpinner(false);
          })
        )
        .subscribe();
    }
  }

  deleteSubscription(): void {
    const dialogRef = this.dialog.open(StructConfirmDialogComponent, {
      data: <ConfirmDialogData>{
        userValueConfirm: true,
        userValueConfirmString: this.subscription?.name,
        description: of('Are you sure you want to delete this subscription?')
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: ConfirmDialogResult) => {
        if (result?.confirmed) {
          this.spinnerService.changeShowSpinner(true);
          const uid = this.currentRoute.snapshot.paramMap.get('uid');
          if (uid) {
            this.subscriptionApi
              .deleteSubscription(uid)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(() => {
                this.spinnerService.changeShowSpinner(false);
                this.router.navigateByUrl('/subscriptions');
              });
          }
        }
      });
  }

  submitSubscription(): void {
    if (!this.subscriptionForm.valid || this.subscription == null) {
      for (const i in this.subscriptionForm.controls) {
        this.subscriptionForm.controls[i].markAsTouched();
      }
      this.showError = true;
    } else {
      this.spinnerService.changeShowSpinner(true);
      const cmd = new UpdateSubscriptionCommand({
        uid: this.subscription.uid,
        name: this.subscription.name,
        subscriptionPlanUid: this.subscription.subscriptionPlanUid,
        companyName: this.subscription.companyName,
        vatNumber: this.subscription.vatNumber,
        postal: this.subscription.postal,
        city: this.subscription.city,
        address: this.subscription.address,
        address2: this.subscription.address2,
        country: this.subscription.country,
        contactPerson: this.subscription.contactPerson,
        contactEmail: this.subscription.contactEmail,
        contactPhone: this.subscription.contactPhone,
        firstInvoiceDate: this.subscription.firstInvoiceDate,
        invoiceEmail: this.subscription.invoiceEmail,
        paymentCurrency: this.subscription.paymentCurrency,
        status: this.subscription.status,
        setup: this.subscription.setup
      });

      this.subscriptionApi.updateSubscription(cmd).subscribe(() => {
        this.spinnerService.changeShowSpinner(false);
        this.router.navigateByUrl('/subscriptions');
      });
    }
  }
}
