// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Invite } from "@struct/models/struct/usermanagementmodule/invite";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantInvite extends Invite {
  tenantUid: string;
  tenantUrl: string;
  tenantName: string;
}

export class TenantInvite extends Invite implements ITenantInvite {
  tenantUid = "00000000-0000-0000-0000-000000000000";

  tenantUrl = "";

  tenantName = "";

  override uid = "00000000-0000-0000-0000-000000000000";

  override email = "";

  override created: Date | string = new Date(0);

  override expires: Date | string = new Date(0);

  override invitedBy = "";

  override inviteToken = "";

  override accepted: boolean | null = null;

  override type = "";


  constructor(data?: Partial<ITenantInvite>) {
    super(data);
    Object.assign(this, data);
  }
}