import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageSearchModel } from '@struct/models/struct/app/frontendapi/models/language/language-search-model';
import { IconService } from './icon.service';
import { Icon } from './pack/icon';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'struct-icon-picker',
  templateUrl: './struct-icon-picker.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class StructIconPickerComponent {
  @Input() multiple: boolean = false;
  @Input() value: any | null = null;
  @Input() required: boolean = false;
  @Input() loading: boolean = true;
  @Input({ required: true }) name = 'language';
  @Input() placeholder: string = 'placeholder';
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter();

  icons: Icon[] = [];

  languages: LanguageSearchModel[] = [];

  constructor(private iconService: IconService, private tr: TranslateService) {
    this.icons = this.iconService.getIcons(['mat']);
    // this.icons = this.iconService.getIcons(['struct']);
    this.placeholder = this.tr.instant("General.SelectIcon");
  }

  iconChanged(event: any) {
    this.valueChange.emit(event);
  }
}
