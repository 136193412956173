<div class="flex items-center" data-cy="struct.button.container">
    <!-- <div *ngIf="working" class="mr-8" data-cy="struct.button.spinner">
        <div class="dot-falling"></div>
    </div> -->
    <div class="relative" data-cy="struct.button.enabled">
      <span class="struct-button-pulsate" *ngIf="pulsate"></span>
      <button type="{{buttonType}}" mat-flat-button [color]="color" class="" [ngClass]="isStroked ? 'mat-stroked-button' : 'mat-flat-button'" [disabled]="disabled || working" data-cy="struct.button">
          <div class="inline-flex items-center justify-center gap-2" [ngClass]="{'opacity-0' : working}">
            <ng-content></ng-content>
          </div>

          <div *ngIf="working" class="absolute inset-0 flex justify-center items-center" data-cy="struct.button.spinner">
            <!-- <mat-spinner diameter="24"></mat-spinner> -->
            <div>
              <div class="dot-falling-small"></div>
            </div>

          </div>
      </button>
    </div>
</div>
