// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IModulePrices  {
  azureADFederationPrice: Price;
  supplierPortalPrice: Price;
  publicationPrice: Price;
  aiAssistantPrice: Price;
}

export class ModulePrices  implements IModulePrices {
  azureADFederationPrice: Price = new Price();

  supplierPortalPrice: Price = new Price();

  publicationPrice: Price = new Price();

  aiAssistantPrice: Price = new Price();


  constructor(data?: Partial<IModulePrices>) {
    Object.assign(this, data);
  }
}