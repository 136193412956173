
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateDimensionCommand } from "@struct/models/struct/app/domain/attributes/commands/dimensions/create-dimension-command";
import { UpdateDimensionCommand } from "@struct/models/struct/app/domain/attributes/commands/dimensions/update-dimension-command";
import { Dimension } from "@struct/models/struct/app/domain/attributes/dimension";
import { DimensionSegment } from "@struct/models/struct/app/domain/attributes/dimension-segment";

@Injectable({ providedIn: "root" })
export class DimensionsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getSegment(uid: string): Observable<DimensionSegment> {
    return this.http.get<DimensionSegment>(`${this.apiUrl}dimensions/segments/${uid}`, this.httpOptions );
  }

  getSegments(): Observable<DimensionSegment[]> {
    return this.http.get<DimensionSegment[]>(`${this.apiUrl}dimensions/segments`, this.httpOptions );
  }

  getDimensions(): Observable<Dimension[]> {
    return this.http.get<Dimension[]>(`${this.apiUrl}dimensions`, this.httpOptions );
  }

  getDimension(uid: string): Observable<Dimension | null> {
    return this.http.get<Dimension | null>(`${this.apiUrl}dimensions/${uid}`, this.httpOptions );
  }

  deleteDimension(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}dimensions?uid=${uid}`, this.httpOptions );
  }

  createDimension(command: CreateDimensionCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}dimensions`, command, this.httpOptions);
  }

  updateDimension(command: UpdateDimensionCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}dimensions`, command, this.httpOptions);
  }
}

