import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, of, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog/struct-confirm-dialog.component';
import { StructSpinnerService } from '@struct/ui/struct-spinner/struct-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import {TenantApiService} from "@struct/services/account-management/tenant-api.service";
import {Tenant} from "@struct/models/accountmanagement/domain/tenants";
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { CloneTenantCommand } from '@struct/models/accountmanagement/domain/tenants/commands';

@Component({
  selector: 'accounts-clone-tenant',
  templateUrl: './clone-tenant.component.html',
  styleUrls: ['./clone-tenant.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, AccountsHeaderComponent, AccountsFormComponent, AccountsFormSectionComponent, AccountsFormSectionTitleComponent, AccountsFormSectionDescriptionComponent,AccountsFormSectionContentComponent,AccountsFormSectionComponent],

})
export class CloneTenantComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();
  showError = false;

  @ViewChild('tenantform') tenantform: NgForm | null = null;
  name: string = '';
  description: string = '';
  organizationUid: string | null = null;
  constructor(private tenantApi: TenantApiService, private currentRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, private spinnerService: StructSpinnerService) {}

  ngOnInit(): void {
    console.log('CloneTenantComponent ngOnInit');
  }
  
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  cloneTenant(): void {
    const uid = this.currentRoute.snapshot.paramMap.get('uid');
    if (uid !== null) {
      const cmd: CloneTenantCommand = new CloneTenantCommand(
      {
        uid: uid,
        name: this.name,
        description: this.description,
        organizationUid: this.organizationUid

      });
      this.tenantApi.cloneTenantToTemplate(cmd).subscribe(() => {
        this.spinnerService.changeShowSpinner(false);
        this.router.navigateByUrl('/tenants');
      });
    }
  }
}
