import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiModule } from '@struct/ui/ui.module';
import { forkJoin, ReplaySubject, takeUntil } from 'rxjs';
import {AccountsHeaderComponent} from "../../accounts-ui/accounts-header/accounts-header.component";
import {AccountsListComponent} from "../../accounts-ui/accounts-list/accounts-list/accounts-list.component";
import {
  AccountsListSearchComponent
} from "../../accounts-ui/accounts-list/accounts-list-search/accounts-list-search.component";
import {
  AccountsListEmptyComponent
} from "../../accounts-ui/accounts-list/accounts-list-empty/accounts-list-empty.component";
import {
  AccountsListItemBadgeComponent
} from "../../accounts-ui/accounts-list/accounts-list-item-badge/accounts-list-item-badge.component";
import {
  AccountsListItemDescriptionComponent
} from "../../accounts-ui/accounts-list/accounts-list-item-description/accounts-list-item-description.component";
import {
  AccountsListContentComponent
} from "../../accounts-ui/accounts-list/accounts-list-content/accounts-list-content.component";
import {
  AccountsListItemComponent
} from "../../accounts-ui/accounts-list/accounts-list-item/accounts-list-item.component";

import {SubscriptionApiService, SubscriptionPlanApiService} from "@struct/services/account-management";
import {SubscriptionModel} from "../models/subscription-model";
import { Router } from '@angular/router';
import { SubscriptionStatus } from '@struct/models/struct/shared/subscription/subscription-status';

@Component({
  selector: 'accounts-subscriptions',
  templateUrl: './subscriptions.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
    AccountsListComponent,
    AccountsListSearchComponent,
    AccountsListEmptyComponent,
    AccountsListItemBadgeComponent,
    AccountsListItemDescriptionComponent,
    AccountsListContentComponent,
    AccountsListItemBadgeComponent,
    AccountsListItemComponent,
  ],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();
  subscriptions: SubscriptionModel[];
  allSubscriptions: SubscriptionModel[];
  searchTerm: string;

  constructor(private subscriptionPlanApi: SubscriptionPlanApiService, private subscriptionApi: SubscriptionApiService, private router: Router) {
    this.subscriptions = [];
    this.allSubscriptions = [];
    this.searchTerm = '';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.allSubscriptions = [];

    const subscriptionsReq = this.subscriptionApi.getSubscriptions().pipe(takeUntil(this.destroyed$));
    const subscriptionPlansReq = this.subscriptionPlanApi.getSubscriptionPlans().pipe(takeUntil(this.destroyed$));

    this.subscriptionPlanApi.getSubscriptionPlans().subscribe(plans => {
      this.subscriptionApi.getSubscriptions().subscribe(subscriptions => {
        
      })
    })

    forkJoin([subscriptionsReq, subscriptionPlansReq]).subscribe(results => {
      const subscriptions = results[0];
      const subscriptionPlans = results[1];
      subscriptions.forEach(subscription => {
        const subscriptionPlan = subscriptionPlans.find(x => x.uid === subscription.subscriptionPlanUid);
        if (subscriptionPlan) {
          const model = new SubscriptionModel(subscription, subscriptionPlan);
          this.allSubscriptions.push(model);
        }
      });

      this.search();
    });
  }

  createSubscription(): void {
    this.router.navigate(['/subscriptions/create']);
  }

  public searchChanged(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.search();
  }

  // Todo -> Does not makes sense
  public get subscriptionStatus(): typeof SubscriptionStatus {
    return SubscriptionStatus;
  }

  public search(): void {
    if (this.searchTerm !== null && this.searchTerm.length > 0) {
      this.subscriptions = this.allSubscriptions.filter(x => x.name && x.name.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) !== -1);
    } else {
      this.subscriptions = Object.assign([], this.allSubscriptions);
    }
  }
}
