
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateArchiveReasonCommand } from "@struct/models/struct/app/domain/archiving/commands/create-archive-reason-command";
import { UpdateArchiveReasonCommand } from "@struct/models/struct/app/domain/archiving/commands/update-archive-reason-command";
import { ArchiveReason } from "@struct/models/struct/app/domain/archiving/archive-reason";

@Injectable({ providedIn: "root" })
export class ArchivingReasonsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getArchiveReasons(): Observable<ArchiveReason[]> {
    return this.http.get<ArchiveReason[]>(`${this.apiUrl}archivingreasons`, this.httpOptions );
  }

  getArchiveReason(uid: string): Observable<ArchiveReason> {
    return this.http.get<ArchiveReason>(`${this.apiUrl}archivingreasons/${uid}`, this.httpOptions );
  }

  createArchiveReason(cmd: CreateArchiveReasonCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}archivingreasons`, cmd, this.httpOptions);
  }

  updateArchiveReason(cmd: UpdateArchiveReasonCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}archivingreasons`, cmd, this.httpOptions);
  }

  deleteArchiveReason(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}archivingreasons/${uid}`, this.httpOptions );
  }
}

