<struct-app-content-container>
    <struct-app-content-header [title]="'UIComponents.StructIconChooser.Title' | translate">
    </struct-app-content-header>
    <struct-app-content>
        <struct-app-content-workarea>

          <div class="w-full bg-gray-50 p-3 flex flex-col flex-1 h-full">
            <div class="w-full h-16 mb-2 flex-grow-none">
              <form>
                  <struct-input inputType="search" (valueChange)="onSearchChanged($event)"
                                [name]="'UIComponents.StructIconChooser.SearchIcons' | translate"
                                [placeholder]="'UIComponents.StructIconChooser.SearchIcons' | translate"
                                appearance="fill">
                      <struct-input-suffix>
                          <struct-icon icon="search"></struct-icon>
                      </struct-input-suffix>
                  </struct-input>
              </form>
            </div>

            <div class="w-full flex-grow">

              <cdk-virtual-scroll-viewport appendOnly itemSize="0" class="viewport h-full w-full border border-gray-200 bg-gray-100 p-5">
                  <ng-container *cdkVirtualFor="let icon of shownIcons" class="w-full flex-1 m-5">
                    <div class="w-24 h-24 p-5 bg-white border border-gray-100 hover:border-gray-300 ring-2 hover:ring-gray-300 cursor-pointer flex items-center justify-center"
                      [ngClass]="{'!bg-blue-50 !border-blue-500 !pointer-events-none ring-2 ring-blue-300' : selectedIcon === icon}"
                      (click)="selectIcon(icon)"
                      matTooltip="{{icon.name}}">
                      <!-- {{icon.name}} -->
                      <struct-icon icon="{{icon.id}}" size="24"
                      [useMaterialIcons]="true"></struct-icon>
                    </div>
                  </ng-container>
              </cdk-virtual-scroll-viewport>

            </div>

          </div>

        </struct-app-content-workarea>
    </struct-app-content>
    <struct-app-content-footer>
        <struct-button (click)="cancel()">Cancel</struct-button>
        <struct-button (click)="updateIcon()" [disabled]="!changed" color="primary">Update icon</struct-button>
    </struct-app-content-footer>
</struct-app-content-container>
