// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/boolean-query-operator";
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBooleanFieldQuery extends FieldQuery {
  filterValue?: boolean | null;
  queryOperator: BooleanQueryOperator;
  fieldQueryType: string;
}

export class BooleanFieldQuery extends FieldQuery implements IBooleanFieldQuery {
  filterValue: boolean | null = null;

  queryOperator: BooleanQueryOperator = BooleanQueryOperator.Equals;

  override fieldQueryType = "BooleanFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<IBooleanFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}