import { Injectable } from '@angular/core';
import { UserColumn } from './models/user-column';
import { SimpleQuery } from '@struct/models/struct/shared/search';
import { TableDisplayMode } from './struct-table.component';

@Injectable({
  providedIn: 'root'
})
export class StructTableService {
  private queryStorage : { [scope: string] : SimpleQuery[] } = {};
  private pageStorage : { [scope: string] : number } = {};

  public setTableQuery(scope: string, columnQueries: SimpleQuery[]){
    this.queryStorage[scope] = columnQueries;
  }

  public getTableQuery(scope: string) : SimpleQuery[]{
    const val = this.queryStorage[scope];
    if(val === undefined){
      return [];
    }
    return val;
  }

  public setPage(scope: string, page: number){
    this.pageStorage[scope] = page;
  }

  public getPage(scope: string) : number{
    const val = this.pageStorage[scope];
    if(val === undefined){
      return 0;
    }
    return val;
  }
  
  public setDisplayMode(scope : string, displayMode : TableDisplayMode){
    localStorage.setItem("structtable_displaymode_" + scope, displayMode.toString());
  }

  public getDisplayType(scope : string) : TableDisplayMode | null {
    const v = localStorage.getItem("structtable_displaymode_" + scope);
    if(v === null){
      return null;
    }
    return <TableDisplayMode>Number.parseInt(v);
  }

  public setColumns(scope : string, userColumns : UserColumn[]){
    localStorage.setItem("structtable_" + scope, JSON.stringify(userColumns));
  }

  public getUserColumns(scope : string) : UserColumn[] | null {
    const v = localStorage.getItem("structtable_" + scope)
    if(v === null){
      return null;
    }
    const obj = JSON.parse(v);
    return <UserColumn[]>obj
  }
}


