<header class="relative w-full flex flex-1 flex-shrink-0 items-center" [ngClass]="isPinned ? 'is-pinned' : 'is-not-pinned'">

  <!-- Desktop nav area -->
  <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-end md:justify-between py-2 px-2 sm:px-4 lg:px-4 gap-4 h-full">
    <div class="struct-app-dam-header-image group">
      <img *ngIf="image !== null" [src]="image" alt="" class="w-full h-auto" />
      <span class="absolute bottom-2 right-2 inline-flex items-center rounded bg-gray-700/75 px-2 py-1 text-xs font-medium text-white uppercase">PNG</span>

      <div class="absolute inset-0 hidden group-hover:flex items-center justify-center animate-in slide-in-from-bottom-2 duration-600">
        <div class="bg-white flex p-1 rounded-full">
          <struct-icon-button iconSize="16" icon="maximize"></struct-icon-button>
          <struct-icon-button iconSize="16" icon="edit" (click)="openMediaEditorDialog()"></struct-icon-button>
        </div>

      </div>
    </div>

    <div class="min-w-0 flex-1 flex items-start h-full pt-4" [class.pl-0]="goBackToRoute === null">
      <struct-icon-button class="-ml-4 mr-2" *ngIf="goBackToRoute !== null" [routerLink]="[goBackToRoute]" icon="chevron_left" #tooltip="matTooltip" matTooltip="{{'General.GoBack' | translate}}" [matTooltipPosition]="'below'"></struct-icon-button>
      <div class="flex flex-col h-full">
        <div class="struct-app-dam-header-title-wrap grow-0">
          <h1 *ngIf="title === null" class="struct-app-dam-header-title">Nike Air Max 90</h1>
          <h1 *ngIf="title !== null" class="struct-app-dam-header-title">{{title}}</h1>

          <struct-icon-button icon="star_outline" iconSize="16" class="text-gray-400 ml-1" [useMaterialIcons]="true" #tooltip="matTooltip" matTooltip="{{'General.Shortcut' | translate}}" [matTooltipPosition]="'below'"></struct-icon-button>
        </div>

        <nav class="struct-app-dam-header-breadcrumb grow-0" aria-label="Breadcrumb" *ngIf="breadcrumb !== null && breadcrumb.items.length > 0">
          <ol role="list" class="flex items-center space-x-2">
              <li *ngFor="let item of breadcrumb.items; let index=index">
                  <div class="flex items-center">
                      <struct-icon icon="chevron_right" size="8" *ngIf="index > 0"></struct-icon>
                      <a *ngIf="item.route === null || item.route === undefined" (click)="breadcrumbClicked(item)" [ngClass]="{'ml-2': index > 0, 'cursor-pointer': item.action}" class="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <span>{{(getLabel(item) | async) ?? '' | translate}}</span>
                      </a>
                      <a *ngIf="item.route !== null && item.route !== undefined" [routerLink]="[item.route]" [ngClass]="{'ml-2': index > 0}" class="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <span>{{(getLabel(item) | async) ?? ''  | translate}}</span>
                      </a>
                  </div>
              </li>
          </ol>
        </nav>

        <div class="struct-app-dam-header-meta struct-app-dam-header-meta-1 flex-grow">
          <div class="struct-app-dam-header-meta-item">
            <span class="text-sm font-medium">
              Width:
            </span>
            <span class="">580 px</span>
          </div>
          <div class="struct-app-dam-header-meta-item">
            <span class="text-sm font-medium">
              Height:
            </span>
            <span class="">445 px</span>
          </div>
          <div class="struct-app-dam-header-meta-item">
            <span class="text-sm font-medium">
              Size:
            </span>
            <span class="">465 kb</span>
          </div>
        </div>

        <div class="struct-app-dam-header-meta grow-0">
          <!-- <div class="struct-app-dam-header-meta-item">
            <span class="font-medium">Completeness:</span>
            <div class="struct-app-dam-header-meta-item-content overflow-visible" [matMenuTriggerFor]="completenessMenu">

              <div class="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
                <span class="h-full w-[84%] bg-green-400 block"></span>
              </div>

              <span class="text-xl font-medium">84%</span>
              <struct-icon icon="arrow_sm_down" class="ml-auto" ></struct-icon>

              <mat-menu #completenessMenu="matMenu" xPosition="before" class="w-96">
                <h3 class="text-xl font-medium px-4 py-2">Overall completeness</h3>
                <div class="flex flex-col divide-y divide-gray-200 space-y-2">

                  <div class="flex flex-col py-2 px-4">
                    <span class="text-sm text-gray-500 font-medium block">Long description translation</span>
                    <div class="flex items-center gap-2">

                      <span class="text-xl text-gray-500 font-medium">56%</span>
                      <a href="" class="text-sm text-gray-500 ml-auto">Missing 2 values</a>
                    </div>

                    <div class="w-full h-1 bg-gray-100">
                      <span class="h-full w-[56%] bg-green-300 block"></span>
                    </div>
                  </div>

                  <div class="flex flex-col py-2 px-4">
                    <span class="text-sm text-gray-500 font-medium block">Primary name translation</span>
                    <div class="flex items-center gap-2">
                      <span class="text-xl text-gray-500 font-medium">87%</span>
                      <a href="" class="text-sm text-gray-500 ml-auto">Missing 1 value</a>
                    </div>
                    <div class="w-full h-1 bg-gray-100">
                      <span class="h-full w-[87%] bg-green-500 block"></span>
                    </div>
                  </div>

                  <div class="flex flex-col py-2 px-4">
                    <span class="text-sm text-gray-500 font-medium block">Primary name translation</span>
                    <div class="flex items-center gap-2">
                      <span class="text-lg text-gray-500 font-medium">24%</span>
                      <a href="" class="text-sm text-gray-500 ml-auto">Missing 1 value</a>
                    </div>
                    <div class="w-full h-1 bg-gray-100">
                      <span class="h-full w-[24%] bg-green-200 block"></span>
                    </div>
                  </div>


                </div>
              </mat-menu>
            </div>

          </div> -->
          <div class="struct-app-dam-header-meta-item">
            <span class="font-medium">Workflow state:</span>
            <div class="struct-app-dam-header-meta-item-content" [matMenuTriggerFor]="workflowMenu">
              <div class="px-4 -ml-2 bg-green-500 rounded flex items-center gap-2">
                <span class="block w-2 h-2 bg-green-50 rounded-full"></span>
                <span class="text-lg font-medium text-white">New</span>
              </div>
              <struct-icon icon="arrow_sm_down" class="ml-auto" ></struct-icon>
              <mat-menu #workflowMenu="matMenu" xPosition="after" class="w-96">
                <!-- <h3 class="text-xl font-medium px-4 py-2">Workflow</h3> -->
                <button mat-menu-item>
                  <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                    <span class="block w-2 h-2 bg-green-500 rounded-full"></span>
                  </span>
                  <span class="text-base">Start enrichment</span>
                  <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                </button>
                <button mat-menu-item>
                  <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                    <span class="block w-2 h-2 bg-orange-500 rounded-full"></span>
                  </span>
                  <span class="text-base">Send to review</span>
                  <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                </button>
                <!-- <div class="bg-white">
                  <div class="flow-root">
                    <ul role="list" class="px-4 mb-4">
                      <li>
                        <div class="relative pb-8">
                          <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                          <div class="relative flex space-x-3">
                            <div>
                              <span class="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center ring-8 ring-white">
                                <span class="block w-2 h-2 bg-green-500 rounded-full"></span>
                              </span>
                            </div>
                            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div class="flex flex-col">
                                <span class="text-sm text-gray-500">Current state</span>
                                <span class="text-xl font-medium leading-4">New</span>
                              </div>
                              <div class="whitespace-nowrap text-right text-sm text-gray-500">
                                <time datetime="2020-09-20">Sep 20</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>


                    </ul>

                    <button mat-menu-item>
                      <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                        <span class="block w-2 h-2 bg-green-500 rounded-full"></span>
                      </span>
                      <span class="text-base">Start enrichment</span>
                      <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                    </button>
                    <button mat-menu-item>
                      <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                        <span class="block w-2 h-2 bg-orange-500 rounded-full"></span>
                      </span>
                      <span class="text-base">Send to review</span>
                      <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                    </button>
                  </div>
                </div> -->
                <!-- <button mat-menu-item>Start enrichment</button> -->
              </mat-menu>
            </div>
          </div>

          <div class="struct-app-dam-header-meta-item">
            <span class="font-medium">Tasks:</span>
            <div class="struct-app-dam-header-meta-item-content" [matMenuTriggerFor]="taskMenu">
              <div class="flex items-center gap-1">
                <!-- <span class="block w-2 h-2 bg-green-500 rounded-full"></span> -->
                <span class="text-xl font-medium">2 open</span>

              </div>


              <struct-icon icon="arrow_sm_down" class="ml-auto" ></struct-icon>

              <mat-menu #taskMenu="matMenu" xPosition="after" class="w-96">
                <!-- <h3 class="text-xl font-medium px-4 py-2">Tasks</h3> -->
                <button mat-menu-item>
                  <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                    <span class="block w-2 h-2 bg-green-500 rounded-full"></span>
                  </span>
                  <span class="text-base">Add translations</span>
                  <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                </button>
                <button mat-menu-item>
                  <span class="h-8 w-8 rounded-full bg-white ring-1 ring-gray-100 flex items-center justify-center mr-2">
                    <span class="block w-2 h-2 bg-orange-500 rounded-full"></span>
                  </span>
                  <span class="text-base">Send to review</span>
                  <struct-icon icon="arrow_sm_right" class="ml-auto"></struct-icon>
                </button>
              </mat-menu>
            </div>
          </div>

        </div>
      </div>

    </div>


  </div>

  <div class="md:ml-10 flex flex-shrink-0 items-center space-x-4 absolute top-0 right-0 h-24 px-4 lg:px-8">
    <struct-icon-button icon="pin" (click)="toggleHeaderPinned()"></struct-icon-button>
    <div class="w-px h-10 bg-gray-200 block"></div>
    <ng-content></ng-content>
  </div>

</header>
<section class="relative h-14 flex flex-shrink-0 w-full">
  <div class="absolute h-14 block inset-0">
    <ng-content select="[tabs]"></ng-content>
  </div>
</section>
