// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRegisterWithMicrosoftRequest  {
  inviteUid: string;
  inviteToken: string;
}

export class RegisterWithMicrosoftRequest  implements IRegisterWithMicrosoftRequest {
  inviteUid = "00000000-0000-0000-0000-000000000000";

  inviteToken = "";


  constructor(data?: Partial<IRegisterWithMicrosoftRequest>) {
    Object.assign(this, data);
  }
}