// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
import { BooleanOperator } from "@struct/models/struct/shared/search/boolean-operator";
import { Query } from "@struct/models/struct/shared/search/query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISimpleQuery extends Query {
  fieldQueries: FieldQuery[];
  queryType: string;
}

export class SimpleQuery extends Query implements ISimpleQuery {
  fieldQueries: FieldQuery[] = [];

  override queryType = "SimpleQuery";

  override booleanOperator: BooleanOperator = BooleanOperator.And;

  override includeArchived = false;


  constructor(data?: Partial<ISimpleQuery>) {
    super(data);
    Object.assign(this, data);
  }
}