// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum SubscriptionStatus {
  AwaitingApproval = "AwaitingApproval",
  Active = "Active",
  Suspended = "Suspended",
  Terminated = "Terminated",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace SubscriptionStatus {
  export function toString(value: SubscriptionStatus): string {
    return SubscriptionStatus[value];
  }

  export const values = (): SubscriptionStatus[] => [
    SubscriptionStatus.AwaitingApproval,
    SubscriptionStatus.Active,
    SubscriptionStatus.Suspended,
    SubscriptionStatus.Terminated,
  ];

  export function parse(value: string, ignoreCase?: boolean): SubscriptionStatus | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'SubscriptionStatus';
  }
}
