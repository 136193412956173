// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum BooleanOperator {
  And = "And",
  Or = "Or",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace BooleanOperator {
  export function toString(value: BooleanOperator): string {
    return BooleanOperator[value];
  }

  export const values = (): BooleanOperator[] => [
    BooleanOperator.And,
    BooleanOperator.Or,
  ];

  export function parse(value: string, ignoreCase?: boolean): BooleanOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'BooleanOperator';
  }
}
