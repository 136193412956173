// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanOperator } from "@struct/models/struct/shared/search/boolean-operator";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuery  {
  booleanOperator: BooleanOperator;
  queryType: string;
  includeArchived: boolean;
}

export class Query  implements IQuery {
  booleanOperator: BooleanOperator = BooleanOperator.And;

  queryType = "";

  includeArchived = false;


  constructor(data?: Partial<IQuery>) {
    Object.assign(this, data);
  }
}