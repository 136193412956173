// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRegisterUserCommand  {
  inviteToken: string;
  inviteUid: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  organization?: string | null;
  password: string;
  confirmPassword: string;
}

export class RegisterUserCommand  implements IRegisterUserCommand {
  inviteToken = "";

  inviteUid = "00000000-0000-0000-0000-000000000000";

  firstName = "";

  lastName = "";

  email = "";

  phoneNumber = "";

  organization: string | null = null;

  password = "";

  confirmPassword = "";


  constructor(data?: Partial<IRegisterUserCommand>) {
    Object.assign(this, data);
  }
}