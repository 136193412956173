<div class="relative z-10" aria-labelledby="confirm-dialog" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-black/50 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-2 pb-4 pt-5 sm:p-4 sm:pb-4" *ngIf="!data.userValueConfirm">

          <div class="w-full h-48 relative flex justify-center mb-5">

            <div class="absolute right-14 top-14 flex items-center justify-center">
              <struct-icon icon="trash_48" size="64" class="text-gray-500"></struct-icon>

            </div>
            <div class="absolute left-64 top-0 text-gray-500">
            <struct-icon icon="redo_48" size="48"></struct-icon>
            </div>
            <div class="absolute left-0 top-16 flex items-center gap-4">
              <div class="relative w-20 h-20">
                <div class="absolute w-20 h-20 bg-gray-200 opacity-40 rounded-sm -bottom-1 -right-1"></div>
                <div class="absolute top-0 left-0 w-20 h-20 bg-white shadow-sm rounded-sm flex flex-col gap-2 p-2">
                  <span class="w-10 h-2 bg-gray-400"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-1/2 h-1 bg-gray-100"></span>
                </div>
              </div>
              <div class="relative w-20 h-20">
                <div class="absolute w-20 h-20 bg-gray-200 opacity-40 rounded-sm -bottom-1 -right-1"></div>
                <div class="absolute top-0 left-0 w-20 h-20 bg-white shadow-sm rounded-sm flex flex-col gap-2 p-2">
                  <span class="w-10 h-2 bg-gray-400"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-1/2 h-1 bg-gray-100"></span>
                </div>
              </div>
              <div class="relative w-20 h-20">
                <div class="absolute w-20 h-20 bg-gray-200 opacity-40 rounded-sm -bottom-1 -right-1"></div>
                <div class="absolute top-0 left-0 w-20 h-20 bg-white shadow-sm rounded-sm flex flex-col gap-2 p-2">
                  <span class="w-10 h-2 bg-gray-400"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-full h-1 bg-gray-100"></span>
                  <span class="w-1/2 h-1 bg-gray-100"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="sm:flex sm:items-start animate-in slide-in-from-bottom-4 duration-800 relative">
            <div class="mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left">
              <h3 class="text-xl font-medium leading-6 text-gray-900" id="title">{{'General.Confirm' | translate}}</h3>
              <div class="mt-2">
                <p class="text-base text-gray-500" id="message">{{ data.description | async }} </p>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white" *ngIf="data.userValueConfirm">
          <form>

            <div class="w-full h-48 relative flex justify-center">

              <div class="absolute -left-24 -top-24 w-96 h-96  bg-white rounded-full flex items-center justify-center">
                <struct-icon icon="error_32" size="64" class="text-red-500 animate-in animate-bounce duration-150 repeat-1"></struct-icon>

              </div>
            </div>

            <div class="sm:flex sm:items-start animate-in slide-in-from-bottom-4 duration-800 relative">
              <div class="mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left w-full">
                <div class="px-4 py-2">
                  <h3 class="text-xl font-medium leading-6 text-gray-900" id="title">{{'General.Confirm' | translate}}</h3>
                  <div class="mt-2">
                    <p class="text-base text-gray-500" id="message">{{ data.description | async }}</p>
                  </div>

                </div>
                <div class="w-full mt-4 bg-gray-50 border-b border-t border-gray-200 px-4 py-4">
                  <p class="text-base text-gray-700 mb-4 ">
                    Please write <span class="font-semibold">{{ data.userValueConfirmString }}</span> in the input to confirm.
                  </p>
                  <struct-input [name]="'confirmStringInput'" [(value)]="confirmStringInput" [required]="true" (valueChange)="confirmValueCheck()" class="w-full"></struct-input>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="bg-gray-50 px-4 py-3">
          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button> -->
          <!-- <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          <div class="sm:flex justify-between gap-4 animate-in slide-in-from-bottom-4 duration-800">
            <struct-button buttonType="submit" (click)="onCancelClick()" [attr.data-cy]="'button.cancel'">{{'General.Cancel' | translate}}</struct-button>
            <struct-button buttonType="submit" color="primary" (click)="onOkClick()" [attr.data-cy]="'button.yesImSure'" *ngIf="!data.userValueConfirm">{{'General.YesImSure' | translate}}</struct-button>
            <struct-button buttonType="submit" [disabled]="!confirmValueCheck()" color="primary" (click)="onOkClick()" [attr.data-cy]="'button.yesImSure'" *ngIf="data.userValueConfirm">{{'General.YesImSure' | translate}}</struct-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

