// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetBandwidthQuota } from "@struct/models/struct/shared/subscription/asset-bandwidth-quota";
import { AssetStorageQuota } from "@struct/models/struct/shared/subscription/asset-storage-quota";
import { ChangelogRetentionQuota } from "@struct/models/struct/shared/subscription/changelog-retention-quota";
import { CustomExtensionQuota } from "@struct/models/struct/shared/subscription/custom-extension-quota";
import { LanguageQuota } from "@struct/models/struct/shared/subscription/language-quota";
import { TenantQuota } from "@struct/models/struct/shared/subscription/tenant-quota";
import { UserQuota } from "@struct/models/struct/shared/subscription/user-quota";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuotas  {
  languageQuota: LanguageQuota;
  customExtensionQuota: CustomExtensionQuota;
  changelogRetentionQuota: ChangelogRetentionQuota;
  userQuota: UserQuota;
  tenantQuota: TenantQuota;
  assetStorageQuota: AssetStorageQuota;
  assetBandwidthQuota: AssetBandwidthQuota;
}

export class Quotas  implements IQuotas {
  languageQuota: LanguageQuota = new LanguageQuota();

  customExtensionQuota: CustomExtensionQuota = new CustomExtensionQuota();

  changelogRetentionQuota: ChangelogRetentionQuota = new ChangelogRetentionQuota();

  userQuota: UserQuota = new UserQuota();

  tenantQuota: TenantQuota = new TenantQuota();

  assetStorageQuota: AssetStorageQuota = new AssetStorageQuota();

  assetBandwidthQuota: AssetBandwidthQuota = new AssetBandwidthQuota();


  constructor(data?: Partial<IQuotas>) {
    Object.assign(this, data);
  }
}