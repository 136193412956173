// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
import { NumberQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/number-query-operator";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INumberFieldQuery extends FieldQuery {
  filterValues: number[];
  filterValue: number;
  queryOperator: NumberQueryOperator;
  fieldQueryType: string;
}

export class NumberFieldQuery extends FieldQuery implements INumberFieldQuery {
  filterValues: number[] = [];

  filterValue = 0;

  queryOperator: NumberQueryOperator = NumberQueryOperator.Equals;

  override fieldQueryType = "NumberFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<INumberFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}