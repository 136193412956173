<accounts-form>
  <accounts-form-section>
    <accounts-form-section-title>
      General information
    </accounts-form-section-title>
    <accounts-form-section-description>
      Add overall information on the subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription plan name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Name'" [(value)]="subscriptionPlan.name" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Description
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription plan description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-textarea [name]="'Description'" [(value)]="subscriptionPlan.description"></struct-textarea>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Generally available
          </struct-form-row-headline>
          <struct-form-row-description>
            Generally available
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-checkbox [(value)]="subscriptionPlan.generallyAvailable">Generally available</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>


  <accounts-form-section>
    <accounts-form-section-title>
      Optional Features
    </accounts-form-section-title>
    <accounts-form-section-description>
      Which optional features are included
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Advanced Media Library
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.advancedMediaLibrary">Advanced Media Library</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Rules
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.businessRules">Business Rules</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dynamic Categories
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.dynamicCategories">Dynamic Categories</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dimensioned Attributes
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.dimensionedAttributes">Dimensioned Attributes</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Units
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.businessUnits">Business Units</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Enrichment Insights
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.enrichmentInsights">Enrichment Insights</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Workflows
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.workflows">Workflows</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Tasks
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscriptionPlan.setup!.optionalFeatures!.userTasks">User Tasks</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>



  <accounts-form-section>
    <accounts-form-section-title>
      Price information
    </accounts-form-section-title>
    <accounts-form-section-description>
      Set the pricing for this subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Price
          </struct-form-row-headline>
          <struct-form-row-description>
            Price description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.price!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.price!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.price!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title>
      Module Prices
    </accounts-form-section-title>
    <accounts-form-section-description>
      Prices for each module available for this subscription plan
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Azure AD Federation
          </struct-form-row-headline>
          <struct-form-row-description>
            Price
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.azureADFederationPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.azureADFederationPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.azureADFederationPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Supplier Portal
          </struct-form-row-headline>
          <struct-form-row-description>
            Price
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.supplierPortalPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.supplierPortalPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.supplierPortalPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Publication
          </struct-form-row-headline>
          <struct-form-row-description>
            Price
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.publicationPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.publicationPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.publicationPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            AI Assistant
          </struct-form-row-headline>
          <struct-form-row-description>
            Price
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.aiAssistantPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.aiAssistantPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="4">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscriptionPlan.setup!.modulePrices!.aiAssistantPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>


  <accounts-form-section>
    <accounts-form-section-title>
      Quotas
    </accounts-form-section-title>
    <accounts-form-section-description>
      Quota setup for a subscription plan. Quotas can be overriden on a subscription.
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Language Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Languages'" [(value)]="subscriptionPlan.setup!.quotas!.languageQuota!.includedLanguages" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscriptionPlan.setup!.quotas!.languageQuota!.extraLanguagePackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Users'" [(value)]="subscriptionPlan.setup!.quotas!.userQuota!.includedUsers" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscriptionPlan.setup!.quotas!.userQuota!.extraUsersPackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Tenant Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Tenants'" [(value)]="subscriptionPlan.setup!.quotas!.tenantQuota!.includedTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Max Number Tenants'" [(value)]="subscriptionPlan.setup!.quotas!.tenantQuota!.maxNumTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price DKK'" [(value)]="subscriptionPlan.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price EUR'" [(value)]="subscriptionPlan.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price USD'" [(value)]="subscriptionPlan.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Custom Extension
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Number of customs extensions'" [(value)]="subscriptionPlan.setup!.quotas!.customExtensionQuota!.numCustomExtensionsIncluded" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Change Log Retention
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Days change log retention'" [(value)]="subscriptionPlan.setup!.quotas!.changelogRetentionQuota!.numDaysRetention" [required]="true">
                <struct-input-suffix><span>DAYS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Storage Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Asset Storage Included In GB'" [(value)]="subscriptionPlan.setup!.quotas!.assetStorageQuota!.assetStorageIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Size In GB'" [(value)]="subscriptionPlan.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price DKK'" [(value)]="subscriptionPlan.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price EUR'" [(value)]="subscriptionPlan.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price USD'" [(value)]="subscriptionPlan.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Bandwidth Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Asset Bandwidth Included In GB'" [(value)]="subscriptionPlan.setup!.quotas!.assetBandwidthQuota!.assetBandwidthIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Size In GB'" [(value)]="subscriptionPlan.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price DKK'" [(value)]="subscriptionPlan.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price EUR'" [(value)]="subscriptionPlan.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price USD'" [(value)]="subscriptionPlan.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>

</accounts-form>
