<div class="flex h-full flex-col overflow-hidden absolute top-0 left-0 w-full">

  <!-- Top nav-->
  <header class="relative flex h-20 flex-shrink-0 items-center bg-white border-b border-gray-200 z-40">
    <!-- Desktop nav area -->
    <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2 md:px-4">
      <div class="min-w-0 flex-1 flex items-center">
        <struct-icon-button icon="chevron_left" #tooltip="matTooltip" matTooltip="Go back" [matTooltipPosition]="'below'"></struct-icon-button>
        <h1 class="text-4xl font-medium">Catalogues</h1>
      </div>
      <div class="md:ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">

        <div class="flex items-center space-x-4">
          <span class="inline-flex">
            <struct-icon-button icon="bookmark_add" iconSize="16" #tooltip="matTooltip" matTooltip="Add bookmark" [matTooltipPosition]="'below'"></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button icon="actions" iconSize="16" #tooltip="matTooltip" matTooltip="Actions" [matTooltipPosition]="'below'"
                ></struct-icon-button>

          </span>

          <span class="inline-flex">
              <struct-icon-button icon="quick_actions" iconSize="16" #tooltip="matTooltip" matTooltip="Quick actions" [matTooltipPosition]="'below'"
                ></struct-icon-button>
          </span>


        </div>
      </div>
    </div>
  </header>

  <!-- Bottom section -->
  <div class="flex min-h-0 flex-1 overflow-hidden">

    <!-- Main area -->
    <main class="relative min-w-0 flex-1 bg-gray-50 lg:flex p-2 sm:p-4 lg:p-8">

      <accounts-tenants></accounts-tenants>

    </main>

  </div>


</div>
