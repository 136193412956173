
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WizardEditorModel } from "@struct/models/struct/app/domain/dataconfiguration/wizard-editor-model";
import { VariantEditorModel } from "@struct/models/struct/app/domain/editor/variant-editor-model";
import { ArchiveVariantsCommand } from "@struct/models/struct/app/domain/variants/commands/archive-variants-command";
import { CopyVariantsCommand } from "@struct/models/struct/app/domain/variants/commands/copy-variants-command";
import { DeleteVariantsCommand } from "@struct/models/struct/app/domain/variants/commands/delete-variants-command";
import { UnarchiveVariantsCommand } from "@struct/models/struct/app/domain/variants/commands/unarchive-variants-command";
import { SaveTabModel } from "@struct/models/struct/app/frontendapi/models/editormodel/save-tab-model";
import { StructEntityUpdatedModel } from "@struct/models/struct/app/frontendapi/models/editormodel/struct-entity-updated-model";
import { CreateVariantModel } from "@struct/models/struct/app/frontendapi/models/variants/create-variant-model";

@Injectable({ providedIn: "root" })
export class VariantsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getCreateEditorModel(productId: number): Observable<WizardEditorModel> {
    return this.http.get<WizardEditorModel>(`${this.apiUrl}variants/create/${productId}`, this.httpOptions );
  }

  getEditorModel(variantId: number): Observable<VariantEditorModel> {
    return this.http.get<VariantEditorModel>(`${this.apiUrl}variants/${variantId}`, this.httpOptions );
  }

  deleteVariant(model: DeleteVariantsCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}variants/delete`, model, this.httpOptions);
  }

  archiveVariant(model: ArchiveVariantsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variants/archive`, model, this.httpOptions);
  }

  unArchiveVariant(model: UnarchiveVariantsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variants/unarchive`, model, this.httpOptions);
  }

  copyVariant(model: CopyVariantsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variants/copy`, model, this.httpOptions);
  }

  createVariant(model: CreateVariantModel): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}variants/create`, model, this.httpOptions);
  }

  saveTab(model: SaveTabModel): Observable<StructEntityUpdatedModel> {
    return this.http.patch<StructEntityUpdatedModel>(`${this.apiUrl}variants/savetab`, model, this.httpOptions);
  }
}

