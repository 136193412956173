import { Component } from '@angular/core';

@Component({
  selector: 'struct-spinner',
  templateUrl: './struct-spinner.component.html',
  styleUrls: ['./struct-spinner.component.scss'],
})
export class StructSpinnerComponent {

}
