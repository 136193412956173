<section class="w-full" [ngClass]="showMarketing ? 'bg-white' : 'bg-gray-50'">

  <!-- Grid -->
  <div class="h-screen w-full grid" [ngClass]="showMarketing ? 'grid-cols-2' : 'grid-cols-1'">
    <!-- <div class="absolute inset-0 bg-[url('/assets/images/login/hero.png')] bg-right-bottom bg-no-repeat"></div> -->

    <!-- First column -->
     <div class="flex flex-col h-full">
      <div class="p-4 flex flex-grow-0 border-b border-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-8 h-8 text-blue-200">
          <g>
            <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path>
            <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path>
            <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path>
          </g>
        </svg>
      </div>

      <div class="flex items-center justify-center flex-grow">

        <div class="p-4 lg:p-12 w-full lg:max-w-[500px] items-center relative @container/workarea bg-white rounded" [ngClass]="showMarketing ? '' : 'shadow-xl'">
          <div class="absolute inset-2 z-10 bg-white/90 flex items-center justify-center" *ngIf="working">
            <struct-spinner></struct-spinner>
          </div>
          <div class="flex flex-col w-full">


            <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8">{{"ForgotPassword.Title" | translate}}</h1>

            <div *ngIf="showSuccess" class="bg-green-50 rounded -mx-4 -mt-4 px-4 py-2 mb-8 text-green-600 animate-in slide-in-from-bottom-4">
              <p class="font-medium">{{'ForgotPassword.SuccessMessage' | translate}}</p>
            </div>

            <form class="form w-full" [formGroup]="forgotPasswordForm" autocomplete="off" novalidate (ngSubmit)="submit()">

              <struct-form-layout *ngIf="!showSuccess">
                <struct-form-row [borderBelow]="true">
                  <struct-form-row-headline>{{"ForgotPassword.Email" | translate}}</struct-form-row-headline>
                  <struct-form-row-description>{{"ForgotPassword.EmailDescription" | translate}}</struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="4">
                      <mat-form-field appearance="outline" class="w-full">
                        <mat-label>{{"ForgotPassword.Email" | translate}}</mat-label>
                        <input matInput [placeholder]="'ForgotPassword.Email' | translate"  appearance="outline" name="email" formControlName="email" />
                        <mat-error *ngIf="forgotPasswordForm.controls['email'].invalid">{{"ForgotPassword.EmailIsInvalid" | translate}}</mat-error>
                      </mat-form-field>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>

                </struct-form-row>



              </struct-form-layout>
              <div class="flex flex-col gap-4">
                <button *ngIf="!showSuccess" mat-flat-button color="primary" [disabled]="!forgotPasswordForm.valid" type="submit" class="w-full">{{"ForgotPassword.Submit" | translate}}</button>
                

                <div class="items-end">
                  <a class="text-sm text-gray-400 flex items-center gap-2" [routerLink]="['/sign-in']">
                    <struct-icon icon="arrow_left"></struct-icon>
                    {{"ForgotPassword.BackToLogin" | translate}}
                  </a>
                </div>
              </div>

              </form>


          </div>
        </div>


      </div>

      <div class="p-4 flex flex-grow-0 border-t border-gray-100">
        <div class="">
          <div class="flex gap-4">
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Help" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Privacy" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Terms" | translate}}</a>
          </div>
        </div>
        <div class="ml-auto">
          <a class="text-sm text-gray-500 hover:underline hover:text-blue-500 cursor-pointer" (click)="toggleMarketingColumn()">Hide marketing</a>
        </div>
      </div>
     </div>
    <!-- First column -->

    <!-- Second column -->

    <struct-carousel class="usein flex w-full h-full relative" *ngIf="showMarketing"
                 [itemCount]="5"
                 [itemsPerPage]="1"
                 [interval]="10000"
                 [gap]="0"
                 [freezePeriod]="5000"
                 [showHandles]="false"
                 [mainColor]="'--color-blue-200'"
                 [secondColor]="'--color-red-500'">
      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-blue-800">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-blue-300 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
            <div class="bg-blue-400 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 duration-1000"></div>
            <div class="bg-blue-500 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
            <div class="bg-blue-600 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
          </div>
          <div class="absolute top-1/2 left-1/4 -translate-y-1/2 -translate-x-1/2">
            <div class=" w-24 h-24 bg-blue-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl animate-ping">
              AI
            </div>
          </div>

        </div>
        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000 mb-8">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-green-50">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-green-200 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
            <div class="bg-green-300 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 duration-1000"></div>
            <div class="bg-green-400 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
            <div class="bg-green-500 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-16 duration-1000"></div>
          </div>
          <div class="absolute top-1/2 left-1/4 w-24 h-24 bg-green-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl -translate-y-1/2 -translate-x-1/2">
            AI
          </div>
        </div>
        <div class="absolute bottom-16 left-16 text-green-600 flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-12 duration-1000">Struct PIM Add-on: Supplier</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-12 duration-1000">Let your suppliers enrich your product data</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-yellow-900">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 "></div>
          </div>
          <div class="absolute top-1/2 left-1/4 w-24 h-24 bg-gray-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl -translate-y-1/2 -translate-x-1/2">
            AI
          </div>
        </div>
        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4">Struct PIM Add-on: Publications</p>
          <h1 class="text-3xl lg:text-5xl font-medium animate-in slide-in-from-bottom-4">Turn your Struct PIM product data into Publications</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-700">

        <div class="w-1/2">
          <div class="bg-gray-800 rounded-xl w-full h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
        </div>

        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">

        <div class="w-1/2">
          <div class="bg-white rounded-xl shadow-xl w-full h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
        </div>

        <div class="absolute bottom-16 left-16 text-gray-700 flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>


    </struct-carousel>


  </div>
  <!-- Grid -->
</section>
<!-- Section: Split screen -->


























<!-- <div class="card">
    <div class="card-body" *ngIf="!showSuccess">
        <h1 class="card-title">Forgot Password</h1>
        <div *ngIf="showError && errorMessageKey" class="alert alert-danger" role="alert">
            {{errorMessageKey | translate}}
        </div>
        <form [formGroup]="forgotPasswordForm" autocomplete="off" novalidate (ngSubmit)="submit()">
            <div class="mb-3 row">
                <label for="email" class="col-form-label col-sm-2">Email:</label>
                <div class="col-md-5">
                    <mat-form-field>
                        <mat-label>{{"ForgotPassword.Email" | translate}}</mat-label>
                        <input matInput [placeholder]="'ForgotPassword.Email' | translate"  appearance="outline" name="email" formControlName="email" />
                        <mat-error *ngIf="forgotPasswordForm.controls['email'].invalid">{{"ForgotPassword.EmailIsInvalid" | translate}}</mat-error>
                      </mat-form-field>
                </div>
            </div>
            <br>
            <div class="mb-3 row">
                <div class="col-md-1">
                    <button type="submit" class="btn btn-info" [disabled]="!forgotPasswordForm.valid">Submit</button>
                </div>
            </div>
        </form>
    </div>

    <div class="card-body" *ngIf="showSuccess">
        {{'ForgotPassword.SuccessMessage' | translate}}
    </div>
  </div> -->
