// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum BooleanQueryOperator {
  Equals = "Equals",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace BooleanQueryOperator {
  export function toString(value: BooleanQueryOperator): string {
    return BooleanQueryOperator[value];
  }

  export const values = (): BooleanQueryOperator[] => [
    BooleanQueryOperator.Equals,
  ];

  export function parse(value: string, ignoreCase?: boolean): BooleanQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'BooleanQueryOperator';
  }
}
