import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-boolean-column',
  templateUrl: './struct-boolean-column.component.html',
  styleUrls: ['./struct-boolean-column.component.scss'],
})
export class StructBooleanColumnComponent {
  @Input() value? : boolean;
}
