import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'struct-add-button',
  templateUrl: './struct-add-button.component.html',
  styleUrls: ['./struct-add-button.component.css'],
})
export class StructAddButtonComponent {
  @Input() disabled = false;
  @Input() hasClipboardItems = false;
  @Input() data: any | null = null;

  @Output() addClicked = new EventEmitter<StructAddButtonOutData>();
  @Output() clipboardClicked = new EventEmitter<StructAddButtonOutData>();

  click() {
    this.addClicked.emit(new StructAddButtonOutData(this.data));
  }

  clipboardClick() {
    this.clipboardClicked.emit(new StructAddButtonOutData(this.data));
  }
}

export class StructAddButtonOutData {
  /**
   *
   */
  constructor(data: any) {
    this.data = data;
  }
  data: any | null = null;
}