
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InviteUserToTenantCommand } from "@struct/models/struct/app/domain/usermanagement/commands/invite-user-to-tenant-command";
import { ResendTenantInviteCommand } from "@struct/models/struct/app/domain/usermanagement/commands/resend-tenant-invite-command";
import { UpdateTenantUserCommand } from "@struct/models/struct/app/domain/usermanagement/commands/update-tenant-user-command";
import { User } from "@struct/models/struct/app/domain/usermanagement/user";
import { UserDimension } from "@struct/models/struct/app/domain/usermanagement/user-dimension";
import { UserLanguage } from "@struct/models/struct/app/domain/usermanagement/user-language";
import { UserProfileSettings } from "@struct/models/struct/app/frontendapi/models/users/user-profile-settings";
import { UserSearchModel } from "@struct/models/struct/app/frontendapi/models/users/user-search-model";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";

@Injectable({ providedIn: "root" })
export class UsersApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getUserPermissions(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}users/myPermissions`, this.httpOptions );
  }

  getUserLanguages(): Observable<UserLanguage[]> {
    return this.http.get<UserLanguage[]>(`${this.apiUrl}users/mylanguages`, this.httpOptions );
  }

  getUserDimensions(): Observable<UserDimension[]> {
    return this.http.get<UserDimension[]>(`${this.apiUrl}users/myDimensions`, this.httpOptions );
  }

  getUserProfile(): Observable<UserProfileSettings> {
    return this.http.get<UserProfileSettings>(`${this.apiUrl}users/myprofile`, this.httpOptions );
  }

  getUsers(): Observable<UserSearchModel[]> {
    return this.http.get<UserSearchModel[]>(`${this.apiUrl}users`, this.httpOptions );
  }

  inviteUser(command: InviteUserToTenantCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/invite`, command, this.httpOptions);
  }

  resendInviteUser(command: ResendTenantInviteCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/resendinvite`, command, this.httpOptions);
  }

  getUser(uid: string): Observable<User | null> {
    return this.http.get<User | null>(`${this.apiUrl}users/${uid}`, this.httpOptions );
  }

  updateUser(cmd: UpdateTenantUserCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}users`, cmd, this.httpOptions);
  }

  removeUser(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}users/${uid}`, this.httpOptions );
  }
}

