import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard  {

  constructor(private oidcSecurityService: OidcSecurityService,private router: Router,) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return of(true);
    // return this.oidcSecurityService.isAuthenticated().pipe(
    //   switchMap((isAuthenticated: boolean) => {
    //     if(!isAuthenticated){
    //       this.router.navigate(['/unauthorized']);
    //     }
    //     return of(true);
    //   })
    // );    
  }
}
