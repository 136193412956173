import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { UiModule } from '@struct/ui/ui.module';
import { UsersApiService } from '@struct/services/account-management';
import { InviteAdminCommand } from '@struct/models/struct/usermanagementmodule/commands/invite-admin-command';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
  selector: 'accounts-invite-admin-user-dialog',
  templateUrl: './invite-admin-user-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AccountsHeaderComponent,
    UiModule,
  ],
})
export class InviteAdminUserDialogComponent implements OnInit {
  working = false;
  command = new InviteAdminCommand();

  @ViewChild('inviteform') inviteForm: NgForm | null = null;

  constructor(private authService: AuthenticationService, private userApi: UsersApiService, private dialogRef: MatDialogRef<InviteAdminUserDialogComponent>) {}

  ngOnInit(): void {
      this.authService.getAuthenticatedUser().subscribe(user => {
        this.command.invitedBy = user?.email ?? '';
      });
  }

  sendInvite(): void {
    this.working = true;
    this.userApi.inviteAdmin(this.command).subscribe(() => {
      this.working = false;
      this.dialogRef.close();
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
