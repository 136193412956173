<ng-container *ngIf="itemsPerPage > 0">
  <div class="hidescroll items" [style.--scroll_snap_align]="itemsPerPage === 1 ? 'center' : 'start'" [style.--gap]="gap + 'px'" #itemsElement>
    <ng-content></ng-content>
  </div>

  <div class="d absolute top-16 left-16 z-10" [style.--mainColor]="mainColor" [style.--secondcolor]="secondColor">

    <button *ngIf="showHandles" type="button" class="nav prev" (click)="btnBack()" aria-label="previous slide">
      <svg [attr.fill]="secondColor" class="flip" width="45" height="45" viewBox="0 -960 960 960">
        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
      </svg>
    </button>

    <div *ngIf="showIndicators || !!interval" class="carousel">

      <div *ngIf="showIndicators" class="indicators">
        <ng-container *ngFor="let p of items; let i = index">
          <button *ngIf="i % itemsPerPage === 0" [attr.aria-label]="'go to slide ' + (i + 1)" [attr.index]="i / itemsPerPage" (click)="scrolProduct(i / itemsPerPage)" type="button" class="indicator" [class.active]="currentItem === i / itemsPerPage"></button>
        </ng-container>
      </div>
      <div *ngIf="!!interval" class="timer" [class.full]="timer" [style.--interval]="interval + 'ms'"></div>

    </div>

    <button *ngIf="showHandles" type="button" class="nav next" (click)="btnNext()" aria-label="next slide">
      <svg [attr.fill]="secondColor" class="flip" width="45" height="45" viewBox="0 -960 960 960">
        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
      </svg>
    </button>

  </div>
</ng-container>
