
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateLanguageCommand } from "@struct/models/struct/app/domain/shared/language/commands/create-language-command";
import { SortLanguagesCommand } from "@struct/models/struct/app/domain/shared/language/commands/sort-languages-command";
import { UpdateLanguageCommand } from "@struct/models/struct/app/domain/shared/language/commands/update-language-command";
import { Culture } from "@struct/models/struct/app/domain/shared/language/culture";
import { Language } from "@struct/models/struct/app/domain/shared/language/language";
import { LanguageSearchModel } from "@struct/models/struct/app/frontendapi/models/language/language-search-model";

@Injectable({ providedIn: "root" })
export class LanguagesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  createLanguage(command: CreateLanguageCommand): Observable<Language> {
    return this.http.post<Language>(`${this.apiUrl}languages`, command, this.httpOptions);
  }

  updateLanguage(command: UpdateLanguageCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}languages`, command, this.httpOptions);
  }

  deleteLanguage(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}languages/${id}`, this.httpOptions );
  }

  sortLanguages(command: SortLanguagesCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}languages/sorting`, command, this.httpOptions);
  }

  getLanguages(): Observable<LanguageSearchModel[]> {
    return this.http.get<LanguageSearchModel[]>(`${this.apiUrl}languages`, this.httpOptions );
  }

  getCultures(): Observable<Culture[]> {
    return this.http.get<Culture[]>(`${this.apiUrl}cultures`, this.httpOptions );
  }

  getLanguage(id: number): Observable<Language> {
    return this.http.get<Language>(`${this.apiUrl}languages/${id}`, this.httpOptions );
  }
}

