<struct-app-content-container *ngIf="loaded">
  <accounts-header>Edit profile </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <ng-container *ngIf="showSpinner">
        <struct-spinner class="z-50"></struct-spinner>
        <div class="fixed h-screen w-screen bg-white z-40 opacity-50"></div>
      </ng-container>
      <form #profileform="ngForm" *ngIf="user !== null">
        <accounts-form>
          <accounts-form-section>
            <accounts-form-section-title> Credentials </accounts-form-section-title>
            <accounts-form-section-description> Account contact and login information </accounts-form-section-description>
            <accounts-form-section-content>
              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Email address </struct-form-row-headline>
                  <struct-form-row-description> Email address description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-input [name]="'Email'" [(value)]="user.email" [required]="true" [disabled]="true"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Sign in method </struct-form-row-headline>
                  <struct-form-row-description> Manage how you sign in to your account </struct-form-row-description>
                  <struct-form-row-grid *ngIf="user.authenticationMethod === authMethods.UsernamePassword">
                    <struct-form-row-grid-tile data-cols="6">
                      <div class="flex flex-col gap-2">

                        <div class="flex border rounded relative">
                          <div class="flex w-full items-center justify-start gap-4 bg-white px-4 py-4 text-sm text-gray-900 animate-in slide-in-from-bottom-4">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="h-10 w-10">
                              <g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10"><path d="M45 20V15C45 12.2386 42.7614 10 40 10H8C5.23858 10 3 12.2386 3 15V29C3 31.7614 5.23858 34 8 34H23" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M23.2499 22C23.2499 22.4142 23.5857 22.75 23.9999 22.75C24.4141 22.75 24.7499 22.4142 24.7499 22C24.7499 21.5858 24.4141 21.25 23.9999 21.25C23.5857 21.25 23.2499 21.5858 23.2499 22Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M22.75 22C22.75 22.6904 23.3096 23.25 24 23.25C24.6904 23.25 25.25 22.6904 25.25 22C25.25 21.3096 24.6904 20.75 24 20.75C23.3096 20.75 22.75 21.3096 22.75 22Z" fill="currentColor" data-stroke="none" stroke="none"></path> <path d="M12.4999 22C12.4999 22.4142 12.8357 22.75 13.2499 22.75C13.6641 22.75 13.9999 22.4142 13.9999 22C13.9999 21.5858 13.6641 21.25 13.2499 21.25C12.8357 21.25 12.4999 21.5858 12.4999 22Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M12 22C12 22.6904 12.5596 23.25 13.25 23.25C13.9404 23.25 14.5 22.6904 14.5 22C14.5 21.3096 13.9404 20.75 13.25 20.75C12.5596 20.75 12 21.3096 12 22Z" fill="currentColor" data-stroke="none" stroke="none"></path> <path d="M43.5 30H30.5C29.1193 30 28 31.1193 28 32.5V38.5C28 39.8807 29.1193 41 30.5 41H43.5C44.8807 41 46 39.8807 46 38.5V32.5C46 31.1193 44.8807 30 43.5 30Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M32.5 30V26.5C32.5 24.0147 34.5147 22 37 22V22C39.4853 22 41.5 24.0147 41.5 26.5V30" stroke="currentColor" stroke-width="3" fill="none"></path></g>
                            </svg>

                            <div class="flex flex-col gap-0">
                              <h3 class="font-medium leading-tight">Email and password</h3>
                              <span class="text-sm leading-6 text-gray-400">You are signing in using email and password</span>
                            </div>

                            <div class="ml-auto flex gap-2">
                              <button mat-flat-button
                                (click)="changePassword()">
                                <struct-icon icon="lock" size="12"></struct-icon>
                                <span class="">Change password</span>
                              </button>
                              <span class="w-8 h-8 rounded-full flex items-center justify-center bg-green-500 text-white">
                                <struct-icon icon="check" size="12"></struct-icon>
                              </span>

                            </div>
                          </div>
                        </div>

                        <div class="relative my-2">
                          <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-100"></div>
                          </div>
                          <div class="relative flex justify-center text-sm font-medium leading-6">
                            <span class="bg-white px-6 text-gray-900">Change sign in method</span>
                          </div>
                        </div>

                        <div class="flex border rounded">

                          <a (click)="signInWithMicrosoft()" class="flex cursor-pointer w-full items-center justify-start gap-4 bg-gray-50 px-4 py-4 text-sm text-gray-900 hover:bg-gray-100 animate-in slide-in-from-bottom-4">

                            <div class="relative">

                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="h-10 w-10">
                                <g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10"><rect x="3" y="3" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="3" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 24)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="3" y="27" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="27" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 72)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect></g>
                              </svg>

                            </div>
                            <div class="flex flex-col gap-0">
                              <h3 class="font-medium leading-tight">Microsoft account</h3>
                              <span class="text-sm leading-6 text-gray-400">Sign in with your Microsoft account</span>
                            </div>

                            <div class="ml-auto">
                              <span class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 text-gray-500">
                                <struct-icon icon="chevron_right" size="12"></struct-icon>
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                  <struct-form-row-grid *ngIf="user.authenticationMethod === authMethods.Microsoft">
                    <struct-form-row-grid-tile data-cols="6">
                      <div class="flex flex-col gap-2">
                        <div class="flex border rounded">

                          <a class="flex w-full items-center justify-start gap-4 bg-white px-4 py-4 text-sm text-gray-900 animate-in slide-in-from-bottom-4">

                            <div class="relative">

                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="h-10 w-10">
                                <g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10"><rect x="3" y="3" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="3" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 24)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="3" y="27" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="27" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 72)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect></g>
                              </svg>

                            </div>
                            <div class="flex flex-col gap-0">
                              <h3 class="font-medium leading-tight">Microsoft account</h3>
                              <span class="text-sm leading-6 text-gray-400">You are signing in with your Microsoft account</span>
                            </div>

                            <div class="ml-auto">
                              <span class="w-8 h-8 rounded-full flex items-center justify-center bg-green-500 text-white">
                                <struct-icon icon="check" size="12"></struct-icon>
                              </span>
                            </div>
                          </a>
                        </div>

                        <div class="relative my-2">
                          <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-100"></div>
                          </div>
                          <div class="relative flex justify-center text-sm font-medium leading-6">
                            <span class="bg-white px-6 text-gray-900">Change sign in method</span>
                          </div>
                        </div>

                        <div class="flex border rounded relative">
                          <a (click)="signInEmailAndPassword()" class="flex cursor-pointer w-full items-center justify-start gap-4 bg-gray-50 px-4 py-4 text-sm text-gray-900  hover:bg-gray-100 animate-in slide-in-from-bottom-4">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="h-10 w-10">
                              <g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10"><path d="M45 20V15C45 12.2386 42.7614 10 40 10H8C5.23858 10 3 12.2386 3 15V29C3 31.7614 5.23858 34 8 34H23" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M23.2499 22C23.2499 22.4142 23.5857 22.75 23.9999 22.75C24.4141 22.75 24.7499 22.4142 24.7499 22C24.7499 21.5858 24.4141 21.25 23.9999 21.25C23.5857 21.25 23.2499 21.5858 23.2499 22Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M22.75 22C22.75 22.6904 23.3096 23.25 24 23.25C24.6904 23.25 25.25 22.6904 25.25 22C25.25 21.3096 24.6904 20.75 24 20.75C23.3096 20.75 22.75 21.3096 22.75 22Z" fill="currentColor" data-stroke="none" stroke="none"></path> <path d="M12.4999 22C12.4999 22.4142 12.8357 22.75 13.2499 22.75C13.6641 22.75 13.9999 22.4142 13.9999 22C13.9999 21.5858 13.6641 21.25 13.2499 21.25C12.8357 21.25 12.4999 21.5858 12.4999 22Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M12 22C12 22.6904 12.5596 23.25 13.25 23.25C13.9404 23.25 14.5 22.6904 14.5 22C14.5 21.3096 13.9404 20.75 13.25 20.75C12.5596 20.75 12 21.3096 12 22Z" fill="currentColor" data-stroke="none" stroke="none"></path> <path d="M43.5 30H30.5C29.1193 30 28 31.1193 28 32.5V38.5C28 39.8807 29.1193 41 30.5 41H43.5C44.8807 41 46 39.8807 46 38.5V32.5C46 31.1193 44.8807 30 43.5 30Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M32.5 30V26.5C32.5 24.0147 34.5147 22 37 22V22C39.4853 22 41.5 24.0147 41.5 26.5V30" stroke="currentColor" stroke-width="3" fill="none"></path></g>
                            </svg>

                            <div class="flex flex-col gap-0">
                              <h3 class="font-medium leading-tight">Email and password</h3>
                              <span class="text-sm leading-6 text-gray-400">Sign in using email and password</span>
                            </div>

                            <div class="ml-auto">
                              <span class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 text-white">
                                <struct-icon icon="chevron_right" size="12"></struct-icon>
                              </span>
                            </div>
                          </a>
                        </div>

                      <!-- <div class="relative mb-4 mt-4">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                          <div class="w-full border-t border-gray-200"></div>
                        </div>
                        <div class="relative flex justify-center text-sm font-medium leading-6">
                          <span class="bg-white px-6 text-gray-900">Change sign in method</span>
                        </div>
                      </div> -->
                      </div>
                    </struct-form-row-grid-tile>

                  </struct-form-row-grid>
                </struct-form-row>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6"> </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>


            </accounts-form-section-content>
          </accounts-form-section>
          <accounts-form-section>
            <accounts-form-section-title> Profile </accounts-form-section-title>
            <accounts-form-section-description> Add information about yourself </accounts-form-section-description>
            <accounts-form-section-content>
              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> First name </struct-form-row-headline>
                  <struct-form-row-description> First name description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-input [name]="'First name'" [(value)]="user.firstName" [required]="true"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Last name </struct-form-row-headline>
                  <struct-form-row-description> Last name description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-input [name]="'Last name'" [(value)]="user.lastName" [required]="true"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Phone number </struct-form-row-headline>
                  <struct-form-row-description> Phone number description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-input [name]="'Phone number'" [required]="false" [(value)]="user.phoneNumber"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Organization </struct-form-row-headline>
                  <struct-form-row-description> Organization description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-input [name]="'Organization'" [required]="false" [(value)]="user.organization"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>
            </accounts-form-section-content>
          </accounts-form-section>

          <accounts-form-section>
            <accounts-form-section-title> Culture </accounts-form-section-title>
            <accounts-form-section-description> Culture used when formatting values </accounts-form-section-description>
            <!-- <accounts-form-section-title> Prefered language </accounts-form-section-title>
            <accounts-form-section-description> Language used when you are logged in to Struct </accounts-form-section-description> -->
            <accounts-form-section-content>
              <!-- <div class="md:grid md:grid-cols-12 md:gap-6">
                <div class="md:col-span-12">
                  <struct-simple-select
                    [options]="(languages$ | async) ?? []"
                    bindLabel="displayName"
                    bindValue="name"
                    [(ngModel)]="user.languageName"
                    name="Language"
                    [required]="true"
                    [placeholder]="'Language'"
                    name="languageName"></struct-simple-select>
                </div>
              </div> -->

              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Culture code </struct-form-row-headline>
                  <struct-form-row-description> Regional format (your number, time, date and currency format) </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-simple-select
                        [options]="cultures"
                        bindLabel="name"
                        bindValue="cultureCode"
                        [(ngModel)]="user.cultureCode"
                        [placeholder]="'Regional format (your number, time, date and currency format)'"
                        name="cultureCode"></struct-simple-select>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>
            </accounts-form-section-content>
          </accounts-form-section>
          <accounts-form-section>
            <accounts-form-section-title> Timezone </accounts-form-section-title>
            <accounts-form-section-description> The time zone you will see dates and times in </accounts-form-section-description>
            <accounts-form-section-content>
              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Timezone </struct-form-row-headline>
                  <struct-form-row-description> Timezone description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-simple-select
                        [options]="timeZones"
                        bindLabel="displayName"
                        bindValue="id"
                        [(ngModel)]="user.timezoneId"
                        [placeholder]="'Timezone'"
                        name="timeZoneId"></struct-simple-select>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>
            </accounts-form-section-content>
          </accounts-form-section>

          <!-- <accounts-form-danger-zone *ngIf="false"
            [title]="'Delete account'"
            [description]="'Deleting your account is irreverisble and cannot be undone. Once your account is deleted, you will no loonger have access to any encrionmnets or this account page'"
            [buttonText]="'Delete account'"
            (onDeleteClicked)="deleteUser(user)">
          </accounts-form-danger-zone> -->
        </accounts-form>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button buttonType="button" (click)="updateUser(user)" color="primary" [disabled]="!profileform?.dirty">Update profile</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
