<ng-container *ngIf="type===QueryType.simple">
  <section class="flex flex-col struct-query-condition-set-wrapper">
    <!-- <div class="struct-query-condition-set-operator">
      <button mat-flat-button [matMenuTriggerFor]="menuOperator" color="primary">
        <div class="flex items-center">
          <span class="">And</span>
          <struct-icon icon="arrow_sm_down" class="-mr-2"></struct-icon>
        </div>

      </button>
      <mat-menu #menuOperator="matMenu">
        <button mat-menu-item>And</button>
        <button mat-menu-item>Or</button>
      </mat-menu>
    </div> -->

    <ul role="list" class="struct-query-condition-set-list">

      <li class="list-none struct-query-condition-set-item" *ngFor="let set of conditionSets" [cdkContextMenuTriggerFor]="condition_set_context_menu">
        <div class="flex items-center gap-2">
          <div class="flex-shrink-0 flex gap-1">
            <struct-icon icon="condition_24" size="20"></struct-icon>
          </div>
          <div class="min-w-0 flex-1">
            <a href="javascript:;" class="truncate text-md font-medium text-gray-900">{{set.name}}</a>
          </div>
          <div class="flex items-center ml-5">

            <struct-icon-button
              icon="delete"
              size="16"
              class=""
              #tooltip="matTooltip"
              matTooltip="remove this condition set"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </div>
        </div>



        <section class="flex flex-col struct-query-condition-wrapper">
          <div class="struct-query-condition-operator">
            <button mat-flat-button [matMenuTriggerFor]="menuOperator" color="primary">
              <div class="flex items-center">
                <span class="">And</span>
                <struct-icon icon="arrow_sm_down" class="-mr-2"></struct-icon>
              </div>
            </button>
            <mat-menu #menuOperator="matMenu">
              <button mat-menu-item>And</button>
              <button mat-menu-item>Or</button>
            </mat-menu>
            <!-- <button mat-flat-button color="primary">And</button>
            <button mat-flat-button color="default">Or</button> -->
          </div>
          <ul role="list" class="struct-query-condition-list">

            <li class="group list-none struct-query-condition-item" *ngFor="let condition of conditions" [cdkContextMenuTriggerFor]="condition_context_menu">
              <div class="flex items-center gap-2">
                <div class="flex-shrink-0 flex gap-1">
                  <struct-icon icon="businessrule_16" size="16" />
                </div>
                <div class="min-w-0 flex-1">
                  <a href="javascript:;" class="truncate text-md font-medium text-gray-900">{{condition.name}}</a>
                  <!-- <p class="truncate text-sm text-gray-500">{{condition.type}}</p> -->
                </div>
                <div class="flex items-center ml-5 opacity-0 group-hover:opacity-100">
                  <struct-icon-button
                    icon="edit"
                    size="16"
                    class=""
                    #tooltip="matTooltip"
                    matTooltip="Edit this condition"
                    [matTooltipPosition]="'below'"
                  ></struct-icon-button>
                  <struct-icon-button
                    icon="cut"
                    size="16"
                    class=""
                    #tooltip="matTooltip"
                    matTooltip="Cut this condition"
                    [matTooltipPosition]="'below'"
                    (click)="toggleRulesCut()"
                  ></struct-icon-button>
                  <struct-icon-button
                    icon="remove"
                    size="16"
                    class=""
                    #tooltip="matTooltip"
                    matTooltip="remove this condition"
                    [matTooltipPosition]="'below'"
                  ></struct-icon-button>
                </div>
              </div>
            </li>
            <li class="struct-query-condition-add-new-wrapper">
              <struct-add-button [hasClipboardItems]="true">Add condition</struct-add-button>
            </li>
          </ul>
        </section>
      </li>
      <li class="struct-query-condition-set-add-new-wrapper">
        <struct-add-button>Add condition set</struct-add-button>

      </li>
    </ul>
  </section>
</ng-container>

<ng-container *ngIf="type===QueryType.advanced">

</ng-container>



<ng-template #condition_set_context_menu>
  <div class="struct-context-menu" cdkMenu>
    <button class="struct-context-menu-item" cdkMenuItem>
      <struct-icon icon="edit" size="12"></struct-icon>
      <span>Edit</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="remove" size="12"></struct-icon><span>Delete</span></button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="cut" size="12"></struct-icon><span>Cut</span></button>
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="copy" size="12"></struct-icon><span>Copy</span></button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="add" size="12"></struct-icon>
        <struct-icon icon="move_layer_up" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Add condition set above</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="add" size="12"></struct-icon>
        <struct-icon icon="move_layer_up" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Add condition set below</span>
    </button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="clipboard" size="12"></struct-icon>
        <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Paste above</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="clipboard" size="12"></struct-icon>
        <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Paste below</span>
    </button>
  </div>
</ng-template>

<ng-template #condition_context_menu>
  <div class="struct-context-menu" cdkMenu>
    <button class="struct-context-menu-item" cdkMenuItem>
      <struct-icon icon="edit" size="12"></struct-icon>
      <span>Edit</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="remove" size="12"></struct-icon><span>Delete</span></button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="cut" size="12"></struct-icon><span>Cut</span></button>
    <button class="struct-context-menu-item" cdkMenuItem><struct-icon icon="copy" size="12"></struct-icon><span>Copy</span></button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="add" size="12"></struct-icon>
        <struct-icon icon="move_layer_up" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Add condition above</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="add" size="12"></struct-icon>
        <struct-icon icon="move_layer_up" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Add condition below</span>
    </button>
    <hr />
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="clipboard" size="12"></struct-icon>
        <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Paste above</span>
    </button>
    <button class="struct-context-menu-item" cdkMenuItem>
      <div class="relative flex items-center">
        <struct-icon icon="clipboard" size="12"></struct-icon>
        <struct-icon icon="move_layer_down" size="8" class="absolute -left-3"></struct-icon>
      </div>
      <span>Paste below</span>
    </button>
  </div>
</ng-template>
