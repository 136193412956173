// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserQuota  {
  includedUsers: number;
  extraUsersPackSize: number;
  extraUsersPackPrice: Price;
}

export class UserQuota  implements IUserQuota {
  includedUsers = 0;

  extraUsersPackSize = 0;

  extraUsersPackPrice: Price = new Price();


  constructor(data?: Partial<IUserQuota>) {
    Object.assign(this, data);
  }
}