<struct-app-content-container *ngIf="loaded">
  <!-- <accounts-header>{{'ChangeSignInToMicrosoft.Title' | translate}} </accounts-header> -->
  <struct-app-content>

    <struct-app-content-workarea [noBottomPadding]="true">
      <ng-container *ngIf="working">
        <div class="absolute left-0 top-0 w-full h-full bg-white z-40 opacity-50"></div>
      </ng-container>
      <div class="flex items-center gap-4">
        <div class="w-1/2 border-r h-full flex items-center p-4 justify-center">
          <div class="w-32 h-32 relative flex-grow-0 flex items-center justify-center animate-in slide-in-from-bottom-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10"><rect x="3" y="3" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="3" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 24)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="3" y="27" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect> <rect x="27" y="27" transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 72 72)" fill="none" stroke="currentColor" stroke-width="3" width="18" height="18"></rect></g>
            </svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-24 h-24"><g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10">
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,22 2,27.7999992 2,62 62,62 62,27.7999992 52,22 " stroke-linecap="butt"></polyline>
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,33.4000015 12,2 52,2 52,33.2000008 " stroke-linecap="butt"></polyline>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="2" y1="27.7999992" x2="62" y2="62" stroke-linecap="butt"></line>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="62" y1="27.7999992" x2="31.2000008" y2="44.4000015" stroke-linecap="butt"></line>
            </g></svg> -->
          </div>
        </div>

        <div class="flex flex-col gap-2 p-4">
          <h3 class="text-xl font-medium animate-in slide-in-from-bottom-4">
            {{'ChangeSignInToMicrosoft.Title' | translate}}
          </h3>
          <div class="text-sm animate-in slide-in-from-bottom-4">
            {{'ChangeSignInToMicrosoft.ConnectToMicrosoftAccountDecription' | translate}}
          </div>
        </div>
      </div>

      <!-- <div class="flex flex-col items-center gap-4">
        <div class="w-32 h-32 relative flex-grow-0 flex items-center justify-center bg-gray-100 rounded-full animate-in slide-in-from-bottom-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-20 w-20 text-blue-500"><g fill="currentColor"><rect x="1" y="1" fill="currentColor" width="10" height="10"></rect><rect x="13" y="1" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 12)" fill="currentColor" width="10" height="10"></rect><rect x="1" y="13" fill="currentColor" width="10" height="10"></rect><rect x="13" y="13" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 36)" fill="currentColor" width="10" height="10"></rect></g></svg>
        </div>
        <div class="flex flex-col">
          <div class="pb-4 text-base font-medium animate-in slide-in-from-bottom-4 text-center">
            {{'ChangeSignInToMicrosoft.ConnectToMicrosoftAccountDecription' | translate}}
          </div>
        </div>
      </div> -->
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <struct-button buttonType="button" *ngIf="!working" (click)="cancel()" color="default">{{'General.Cancel' | translate}}</struct-button>
    <struct-button buttonType="button" [working]="working" (click)="connectToMicrosoftAccount()" color="primary">{{'ChangeSignInToMicrosoft.ConnectToMicrosoftAccount' | translate}}</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
