
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddUserTaskCommentCommand } from "@struct/models/struct/app/domain/usertask/commands/add-user-task-comment-command";
import { AttachedEntityCommandModel } from "@struct/models/struct/app/domain/usertask/commands/attached-entity-command-model";
import { CreateUserTaskCommand } from "@struct/models/struct/app/domain/usertask/commands/create-user-task-command";
import { DeleteUserTaskCommand } from "@struct/models/struct/app/domain/usertask/commands/delete-user-task-command";
import { DeleteUserTaskCommentCommand } from "@struct/models/struct/app/domain/usertask/commands/delete-user-task-comment-command";
import { SetDeadlineCommand } from "@struct/models/struct/app/domain/usertask/commands/set-deadline-command";
import { SetLastViewedCommand } from "@struct/models/struct/app/domain/usertask/commands/set-last-viewed-command";
import { SetResponsibleCommand } from "@struct/models/struct/app/domain/usertask/commands/set-responsible-command";
import { SetStatusCommand } from "@struct/models/struct/app/domain/usertask/commands/set-status-command";
import { UpdateUserTaskCommand } from "@struct/models/struct/app/domain/usertask/commands/update-user-task-command";
import { UpdateUserTaskCommentCommand } from "@struct/models/struct/app/domain/usertask/commands/update-user-task-comment-command";
import { UpdateUserTaskEntityStatusCommand } from "@struct/models/struct/app/domain/usertask/commands/update-user-task-entity-status-command";
import { UserTask } from "@struct/models/struct/app/domain/usertask/user-task";
import { UserTaskComment } from "@struct/models/struct/app/domain/usertask/user-task-comment";
import { UserTaskEntity } from "@struct/models/struct/app/domain/usertask/user-task-entity";
import { UserTaskReference } from "@struct/models/struct/app/domain/usertask/user-task-reference";

@Injectable({ providedIn: "root" })
export class UserTasksApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getUserTask(uid: string): Observable<UserTask | null> {
    return this.http.get<UserTask | null>(`${this.apiUrl}usertasks/${uid}`, this.httpOptions );
  }

  getAllUserTasks(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks`, this.httpOptions );
  }

  getAllOpenUserTasks(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks/open`, this.httpOptions );
  }

  getAllUserTasksCreatedByMe(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks/createdbyme`, this.httpOptions );
  }

  getOpenUserTasksCreatedByMe(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks/opencreatedbyme`, this.httpOptions );
  }

  getUserTasksAssignedtoMe(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks/assignedtome`, this.httpOptions );
  }

  getOpenUserTasksAssignedToMe(): Observable<UserTaskReference[]> {
    return this.http.get<UserTaskReference[]>(`${this.apiUrl}usertasks/openassignedtome`, this.httpOptions );
  }

  setResponsibleOnTasks(commands: SetResponsibleCommand[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}usertasks/batch/responsible`, commands, this.httpOptions);
  }

  getEntityInformation(entities: AttachedEntityCommandModel[]): Observable<UserTaskEntity[]> {
    return this.http.post<UserTaskEntity[]>(`${this.apiUrl}usertasks/usertaskentityinformation`, entities, this.httpOptions);
  }

  setStatusOnTasks(commands: SetStatusCommand[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}usertasks/batch/status`, commands, this.httpOptions);
  }

  setDeadlineOnTasks(commands: SetDeadlineCommand[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}usertasks/batch/deadline`, commands, this.httpOptions);
  }

  createUserTask(cmd: CreateUserTaskCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usertasks`, cmd, this.httpOptions);
  }

  updateUserTask(cmd: UpdateUserTaskCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}usertasks`, cmd, this.httpOptions);
  }

  deleteUserTasks(commands: DeleteUserTaskCommand[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usertasks/delete`, commands, this.httpOptions);
  }

  addComment(cmd: AddUserTaskCommentCommand): Observable<UserTaskComment> {
    return this.http.post<UserTaskComment>(`${this.apiUrl}usertasks/addcomment`, cmd, this.httpOptions);
  }

  deleteComment(cmd: DeleteUserTaskCommentCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usertasks/deletecomment`, cmd, this.httpOptions);
  }

  updateComment(cmd: UpdateUserTaskCommentCommand): Observable<UserTaskComment> {
    return this.http.post<UserTaskComment>(`${this.apiUrl}usertasks/updatecomment`, cmd, this.httpOptions);
  }

  updateEntityStatus(cmd: UpdateUserTaskEntityStatusCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usertasks/updateentitystatus`, cmd, this.httpOptions);
  }

  setLastViewed(cmd: SetLastViewedCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}usertasks/setlastviewed`, cmd, this.httpOptions);
  }

  getSearchUrlForAttachedProducts(userTaskUid: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}usertasks/productsearchurl?userTaskUid=${userTaskUid}`, this.httpOptions );
  }

  getSearchUrlForAttachedVariants(userTaskUid: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}usertasks/variantsearchurl?userTaskUid=${userTaskUid}`, this.httpOptions );
  }

  getSearchUrlForAttachedVariantGroups(userTaskUid: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}usertasks/variantgroupsearchurl?userTaskUid=${userTaskUid}`, this.httpOptions );
  }

  getSearchUrlForAttachedCategories(userTaskUid: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}usertasks/categorysearchurl?userTaskUid=${userTaskUid}`, this.httpOptions );
  }
}

