import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { StructAvatarSize } from './struct-avatar-size';
import { StructAvatarSrc } from './struct-avatar-src';
import { StructAvatarInitialsColor } from './struct-avatar-initials-color';

@Component({
  selector: 'struct-avatar',
  templateUrl: './struct-avatar.component.html',
  styleUrls: ['./struct-avatar.component.scss'],
})
export class StructAvatarComponent implements AfterViewInit {

  constructor(private elementRef: ElementRef){}

  bgColors = StructAvatarInitialsColor;

  /**
   * Specifies the name of the record that the avatar represents.
   * If the `src` property does not specify an image, the component displays
   * initials from the first and last words in the name. To ensure
   * that the component extracts the correct initials, specify a name with no prefix
   * or suffix, or just specify initials with a space between them. This property is
   * not required, but the component requires either the `name` or `src` property.
   */
  @Input()
  public name: string | null = null;

  @Input()
  public bgColor: StructAvatarInitialsColor = StructAvatarInitialsColor.Default;

  /**
   * Specifies an image to display to identify a record. This property is
   * not required, but the component requires either the `name` or `src` property.
   */
  @Input()
  public src: StructAvatarSrc | null = null;

  /**
   * Specifies the size of the avatar.
   */
  @Input()
  public size: StructAvatarSize = 'small';

  public get initials(): string {
    let initials = "";

    if (this.name) {
      const nameSplit = this.name.split(' ');
      initials = this.getInitial(nameSplit[0]);

      if (nameSplit.length > 1) {
        initials += this.getInitial(nameSplit[nameSplit.length - 1]);
      }
    }

    return initials;
  }

  private getInitial(name: string): string {
    return name.charAt(0).toUpperCase();
  }

  public ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;

    if (el) {
      const imageEl = el.querySelector('.struct-avatar-image');

      if (imageEl) {
        // Notice the quotes inside the `url` function. This ensures proper url escaping.
        imageEl.style.backgroundImage = this.src ? 'url("' + this.src + '")' : '';
      }
    }
  }
}
