
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateAssetFolderCommand } from "@struct/models/struct/app/domain/assets/commands/create-asset-folder-command";
import { DeleteAssetFolderCommandResponse } from "@struct/models/struct/app/domain/assets/commands/delete-asset-folder-command-response";
import { MoveAssetFolderCommand } from "@struct/models/struct/app/domain/assets/commands/move-asset-folder-command";
import { UpdateAssetFolderCommand } from "@struct/models/struct/app/domain/assets/commands/update-asset-folder-command";
import { AssetFolder } from "@struct/models/struct/app/domain/assets/asset-folder";
import { AssetFolderSearchRequest } from "@struct/models/struct/app/frontendapi/models/assetfolders/asset-folder-search-request";
import { AssetFolderSearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/assetfolders/asset-folder-search-result-item-model";
import { AssetFolderTreeModel } from "@struct/models/struct/app/frontendapi/models/assetfolders/asset-folder-tree-model";

@Injectable({ providedIn: "root" })
export class AssetFoldersApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  search(request: AssetFolderSearchRequest): Observable<AssetFolderSearchResultItemModel[]> {
    return this.http.post<AssetFolderSearchResultItemModel[]>(`${this.apiUrl}assetfolders/search`, request, this.httpOptions);
  }

  getAssetFolder(assetFolderUid: string): Observable<AssetFolder> {
    return this.http.get<AssetFolder>(`${this.apiUrl}assetfolders/${assetFolderUid}`, this.httpOptions );
  }

  getRootfolders(): Observable<AssetFolderTreeModel[]> {
    return this.http.get<AssetFolderTreeModel[]>(`${this.apiUrl}assetfolders/rootfolders`, this.httpOptions );
  }

  getChildFolders(uid: string): Observable<AssetFolderTreeModel[]> {
    return this.http.get<AssetFolderTreeModel[]>(`${this.apiUrl}assetfolders/${uid}/childfolders`, this.httpOptions );
  }

  createAssetFolder(command: CreateAssetFolderCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}assetfolders/create`, command, this.httpOptions);
  }

  updateAssetFolder(command: UpdateAssetFolderCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}assetfolders/update`, command, this.httpOptions);
  }

  moveAssetFolder(command: MoveAssetFolderCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}assetfolders/move`, command, this.httpOptions);
  }

  deleteAssetFolder(uid: string): Observable<DeleteAssetFolderCommandResponse> {
    return this.http.delete<DeleteAssetFolderCommandResponse>(`${this.apiUrl}assetfolders/${uid}`, this.httpOptions );
  }
}

