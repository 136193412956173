
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateProductStructureCommand } from "@struct/models/struct/app/domain/dataconfiguration/commands/create-product-structure-command";
import { UpdateProductStructureCommand } from "@struct/models/struct/app/domain/dataconfiguration/commands/update-product-structure-command";
import { ProductStructure } from "@struct/models/struct/app/domain/dataconfiguration/product-structure";
import { ProductStructureReference } from "@struct/models/struct/app/domain/dataconfiguration/product-structure-reference";
import { VariationDefinition } from "@struct/models/struct/app/domain/dataconfiguration/variation-definition";
import { VariationDefinitionReference } from "@struct/models/struct/app/domain/dataconfiguration/variation-definition-reference";

@Injectable({ providedIn: "root" })
export class ProductStructuresApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getProductStructureReferences(): Observable<ProductStructureReference[]> {
    return this.http.get<ProductStructureReference[]>(`${this.apiUrl}productstructurereferences`, this.httpOptions );
  }

  getProductStructure(uid: string): Observable<ProductStructure> {
    return this.http.get<ProductStructure>(`${this.apiUrl}productstructureres/${uid}`, this.httpOptions );
  }

  createProductStructure(command: CreateProductStructureCommand): Observable<ProductStructure> {
    return this.http.post<ProductStructure>(`${this.apiUrl}productstructureres`, command, this.httpOptions);
  }

  updateProductStructure(command: UpdateProductStructureCommand): Observable<ProductStructure> {
    return this.http.put<ProductStructure>(`${this.apiUrl}productstructureres`, command, this.httpOptions);
  }

  deleteProductStructure(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}productstructureres/${uid}`, this.httpOptions );
  }

  getVariationDefinitionReferences(): Observable<VariationDefinitionReference[]> {
    return this.http.get<VariationDefinitionReference[]>(`${this.apiUrl}variationdefinitionreferences`, this.httpOptions );
  }

  getVariationDefinitions(productStructureUid: string): Observable<VariationDefinition[]> {
    return this.http.get<VariationDefinition[]>(`${this.apiUrl}variationdefinitions?productStructureUid=${productStructureUid}`, this.httpOptions );
  }
}

