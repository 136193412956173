import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { RouterModule } from '@angular/router';
import { RegisterUserComponent } from '../register-user/register-user.component';
import { Invite } from '@struct/models/struct/usermanagementmodule/invite';
import { AcceptInviteCommand } from '@struct/models/struct/usermanagementmodule/commands/accept-invite-command';
import { DeclineInviteCommand } from '@struct/models/struct/usermanagementmodule/commands/decline-invite-command';
import { UsersApiService } from '@struct/services/account-management';
import { AdminInvite, TenantInvite } from '@struct/models/struct/usermanagementmodule';
import { InstanceofPipe } from '@struct/utilities/index';

@Component({
  selector: 'accounts-accept-existing-user-invite',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, RouterModule, RegisterUserComponent, InstanceofPipe],
  templateUrl: './accept-existing-user-invite.component.html',
  styleUrls: ['./accept-existing-user-invite.component.scss'],
})
export class AcceptExistingUserInviteComponent {
  @Input({required:true}) invite! : Invite;
  accepted: boolean = false;

  constructor(private usersApi: UsersApiService){}

  TenantInvite = TenantInvite;
  AdminInvite = AdminInvite;

  acceptInvite() {
      const command = new AcceptInviteCommand({
        inviteUid: this.invite.uid,
        inviteToken: this.invite.inviteToken,
        email: this.invite.email
      });

      this.usersApi.acceptInvite(command).subscribe(x => {
        if(x.succeeded){
            if(x.succeeded && this.invite.type === TenantInvite.name){
              const t = this.invite as TenantInvite;
              window.location.href = t.tenantUrl;
            }
            else if(x.succeeded){
              this.accepted = true;
            }
          }
      });
  }

  declineInvite() {
    if(this.invite !== null && this.invite !== null){
      const command = new DeclineInviteCommand({
        inviteUid: this.invite.uid,
        token: this.invite.inviteToken
      });

      this.usersApi.declineInvite(command).subscribe(() => {
        this.accepted = false;
      });
    }
  }
}
