<div class="relative">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="inline">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid class="struct-simple-select__multiple-chip-row z-[99999]" #chipGrid [ariaLabel]="placeholder" [formControl]="selectGrid" cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container *ngIf="showSelectedEntities">
      <mat-chip-row
        cdkDrag
        [cdkDragLockAxis]="'x'"
        class="p-1 mt-0 mb-0 flex gap-2 items-center rounded-full relative group hover:bg-blue-100 bg-gray-100"
        [cdkDragDisabled]="!allowMultiselect"
        *ngFor="let selectedOption of selectedEntities"
        (removed)="removeSelectedOption(selectedOption)">
        <div class="" *cdkDragPlaceholder></div>

        <!-- Hover image -->
        <div *ngIf="selectedOption.imageUrl !== null" class="w-48 h-48 rounded overflow-hidden bg-white border border-gray-300 shadow-lg absolute -top-8 flex items-center justify-center p-4 -translate-y-full opacity-0 group-hover:opacity-100 pointer-events-none animate-in slide-in-from-bottom-4">
          <img class="h-40 w-auto" [src]="selectedOption.imageUrl + '?width=200&height=200&bgcolor=white'" />
          <div class="absolute bottom-0 left-0 w-48 px-4 py-2 bg-white/75">
            <div class="font-medium truncate">{{ selectedOption.name }}</div>

            <div class="leading-none" *ngIf="selectedOption.pathInfo !== null">
              <span class="text-xs leading-none text-gray-500 font-normal" *ngFor="let p of selectedOption.pathInfo; let i = index">
                <span class="">{{ p }}</span>
                <span *ngIf="i < selectedOption.pathInfo.length - 1"> / </span>
              </span>
            </div>


          </div>
        </div>

        <div class="flex gap-2 items-center">
          <div class="flex-shrink-0 flex items-center -ml-3">
            <div class="w-8 h-8 overflow-hidden rounded-full bg-white flex items-center justify-center">
              <img *ngIf="selectedOption.imageUrl !== null" class="h-8 w-auto" [src]="selectedOption.imageUrl + '?width=200&height=200&bgcolor=white'" />
              <struct-icon *ngIf="selectedOption.imageUrl === null" icon="folder_filled" size="16" class="text-gray-500"></struct-icon>
            </div>

          </div>
          <div class="flex-grow h-full flex items-center">
            <div class="flex flex-col gap-0">
              <span class="leading-none">{{ selectedOption.name }}</span>
              <div class="leading-none" *ngIf="selectedOption.pathInfo !== null">
                <span class="text-xs leading-none text-gray-500 font-normal" *ngFor="let p of selectedOption.pathInfo; let i = index">
                  <span class="">{{ p }}</span>
                  <span *ngIf="i < selectedOption.pathInfo.length - 1"> / </span>
                </span>
              </div>
            </div>
          </div>
          <div class="ml-4 flex items-center -mr-3">
            <struct-icon icon="goto" size="16" class="text-gray-500" (click)="goToEntity(selectedOption.id)" [attr.aria-label]="('General.GoTo' | translate) + ' ' + selectedOption.name"></struct-icon>
            <struct-icon matChipRemove icon="c_remove" size="16" [attr.aria-label]="('General.Remove' | translate) + ' ' + selectedOption.name"></struct-icon>
          </div>
        </div>
      </mat-chip-row>
    </ng-container>

      <input
        #selectInput
        [hidden]="selectedEntities.length > 0 && !allowMultiselect"
        placeholder="{{ 'General.Search' | translate }}"
        [formControl]="selectCtrl"
        (blur)="selectInput.value = ''"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [attr.data-cy]="'struct-simple-select-input.multiple.search'" />

        <div matSuffix class="flex-grow-0 flex items-center ml-auto">
          <mat-spinner class="mr-2" diameter="16" color="primary" *ngIf="searching"></mat-spinner>
          <struct-icon icon="search_advanced" (click)="openAdvancedSearchDialog()"  class="text-gray-500 cursor-pointer"></struct-icon>
        </div>


      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption hideSingleSelectionIndicator (optionSelected)="selectOption($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          <div class="flex gap-2 items-center py-2">
            <div class="flex items-center justify-center w-8 h-8 overflow-hidden">
              <img *ngIf="option.imageUrl !== null" class="h-auto max-h-8 w-auto max-w-8" [src]="option.imageUrl + '?width=200&height=200&bgcolor=white'" />
              <struct-icon *ngIf="option.icon !== null && option.imageUrl === null" [icon]="option.icon ?? ''" size="16" class="text-gray-500"></struct-icon>
            </div>
            <div class="flex flex-col leading-none">
              <span class="font-medium">{{ option.name }}</span>
              <div *ngIf="option.pathInfo !== null">
                <span class="text-xs text-gray-500" *ngFor="let p of option.pathInfo; let i = index">
                  <span>{{ p }}</span>
                  <span *ngIf="i < option.pathInfo.length - 1"> / </span>
                </span>
              </div>
            </div>
          </div>
        </mat-option>
        <mat-option [disabled]="true" *ngIf="(filteredOptions.length === 0 ?? true) && selectInput.value.length > 0 && !searching" [value]="null" class="mdc-list-item__disabled-opacity-full">
          <div class="flex flex-grow w-96 mx-auto flex-col items-center justify-center py-8 animate-in slide-in-from-bottom-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-32">
              <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                <path d="M56,58H8a5,5,0,0,1-5-5V6H24l6,10H61V53A5,5,0,0,1,56,58Z"></path>
                <line x1="37.1" y1="42.1" x2="45" y2="50"></line>
                <circle  cx="30" cy="35" r="10"></circle>
              </g>
            </svg>
            <h4 class="mt-4 text-xl font-medium">{{ 'General.NoResultsFound' | translate }}</h4>
          </div>
        </mat-option>
        <mat-option [disabled]="true" *ngIf="searching" [value]="null" class="mdc-list-item__disabled-opacity-full">
          <div class="h-64 w-full flex items-center justify-center">
            <div class="flex flex-col gap-1 items-center">
              <struct-spinner></struct-spinner>
              <span>{{'General.Searching' | translate}}</span>
            </div>

          </div>
        </mat-option>
      </mat-autocomplete>


    </mat-chip-grid>
    <mat-error *ngIf="selectedEntities.length === 0">{{ 'General.FieldRequired' | translate }}</mat-error>
  </mat-form-field>

  <div *ngIf="!inline">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="selectedEntities.length === 0 || allowMultiselect" class="mb-2 relative">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        #selectInput
        matInput
        placeholder="{{ 'General.Search' | translate }}"
        [formControl]="selectCtrl"
        (blur)="selectInput.value = ''"
        [matAutocomplete]="auto"
        [attr.data-cy]="'struct-simple-select-input.multiple.search'" />

        <div matSuffix class="flex items-center pr-3">
          <mat-spinner class="mr-2" diameter="16" color="primary" *ngIf="searching"></mat-spinner>
          <struct-icon icon="search_advanced" (click)="openAdvancedSearchDialog()"  class="text-gray-500 cursor-pointer"></struct-icon>
        </div>



      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption hideSingleSelectionIndicator (optionSelected)="selectOption($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          <div class="flex gap-2 items-center py-2">
            <div class="flex items-center justify-center w-8 h-8 overflow-hidden">
              <img *ngIf="option.imageUrl !== null" class="h-auto max-h-8 w-auto max-w-8" [src]="option.imageUrl + '?width=200&height=200&bgcolor=white'" />
              <struct-icon *ngIf="option.icon !== null && option.imageUrl === null" [icon]="option.icon ?? ''" size="16" class="text-gray-500"></struct-icon>
            </div>
            <div class="flex flex-col leading-none">
              <span class="font-medium">{{ option.name }}</span>
              <div *ngIf="option.pathInfo !== null">
                <span class="text-xs text-gray-500" *ngFor="let p of option.pathInfo; let i = index">
                  <span>{{ p }}</span>
                  <span *ngIf="i < option.pathInfo.length - 1"> / </span>
                </span>
              </div>
            </div>
          </div>
        </mat-option>
        <mat-option [disabled]="true" *ngIf="(filteredOptions.length === 0 ?? true) && selectInput.value.length > 0 && !searching" [value]="null" class="mdc-list-item__disabled-opacity-full">
          <div class="flex flex-grow w-96 mx-auto flex-col items-center justify-center py-8 animate-in slide-in-from-bottom-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-32">
              <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                <path d="M56,58H8a5,5,0,0,1-5-5V6H24l6,10H61V53A5,5,0,0,1,56,58Z"></path>
                <line x1="37.1" y1="42.1" x2="45" y2="50"></line>
                <circle  cx="30" cy="35" r="10"></circle>
              </g>
            </svg>
            <h4 class="mt-4 text-xl font-medium">{{ 'General.NoResultsFound' | translate }}</h4>
          </div>


        </mat-option>
        <mat-option [disabled]="true" *ngIf="searching" [value]="null" class="mdc-list-item__disabled-opacity-full">
          <div class="h-64 w-full flex items-center justify-center">
            <div class="flex flex-col gap-1 items-center">
              <struct-spinner></struct-spinner>
              <span>{{'General.Searching' | translate}}</span>
            </div>

          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="selectedEntities.length === 0">{{ 'General.FieldRequired' | translate }}</mat-error>
    </mat-form-field>

    <div class="flex gap-2 struct-draggable-list flex-wrap max-h-96 overflow-y-auto struct-scrollbar" cdkDropList (cdkDropListDropped)="drop($event)" [ngClass]="{'p-0.5 pb-4': selectedEntities.length > 0}">

      <div cdkDrag [cdkDragLockAxis]="'x'" class="struct-draggable-list-item bg-white h-auto w-48 p-4 relative rounded ring-1 ring-gray-300 text-center hover:ring-blue-500 hover:shadow group " *ngFor="let item of selectedEntities">
        <div class="border-2 border-blue-200 bg-blue-50 h-auto w-48 border-dashed" *cdkDragPlaceholder></div>
        <div class="flex w-full text-center justify-center">
          <img *ngIf="item.imageUrl !== null" class="h-40 w-auto" [src]="item.imageUrl + '?width=200&height=200&bgcolor=white'" />
          <struct-icon *ngIf="item.imageUrl === null" icon="folder_filled" size="64" class="w-40 h-24 text-gray-500"></struct-icon>
        </div>


        <div class="text-sm font-medium line-clamp-2">{{ item.name }}</div>
        <div *ngIf="item.pathInfo !== null">
          <span class="text-xs text-gray-500" *ngFor="let p of item.pathInfo; let i = index">
            <span>{{ p }}</span>
            <span *ngIf="i < item.pathInfo.length - 1"> / </span>
          </span>
        </div>

        <div class="absolute inset-0 hidden group-hover:flex items-center justify-center animate-in slide-in-from-bottom-2 duration-600">
          <div class="bg-white flex p-1 rounded-full shadow-lg">
            <struct-icon-button icon="goto" (click)="goToEntity(item.id)" [attr.aria-label]="('General.GoTo' | translate) + ' ' + item.name"></struct-icon-button>
            <struct-icon-button icon="delete" (click)="removeSelectedOption(item)" [attr.aria-label]="('General.Remove' | translate) + ' ' + item.name"></struct-icon-button>
          </div>
        </div>

      </div>
    </div>

    <div class="flex justify-end items-center mt-0 pt-2 border-t border-gray-200" *ngIf="selectedEntities.length > 8">
      <button mat-flat-button (click)="selectedEntities = []">{{ 'General.Clear' | translate }}</button>
    </div>
  </div>
</div>
