
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BuildQuickEntityExcelExportCommand } from "@struct/models/struct/app/domain/exportimport/export/commands/build-quick-entity-excel-export-command";
import { BuildQuickExcelExportCommand } from "@struct/models/struct/app/domain/exportimport/export/commands/build-quick-excel-export-command";
import { BuildQuickGlobalListExcelExportCommand } from "@struct/models/struct/app/domain/exportimport/export/commands/build-quick-global-list-excel-export-command";
import { ExternalExporterInfo } from "@struct/models/struct/app/domain/exportimport/export/external-exporter-info";
import { ExcelEntityExportAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/excel-entity-export-async-task";
import { ExcelEntityExportFromTemplateAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/excel-entity-export-from-template-async-task";
import { ExcelGlobalListExportAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/excel-global-list-export-async-task";
import { ExcelListAttributeExportAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/excel-list-attribute-export-async-task";
import { JsonEntityExportAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/json-entity-export-async-task";
import { JsonEntityExportFromTemplateAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/json-entity-export-from-template-async-task";
import { XmlEntityExportAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/xml-entity-export-async-task";
import { XmlEntityExportFromTemplateAsyncTask } from "@struct/models/struct/app/domain/exportimport/export/tasks/xml-entity-export-from-template-async-task";
import { ExportImportDataFieldReference } from "@struct/models/struct/app/domain/exportimport/sharedentities/export-import-data-field-reference";
import { AsyncTaskInfo } from "@struct/models/struct/app/domain/infrastructure/asynctask/async-task-info";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { ListAttributeField } from "@struct/models/struct/app/frontendapi/models/export/list-attribute-field";
import { ListStringItem } from "@struct/models/struct/app/frontendapi/models/export/list-string-item";

@Injectable({ providedIn: "root" })
export class ExportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getExternalExporters(entityType: StructEntityType): Observable<ExternalExporterInfo[]> {
    return this.http.get<ExternalExporterInfo[]>(`${this.apiUrl}export/externalexporters?entityType=${entityType}`, this.httpOptions );
  }

  getAvailableGlobalListFields(globalListUid: string): Observable<ExportImportDataFieldReference[]> {
    return this.http.get<ExportImportDataFieldReference[]>(`${this.apiUrl}export/availablegloballistfields?globalListUid=${globalListUid}`, this.httpOptions );
  }

  getAvailableEntityFields(itemType: StructEntityType): Observable<ExportImportDataFieldReference[]> {
    return this.http.get<ExportImportDataFieldReference[]>(`${this.apiUrl}export/availableentityfields?itemType=${itemType}`, this.httpOptions );
  }

  buildExcelGlobalListExport(task: ExcelGlobalListExportAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildexcelgloballistexport`, task, this.httpOptions);
  }

  buildExcelEntityExport(task: ExcelEntityExportAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildexcelentityexport`, task, this.httpOptions);
  }

  buildExcelEntityExportFromTemplate(task: ExcelEntityExportFromTemplateAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildexcelentityexportfromtemplate`, task, this.httpOptions);
  }

  buildExcelListAttributeExport(task: ExcelListAttributeExportAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildexcellistattributeexport`, task, this.httpOptions);
  }

  buildJsonEntityExport(task: JsonEntityExportAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildjsonentityexport`, task, this.httpOptions);
  }

  buildJsonEntityExportFromTemplate(task: JsonEntityExportFromTemplateAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildjsonentityexportfromtemplate`, task, this.httpOptions);
  }

  buildXmlEntityExport(task: XmlEntityExportAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildxmlentityexport`, task, this.httpOptions);
  }

  buildXmlEntityExportFromTemplate(task: XmlEntityExportFromTemplateAsyncTask): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}export/buildxmlentityexportfromtemplate`, task, this.httpOptions);
  }

  downloadExportFromTask(taskUid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}export/download?taskUid=${taskUid}`, {responseType:'blob' as 'json', withCredentials: true} );
  }

  getExportTaskInfo(taskUid: string): Observable<AsyncTaskInfo | null> {
    return this.http.get<AsyncTaskInfo | null>(`${this.apiUrl}export/${taskUid}`, this.httpOptions );
  }

  quickExport(command: BuildQuickExcelExportCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/quickexport`, command, {responseType:'blob' as 'json', withCredentials: true});
  }

  quickExportGlobalListData(command: BuildQuickGlobalListExcelExportCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/quickexportgloballistdata`, command, {responseType:'blob' as 'json', withCredentials: true});
  }

  quickExportEntityData(command: BuildQuickEntityExcelExportCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}export/quickexportentitydata`, command, {responseType:'blob' as 'json', withCredentials: true});
  }

  getListAttributes(entityType: StructEntityType): Observable<ListStringItem[]> {
    return this.http.get<ListStringItem[]>(`${this.apiUrl}export/listattributes?entityType=${entityType}`, this.httpOptions );
  }

  getAvailableIdentifiers(entityType: StructEntityType): Observable<ListStringItem[]> {
    return this.http.get<ListStringItem[]>(`${this.apiUrl}export/availableidentifiers?entityType=${entityType}`, this.httpOptions );
  }

  getAvailableListAttributeFields(listAttributeUid: string): Observable<ListAttributeField[]> {
    return this.http.get<ListAttributeField[]>(`${this.apiUrl}export/availablelistattributefields?listAttributeUid=${listAttributeUid}`, this.httpOptions );
  }
}

