import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { UiModule } from '@struct/ui/ui.module';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'accounts-quick-actions-dialog',
  templateUrl: './quick-actions-dialog.component.html',
  styleUrls: ['./quick-actions-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, MatDialogModule],
})
export class QuickActionsDialogComponent {
  constructor(public dialogRef: MatDialogRef<QuickActionsDialogComponent>) {}

  cancel(): void {
    this.dialogRef.close();
  }
}
