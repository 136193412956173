
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TenantQuotaSetup } from "@struct/models/struct/shared/subscription/tenant-quota-setup";

@Injectable({ providedIn: "root" })
export class TenantApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getTenantQuota(): Observable<TenantQuotaSetup> {
    return this.http.get<TenantQuotaSetup>(`${this.apiUrl}tenant/quota`, this.httpOptions );
  }
}

