// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssetBandwidthQuota  {
  assetBandwidthIncludedInGB: number;
  extraAssetBandwidthPackSizeInGB: number;
  extraAssetBandwidthPackPrice: Price;
}

export class AssetBandwidthQuota  implements IAssetBandwidthQuota {
  assetBandwidthIncludedInGB = 0;

  extraAssetBandwidthPackSizeInGB = 0;

  extraAssetBandwidthPackPrice: Price = new Price();


  constructor(data?: Partial<IAssetBandwidthQuota>) {
    Object.assign(this, data);
  }
}