import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UiModule } from "../../../../../../libs/ui/src/lib/ui.module";
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition, IRowAction, ITableDefinition, TableColumn } from "@struct/ui/struct-table";
import { DisplayType } from "@struct/models/struct/shared/search";
import { TranslateService } from "@ngx-translate/core";
import { MyTenantApiService } from "@struct/services/account-management";
import { Observable } from "rxjs";
import { CommonModule } from "@angular/common";
import { ProvisionStatusMessage } from "@struct/models/accountmanagement/domain/tenants";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";

@Component({
    selector: 'accounts-mytenants-log-dialog',
    templateUrl: './mytenants-log-dialog.component.html',
    styleUrls: ['./mytenants-log-dialog.component.scss'],
    standalone: true,
    imports: [
    UiModule,
    CommonModule,
    AccountsHeaderComponent
],
  })

  export class MyTenantsLogDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProvisionStatusMessage[],
        private dialogRef: MatDialogRef<MyTenantsLogDialogComponent>,
         ) {
        
         }

    ngOnInit(): void {
        // const rowActions: IRowAction[] = [];
        // const tableColumns = [
        //     new TableColumn({id: 'timestamp', title: this.tr.instant('Tenant.Log.Timestamp'), type: DisplayType.DateTime}),
        //     new TableColumn({id: 'message', title: this.tr.instant('Tenant.Log.Message'), type: DisplayType.Text})
        //   ];
          
          // const dataProvider = new TenantLogDataProvider(this.myTenantApiService, this.data);
          // this.tableDefinition = new ClientSideSearchTableDefinition('tenantLog', tableColumns, rowActions, 'id', 'user', null, dataProvider);
    }

    cancel(): void {
      this.dialogRef.close();
    }
  }

  // export class TenantLogDataProvider implements ClientSideSearchDataProvider {
  //   constructor(private myTenantApiService2: MyTenantApiService, private tenantUid: string) {
  //   }
  
  //   getData(): Observable<any[]> {
  //     return this.myTenantApiService2.getTenantLogs(this.tenantUid);
  //   }
  // }