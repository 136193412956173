// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
import { TextQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/text-query-operator";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITextFieldQuery extends FieldQuery {
  filterValues: string[];
  filterValue: string;
  queryOperator: TextQueryOperator;
  fieldQueryType: string;
}

export class TextFieldQuery extends FieldQuery implements ITextFieldQuery {
  filterValues: string[] = [];

  filterValue = "";

  queryOperator: TextQueryOperator = TextQueryOperator.Equals;

  override fieldQueryType = "TextFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<ITextFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}