
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VariantGroupEditorModel } from "@struct/models/struct/app/domain/editor/variant-group-editor-model";
import { ArchiveVariantGroupsCommand } from "@struct/models/struct/app/domain/variantgroups/commands/archive-variant-groups-command";
import { CopyVariantGroupsCommand } from "@struct/models/struct/app/domain/variantgroups/commands/copy-variant-groups-command";
import { DeleteVariantGroupsCommand } from "@struct/models/struct/app/domain/variantgroups/commands/delete-variant-groups-command";
import { UnarchiveVariantGroupsCommand } from "@struct/models/struct/app/domain/variantgroups/commands/unarchive-variant-groups-command";
import { SaveTabModel } from "@struct/models/struct/app/frontendapi/models/editormodel/save-tab-model";
import { StructEntityUpdatedModel } from "@struct/models/struct/app/frontendapi/models/editormodel/struct-entity-updated-model";

@Injectable({ providedIn: "root" })
export class VariantGroupsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getEditorModel(variantGroupId: number): Observable<VariantGroupEditorModel> {
    return this.http.get<VariantGroupEditorModel>(`${this.apiUrl}variantgroups/${variantGroupId}`, this.httpOptions );
  }

  deleteVariantGroup(model: DeleteVariantGroupsCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}variantgroups/delete`, model, this.httpOptions);
  }

  archiveVariantGroup(model: ArchiveVariantGroupsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variantgroups/archive`, model, this.httpOptions);
  }

  unArchiveVariantGroup(model: UnarchiveVariantGroupsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variantgroups/unarchive`, model, this.httpOptions);
  }

  copyVariantGroup(model: CopyVariantGroupsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}variantgroups/copy`, model, this.httpOptions);
  }

  saveTab(model: SaveTabModel): Observable<StructEntityUpdatedModel> {
    return this.http.patch<StructEntityUpdatedModel>(`${this.apiUrl}variantgroups/savetab`, model, this.httpOptions);
  }
}

