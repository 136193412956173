// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Price } from "@struct/models/struct/shared/subscription/price";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssetStorageQuota  {
  assetStorageIncludedInGB: number;
  extraAssetStoragePackSizeInGB: number;
  extraAssetStoragePackPrice: Price;
}

export class AssetStorageQuota  implements IAssetStorageQuota {
  assetStorageIncludedInGB = 0;

  extraAssetStoragePackSizeInGB = 0;

  extraAssetStoragePackPrice: Price = new Price();


  constructor(data?: Partial<IAssetStorageQuota>) {
    Object.assign(this, data);
  }
}