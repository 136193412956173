
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SubscriptionUserInviteCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/subscription-user-invite-command";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";
import { CreateSubscriptionCommand } from "@struct/models/struct/shared/subscription/commands/create-subscription-command";
import { RemoveSubscriptionToUserCommand } from "@struct/models/struct/shared/subscription/commands/remove-subscription-to-user-command";
import { UpdateSubscriptionCommand } from "@struct/models/struct/shared/subscription/commands/update-subscription-command";
import { Subscription } from "@struct/models/struct/shared/subscription/subscription";
import { UserSubscription } from "@struct/models/struct/shared/subscription/user-subscription";
import { SubscriptionInvite } from "@struct/models/struct/usermanagementmodule/subscription-invite";

@Injectable({ providedIn: "root" })
export class SubscriptionApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.apiUrl}subscriptions`, this.httpOptions );
  }

  getSubscription(uid: string): Observable<Subscription> {
    return this.http.get<Subscription>(`${this.apiUrl}subscriptions/${uid}`, this.httpOptions );
  }

  createSubscription(cmd: CreateSubscriptionCommand | null): Observable<Subscription> {
    return this.http.post<Subscription>(`${this.apiUrl}subscriptions`, cmd, this.httpOptions);
  }

  updateSubscription(cmd: UpdateSubscriptionCommand | null): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}subscriptions`, cmd, this.httpOptions);
  }

  deleteSubscription(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}subscriptions/${uid}`, this.httpOptions );
  }

  getUsersInSubscription(uid: string | null): Observable<UserSubscription[] | null> {
    return this.http.get<UserSubscription[] | null>(`${this.apiUrl}subscriptions/users/${uid}`, this.httpOptions );
  }

  getInvitesInSubscription(uid: string | null): Observable<SubscriptionInvite[] | null> {
    return this.http.get<SubscriptionInvite[] | null>(`${this.apiUrl}subscriptions/invites/${uid}`, this.httpOptions );
  }

  inviteUserToSubScription(cmd: SubscriptionUserInviteCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}subscriptions/invite`, cmd, this.httpOptions);
  }

  cancelSubscriptionInvite(invitationUid: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}subscriptions/cancelinvite?invitationUid=${encodeURIComponent(invitationUid)}`, null, this.httpOptions);
  }

  resendSubscriptionInvite(invitationUid: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}subscriptions/resendinvite?invitationUid=${encodeURIComponent(invitationUid)}`, null, this.httpOptions);
  }

  removeUserFromSubscription(removeSubscriptionToUserCommand: RemoveSubscriptionToUserCommand | null): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}subscriptions/remove/user`, removeSubscriptionToUserCommand, this.httpOptions);
  }
}

