import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StructColorService {
  private colors: StructColor[];

  constructor(private tr: TranslateService) {
    this.colors = [
      new StructColor('white', this.tr.instant('General.Colors.White'), 'gray-700', '#ffffff'),
      new StructColor('Black', this.tr.instant('General.Colors.Black'), 'gray-700', '#000000'),
      // Grays
      new StructColor('gray-50', this.tr.instant('General.Colors.Gray50'), 'gray-700', '#fafafa'),
      new StructColor('gray-100', this.tr.instant('General.Colors.Gray100'), 'gray-700', '#f4f4f5'),
      new StructColor('gray-200', this.tr.instant('General.Colors.Gray200'), 'gray-700', '#e4e4e7'),
      new StructColor('gray-300', this.tr.instant('General.Colors.Gray300'), 'gray-700', '#d4d4d8'),
      new StructColor('gray-400', this.tr.instant('General.Colors.Gray400'), 'white', '#a1a1aa'),
      new StructColor('gray-500', this.tr.instant('General.Colors.Gray500'), 'white', '#71717a'),
      new StructColor('gray-600', this.tr.instant('General.Colors.Gray600'), 'white', '#52525b'),
      new StructColor('gray-700', this.tr.instant('General.Colors.Gray700'), 'white', '#3f3f46'),
      new StructColor('gray-800', this.tr.instant('General.Colors.Gray800'), 'white', '#27272a'),
      new StructColor('gray-900', this.tr.instant('General.Colors.Gray900'), 'white', '#18181b'),
      new StructColor('gray-950', this.tr.instant('General.Colors.Gray950'), 'white', '#09090b'),

      // Blues
      new StructColor('blue-50', this.tr.instant('General.Colors.Blue50'), 'blue-700', '#f0f9ff'),
      new StructColor('blue-100', this.tr.instant('General.Colors.Blue100'), 'blue-700', '#e0f2fe'),
      new StructColor('blue-200', this.tr.instant('General.Colors.Blue200'), 'blue-700', '#bae6fd'),
      new StructColor('blue-300', this.tr.instant('General.Colors.Blue300'), 'blue-700', '#7dd3fc'),
      new StructColor('blue-400', this.tr.instant('General.Colors.Blue400'), 'white', '#38bdf8'),
      new StructColor('blue-500', this.tr.instant('General.Colors.Blue500'), 'white', '#0ea5e9'),
      new StructColor('blue-600', this.tr.instant('General.Colors.Blue600'), 'white', '#0284c7'),
      new StructColor('blue-700', this.tr.instant('General.Colors.Blue700'), 'white', '#0369a1'),
      new StructColor('blue-800', this.tr.instant('General.Colors.Blue800'), 'white', '#075985'),
      new StructColor('blue-900', this.tr.instant('General.Colors.Blue900'), 'white', '#0c4a6e'),
      new StructColor('blue-950', this.tr.instant('General.Colors.Blue950'), 'white', '#082f49'),

      // Greens
      new StructColor('green-50', this.tr.instant('General.Colors.Green50'), 'green-700', '#f0fdf4'),
      new StructColor('green-100', this.tr.instant('General.Colors.Green100'), 'green-700', '#dcfce7'),
      new StructColor('green-200', this.tr.instant('General.Colors.Green200'), 'green-700', '#bbf7d0'),
      new StructColor('green-300', this.tr.instant('General.Colors.Green300'), 'green-700', '#86efac'),
      new StructColor('green-400', this.tr.instant('General.Colors.Green400'), 'white', '#4ade80'),
      new StructColor('green-500', this.tr.instant('General.Colors.Green500'), 'white', '#22c55e'),
      new StructColor('green-600', this.tr.instant('General.Colors.Green600'), 'white', '#16a34a'),
      new StructColor('green-700', this.tr.instant('General.Colors.Green700'), 'white', '#15803d'),
      new StructColor('green-800', this.tr.instant('General.Colors.Green800'), 'white', '#166534'),
      new StructColor('green-900', this.tr.instant('General.Colors.Green900'), 'white', '#14532d'),
      new StructColor('green-950', this.tr.instant('General.Colors.Green950'), 'white', '#052e16'),

      // Yellows
      new StructColor('yellow-50', this.tr.instant('General.Colors.Yellow50'), 'yellow-700', '#fffbeb'),
      new StructColor('yellow-100', this.tr.instant('General.Colors.Yellow100'), 'yellow-700', '#fef3c7'),
      new StructColor('yellow-200', this.tr.instant('General.Colors.Yellow200'), 'yellow-700', '#fde68a'),
      new StructColor('yellow-300', this.tr.instant('General.Colors.Yellow300'), 'yellow-700', '#fcd34d'),
      new StructColor('yellow-400', this.tr.instant('General.Colors.Yellow400'), 'white', '#fbbf24'),
      new StructColor('yellow-500', this.tr.instant('General.Colors.Yellow500'), 'white', '#f59e0b'),
      new StructColor('yellow-600', this.tr.instant('General.Colors.Yellow600'), 'white', '#d97706'),
      new StructColor('yellow-700', this.tr.instant('General.Colors.Yellow700'), 'white', '#b45309'),
      new StructColor('yellow-800', this.tr.instant('General.Colors.Yellow800'), 'white', '#92400e'),
      new StructColor('yellow-900', this.tr.instant('General.Colors.Yellow900'), 'white', '#78350f'),
      new StructColor('yellow-950', this.tr.instant('General.Colors.Yellow950'), 'white', '#451a03'),

      // Oranges
      new StructColor('orange-50', this.tr.instant('General.Colors.Orange50'), 'orange-700', '#fff7ed'),
      new StructColor('orange-100', this.tr.instant('General.Colors.Orange100'), 'orange-700', '#ffedd5'),
      new StructColor('orange-200', this.tr.instant('General.Colors.Orange200'), 'orange-700', '#fed7aa'),
      new StructColor('orange-300', this.tr.instant('General.Colors.Orange300'), 'orange-700', '#fdba74'),
      new StructColor('orange-400', this.tr.instant('General.Colors.Orange400'), 'white', '#fb923c'),
      new StructColor('orange-500', this.tr.instant('General.Colors.Orange500'), 'white', '#f97316'),
      new StructColor('orange-600', this.tr.instant('General.Colors.Orange600'), 'white', '#ea580c'),
      new StructColor('orange-700', this.tr.instant('General.Colors.Orange700'), 'white', '#c2410c'),
      new StructColor('orange-800', this.tr.instant('General.Colors.Orange800'), 'white', '#9a3412'),
      new StructColor('orange-900', this.tr.instant('General.Colors.Orange900'), 'white', '#7c2d12'),
      new StructColor('orange-950', this.tr.instant('General.Colors.Orange950'), 'white', '#431407'),

      // Red
      new StructColor('red-50', this.tr.instant('General.Colors.Red50'), 'red-700', '#fef2f2'),
      new StructColor('red-100', this.tr.instant('General.Colors.Red100'), 'red-700', '#fee2e2'),
      new StructColor('red-200', this.tr.instant('General.Colors.Red200'), 'red-700', '#fecaca'),
      new StructColor('red-300', this.tr.instant('General.Colors.Red300'), 'red-700', '#fca5a5'),
      new StructColor('red-400', this.tr.instant('General.Colors.Red400'), 'white', '#f87171'),
      new StructColor('red-500', this.tr.instant('General.Colors.Red500'), 'white', '#ef4444'),
      new StructColor('red-600', this.tr.instant('General.Colors.Red600'), 'white', '#dc2626'),
      new StructColor('red-700', this.tr.instant('General.Colors.Red700'), 'white', '#b91c1c'),
      new StructColor('red-800', this.tr.instant('General.Colors.Red800'), 'white', '#991b1b'),
      new StructColor('red-900', this.tr.instant('General.Colors.Red900'), 'white', '#7f1d1d'),
      new StructColor('red-950', this.tr.instant('General.Colors.Red950'), 'white', '#450a0a'),

      // Purples
      new StructColor('purple-50', this.tr.instant('General.Colors.Purple50'), 'purple-700', '#faf5ff'),
      new StructColor('purple-100', this.tr.instant('General.Colors.Purple100'), 'purple-700', '#f3e8ff'),
      new StructColor('purple-200', this.tr.instant('General.Colors.Purple200'), 'purple-700', '#e9d5ff'),
      new StructColor('purple-300', this.tr.instant('General.Colors.Purple300'), 'purple-700', '#d8b4fe'),
      new StructColor('purple-400', this.tr.instant('General.Colors.Purple400'), 'white', '#c084fc'),
      new StructColor('purple-500', this.tr.instant('General.Colors.Purple500'), 'white', '#a855f7'),
      new StructColor('purple-600', this.tr.instant('General.Colors.Purple600'), 'white', '#9333ea'),
      new StructColor('purple-700', this.tr.instant('General.Colors.Purple700'), 'white', '#7e22ce'),
      new StructColor('purple-800', this.tr.instant('General.Colors.Purple800'), 'white', '#6b21a8'),
      new StructColor('purple-900', this.tr.instant('General.Colors.Purple900'), 'white', '#581c87'),
      new StructColor('purple-950', this.tr.instant('General.Colors.Purple950'), 'white', '#3b0764'),
    ];
  }

  getPercentageColor(percentage: number): string {
    if (percentage >= 90) {
      return 'green-700';
    } else if (percentage >= 80 && percentage < 90) {
      return 'green-500';
    } else if (percentage >= 70 && percentage < 80) {
      return 'green-400';
    } else if (percentage >= 60 && percentage < 70) {
      return 'yellow-400';
    } else if (percentage >= 50 && percentage < 60) {
      return 'yellow-500';
    } else if (percentage >= 40 && percentage < 50) {
      return 'orange-500';
    } else if (percentage >= 30 && percentage < 40) {
      return 'orange-600';
    } else if (percentage >= 20 && percentage < 30) {
      return 'orange-700';
    } else {
      return 'orange-800';
    }
  }

  getColors(): StructColor[] {
      return this.colors;
  }

  getComplimentaryColor(color: string): string {
      const structColor = this.colors.find(c => c.uid === color);
      if (structColor) {
          return structColor.complimentaryColor;
      }
      return 'white';
  }

  getHEXColor(color: string): string {
    const structColor = this.colors.find(c => c.uid === color);
    if (structColor) {
        return structColor.hex;
    }
    return 'white';
  }

  hexToRGB(hex:string, alpha:number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  getTintColors(tint?: string ): StructColor[] {
    let ColorArray: StructColor[] = [];

    if (tint) {
      ColorArray = this.colors.filter((x) => x.uid.includes(tint));
    }
    return ColorArray;
  }

  getTintColorsHEX(color: string): string[] {
    const ColorArray: string[] = [];

    if (color) {
      const colorString = color.substring(0, color.indexOf("-"))
      this.colors.forEach( (el) => {
        if( el.uid.includes(colorString)) {
          ColorArray.push(el.hex);
        }
      });
    }

    return ColorArray;
  }
}



export class StructColor {
  uid: string;
  name: string;
  complimentaryColor: string;
  hex: string;

  constructor(uid: string, name: string, complimentaryColor: string, hex: string) {
    this.uid = uid;
    this.name = name;
    this.complimentaryColor = complimentaryColor;
    this.hex = hex;
  }
}
