<ng-container *ngIf="loaded">
<ng-container *ngIf="isAuthenticated && !openRoute else notAuthenticated">
  <mat-toolbar class="md:hidden">
    <struct-mini-fab-button (click)="sidenav.toggle()" color="primary" icon="menu"></struct-mini-fab-button>
  </mat-toolbar>
  <mat-sidenav-container class="w-full h-full">
    <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="openSidenav" [fixedInViewport]="true" class="struct-scrollbar">
      <accounts-sysadmin-navigation *ngIf="getUserRole() === 'Admin'"></accounts-sysadmin-navigation>
      <accounts-user-navigation *ngIf="getUserRole() !== 'Admin'"></accounts-user-navigation>
    </mat-sidenav>
    <mat-sidenav-content class="flex-1 grow h-full">
      <struct-spinner *ngIf="showSpinner" class="z-50"></struct-spinner>
      <div class="fixed h-screen w-screen bg-white z-40 opacity-50" *ngIf="showSpinner"></div>

        <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
<ng-template #notAuthenticated>
  <router-outlet></router-outlet>
</ng-template>
</ng-container>