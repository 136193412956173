<struct-app-content-container>
  <accounts-header [headerButtonText]="'Invite new admin user'" (headerButtonClicked)="inviteAdminUser()">
    Users
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <struct-table *ngIf="tableDefinition !== null"
        [tableDefinition]="tableDefinition"
        [defaultPageSize]="10"
        [pageSizeOptions]="[10, 25, 50, 100]"
        [showFirstLastPageButton]="false"
        [showPageSizeSelector]="true"
        [defaultColumnIds]="['name','slug','stamp']"
      >
        <struct-selected-items-actions>
        </struct-selected-items-actions>
      </struct-table>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
