import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'struct-filetype-icon',
  templateUrl: './struct-filetype-icon.component.html',
  styleUrls: ['./struct-filetype-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructFiletypeIconComponent {
  @Input() public filetype: string | null = null;
  @Input() public size: string | null = null;

  constructor(){
    this.size = "16px";
   }

}
