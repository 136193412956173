<div class="absolute top-0 -left-96 w-96 h-full bg-gray-300 flex">
  test
</div>
<struct-app-content-container>
  <struct-app-content-header [title]="'UIComponents.StructMediaEditor.Title' | translate">
  </struct-app-content-header>
  <struct-app-content>
      <struct-app-content-workarea>

        <div class="struct-media-editor-outer-wrapper mb-2">
          <div class="struct-media-editor-toolbar">
            <div>
              <struct-icon-button icon="edit" matTooltip="Edit" [matTooltipPosition]="'below'"></struct-icon-button>
              <struct-icon-button icon="maximize" matTooltip="View fullscreen" [matTooltipPosition]="'below'"></struct-icon-button>
            </div>
            <div>

            </div>

          </div>
          <div class="struct-media-editor-inner-wrapper">
            <div class="struct-media-editor bg-gray-100 relative">
              <div class="absolute bottom-4 right-4 flex">

              </div>

              <div class="struct-media-editor-wrapper">

                <div class="struct-media-editor-item ">
                  <img src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/a2eaf0c0-a40d-4098-9511-d319f4be9ab9/dunk-low-shoes-NDWvwq.png" alt="" class="w-auto max-w-full h-auto max-h-full" />
                  <span class="absolute top-50% left-50% rounded-full bg-blue-500 w-6 h-6 -m-5 cursor-pointer ring-4 ring-white opacity-75"
                    cdkDrag
                    cdkDragBoundary=".struct-media-editor-item"
                    #tooltip="matTooltip"
                    matTooltip="{{'DAM.Assets.FocalPointAdjust' | translate}}"
                    [matTooltipPosition]="'right'"></span>
                </div>
              </div>
            </div>


            <div class="struct-media-editor-meta-wrapper">


              <div class="flex-grow">

              </div>

              <div class="grow-0 px-4">
                <form>
                <struct-form-layout>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Name</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <span>sb-dunk-low-pro-skate-shoe.png</span>
                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Filetype</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <div class="flex gap-2">
                            <struct-icon icon="file_text" size="12"></struct-icon>
                            <span>png</span>
                          </div>

                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Size</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <span>3.1 KB</span>
                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Dimensions</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <span>592 x 592 px</span>
                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Created by</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <span>Migration service</span>
                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Creation date</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <div class="flex gap-2">
                            <struct-icon icon="calendar" size="12"></struct-icon>
                            <span>Jan 31, 2024, 11:51:33 PM</span>
                          </div>

                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>Asset model</struct-form-row-headline>
                    <struct-form-row-description></struct-form-row-description>
                    <struct-form-row-grid>
                        <struct-form-row-grid-tile data-cols="12">
                          <span>General asset</span>
                        </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>
                </struct-form-layout>
                </form>
              </div>


              <div class="">

              </div>

            </div>
          </div>


        </div>

      </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
      <struct-button >Cancel</struct-button>
      <struct-button color="primary">Update media</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
