<struct-app-content-container>
  <accounts-header>My Tenants</accounts-header>


  <struct-app-content>
    <struct-app-content-workarea>

      <!-- <div *ngFor="let subscription of subscriptions" class="mb-8">
        <h3 class="text-lg font-medium">Subscription: {{subscription.subscriptionName}} </h3>

        <accounts-list>
          <accounts-list-content>
            <accounts-list-item [iconName]="'subscription'" *ngFor="let tenantmodel of subscription.tenants">
              Tenant: {{tenantmodel.name}}

              <accounts-list-item-description>
                <div class="w-full flex justify-between">

                  <div class="flex items-center gap-4">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <struct-icon icon="account_circle" size="12" class="mr-2"></struct-icon>
                        Slug: {{tenantmodel.slug}}
                      </p>
                    </div>

                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-icon icon="calendar_month" size="12" class="mr-2"></struct-icon>
                      <p>
                        Created
                        <time datetime="2022-01-07">{{tenantmodel.created | date}}</time>
                      </p>
                    </div>
                  </div>

                  <div class="flex-shrink-0 flex items-center gap-2">
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-button [color]="'default'" (click)="openInstance(tenantmodel.slug)">
                        Go to instance
                        <struct-icon icon="arrow_right" size="12"></struct-icon>
                      </struct-button>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <struct-button [color]="'default'" (click)="cloneTenant(tenantmodel.tenantUid)">
                        <struct-icon icon="add" size="12"></struct-icon>
                        Create database template
                      </struct-button>
                    </div>
                  </div>

                </div>

              </accounts-list-item-description>

              <accounts-list-item-badge>

              </accounts-list-item-badge>

            </accounts-list-item>


          </accounts-list-content>
        </accounts-list>
      </div> -->

      <mat-accordion multi>

        <mat-expansion-panel expanded="true" *ngFor="let subscription of subscriptions">
          <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex items-center">
                  <span>{{subscription.subscriptionName}}</span>
                  <span class="ml-2 pl-2 border-l border-gray-300 text-gray-400 font-regular">{{subscription.tenants.length}}</span>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                Subscription
              </mat-panel-description>
          </mat-expansion-panel-header>
          <section class="bg-gray-50 p-4">
            <div class="flex justify-between items-center">
              <div class="text-sm text-gray-400 flex gap-2">
                <span>Tenants in this subscription:</span>
                <span>{{subscription.tenants.length}}</span>
              </div>
              <struct-button mat-flat-button color="default" (click)="createTenant(subscription.subscriptionUid)" >Create new tenant</struct-button>
            </div>
            <accounts-list>
              <accounts-list-content>
                <accounts-list-item [iconName]="'subscription'" *ngFor="let tenantmodel of subscription.tenants">
                  <div class="flex gap-2 items-center">
                    <span class="text-lg">Tenant: {{tenantmodel.name}}</span>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'AwaitingDeprovisioning'" [bgColorClass]="'bg-orange-400'">Awaiting Deprovisioning</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'Deprovisioning'" [bgColorClass]="'bg-orange-400'">Deprovisioning</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'AwaitingProvisioning'" [bgColorClass]="'bg-blue-400'">Awaiting Provisioning</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'Provisioning'" [bgColorClass]="'bg-blue-400'">Provisioning</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'ProvisioningFailed'" [bgColorClass]="'bg-red-400'">Provisioning Failed</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'Inactive'" [bgColorClass]="'bg-red-400'">Inactive</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'InMaintenance'" [bgColorClass]="'bg-red-400'">In Maintenance</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'DeprovisioningFailed'" [bgColorClass]="'bg-red-400'">Deprovisioning Failed</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'Active'" [bgColorClass]="'bg-green-400'">Active</accounts-list-item-badge>
                    <accounts-list-item-badge *ngIf="tenantmodel.status === 'Deprovisioned'" [bgColorClass]="'bg-grey-400'">Deprovisioned</accounts-list-item-badge>
                  </div>
                  <accounts-list-item-description>
                    <div class="w-full flex justify-between">

                      <div class="flex items-center gap-4">
                        <div class="sm:flex">
                          <p class="flex items-center text-sm text-gray-500">
                            <struct-icon icon="account_circle" size="12" class="mr-2"></struct-icon>
                            Slug: {{tenantmodel.slug}}
                          </p>
                        </div>

                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <struct-icon icon="calendar_month" size="12" class="mr-2"></struct-icon>
                          <p>
                            Created
                            <time datetime="2022-01-07">{{tenantmodel.created | date}}</time>
                          </p>
                        </div>
                      </div>

                      <div class="flex-shrink-0 flex items-center gap-2">
                        <div class="flex items-center text-sm text-gray-500">
                          <struct-button [color]="''" (click)="openInstance(tenantmodel.slug)">
                            Go to instance
                            <struct-icon icon="arrow_right" size="12"></struct-icon>
                          </struct-button>
                        </div>
                        <!-- <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <struct-button [color]="''" (click)="cloneTenant(tenantmodel.tenantUid)">
                            <struct-icon icon="add" size="12"></struct-icon>
                            Create database template
                          </struct-button>
                        </div>
                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <struct-button [color]="''" (click)="showLog(tenantmodel.provisionStatusMessages)">
                            <struct-icon icon="add" size="12"></struct-icon>
                            Show log
                          </struct-button>
                        </div>
                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0" *ngIf="tenantmodel.status === 'Active'">
                          <struct-button [color]="''" (click)="setState(tenantmodel.tenantUid,'InActive')">
                            <struct-icon icon="add" size="12"></struct-icon>
                            Shut down
                          </struct-button>
                        </div>
                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0" *ngIf="tenantmodel.status === 'Inactive'">
                          <struct-button [color]="''" (click)="setState(tenantmodel.tenantUid,'Deprovision')">
                            <struct-icon icon="add" size="12"></struct-icon>
                            Deprovision
                          </struct-button>
                        </div> -->

                        <div class="">
                          <struct-icon-button icon="actions" [matMenuTriggerFor]="moreMenu"></struct-icon-button>
                          <mat-menu #moreMenu="matMenu">
                            <button mat-menu-item (click)="cloneTenant(tenantmodel.tenantUid)">Create database template</button>
                            <button mat-menu-item (click)="showLog(tenantmodel.provisionStatusMessages)">Show log</button>
                            <button mat-menu-item (click)="setState(tenantmodel.tenantUid,'InActive')" *ngIf="tenantmodel.status === 'Active'">Shut down</button>
                            <button mat-menu-item (click)="setState(tenantmodel.tenantUid,'Deprovision')" *ngIf="tenantmodel.status === 'Inactive'">Deprovision</button>
                          </mat-menu>
                        </div>
                      </div>

                    </div>

                  </accounts-list-item-description>

                </accounts-list-item>


              </accounts-list-content>
            </accounts-list>
          </section>
        </mat-expansion-panel>

      </mat-accordion>


    </struct-app-content-workarea>
  </struct-app-content>


</struct-app-content-container>
