import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { of, switchMap } from 'rxjs';
import { StructNotificationService } from '@struct/ui/struct-notification';
import { environment } from 'apps/accounts/src/environments/environment';
import { RegisterUserWithMicrosoftCommandResponse } from '@struct/models/struct/usermanagementmodule/commands/register-user-with-microsoft-command-response';

@Component({
  selector: 'accounts-register-with-microsoft-callback',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, RouterModule],
  templateUrl: './register-with-microsoft-callback.component.html',
})
export class RegisterWithMicrosoftCallbackComponent implements OnInit {
  private tenant: string | null = null;
  errorMsg: string | undefined = undefined;
  loading = true;
  showError = false;
  registerResponse: RegisterUserWithMicrosoftCommandResponse | null = null;
  authToken: string = '';

  constructor(private authService: AuthenticationService, private tr: TranslateService, private notificationService: StructNotificationService, private router: Router) {
  }

  ngOnInit(): void {
    this.authService
      .handleEntraIdRegisterCallback()
      .pipe(
        switchMap(authResult => {
          if (authResult.success) {
            this.authToken = authResult.accessToken;
            const inviteUid = authResult.inviteUid() ?? '';
            const inviteToken = authResult.inviteToken() ?? '';
            return this.authService.registerWithMicrosoft(this.authToken, inviteUid, inviteToken);
          } else {
            return of(null);
          }
        }),
        switchMap((response : RegisterUserWithMicrosoftCommandResponse | null) => {
          this.registerResponse = response;
          if(response?.succeeded){
            return this.authService.signInWithMicrosoft(this.authToken);
          }
          else{
            this.showError = true;
            const error = response?.errors[0];
            if (error) {
              this.errorMsg = error.code;
            } else {
              this.errorMsg = 'SignInWithMicrosoftCallbackError';
            }
            return of(null);
          }
        })         
      )
      .subscribe((response) => {
        if(response?.isSuccess){
          if(this.registerResponse?.tenantSlug === null || this.registerResponse?.tenantSlug === undefined || this.registerResponse?.tenantSlug === '') {
            window.location.href = environment.accountAppUrl;
          }
          else{
            window.location.href = environment.tenantAppUrl.replace('{tenant}', this.registerResponse?.tenantSlug);
          }
        }
        else{
          this.showError = true;
          this.errorMsg = "UnknownErrorWhileSigningIn";
        }
        this.loading = false;
      });
  }
}
  