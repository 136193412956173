
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Shortcut } from "@struct/models/struct/app/domain/shortcuts/shortcut";
import { ShortcutGroup } from "@struct/models/struct/app/domain/shortcuts/shortcut-group";

@Injectable({ providedIn: "root" })
export class ShortcutsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getShortcutGroups(): Observable<ShortcutGroup[]> {
    return this.http.get<ShortcutGroup[]>(`${this.apiUrl}shortcuts/getshortcutgroups`, this.httpOptions );
  }

  createShortcut(shortcut: Shortcut): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}shortcuts/createshortcut`, shortcut, this.httpOptions);
  }

  saveShortcutSorting(shortcuts: Shortcut[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}shortcuts/saveshortcutsorting`, shortcuts, this.httpOptions);
  }

  deleteShortcut(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}shortcuts/deleteshortcut?uid=${uid}`, this.httpOptions );
  }
}

