// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Query } from "@struct/models/struct/shared/search/query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISearchDefinitionModel  {
  sortField?: string | null;
  sortAsc: boolean;
  searchQuery: Query;
  pageIndex: number;
  pageSize: number;
}

export class SearchDefinitionModel  implements ISearchDefinitionModel {
  sortField: string | null = null;

  sortAsc = false;

  searchQuery: Query = new Query();

  pageIndex = 0;

  pageSize = 10;


  constructor(data?: Partial<ISearchDefinitionModel>) {
    Object.assign(this, data);
  }
}