// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAcceptInviteCommand  {
  inviteToken: string;
  inviteUid: string;
  email: string;
}

export class AcceptInviteCommand  implements IAcceptInviteCommand {
  inviteToken = "";

  inviteUid = "00000000-0000-0000-0000-000000000000";

  email = "";


  constructor(data?: Partial<IAcceptInviteCommand>) {
    Object.assign(this, data);
  }
}