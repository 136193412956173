// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscription  {
  uid: string;
  name: string;
}

export class Subscription  implements ISubscription {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";


  constructor(data?: Partial<ISubscription>) {
    Object.assign(this, data);
  }
}