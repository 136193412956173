import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard  {

  constructor(private router: Router, private authService: AuthenticationService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.isSignedIn()
    .pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          console.log('User is not authenticated');
          return of(this.router.createUrlTree(['/sign-in']));
        }
        return of(true);
      })
    );
  }
}
