
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateRoleCommand } from "@struct/models/struct/app/domain/usermanagement/commands/create-role-command";
import { UpdateRoleCommand } from "@struct/models/struct/app/domain/usermanagement/commands/update-role-command";
import { Role } from "@struct/models/struct/app/domain/usermanagement/role";
import { RoleSearchModel } from "@struct/models/struct/app/frontendapi/models/roles/role-search-model";

@Injectable({ providedIn: "root" })
export class RolesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getRoles(): Observable<RoleSearchModel[]> {
    return this.http.get<RoleSearchModel[]>(`${this.apiUrl}roles`, this.httpOptions );
  }

  getRole(uid: string): Observable<Role | null> {
    return this.http.get<Role | null>(`${this.apiUrl}roles/${uid}`, this.httpOptions );
  }

  createRole(cmd: CreateRoleCommand): Observable<Role> {
    return this.http.post<Role>(`${this.apiUrl}roles`, cmd, this.httpOptions);
  }

  updateRole(cmd: UpdateRoleCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}roles`, cmd, this.httpOptions);
  }

  deleteRole(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}roles/${uid}`, this.httpOptions );
  }
}

