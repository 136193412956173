<button
  [disabled]="disabled"
  type="{{buttonType}}"
  mat-icon-button
  (click)="clicked.emit($event)"
  class="relative"
  [ngClass]="{'struct-icon-button__xs': size === 'xsmall', 'struct-icon-button__sm': size === 'small', 'struct-icon-button__base' : size === 'base', 'struct-icon-button__lg' : size === 'large', 'bg-blue-100 text-blue-800 ring-1 ring-blue-200' : selected === true}">
  <struct-icon size="{{iconSize}}" icon="{{icon}}" [useMaterialIcons]="useMaterialIcons"></struct-icon>
  <ng-content></ng-content>

  <span class="absolute -bottom-5 h-5 left-0 w-full flex justify-center items-center bg-gray-50/0 text-gray-700 py-1 px-2 text-[10px] font-medium rounded" *ngIf="selected && selectedLabel !== ''">{{selectedLabel}}</span>
</button>
