<section class="w-full" [ngClass]="showMarketing ? 'bg-white' : 'bg-gray-50'">

  <!-- Grid -->
  <div class="h-screen w-full grid" [ngClass]="showMarketing ? 'grid-cols-2' : 'grid-cols-1'">
    <!-- <div class="absolute inset-0 bg-[url('/assets/images/login/hero.png')] bg-right-bottom bg-no-repeat"></div> -->

    <!-- First column -->
     <div class="flex flex-col h-full">
      <div class="p-4 flex flex-grow-0 border-b border-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-8 h-8 text-blue-200">
          <g>
            <path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path>
            <path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path>
            <path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path>
          </g>
        </svg>
      </div>

      <div class="flex items-center justify-center flex-grow">

        <div class="p-4 lg:p-8 w-full lg:max-w-[500px] items-center relative @container/workarea bg-white rounded-lg" [ngClass]="showMarketing ? '' : 'shadow-xl'">
          <div class="absolute inset-2 z-10 bg-white/90 flex items-center justify-center" *ngIf="working">
            <struct-spinner></struct-spinner>
          </div>
          <div class="flex flex-col w-full">
            <div *ngIf="!userCreated">
              <div class="animate-in slide-in-from-bottom-4" *ngIf="signUpType !== null">
                <button mat-button (click)="signUpType = null">
                  <struct-icon icon="arrow_left" size="12"></struct-icon>
                  <span>Go back</span>
                </button>
                <hr class="my-4" />
              </div>
              <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 relative">
                <!-- <struct-icon-button *ngIf="signUpType !== null" iconSize="24" class="absolute -left-12 -top-1" icon="arrow_back"  [useMaterialIcons]="true"></struct-icon-button> -->
                {{"RegisterUser.Title" | translate}}
              </h1>
              <h2 class="text-lg font-medium mb-8">{{"RegisterUser.Subtitle" | translate}}</h2>

              <div *ngIf="signUpType === null">

                <div class="mt-6 grid grid-cols-1 gap-4">

                  <a (click)="createWithEmail()" class="flex cursor-pointer w-full items-center justify-center gap-3 rounded bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                    <svg class="h-5 w-5 text-blue-200" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40">
                      <g ><path fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z" class="text-blue-300"></path><path fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z" class="text-blue-500"></path><path fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z" class="text-blue-400"></path></g>
                    </svg>

                    <span class="text-sm font-semibold leading-6">Sign up with email</span>
                  </a>
                  <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                      <div class="w-full border-t border-gray-200"></div>
                    </div>
                    <div class="relative flex justify-center text-sm font-medium leading-6">
                      <span class="bg-white px-6 text-gray-900">Or</span>
                    </div>
                  </div>

                  <a (click)="createWithMicrosoft()" class="flex cursor-pointer w-full items-center justify-center gap-3 rounded bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g fill="currentColor"><rect x="1" y="1" fill="currentColor" width="10" height="10"></rect> <rect x="13" y="1" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 12)" fill="currentColor" width="10" height="10"></rect> <rect x="1" y="13" fill="currentColor" width="10" height="10"></rect> <rect x="13" y="13" transform="matrix(-1 -4.492907e-11 4.492907e-11 -1 36 36)" fill="currentColor" width="10" height="10"></rect></g>
                    </svg>

                    <span class="text-sm font-semibold leading-6">Sign up with Microsoft</span>
                  </a>

                </div>

              </div>

              <form [formGroup]="userForm" autocomplete="off" novalidate (ngSubmit)="createUser()" *ngIf="signUpType === 'email'">

                <struct-form-layout>
                  <struct-form-row [borderBelow]="true">
                    <struct-form-row-headline>{{"RegisterUser.Email" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.EmailDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.Email" | translate}}</mat-label>
                          <input matInput formControlName="email" type="text" />
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"RegisterUser.FirstName" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.FirstNameDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.FirstName" | translate}}</mat-label>
                          <input matInput formControlName="firstname" type="text" />
                          <mat-error *ngIf="userForm.controls['firstname'].hasError('required')">{{"RegisterUser.FirstNameIsRequired" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"RegisterUser.LastName" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.LastNameDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.LastName" | translate}}</mat-label>
                          <input matInput formControlName="lastname" type="text" />
                          <mat-error *ngIf="userForm.controls['lastname'].hasError('required')">{{"RegisterUser.LastNameIsRequired" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"RegisterUser.Phone" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.PhoneDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.Phone" | translate}}</mat-label>
                          <input matInput formControlName="phoneNumber" type="text" />
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"RegisterUser.Company" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.CompanyDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.Company" | translate}}</mat-label>
                          <input matInput formControlName="company" type="text" />
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="false">
                    <struct-form-row-headline>{{"RegisterUser.Password" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.PasswordDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.Password" | translate}}</mat-label>
                          <input matInput formControlName="password" [type]="passwordVisible ? 'text' : 'password'" />
                          <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
                            <mat-icon>{{ passwordVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
                          </button>
                          <mat-error *ngIf="userForm.controls['password'].hasError('required')">{{"RegisterUser.PasswordIsRequired" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                  <struct-form-row [borderBelow]="true">
                    <struct-form-row-headline>{{"RegisterUser.ConfirmPassword" | translate}}</struct-form-row-headline>
                    <struct-form-row-description>{{"RegisterUser.ConfirmPasswordDescription" | translate}}</struct-form-row-description>
                    <struct-form-row-grid>
                      <struct-form-row-grid-tile data-cols="4">
                        <mat-form-field appearance="outline">
                          <mat-label>{{"RegisterUser.ConfirmPassword" | translate}}</mat-label>
                          <input matInput formControlName="confirmPassword" [type]="passwordConfirmVisible ? 'text' : 'password'" />
                          <button mat-icon-button matSuffix (click)="togglePasswordConfirmVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordConfirmVisible">
                            <mat-icon>{{ passwordConfirmVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
                          </button>
                          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">{{"RegisterUser.ConfirmPasswordIsRequired" | translate}}</mat-error>
                          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('mustMatch')">{{"RegisterUser.ConfirmPasswordMustMatch" | translate}}</mat-error>
                        </mat-form-field>
                      </struct-form-row-grid-tile>
                    </struct-form-row-grid>
                  </struct-form-row>

                </struct-form-layout>

                <div *ngIf="showError" class="bg-red-100 text-red-500 text-sm font-medium mb-4 p-4 flex gap-4">
                  <div>
                    <struct-icon [useMaterialIcons]="true" icon="error" class="text-red-500"></struct-icon>
                  </div>
                  <div>
                  <div *ngFor="let err of errors">
                    {{ "RegisterUser.Error_" +  err.code | translate }}
                  </div>
                </div>
                </div>

                <div class="flex items-center w-full">
                  <!-- <struct-button color="primary" buttonType="submit" [disabled]="!userForm.valid">{{"RegisterUser.Submit" | translate}}</struct-button> -->
                  <button mat-flat-button color="primary" [disabled]="!userForm.valid" type="submit" class="w-full">{{"RegisterUser.Submit" | translate}}</button>
                </div>

              </form>
            </div>
          </div>
        </div>


      </div>

      <div class="p-4 flex flex-grow-0 border-t border-gray-100">
        <div class="">
          <div class="flex gap-4">
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Help" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Privacy" | translate}}</a>
            <a class="text-sm text-gray-500 hover:underline hover:text-blue-500" [routerLink]="['/help']">{{"Login.Terms" | translate}}</a>
          </div>
        </div>
        <div class="ml-auto">
          <a class="text-sm text-gray-500 hover:underline hover:text-blue-500 cursor-pointer" (click)="toggleMarketingColumn()">Hide marketing</a>
        </div>
      </div>
     </div>
    <!-- First column -->

    <!-- Second column -->

    <struct-carousel class="usein flex w-full h-full relative" *ngIf="showMarketing"
                 [itemCount]="5"
                 [itemsPerPage]="1"
                 [interval]="10000"
                 [gap]="0"
                 [freezePeriod]="5000"
                 [showHandles]="false"
                 [mainColor]="'--color-blue-200'"
                 [secondColor]="'--color-red-500'">
      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-blue-800">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-blue-300 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
            <div class="bg-blue-400 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 duration-1000"></div>
            <div class="bg-blue-500 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
            <div class="bg-blue-600 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
          </div>
          <div class="absolute top-1/2 left-1/4 -translate-y-1/2 -translate-x-1/2">
            <div class=" w-24 h-24 bg-blue-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl animate-ping">
              AI
            </div>
          </div>

        </div>
        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000 mb-8">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-green-50">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-green-200 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
            <div class="bg-green-300 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 duration-1000"></div>
            <div class="bg-green-400 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 duration-1000"></div>
            <div class="bg-green-500 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-16 duration-1000"></div>
          </div>
          <div class="absolute top-1/2 left-1/4 w-24 h-24 bg-green-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl -translate-y-1/2 -translate-x-1/2">
            AI
          </div>
        </div>
        <div class="absolute bottom-16 left-16 text-green-600 flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-12 duration-1000">Struct PIM Add-on: Supplier</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-12 duration-1000">Let your suppliers enrich your product data</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-yellow-900">
        <div class="relative">
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-4 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-8 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 "></div>
            <div class="bg-yellow-800 rounded-xl w-96 h-96 animate-in slide-in-from-bottom-12 "></div>
          </div>
          <div class="absolute top-1/2 left-1/4 w-24 h-24 bg-gray-900 p-4 flex items-center justify-center text-white text-2xl font-medium rounded shadow-2xl -translate-y-1/2 -translate-x-1/2">
            AI
          </div>
        </div>
        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4">Struct PIM Add-on: Publications</p>
          <h1 class="text-3xl lg:text-5xl font-medium animate-in slide-in-from-bottom-4">Turn your Struct PIM product data into Publications</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-700">

        <div class="w-1/2">
          <div class="bg-gray-800 rounded-xl w-full h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
        </div>

        <div class="absolute bottom-16 left-16 text-white flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>

      <figure class="struct-carousel__item w-full h-full items-center justify-center bg-gray-50">

        <div class="w-1/2">
          <div class="bg-white rounded-xl shadow-xl w-full h-96 animate-in slide-in-from-bottom-4 duration-1000"></div>
        </div>

        <div class="absolute bottom-16 left-16 text-gray-700 flex flex-col gap-2 max-w-96">
          <p class="font-medium text-sm animate-in slide-in-from-bottom-4 duration-1000">Struct PIM Add-on: AI assistant</p>
          <h1 class="text-3xl lg:text-5xl font-regular animate-in slide-in-from-bottom-4 duration-1000">Effortless, unlimited content creation at your fingertips</h1>
        </div>
      </figure>


    </struct-carousel>


  </div>
  <!-- Grid -->
</section>
<!-- Section: Split screen -->









<!-- <div class="" *ngIf="!userCreated">

    <form [formGroup]="userForm" autocomplete="off" novalidate (ngSubmit)="createUser()">
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="text" />
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Firstname</mat-label>
          <input matInput formControlName="firstname" type="text" />
          <mat-error *ngIf="userForm.controls['firstname'].hasError('required')">Firstname is required</mat-error>
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Lastname</mat-label>
          <input matInput formControlName="lastname" type="text" />
          <mat-error *ngIf="userForm.controls['lastname'].hasError('required')">Lastname is required</mat-error>
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phoneNumber" type="text" />
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Company</mat-label>
          <input matInput formControlName="company" type="text" />
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="passwordVisible ? 'text' : 'password'" />
          <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{ passwordVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">Password is required</mat-error>
        </mat-form-field>
      </div>
      <div class="mb-3 row">
        <mat-form-field appearance="outline">
          <mat-label>Confirm password</mat-label>
          <input matInput formControlName="confirmPassword" [type]="passwordConfirmVisible ? 'text' : 'password'" />
          <button mat-icon-button matSuffix (click)="togglePasswordConfirmVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordConfirmVisible">
            <mat-icon>{{ passwordConfirmVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">Confirm password is required</mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('mustMatch')">Passwords must match</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="showError" class="mb-3 row">
        <div class="alert alert-danger" role="alert" *ngFor="let err of errors">
          {{ err.code }}
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-md-1">
          <struct-button color="primary" buttonType="submit" [disabled]="!userForm.valid">Create user</struct-button>
        </div>
      </div>
    </form>

</div> -->
<div *ngIf="userCreated">
    <div class="card m-20">
        <div class="card-body">
        <h1>User created</h1>
        <p>The user has been created, and we have sent you a confirmation mail.</p>
        <a [routerLink]="'/sign-in'">Sign in</a>
        </div>
    </div>
</div>
