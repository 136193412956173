
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExportImportDataFieldReference } from "@struct/models/struct/app/domain/exportimport/sharedentities/export-import-data-field-reference";
import { ImportItemsToShow } from "@struct/models/struct/app/domain/fileimport/import-items-to-show";
import { ImportReport } from "@struct/models/struct/app/domain/fileimport/import-report";
import { ExcelDataImportDetails } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/excel-data-import-details";
import { FileImportAnalysis } from "@struct/models/struct/app/domain/fileimport/importers/excelshared/file-import-analysis";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { GetImportReportRequest } from "@struct/models/struct/app/frontendapi/models/import/get-import-report-request";
import { GlobalListValueImportModel } from "@struct/models/struct/app/frontendapi/models/import/global-list-value-import-model";
import { ImportValidationModel } from "@struct/models/struct/app/frontendapi/models/import/import-validation-model";

@Injectable({ providedIn: "root" })
export class ImportApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableDataFields(entityType: StructEntityType): Observable<ExportImportDataFieldReference[]> {
    return this.http.get<ExportImportDataFieldReference[]>(`${this.apiUrl}standardexcelimport/availabledatafields?entityType=${entityType}`, this.httpOptions );
  }

  getExcelDataImportDetails(uid: string): Observable<ExcelDataImportDetails> {
    return this.http.get<ExcelDataImportDetails>(`${this.apiUrl}standardexcelimport/exceldataimportdetails?uid=${uid}`, this.httpOptions );
  }

  createGlobalListImportUpdateReport(model: GlobalListValueImportModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/creategloballistimportupdatereport`, model, this.httpOptions);
  }

  createEntityUpdateReport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/createentityupdatereport`, model, this.httpOptions);
  }

  analyzeFileForNewItems(model: ImportValidationModel): Observable<FileImportAnalysis> {
    return this.http.post<FileImportAnalysis>(`${this.apiUrl}standardexcelimport/analyzefilefornewitems`, model, this.httpOptions);
  }

  commitEntityImport(model: ImportValidationModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/commitimport`, model, this.httpOptions);
  }

  getImportReport(model: GetImportReportRequest): Observable<ImportReport> {
    return this.http.post<ImportReport>(`${this.apiUrl}standardexcelimport/getimportreport`, model, this.httpOptions);
  }

  commitGlobalListImport(model: GlobalListValueImportModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}standardexcelimport/commitgloballistimport`, model, this.httpOptions);
  }

  getImportReportRecordNosToImport(importReportUid: string, itemsToSelect: ImportItemsToShow): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiUrl}import/getImportReportRecordNosToImport?importReportUid=${importReportUid}&itemsToSelect=${itemsToSelect}`, this.httpOptions );
  }

  getAvailableGlobalListFields(globalListUid: string): Observable<ExportImportDataFieldReference[]> {
    return this.http.get<ExportImportDataFieldReference[]>(`${this.apiUrl}import/availablegloballistfields?globalListUid=${globalListUid}`, this.httpOptions );
  }

  upload(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}import/upload`, null, this.httpOptions);
  }
}

