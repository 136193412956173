import { Injectable, signal } from '@angular/core';
import { IAreaSize } from 'angular-split';

@Injectable({
  providedIn: 'root',
})
export class StructAppSidebarService {
  public sidebarHasMenu = signal<boolean>(false);
  public sidebarIsCollapsed = signal<boolean>(false);

  constructor() {
    this.sidebarIsCollapsed.set(this.getSidebarCollapsed());
  }

  public setCurrentPageHasMenu(hasMenu: boolean) {
    this.sidebarHasMenu.set(hasMenu);
  }

  private getSidebarCollapsed(): boolean {
    const v = localStorage.getItem('StructSidebarMenuCollapsed');
    if (v === null) {
      return false;
    }
    return <boolean>JSON.parse(v);
  }

  public setSidebarCollapsed(collapsed: boolean) {
    localStorage.setItem('StructSidebarMenuCollapsed', JSON.stringify(collapsed));
    this.sidebarIsCollapsed.set(collapsed);
  }

  public getLeftMenuSize(): IAreaSize {
    const v = localStorage.getItem('StructLeftMenuSize');
    if (v === null) {
      return 420;
    }
    return <IAreaSize>JSON.parse(v);
  }

  public setLeftMenuSize(size: IAreaSize) {
    localStorage.setItem('StructLeftMenuSize', JSON.stringify(size));
  }

  public setDefaultAreaSize() {
    this.setLeftMenuSize(420);
  }
}