import { DomSanitizer } from "@angular/platform-browser";
import { FileHandle } from "./FileHandle";

export class FileHelper {
  static formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getInMB(bytes: number, decimals: number): string {
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm)) + " MB";
  }
  

  public static getFileHandle(file: File, sanitizer: DomSanitizer): FileHandle {
    const name = file.name;
    const url = sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    const size = FileHelper.formatBytes(file.size, 0);
    const type = file.type;
    return new FileHandle(name, file, url, size, type);
}

  static getDocumentMimeTypes(): string[] {
    return [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.presentation',
    ];
  }

  static isRenderableImage(fileExtension: string | null): boolean {
    if (!fileExtension) {
      return false;
    }
    return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'bmp', 'eps', 'psd', 'ico', 'tiff'].includes(fileExtension);
  }

  static isRenderableVideo(fileExtension: string | null): boolean {
    if (!fileExtension) {
      return false;
    }
    return ['mp4', 'webm', 'ogg'].includes(fileExtension);
  }

  static isPdf(fileExtension: string | null): boolean {
    if (!fileExtension) {
      return false;
    }
    return fileExtension === 'pdf';
  }

  static isOfficeDocument(fileExtension: string | null): boolean {
    if (!fileExtension) {
      return false;
    }
    return ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'ods', 'odp'].includes(fileExtension);
  }

  static getMimeTypeFromFileExtension(extension: string): string {
    if (extension === 'jpg' || extension === 'jpeg') {
      return 'image/jpeg';
    }
    if (extension === 'png') {
      return 'image/png';
    }
    if (extension === 'gif') {
      return 'image/gif';
    }
    if (extension === 'svg') {
      return 'image/svg+xml';
    }
    if (extension === 'webp') {
      return 'image/webp';
    }
    if (extension === 'bmp') {
      return 'image/bmp';
    }
    if (extension === 'eps') {
      return 'image/eps';
    }
    if (extension === 'psd') {
      return 'image/vnd.adobe.photoshop';
    }
    if (extension === 'ico') {
      return 'image/x-icon';
    }
    if (extension === 'tiff') {
      return 'image/tiff';
    }
    if (extension === 'mp4') {
      return 'video/mp4';
    }
    if (extension === 'webm') {
      return 'video/webm';
    }
    if (extension === 'ogg') {
      return 'video/ogg';
    }
    if (extension === 'mp3') {
      return 'audio/mp3';
    }
    if (extension === 'wav') {
      return 'audio/wav';
    }
    if (extension === 'flac') {
      return 'audio/flac';
    }
    if (extension === 'pdf') {
      return 'application/pdf';
    }
    if (extension === 'doc') {
      return 'application/msword';
    }
    if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (extension === 'xls') {
      return 'application/vnd.ms-excel';
    }
    if (extension === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (extension === 'ppt') {
      return 'application/vnd.ms-powerpoint';
    }
    if (extension === 'pptx') {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    if (extension === 'odt') {
      return 'application/vnd.oasis.opendocument.text';
    }
    if (extension === 'ods') {
      return 'application/vnd.oasis.opendocument.spreadsheet';
    }
    if (extension === 'odp') {
      return 'application/vnd.oasis.opendocument.presentation';
    }
    return '';
  }
}
