import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'accounts-list-item-description',
  templateUrl: './accounts-list-item-description.component.html',
  styleUrls: [ './accounts-list-item-description.component.scss' ],
  standalone: true,
  imports: [CommonModule],
})
export class AccountsListItemDescriptionComponent
{
  
}
