import {IconsPack} from "./icons-pack";
import {Icon} from "./icon";

export class StructIconsPack implements IconsPack {
    public getIcons(): Icon[] {
        return [
            {'name': 'variants_generate', 'id': 'ea42'},
            {'name': 'bookmark_add', 'id': 'ea5e'},
            {'name': 'translation', 'id': 'ea5f'},
            {'name': 'bookmark_delete', 'id': 'ea60'},
            {'name': 'revision_log', 'id': 'ea61'},
            {'name': 'quick_actions', 'id': 'ea62'},
            {'name': 'apps', 'id': 'ea63'},
            {'name': 'notifications', 'id': 'ea64'},
            {'name': 'user', 'id': 'ea65'},
            {'name': 'globe', 'id': 'ea66'},
            {'name': 'expand', 'id': 'ea67'},
            {'name': 'check', 'id': 'ea68'},
            {'name': 'move_right', 'id': 'ea69'},
            {'name': 'move_up', 'id': 'ea6a'},
            {'name': 'move-down', 'id': 'ea6b'},
            {'name': 'move_left', 'id': 'ea6c'},
            {'name': 'chevron_right', 'id': 'ea6d'},
            {'name': 'chevron_left', 'id': 'ea6e'},
            {'name': 'plus', 'id': 'ea6f'},
            {'name': 'minus', 'id': 'ea70'},
            {'name': 'delete', 'id': 'ea71'},
            {'name': 'insert_source', 'id': 'ea72'},
            {'name': 'desktop', 'id': 'ea73'},
            {'name': 'tablet', 'id': 'ea74'},
            {'name': 'phone', 'id': 'ea75'},
            {'name': 'copy_to_all', 'id': 'ea76'},
            {'name': 'copy', 'id': 'ea77'},
            {'name': 'support', 'id': 'ea78'},
            {'name': 'question-mark', 'id': 'ea79'},
            {'name': 'help', 'id': 'ea7a'},
            {'name': 'google_translate', 'id': 'ea7b'},
            {'name': 'archive', 'id': 'ea7c'},
            {'name': 'remove', 'id': 'ea7d'},
            {'name': 'variant', 'id': 'ea7f'},
            {'name': 'product', 'id': 'ea80'},
            {'name': 'search', 'id': 'ea81'},
            {'name': 'image', 'id': 'ea82'},
            {'name': 'image-stack', 'id': 'ea83'},
            {'name': 'undo', 'id': 'ea84'},
            {'name': 'redo', 'id': 'ea85'},
            {'name': 'translation_revision', 'id': 'ea86'},
            {'name': 'sort', 'id': 'ea87'},
            {'name': 'folder_filled', 'id': 'ea88'},
            {'name': 'folder_filled_open', 'id': 'ea89'},
            {'name': 'move', 'id': 'ea8a'},
            {'name': 'more_horiz', 'id': 'ea8b'},
            {'name': 'more_vert', 'id': 'ea8c'},
            {'name': 'services', 'id': 'ea8d'},
            {'name': 'actions', 'id': 'ea8e'},
            {'name': 'edit', 'id': 'ea8f'},
            {'name': 'log_out', 'id': 'ea90'},
            {'name': 'my_profile', 'id': 'ea91'},
            {'name': 'tenants', 'id': 'ea92'},
            {'name': 'subscriptions', 'id': 'ea93'},
            {'name': 'subscription_plans', 'id': 'ea94'},
            {'name': 'product_structure', 'id': 'ea96'},
            {'name': 'warning', 'id': 'ea97'},
            {'name': 'equals', 'id': 'ea98'},
            {'name': 'priority_high', 'id': 'ea99'},
            {'name': 'text_increase', 'id': 'ea9b'},
            {'name': 'text_decrease', 'id': 'ea9c'},
            {'name': 'tenant_add', 'id': 'ea9d'},
            {'name': 'tenant_remove', 'id': 'ea9e'},
            {'name': 'service', 'id': 'eaa0'},
            {'name': 'sell', 'id': 'eaa1'},
            {'name': 'subscription_plan', 'id': 'eaa2'},
            {'name': 'subscription', 'id': 'eaa3'},
            {'name': 'account_circle', 'id': 'eaa4'},
            {'name': 'view_column', 'id': 'eaa5'},
            {'name': 'add', 'id': 'eaa6'},
            {'name': 'toggle_off', 'id': 'eaa7'},
            {'name': 'toggle_on', 'id': 'eaa8'},
            {'name': 'drag_indicator', 'id': 'eaa9'},
            {'name': 'view', 'id': 'eaaa'},
            {'name': 'global_lists', 'id': 'eaab'},
            {'name': 'background_tasks', 'id': 'eaac'},
            {'name': 'collections', 'id': 'eaad'},
            {'name': 'import', 'id': 'eaae'},
            {'name': 'catalogues', 'id': 'eaaf'},
            {'name': 'publications', 'id': 'eab0'},
            {'name': 'data_supplier_onboarding', 'id': 'eab1'},
            {'name': 'product_model_setup', 'id': 'eab2'},
            {'name': 'settings', 'id': 'eab3'},
            {'name': 'shopify_connect', 'id': 'eab4'},
            {'name': 'apps_24', 'id': 'eab5'},
            {'name': 'todo_24', 'id': 'eab9'},
            {'name': 'global_lists_32', 'id': 'eabb'},
            {'name': 'catalogues_32', 'id': 'eabc'},
            {'name': 'collections_32', 'id': 'eabd'},
            {'name': 'import_32', 'id': 'eabe'},
            {'name': 'product_model_setup_32', 'id': 'eabf'},
            {'name': 'arrow_left', 'id': 'eac2'},
            {'name': 'arrow_right', 'id': 'eac3'},
            {'name': 'grid_view', 'id': 'eac4'},
            {'name': 'multiple', 'id': 'eac5'},
            {'name': 'comments', 'id': 'eac6'},
            {'name': 'calendar', 'id': 'eac7'},
            {'name': 'attribute_64', 'id': 'eac8'},
            {'name': 'attribute_48', 'id': 'eac9'},
            {'name': 'attribute_32', 'id': 'eaca'},
            {'name': 'attribute_24', 'id': 'eacb'},
            {'name': 'lock', 'id': 'eacc'},
            {'name': 'unlocked', 'id': 'eacd'},
            {'name': 'settings-gear-12', 'id': 'eace'},
            {'name': 'caret_sm_up', 'id': 'ead2'},
            {'name': 'arrow_sm_down', 'id': 'ead3'},
            {'name': 'arrow_sm_right', 'id': 'ead4'},
            {'name': 'arrow_sm_left', 'id': 'ead5'},
            {'name': 'enlarge', 'id': 'ead6'},
            {'name': 'tasks_16', 'id': 'ead7'},
            {'name': 'shortcut_add', 'id': 'eadc'},
            {'name': 'shortcut_remove', 'id': 'eadd'},
            {'name': 'groups_24', 'id': 'eae4'},
            {'name': 'roles_24', 'id': 'eae5'},
            {'name': 'user_24', 'id': 'eae6'},
            {'name': 'tasks_24', 'id': 'eae7'},
            {'name': 'global_lists_24', 'id': 'eae8'},
            {'name': 'catalogues_24', 'id': 'eae9'},
            {'name': 'product_structure_24', 'id': 'eaea'},
            {'name': 'search_24', 'id': 'eaeb'},
            {'name': 'settings_24', 'id': 'eaec'},
            {'name': 'aiassistant_24', 'id': 'eaed'},
            {'name': 'permission_24', 'id': 'eaee'},
            {'name': 'wand', 'id': 'eaef'},
            {'name': 'trash_48', 'id': 'eaf0'},
            {'name': 'redo_48', 'id': 'eaf1'},
            {'name': 'file_text', 'id': 'eaf2'},
            {'name': 'file_article', 'id': 'eaf3'},
        ];
    }
}
