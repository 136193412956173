
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateServiceBusConnectionCommand } from "@struct/models/struct/app/domain/messaging/commands/create-service-bus-connection-command";
import { UpdateServiceBusConnectionCommand } from "@struct/models/struct/app/domain/messaging/commands/update-service-bus-connection-command";
import { MessagingEvent } from "@struct/models/struct/app/domain/messaging/messaging-event";
import { ServiceBusConnection } from "@struct/models/struct/app/domain/messaging/service-bus-connection";

@Injectable({ providedIn: "root" })
export class MessagingApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getServiceBusConnections(): Observable<ServiceBusConnection[]> {
    return this.http.get<ServiceBusConnection[]>(`${this.apiUrl}messaging/service-bus-connections`, this.httpOptions );
  }

  getServiceBusConnection(uid: string): Observable<ServiceBusConnection> {
    return this.http.get<ServiceBusConnection>(`${this.apiUrl}messaging/service-bus-connections/${uid}`, this.httpOptions );
  }

  createConnection(command: CreateServiceBusConnectionCommand): Observable<ServiceBusConnection[]> {
    return this.http.post<ServiceBusConnection[]>(`${this.apiUrl}messaging/service-bus-connections`, command, this.httpOptions);
  }

  updateConnection(command: UpdateServiceBusConnectionCommand): Observable<ServiceBusConnection[]> {
    return this.http.put<ServiceBusConnection[]>(`${this.apiUrl}messaging/service-bus-connections`, command, this.httpOptions);
  }

  deleteConnection(uid: string): Observable<ServiceBusConnection[]> {
    return this.http.delete<ServiceBusConnection[]>(`${this.apiUrl}messaging/service-bus-connections/${uid}`, this.httpOptions );
  }

  getEvents(): Observable<MessagingEvent[]> {
    return this.http.get<MessagingEvent[]>(`${this.apiUrl}messaging/events`, this.httpOptions );
  }

  testServiceBusConnection(connection: ServiceBusConnection): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}messaging/test-service-bus-connection`, connection, this.httpOptions);
  }
}

