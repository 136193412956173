// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProvisionStatusMessage } from "@struct/models/accountmanagement/domain/tenants/provision-status-message";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProvisioningStatus  {
  publicAssetContainerDeployed: boolean;
  publicAssetContainerName?: string | null;
  publicAssetContainerDataCopied: boolean;
  privateAssetContainerDeployed: boolean;
  privateAssetContainerName?: string | null;
  appDataContainerDeployed: boolean;
  appDataContainerName?: string | null;
  keyVaultEntryCreated: boolean;
  allAdminUsersInvited: boolean;
  adminUsersInvited: string[];
  databaseDeployed: boolean;
  databaseImageImported: boolean;
  databaseConnectionString?: string | null;
  indexesRebuilt: boolean;
  provisionStatusMessages: ProvisionStatusMessage[];
  privateAssetContainerDataCopied: boolean;
}

export class ProvisioningStatus  implements IProvisioningStatus {
  publicAssetContainerDeployed = false;

  publicAssetContainerName: string | null = null;

  publicAssetContainerDataCopied = false;

  privateAssetContainerDeployed = false;

  privateAssetContainerName: string | null = null;

  appDataContainerDeployed = false;

  appDataContainerName: string | null = null;

  keyVaultEntryCreated = false;

  allAdminUsersInvited = false;

  adminUsersInvited: string[] = [];

  databaseDeployed = false;

  databaseImageImported = false;

  databaseConnectionString: string | null = null;

  indexesRebuilt = false;

  provisionStatusMessages: ProvisionStatusMessage[] = [];

  privateAssetContainerDataCopied = false;


  constructor(data?: Partial<IProvisioningStatus>) {
    Object.assign(this, data);
  }
}