
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DatabaseTemplate } from "@struct/models/accountmanagement/domain/databasetemplates/database-template";
import { CreateOrganizationCommand } from "@struct/models/accountmanagement/domain/organizations/commands/create-organization-command";
import { OrganizationUserInviteCommand } from "@struct/models/accountmanagement/domain/organizations/commands/organization-user-invite-command";
import { UpdateOrganizationCommand } from "@struct/models/accountmanagement/domain/organizations/commands/update-organization-command";
import { Organization } from "@struct/models/accountmanagement/domain/organizations/organization";
import { OrganizationUser } from "@struct/models/accountmanagement/domain/organizations/organization-user";
import { OrganizationSearchModel } from "@struct/models/struct/accountmanagement/api/models/organization/organization-search-model";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";
import { OrganizationInvite } from "@struct/models/struct/usermanagementmodule/organization-invite";

@Injectable({ providedIn: "root" })
export class OrganizationsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getOrganizations(): Observable<OrganizationSearchModel[]> {
    return this.http.get<OrganizationSearchModel[]>(`${this.apiUrl}organizations`, this.httpOptions );
  }

  getOrganizationByUid(uid: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.apiUrl}organization/${uid}`, this.httpOptions );
  }

  getOrganizationByUser(): Observable<Organization> {
    return this.http.get<Organization>(`${this.apiUrl}myorganization`, this.httpOptions );
  }

  getOrganizationDatabaseTemplates(): Observable<DatabaseTemplate[]> {
    return this.http.get<DatabaseTemplate[]>(`${this.apiUrl}myorganization/databasetemplates`, this.httpOptions );
  }

  getOrganizationDatabaseTemplatesByOrgUid(orgUid: string): Observable<DatabaseTemplate[]> {
    return this.http.get<DatabaseTemplate[]>(`${this.apiUrl}organization/${orgUid}/databasetemplates`, this.httpOptions );
  }

  getOrganizationUsers(uid: string): Observable<OrganizationUser[]> {
    return this.http.get<OrganizationUser[]>(`${this.apiUrl}organization/${uid}/users`, this.httpOptions );
  }

  getOrganizationUserInvites(uid: string): Observable<OrganizationInvite[]> {
    return this.http.get<OrganizationInvite[]>(`${this.apiUrl}organization/${uid}/invites`, this.httpOptions );
  }

  createOrganization(cmd: CreateOrganizationCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}organization`, cmd, this.httpOptions);
  }

  updateOrganization(cmd: UpdateOrganizationCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}organization`, cmd, this.httpOptions);
  }

  inviteUserToOrganization(cmd: OrganizationUserInviteCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}organization/invite`, cmd, this.httpOptions);
  }

  cancelOrganizationInvite(invitationUid: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}organization/cancelinvite?invitationUid=${encodeURIComponent(invitationUid)}`, null, this.httpOptions);
  }

  resendOrganizationInvite(invitationUid: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}organization/resendinvite?invitationUid=${encodeURIComponent(invitationUid)}`, null, this.httpOptions);
  }
}

