/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ITableRow {
    id: string;
    selected: boolean;
    icon: string | null;
    thumbnailUrl: string | null;
    thumbnailLabel: string | null;
    thumbnailLabelBgColor: string | null;
    thumbnailLabelTextColor: string | null;
    data: any;
  }
  
export class Row implements ITableRow {
    id= '';
    icon: string | null = null;
    thumbnailUrl: string | null = null;
    thumbnailLabel: string | null = null;
    thumbnailLabelBgColor = null;
    thumbnailLabelTextColor = null;
    selected = false;
    data: any;
  
    constructor(data?: Partial<ITableRow>) {
      Object.assign(this, data);
    }   
  }