// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum QueryOperator {
  Equals = "Equals",
  StartsWith = "StartsWith",
  SmallerThan = "SmallerThan",
  LargerThan = "LargerThan",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  Contains = "Contains",
  NotContains = "NotContains",
  NotEquals = "NotEquals",
  NotStartsWith = "NotStartsWith",
  InList = "InList",
  SmallerThanEqual = "SmallerThanEqual",
  LargerThanEqual = "LargerThanEqual",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace QueryOperator {
  export function toString(value: QueryOperator): string {
    return QueryOperator[value];
  }

  export const values = (): QueryOperator[] => [
    QueryOperator.Equals,
    QueryOperator.StartsWith,
    QueryOperator.SmallerThan,
    QueryOperator.LargerThan,
    QueryOperator.IsEmpty,
    QueryOperator.IsNotEmpty,
    QueryOperator.Contains,
    QueryOperator.NotContains,
    QueryOperator.NotEquals,
    QueryOperator.NotStartsWith,
    QueryOperator.InList,
    QueryOperator.SmallerThanEqual,
    QueryOperator.LargerThanEqual,
  ];

  export function parse(value: string, ignoreCase?: boolean): QueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'QueryOperator';
  }
}
