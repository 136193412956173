
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AttributeIndexingSetup } from "@struct/models/struct/app/frontendapi/models/attributeindexing/attribute-indexing-setup";
import { UidNameWrapper } from "@struct/models/struct/app/frontendapi/models/wrappers/uid-name-wrapper";

@Injectable({ providedIn: "root" })
export class SetupApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableTimeZones(): Observable<UidNameWrapper[]> {
    return this.http.get<UidNameWrapper[]>(`${this.apiUrl}setup/available-time-zones`, this.httpOptions );
  }

  getTimeZoneOffsetInMinutes(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}setup/time-zone-offset-in-minutes`, this.httpOptions );
  }

  getAttributeIndexingSetup(): Observable<AttributeIndexingSetup> {
    return this.http.get<AttributeIndexingSetup>(`${this.apiUrl}setup/attribute-indexing-setup`, this.httpOptions );
  }

  getSearchableProductAttributeDataElements(): Observable<UidNameWrapper[]> {
    return this.http.get<UidNameWrapper[]>(`${this.apiUrl}setup/searchable-product-attribute-data-elements`, this.httpOptions );
  }

  getSearchableVariantAttributeDataElements(): Observable<UidNameWrapper[]> {
    return this.http.get<UidNameWrapper[]>(`${this.apiUrl}setup/searchable-variant-attribute-data-elements`, this.httpOptions );
  }

  getSearchableVariantGroupAttributeDataElements(): Observable<UidNameWrapper[]> {
    return this.http.get<UidNameWrapper[]>(`${this.apiUrl}setup/searchable-variant-group-attribute-data-elements`, this.httpOptions );
  }

  getSearchableAttributeDataElements(): Observable<UidNameWrapper[]> {
    return this.http.get<UidNameWrapper[]>(`${this.apiUrl}setup/searchable-attribute-data-elements`, this.httpOptions );
  }

  saveAttributeIndexingSetup(setup: AttributeIndexingSetup): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}setup/attribute-indexing-setup`, setup, this.httpOptions);
  }
}

