// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DateOffsetQueryOperator {
  LargerThan = "LargerThan",
  SmallerThan = "SmallerThan",
  LargerThanOrEqualTo = "LargerThanOrEqualTo",
  SmallerThanOrEqualTo = "SmallerThanOrEqualTo",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DateOffsetQueryOperator {
  export function toString(value: DateOffsetQueryOperator): string {
    return DateOffsetQueryOperator[value];
  }

  export const values = (): DateOffsetQueryOperator[] => [
    DateOffsetQueryOperator.LargerThan,
    DateOffsetQueryOperator.SmallerThan,
    DateOffsetQueryOperator.LargerThanOrEqualTo,
    DateOffsetQueryOperator.SmallerThanOrEqualTo,
  ];

  export function parse(value: string, ignoreCase?: boolean): DateOffsetQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DateOffsetQueryOperator';
  }
}
