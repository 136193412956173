import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject, tap } from 'rxjs';
import { StructDialogService, StructNotificationService, UiModule } from '@struct/ui/index';
import { CommonModule } from '@angular/common';
import { AccountsHeaderComponent } from '../accounts-ui/accounts-header/accounts-header.component';
import { AccountsFormComponent } from '../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionComponent } from '../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormSectionTitleComponent } from '../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionDescriptionComponent } from '../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionContentComponent } from '../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormDangerZoneComponent } from '../accounts-ui/accounts-form/accounts-form-danger-zone/accounts-form-danger-zone.component';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { TimeZoneModel } from '@struct/models/accountmanagement/domain/models';
import { LanguageApiService, TimeApiService, UsersApiService } from '@struct/services/account-management';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { UpdateUserProfileCommand } from '@struct/models/struct/usermanagementmodule/commands/update-user-profile-command';
import { CultureModel } from '@struct/models/struct/accountmanagement/domain/models/culture-model';
import { ProfileModel } from '@struct/models/struct/accountmanagement/api/models/account/profile-model';
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from '@struct/ui/struct-dialog/StructDialogConfig';
import { ChangePasswordDialogData } from './change-password-dialog/change-password-dialog-data';
import { AuthenticationMethod } from '@struct/models/struct/usermanagementmodule';
import { ChangeToMicrosoftDialogComponent } from './change-to-microsoft-dialog/change-to-microsoft-dialog.component';
import { ChangeToPasswordDialogComponent } from './change-to-password-dialog/change-to-password-dialog.component';
import { ChangeToPasswordDialogData } from './change-to-password-dialog/change-to-password-dialog-data';

@Component({
  selector: 'accounts-profile',
  templateUrl: './profile.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    AccountsHeaderComponent,
    AccountsFormComponent,
    AccountsFormSectionComponent,
    AccountsFormSectionTitleComponent,
    AccountsFormSectionDescriptionComponent,
    AccountsFormSectionContentComponent,
    AccountsFormDangerZoneComponent,
  ],
})
export class ProfileComponent implements OnInit {
  @ViewChild('profileform') profileform: NgForm | null = null;
  user: ProfileModel = new ProfileModel();
  cultures: CultureModel[] = [];
  timeZones: TimeZoneModel[] = [];
  showSpinner = false;
  showError = false;
  loaded = false;
  
  authMethods = AuthenticationMethod;

  constructor(
    private languageAPIService: LanguageApiService,
    private timeAPIService: TimeApiService,
    private dialogService: StructDialogService,
    private notificationService: StructNotificationService,
    private usersApi: UsersApiService
  ) {}

  ngOnInit(): void {
    this.showSpinner = true;
    forkJoin([this.usersApi.getMyProfile(), this.languageAPIService.getCultures(), this.timeAPIService.getTimeZones()]).subscribe({
      next: ([profile, cultures, timeZones]) => {
        this.user = profile;
        this.cultures = cultures;
        this.timeZones = timeZones;
      },
      complete: () => {
        this.showSpinner = false;
        this.loaded = true;
      }
    });
  }

  changePassword() {

    const config = new StructDialogConfig({
      position: StructDialogPosition.Center,
      width: StructDialogWidth.Quarter
    });

    const data = new ChangePasswordDialogData({userId: this.user.id, email: this.user.email});
    this.dialogService.open(ChangePasswordDialogComponent, data, config).subscribe(result => {
      if(result){
        this.notificationService.showSuccessNotification(of('Password changed'));
      }
    });
  }

  signInWithMicrosoft() {
    const config = new StructDialogConfig({
      position: StructDialogPosition.Center,
      width: StructDialogWidth.Quarter
    });

    this.dialogService.open(ChangeToMicrosoftDialogComponent, {}, config).subscribe();
  }

  signInEmailAndPassword() {
    const config = new StructDialogConfig({
      position: StructDialogPosition.Center,
      width: StructDialogWidth.Quarter
    });

    const data = new ChangeToPasswordDialogData({userId: this.user.id, email: this.user.email});

    this.dialogService.open(ChangeToPasswordDialogComponent, data, config).subscribe();
  }


  public updateUser(user: ProfileModel) {
    if (this.profileform && !this.profileform.valid) {
      for (const i in this.profileform.controls) {
        this.profileform.controls[i].markAsTouched();
      }
      this.showError = true;
    } else if (this.profileform) {
      const updateUserCommand = new UpdateUserProfileCommand({
        id: user.id,
        organization: user.organization,
        firstname: user.firstName ?? '',
        lastname: user.lastName ?? '',
        phoneNumber: user.phoneNumber,
        timeZoneId: user.timezoneId,
        cultureCode: user.cultureCode
      });
      this.showSpinner = true;
      this.usersApi
        .updateUserProfile(updateUserCommand)
        .subscribe({
          complete: () => {
            this.notificationService.showSuccessNotification(of('Profile updated'));
            this.profileform?.form.markAsPristine();
          },
          error: (err: any) => {
            this.notificationService.showErrorNotification(err.error.Message);
          },
          next: () => (this.showSpinner = false),
        });
    }
  }
}
