// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnabledModules } from "@struct/models/struct/shared/subscription/enabled-modules";
import { OptionalFeatures } from "@struct/models/struct/shared/subscription/optional-features";
import { Price } from "@struct/models/struct/shared/subscription/price";
import { Quotas } from "@struct/models/struct/shared/subscription/quotas";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscriptionSetup  {
  price: Price;
  enabledModules: EnabledModules;
  optionalFeaturesOverride?: OptionalFeatures | null;
  quotasOverride?: Quotas | null;
}

export class SubscriptionSetup  implements ISubscriptionSetup {
  price: Price = new Price();

  enabledModules: EnabledModules = new EnabledModules();

  optionalFeaturesOverride: OptionalFeatures | null = null;

  quotasOverride: Quotas | null = null;


  constructor(data?: Partial<ISubscriptionSetup>) {
    Object.assign(this, data);
  }
}