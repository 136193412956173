// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DateQueryOperator {
  Equals = "Equals",
  NotEquals = "NotEquals",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  LargerThan = "LargerThan",
  SmallerThan = "SmallerThan",
  LargerThanOrEqualTo = "LargerThanOrEqualTo",
  SmallerThanOrEqualTo = "SmallerThanOrEqualTo",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DateQueryOperator {
  export function toString(value: DateQueryOperator): string {
    return DateQueryOperator[value];
  }

  export const values = (): DateQueryOperator[] => [
    DateQueryOperator.Equals,
    DateQueryOperator.NotEquals,
    DateQueryOperator.IsEmpty,
    DateQueryOperator.IsNotEmpty,
    DateQueryOperator.LargerThan,
    DateQueryOperator.SmallerThan,
    DateQueryOperator.LargerThanOrEqualTo,
    DateQueryOperator.SmallerThanOrEqualTo,
  ];

  export function parse(value: string, ignoreCase?: boolean): DateQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DateQueryOperator';
  }
}
