// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IForgotPasswordCommand  {
  email: string;
}

export class ForgotPasswordCommand  implements IForgotPasswordCommand {
  email = "";


  constructor(data?: Partial<IForgotPasswordCommand>) {
    Object.assign(this, data);
  }
}