import {BooleanOperator, DataType, DisplayType, SimpleQuery} from "@struct/models/struct/shared/search";
import {
  DateQueryOperator,
  NumberQueryOperator,
  TextQueryOperator
} from "@struct/models/struct/shared/search/datafieldqueries";

export interface ITableColumn {
  id: string;
  title: string;
  width: number | null;
  description: string | null;
  sortAsc: boolean | null;
  searchText: string | null;
  query: SimpleQuery | null;
  operator: string | null;
  type: DisplayType;
  dataType: DataType | null;
  textQueryOperator: TextQueryOperator;
  numberQueryOperator: NumberQueryOperator;
  dateQueryOperator: DateQueryOperator;
  booleanOperator: BooleanOperator;
  selected: boolean | null;
  selectedDate: Date | null;
  options: string[] | null;
  filterValues: string[] | null;
}

export class TableColumn implements ITableColumn {
  id!: string;
  title!: string;
  width: number | null = null;
  description: string | null = null;
  sortAsc: boolean | null = null;
  searchText: string | null = null;
  query: SimpleQuery | null = null;
  operator: string | null = null;
  type!: DisplayType;
  dataType: DataType | null = null;
  textQueryOperator: TextQueryOperator = TextQueryOperator.StartsWith;
  numberQueryOperator: NumberQueryOperator = NumberQueryOperator.Equals;
  dateQueryOperator: DateQueryOperator = DateQueryOperator.Equals;
  booleanOperator: BooleanOperator = BooleanOperator.Or;
  selected: boolean | null = null;
  selectedDate: Date | null = null;
  options: string[] | null = null;
  filterValues: string[] | null = null;

  constructor(data?: Partial<ITableColumn>) {
    Object.assign(this, data);
  }
}
