<struct-app-content-container *ngIf="loaded">
  <accounts-header *ngIf="mode === 'change'">{{'ChangeToPassword.Title' | translate}} </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea [noBottomPadding]="true">
      <ng-container *ngIf="working">
        <div class="absolute left-0 top-0 w-full h-full bg-white z-40 opacity-50"></div>
      </ng-container>
      <form #changePasswordForm="ngForm">
        <accounts-form>
          <accounts-form-content>
            <struct-form-layout>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline>{{'ChangePassword.NewPassword' | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{'ChangePassword.NewPasswordDescription' | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="6">
                    <struct-input [name]="'New password'" [required]="true" [(value)]="newPassword" inputType="password"></struct-input>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline>{{'ChangePassword.VerifyPassword' | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{'ChangePassword.VerifyPasswordDescription' | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="6">
                    <struct-input [name]="'Verify new password'" [required]="true" [(value)]="verifyNewPassword" inputType="password"></struct-input>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>
            </struct-form-layout>
          </accounts-form-content>
        </accounts-form>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 text-sm">{{errorMsg | translate}}</div>
    <struct-button buttonType="button" *ngIf="!working" (click)="cancel()" color="default">{{'General.Cancel' | translate}}</struct-button>
    <struct-button buttonType="button" [working]="working" (click)="changeToEmailAndPassword()" color="primary" [disabled]="(changePasswordForm !== undefined && !changePasswordForm.valid)">{{'ChangeToPassword.UsePasswordToSignIn' | translate}}</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>