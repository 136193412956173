<struct-app-content-container>
  <accounts-header>Invite new admin user</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea [noBottomPadding]="true">
    <form #inviteForm="ngForm">
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Email
          </struct-form-row-headline>
          <struct-form-row-description>
            Email description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="12">
              <struct-input [name]="'Email'" [inputType]="'email'" [required]="true" [(value)]="command.userEmail"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Message
          </struct-form-row-headline>
          <struct-form-row-description>
            Message description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="12">
              <struct-textarea [name]="'Message'" [(value)]="command.message"></struct-textarea>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <!-- <div *ngIf="showError" class="text-red-400 font-semibold mb-3">Error</div> -->
    <struct-button color="default" (click)="cancel()">Cancel</struct-button>
    <struct-button color="primary" [disabled]="inviteForm.invalid ?? true" (click)="sendInvite()">Send invite</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
