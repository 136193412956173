<div class="struct-table relative flex flex-col flex-1" id="struct-sort-table-component">

    <div class="struct-table-inner-content overflow-hidden flex flex-col">
        <div class="struct-header-row bg-white grow-0" role="row">
            <div class="struct-cell pl-3 py-1 flex-shrink-0 flex-grow-0 w-12" role="gridcell">
                
            </div>
            <div class="struct-header-cell px-4 py-3 relative flex-1 struct-column-{{ column.id }} bg-white flex items-center" *ngFor="let column of columns; let i = index">
                <span class="text-base font-medium">{{column.title}}</span>

                <struct-icon-button [useMaterialIcons]="true" *ngIf="sortField !== column.id" class="text-gray-400" icon="unfold_more" (click)="sortBy(column)"></struct-icon-button>
                <struct-icon-button [useMaterialIcons]="true" *ngIf="sortField === column.id && sortAsc === true" icon="keyboard_arrow_up" (click)="sortBy(column)"></struct-icon-button>
                <struct-icon-button [useMaterialIcons]="true" *ngIf="sortField === column.id && sortAsc === false" icon="keyboard_arrow_down" (click)="sortBy(column)"></struct-icon-button>
            </div>
        </div>
        <div cdkDropList cdkDropListOrientation="vertical" cdkDropListLockAxis="y" (cdkDropListDropped)="drop($event)">
            <div cdkDrag class="struct-row" role="row" *ngFor="let row of rows; trackBy: trackById">
                <div class="struct-cell pl-3 py-1 flex-shrink-0 flex-grow-0 w-12" role="gridcell">
                    <struct-icon [useMaterialIcons]="true" icon="drag_indicator" class="text-gray-500 pl-2 cursor-ns-resize" size="16"></struct-icon>
                </div>

                <div class="struct-cell px-4 py-2  flex-1 struct-column-{{ column.id }} overflow-hidden flex-grow" [ngStyle]="{ minHeight: '43px', width: '100px' }" role="gridcell" *ngFor="let column of columns">
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Text">
                        <struct-text-column [value]="getValue(row, column.id)"></struct-text-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Number">
                        <struct-number-column [value]="getValue(row, column.id)"></struct-number-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Percentage">
                        <struct-percentage-column [value]="getValue(row, column.id)"></struct-percentage-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.FixedList">
                        <struct-fixedlist-column [value]="getValue(row, column.id)"></struct-fixedlist-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Media">
                        <struct-media-column [value]="getValue(row, column.id)"></struct-media-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Boolean">
                        <struct-boolean-column [value]="getValue(row, column.id)"></struct-boolean-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Date || column.type === DisplayType.DateTime">
                        <struct-date-column [displayType]="column.type" [value]="getValue(row, column.id)"></struct-date-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Culture">
                        <struct-culture-column [value]="getValue(row, column.id)"></struct-culture-column>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>