
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddProductsToCategoriesCommand } from "@struct/models/struct/app/domain/products/commands/add-products-to-categories-command";
import { MoveProductsToCategoriesCommand } from "@struct/models/struct/app/domain/products/commands/move-products-to-categories-command";
import { RemoveProductsFromCategoriesCommand } from "@struct/models/struct/app/domain/products/commands/remove-products-from-categories-command";
import { SetPrimaryCategoryForProductsCommand } from "@struct/models/struct/app/domain/products/commands/set-primary-category-for-products-command";
import { CatalogueTreeModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/catalogue-tree-model";
import { CategoryPathModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/category-path-model";
import { CategorySearchResultItemModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/category-search-result-item-model";
import { CategoryTreeModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/category-tree-model";
import { ProductCatalogueSearchResultModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/product-catalogue-search-result-model";
import { ProductTreeModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/product-tree-model";
import { VariantGroupTreeModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/variant-group-tree-model";
import { VariantTreeModel } from "@struct/models/struct/app/frontendapi/models/productcatalogues/variant-tree-model";

@Injectable({ providedIn: "root" })
export class ProductCataloguesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  search(searchTerm: string, languageId: number): Observable<ProductCatalogueSearchResultModel> {
    return this.http.get<ProductCatalogueSearchResultModel>(`${this.apiUrl}productcatalogues/search?searchTerm=${encodeURIComponent(searchTerm)}&languageId=${languageId}`, this.httpOptions );
  }

  searchCategories(catalogueUid: string, searchTerm: string, languageId: number): Observable<CategorySearchResultItemModel[]> {
    return this.http.get<CategorySearchResultItemModel[]>(`${this.apiUrl}productcatalogues/search/categories?catalogueUid=${catalogueUid}&searchTerm=${encodeURIComponent(searchTerm)}&languageId=${languageId}`, this.httpOptions );
  }

  getCategories(categoryIds: number[], languageId: number): Observable<CategoryTreeModel[]> {
    return this.http.post<CategoryTreeModel[]>(`${this.apiUrl}productcatalogues/categories/batch?languageId=${languageId}`, categoryIds, this.httpOptions);
  }

  getCatalogues(): Observable<CatalogueTreeModel[]> {
    return this.http.get<CatalogueTreeModel[]>(`${this.apiUrl}productcatalogues/catalogues`, this.httpOptions );
  }

  getCatalougeChildren(uid: string, languageId: number): Observable<CategoryTreeModel[]> {
    return this.http.get<CategoryTreeModel[]>(`${this.apiUrl}productcatalogues/${uid}/children?languageId=${languageId}`, this.httpOptions );
  }

  getCategoryChildren(id: number, languageId: number): Observable<CategoryTreeModel[]> {
    return this.http.get<CategoryTreeModel[]>(`${this.apiUrl}productcatalogues/categories/${id}/children?languageId=${languageId}`, this.httpOptions );
  }

  getCategoryProducts(id: number, languageId: number): Observable<ProductTreeModel[]> {
    return this.http.get<ProductTreeModel[]>(`${this.apiUrl}productcatalogues/categories/${id}/products?languageId=${languageId}`, this.httpOptions );
  }

  getCategoryVariantGroups(id: number, languageId: number): Observable<VariantGroupTreeModel[]> {
    return this.http.get<VariantGroupTreeModel[]>(`${this.apiUrl}productcatalogues/categories/${id}/variantgroups?languageId=${languageId}`, this.httpOptions );
  }

  getProductVariantGroups(id: number, languageId: number): Observable<VariantGroupTreeModel[]> {
    return this.http.get<VariantGroupTreeModel[]>(`${this.apiUrl}productcatalogues/products/${id}/variantgroups?languageId=${languageId}`, this.httpOptions );
  }

  getCategoryPath(categoryId: number): Observable<CategoryPathModel> {
    return this.http.get<CategoryPathModel>(`${this.apiUrl}productcatalogues/categories/${categoryId}/path`, this.httpOptions );
  }

  getProductVariants(id: number, languageId: number): Observable<VariantTreeModel[]> {
    return this.http.get<VariantTreeModel[]>(`${this.apiUrl}productcatalogues/products/${id}/variants?languageId=${languageId}`, this.httpOptions );
  }

  getVariantGroupVariants(id: number, languageId: number): Observable<VariantTreeModel[]> {
    return this.http.get<VariantTreeModel[]>(`${this.apiUrl}productcatalogues/variantgroups/${id}/variants?languageId=${languageId}`, this.httpOptions );
  }

  getNumberOfUnclassifiedProducts(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}productcatalogues/numunclassifiedproducts`, this.httpOptions );
  }

  addProductsToCategories(model: AddProductsToCategoriesCommand): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}productcatalogues/addproductstocategories`, model, this.httpOptions);
  }

  moveProductsToCategories(model: MoveProductsToCategoriesCommand): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}productcatalogues/moveproductstocategories`, model, this.httpOptions);
  }

  removeProductsFromCategories(model: RemoveProductsFromCategoriesCommand): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}productcatalogues/removeproductsfromcategories`, model, this.httpOptions);
  }

  setPrimaryCategoryForProducts(model: SetPrimaryCategoryForProductsCommand): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}productcatalogues/setprimarycategoryforproducts`, model, this.httpOptions);
  }
}

