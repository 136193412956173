import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { UserModel } from '@struct/models/struct/authenticationmodule/models/user-model';

@Injectable({
  providedIn: 'root',
})
export class AdminRoleGuard  {

  constructor(private router: Router, private authService: AuthenticationService) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.getAuthenticatedUser().pipe(
      switchMap((user: UserModel | null) => {
        if (user === null) {
          this.router.navigate(['/unauthorized']);
          return of(false);
        }
        if (user.role !== "Admin") {
          this.router.navigate(['/unauthorized']);
          return of(false);
        }
        return of(true);
      })
    );
  }
}
