import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-fixedlist-column',
  templateUrl: './struct-fixedlist-column.component.html',
  styleUrls: ['./struct-fixedlist-column.component.scss'],
})
export class StructFixedlistColumnComponent {
  @Input() value? : string | string[];

  isArray() : boolean {
    if(this.value === null)
    {
      return false;
    }

    return Array.isArray(this.value);
  }

  getValueArray() : string[]
  {
    if(this.value ===null){
      return [];
    }
    return <string[]>this.value;
  }
}
