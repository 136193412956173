// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum QueryType {
  Text = "Text",
  Boolean = "Boolean",
  Number = "Number",
  Percentage = "Percentage",
  Date = "Date",
  GlobalList = "GlobalList",
  Category = "Category",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace QueryType {
  export function toString(value: QueryType): string {
    return QueryType[value];
  }

  export const values = (): QueryType[] => [
    QueryType.Text,
    QueryType.Boolean,
    QueryType.Number,
    QueryType.Percentage,
    QueryType.Date,
    QueryType.GlobalList,
    QueryType.Category,
  ];

  export function parse(value: string, ignoreCase?: boolean): QueryType | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'QueryType';
  }
}
