// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DataType {
  String = "String",
  Decimal = "Decimal",
  Integer = "Integer",
  DateTimeOffset = "DateTimeOffset",
  Date = "Date",
  Guid = "Guid",
  Bool = "Bool",
  Enum = "Enum",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DataType {
  export function toString(value: DataType): string {
    return DataType[value];
  }

  export const values = (): DataType[] => [
    DataType.String,
    DataType.Decimal,
    DataType.Integer,
    DataType.DateTimeOffset,
    DataType.Date,
    DataType.Guid,
    DataType.Bool,
    DataType.Enum,
  ];

  export function parse(value: string, ignoreCase?: boolean): DataType | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DataType';
  }
}
