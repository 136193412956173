import { CommonModule, JsonPipe } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { A11yModule } from '@angular/cdk/a11y';
import { AppComponent } from './app.component';
import { SysadminNavigationComponent } from './navigation/sysadmin-navigation/sysadmin-navigation.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UiModule } from '@struct/ui/index';
import { routing } from './app.routes';
import { UserRoleGuard } from '@struct/auth/guards/user.guard';
import { ApiErrorHandler } from '@struct/ui/struct-notification/interceptors/apiErrorHandler.interceptor';
import { XcrfInterceptor } from '@struct/auth/interceptor/xcrf.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationApiService } from '@struct/services/account-management';
import { appInitializer } from './appInitializer';
import { AuthenticatedGuard } from './authentication/guards/authenticated.guard';
import { UnauthorizedErrorHandler } from './authentication/unauthorized.interceptor';
import { MonitoringService } from './monitorService';
import { ErrorHandlerService } from './error-handling-service';
import { UserNavigationComponent } from './navigation/user-navigation/user-navigation.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, SysadminNavigationComponent, UserNavigationComponent],
  imports: [UiModule, A11yModule, FormsModule, ReactiveFormsModule, CommonModule, HttpClientModule, BrowserAnimationsModule, BrowserModule, DragDropModule, routing, HttpClientXsrfModule.withOptions({
    cookieName: 'XSRF-TOKEN',
    headerName: 'X-XSRF-TOKEN'
  })],
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      })
    ),
    AuthenticatedGuard,
    MonitoringService,
    UserRoleGuard,
    JsonPipe,
    { provide: HTTP_INTERCEPTORS, useClass: XcrfInterceptor, multi: true, },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationApiService]},
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedErrorHandler, multi: true },
    { provide: 'accountManagementApiUrl', useValue: environment.accountManagementApiUrl },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent
  ],
})
export class AppModule {}
