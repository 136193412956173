<ng-container *ngIf="loaded">
  <!--Invalid invite-->
  <div *ngIf="inviteIsInvalid" class="w-full h-screen flex items-center justify-center">
    <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 text-center" [innerHTML]="'InviteUser.InviteIsInvalid' | translate"></h1>
  </div>

  <!--Invite expired-->
  <div *ngIf="inviteIsExpired" class="w-full h-screen flex items-center justify-center">
    <div class="flex flex-col gap-2 max-w-lg text-center">
      <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 text-center" [innerHTML]="'InviteUser.InviteIsExpired' | translate"></h1>
      <h2 class="text-lg mb-8" [innerHTML]="'InviteUser.InviteIsExpiredDescription' | translate:{invitedBy: invite?.invite?.invitedBy}"></h2>
    </div>
  </div>

  <!--Invite already accepted-->
  <div *ngIf="inviteIsAlreadyAccepted" class="w-full h-screen flex items-center justify-center">
    <div class="flex flex-col gap-2 max-w-lg text-center">
      <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 text-center" [innerHTML]="'InviteUser.InviteIsAlreadyAccepted' | translate"></h1>
    </div>
  </div>

  <!--Invite already declined-->
  <ng-container *ngIf="inviteIsAlreadyDeclined" class="w-full h-screen flex items-center justify-center">
    <div class="flex flex-col gap-2 max-w-lg text-center">
      <h1 class="text-xl lg:text-2xl xl:text-3xl font-medium mb-8 text-center" [innerHTML]="'InviteUser.InviteIsAlreadyDeclined' | translate"></h1>
    </div>
  </ng-container>

  <!--User can accept or decline invite-->
  <ng-container *ngIf="inviteIsForExistingUser && !inviteIsInvalid && !inviteIsExpired && !inviteIsAlreadyAccepted && !inviteIsAlreadyDeclined">
    <accounts-accept-existing-user-invite [invite]="invite!.invite!"></accounts-accept-existing-user-invite>
  </ng-container>

  <!--Invite not accepted or declined and send to a new user-->
  <ng-container *ngIf="inviteIsForNewUser">
    <accounts-register-user [invite]="invite!.invite!"></accounts-register-user>
  </ng-container>
</ng-container>
