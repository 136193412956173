<div class="h-full w-full flex items-center" *ngIf="loading">
  <div class="w-full">
    <struct-spinner></struct-spinner>
  </div>
</div>

<div *ngIf="!loading && showError" class="h-full w-full flex items-center justify-center">
  <div class="px-20 py-20 bg-gray-100 w-[500px]">
    <div class="font-bold text-md flex">
      <struct-icon icon="error" class="mr-2 text-red-700" [useMaterialIcons]="true"></struct-icon>
      <span class="leading-8">
        {{ 'Login.AnErrorOccurred' | translate }}
      </span>
    </div>

    <div class="ml-9">
      {{ 'Login.' + errorMsg | translate }}
    </div>
  </div>
</div>
