<struct-app-content-container>

  <accounts-header [goBackToRoute]="'/organizations'" [headerButtonText]="'Invite user'" (headerButtonClicked)="inviteUser()">Edit organization</accounts-header>

  <struct-app-content>
    <struct-app-content-workarea>
      <form #organizationForm="ngForm">

          <accounts-form>
              <accounts-form-section>
                <accounts-form-section-title> Organization Details </accounts-form-section-title>
                <accounts-form-section-description> Edit organization details </accounts-form-section-description>
                <accounts-form-section-content>
                  <struct-input [name]="'Organization Name'" [(value)]="organization.name" [required]="true"></struct-input>
                </accounts-form-section-content>

              </accounts-form-section>


              <accounts-form-section>
                <accounts-form-section-title> Users  </accounts-form-section-title>
                <accounts-form-section-description> Users that has access to this organization </accounts-form-section-description>
                <accounts-form-section-content>
                  <struct-table *ngIf="tableDefinitionUsers !== null"
                  [tableDefinition]="tableDefinitionUsers"
                  [defaultPageSize]="10"
                  [pageSizeOptions]="[10, 25, 50, 100]"
                  [showFirstLastPageButton]="false"
                  [showPageSizeSelector]="true"
                  [defaultColumnIds]="['firstname','lastname', 'email','role']"
                  >
                  <struct-selected-items-actions>
                  </struct-selected-items-actions>
                  </struct-table>
                </accounts-form-section-content>
              </accounts-form-section>

              <accounts-form-section>
                <accounts-form-section-title> Invitations  </accounts-form-section-title>
                <accounts-form-section-description> Users that has invited to this organization </accounts-form-section-description>
                <accounts-form-section-content>
                  <struct-table *ngIf="tableDefinitionInvites !== null"
                  [tableDefinition]="tableDefinitionInvites"
                  [defaultPageSize]="10"
                  [pageSizeOptions]="[10, 25, 50, 100]"
                  [showFirstLastPageButton]="false"
                  [showPageSizeSelector]="true"
                  [defaultColumnIds]="['email','role']"
                  >
                  <struct-selected-items-actions>
                  </struct-selected-items-actions>
                  </struct-table>
                </accounts-form-section-content>
              </accounts-form-section>

              <accounts-form-section>
                <accounts-form-section-title>Database Templates</accounts-form-section-title>
                <accounts-form-section-description> Database templates saved under this organization </accounts-form-section-description>
                <accounts-form-section-content>
                  <struct-table *ngIf="tableDefinitionDatabase !== null"
                  [tableDefinition]="tableDefinitionDatabase"
                  [defaultPageSize]="10"
                  [pageSizeOptions]="[10, 25, 50, 100]"
                  [showFirstLastPageButton]="false"
                  [showPageSizeSelector]="true"
                  [defaultColumnIds]="['uid','name','description']"
                  >
                  <struct-selected-items-actions>
                  </struct-selected-items-actions>
                  </struct-table>
                </accounts-form-section-content>
              </accounts-form-section>

            </accounts-form>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button buttonType="submit" (click)="submitOrganization()" color="primary">Update subscription</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
