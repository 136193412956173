
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommitApprovedItemChangeRequestsCommand } from "@struct/models/struct/app/domain/datasupplier/commands/commit-approved-item-change-requests-command";
import { CommitApprovedSuggestedProductRequestCommand } from "@struct/models/struct/app/domain/datasupplier/commands/commit-approved-suggested-product-request-command";
import { CreateCommentCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-comment-command";
import { CreateDataSupplierAttributeTemplateCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-data-supplier-attribute-template-command";
import { CreateDataSupplierCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-data-supplier-command";
import { CreateDataSupplierLoginCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-data-supplier-login-command";
import { CreateDataSupplierSuggestionWizardCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-data-supplier-suggestion-wizard-command";
import { CreateMessageCommand } from "@struct/models/struct/app/domain/datasupplier/commands/create-message-command";
import { DeclineImportRequestCommand } from "@struct/models/struct/app/domain/datasupplier/commands/decline-import-request-command";
import { UpdateDataSupplierAttributeTemplateCommand } from "@struct/models/struct/app/domain/datasupplier/commands/update-data-supplier-attribute-template-command";
import { UpdateDataSupplierCommand } from "@struct/models/struct/app/domain/datasupplier/commands/update-data-supplier-command";
import { UpdateDataSupplierLoginCommand } from "@struct/models/struct/app/domain/datasupplier/commands/update-data-supplier-login-command";
import { UpdateDataSupplierSuggestionWizardCommand } from "@struct/models/struct/app/domain/datasupplier/commands/update-data-supplier-suggestion-wizard-command";
import { DataSupplier } from "@struct/models/struct/app/domain/datasupplier/data-supplier";
import { DataSupplierAttributeTemplate } from "@struct/models/struct/app/domain/datasupplier/data-supplier-attribute-template";
import { DataSupplierLogin } from "@struct/models/struct/app/domain/datasupplier/data-supplier-login";
import { DataSupplierMessageModel } from "@struct/models/struct/app/domain/datasupplier/data-supplier-message-model";
import { DataSupplierReference } from "@struct/models/struct/app/domain/datasupplier/data-supplier-reference";
import { ImportRequest } from "@struct/models/struct/app/domain/datasupplier/import-request";
import { ItemChangeReport } from "@struct/models/struct/app/domain/datasupplier/item-change-report";
import { MessageComment } from "@struct/models/struct/app/domain/datasupplier/message-comment";
import { MessageThread } from "@struct/models/struct/app/domain/datasupplier/message-thread";
import { Notification } from "@struct/models/struct/app/domain/datasupplier/notification";
import { TextMessage } from "@struct/models/struct/app/domain/datasupplier/text-message";
import { DataSupplierSuggestedProductModel } from "@struct/models/struct/app/frontendapi/models/datasupplier/data-supplier-suggested-product-model";
import { DataSupplierSuggestedVariantModel } from "@struct/models/struct/app/frontendapi/models/datasupplier/data-supplier-suggested-variant-model";
import { DataSupplierSuggestionWizardModel } from "@struct/models/struct/app/frontendapi/models/datasupplier/data-supplier-suggestion-wizard-model";
import { ProductAttribute } from "@struct/models/struct/app/frontendapi/models/datasupplier/product-attribute";
import { VariantAttribute } from "@struct/models/struct/app/frontendapi/models/datasupplier/variant-attribute";
import { ImportRequestCloseModel } from "@struct/models/struct/app/frontendapi/models/import/import-request-close-model";
import { ImportRequestCommitModel } from "@struct/models/struct/app/frontendapi/models/import/import-request-commit-model";

@Injectable({ providedIn: "root" })
export class DataSupplierApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getDataSupplier(uid: string): Observable<DataSupplier> {
    return this.http.get<DataSupplier>(`${this.apiUrl}datasuppliers/${uid}`, this.httpOptions );
  }

  createDataSupplier(cmd: CreateDataSupplierCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier`, cmd, this.httpOptions);
  }

  updateDataSupplier(cmd: UpdateDataSupplierCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}datasupplier`, cmd, this.httpOptions);
  }

  deleteDataSupplier(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}datasupplier/${uid}`, this.httpOptions );
  }

  getDataSupplierLogins(dataSupplierUid: string): Observable<DataSupplierLogin[]> {
    return this.http.get<DataSupplierLogin[]>(`${this.apiUrl}datasupplier/${dataSupplierUid}/logins`, this.httpOptions );
  }

  getDataSupplierLogin(dataSupplierUid: string, loginUid: string): Observable<DataSupplierLogin> {
    return this.http.get<DataSupplierLogin>(`${this.apiUrl}datasupplier/${dataSupplierUid}/logins/${loginUid}`, this.httpOptions );
  }

  createDataSupplierLogin(cmd: CreateDataSupplierLoginCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/login`, cmd, this.httpOptions);
  }

  updateDataSupplierLogin(cmd: UpdateDataSupplierLoginCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}datasupplier/login`, cmd, this.httpOptions);
  }

  deleteDataSupplierLogin(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}datasupplier/login/${uid}`, this.httpOptions );
  }

  resetDataSupplierLoginPassword(loginUid: string): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}datasupplier/login/resetpassword/${loginUid}`, null, this.httpOptions);
  }

  getProductAttributes(): Observable<ProductAttribute[]> {
    return this.http.get<ProductAttribute[]>(`${this.apiUrl}datasupplier/product-attributes`, this.httpOptions );
  }

  getVariantAttributes(): Observable<VariantAttribute[]> {
    return this.http.get<VariantAttribute[]>(`${this.apiUrl}datasupplier/variant-attributes`, this.httpOptions );
  }

  getDataSupplierAttributeTemplates(): Observable<DataSupplierAttributeTemplate[]> {
    return this.http.get<DataSupplierAttributeTemplate[]>(`${this.apiUrl}datasupplier/attribute-templates`, this.httpOptions );
  }

  getDataSupplierAttributeTemplate(uid: string): Observable<DataSupplierAttributeTemplate> {
    return this.http.get<DataSupplierAttributeTemplate>(`${this.apiUrl}datasupplier/attribute-template/${uid}`, this.httpOptions );
  }

  createDataSupplierAttributeTemplate(cmd: CreateDataSupplierAttributeTemplateCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/attribute-template`, cmd, this.httpOptions);
  }

  updateDataSupplierAttributeTemplate(cmd: UpdateDataSupplierAttributeTemplateCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}datasupplier/attribute-template`, cmd, this.httpOptions);
  }

  deleteDataSupplierAttributeTemplate(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}datasupplier/attribute-template/${uid}`, this.httpOptions );
  }

  getDataSupplierSuggestionWizards(): Observable<DataSupplierSuggestionWizardModel[]> {
    return this.http.get<DataSupplierSuggestionWizardModel[]>(`${this.apiUrl}datasupplier/suggestion-wizards`, this.httpOptions );
  }

  getDataSupplierSuggestionWizard(uid: string): Observable<DataSupplierSuggestionWizardModel> {
    return this.http.get<DataSupplierSuggestionWizardModel>(`${this.apiUrl}datasupplier/suggestion-wizard/${uid}`, this.httpOptions );
  }

  createDataSupplierSuggestionWizard(cmd: CreateDataSupplierSuggestionWizardCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/suggestion-wizard`, cmd, this.httpOptions);
  }

  updateDataSupplierSuggestionWizard(cmd: UpdateDataSupplierSuggestionWizardCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}datasupplier/suggestion-wizard`, cmd, this.httpOptions);
  }

  deleteDataSupplierSuggestionWizard(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}datasupplier/suggestion-wizard/${uid}`, this.httpOptions );
  }

  getSortedDataSuppliersWithUnreadMessageCount(): Observable<DataSupplierMessageModel[]> {
    return this.http.get<DataSupplierMessageModel[]>(`${this.apiUrl}datasupplier/messages/unread`, this.httpOptions );
  }

  getDataSupplierReferences(): Observable<DataSupplierReference[]> {
    return this.http.get<DataSupplierReference[]>(`${this.apiUrl}datasupplier/references`, this.httpOptions );
  }

  getEntityImportRequestChanges(uid: string): Observable<ItemChangeReport> {
    return this.http.get<ItemChangeReport>(`${this.apiUrl}datasupplier/import-request-changes/${uid}`, this.httpOptions );
  }

  getUnprocessedImportRequests(): Observable<ImportRequest[]> {
    return this.http.get<ImportRequest[]>(`${this.apiUrl}datasupplier/unprocessed-import-requests`, this.httpOptions );
  }

  getImportRequest(uid: string): Observable<ImportRequest | null> {
    return this.http.get<ImportRequest | null>(`${this.apiUrl}datasupplier/unprocessed-import-requests/${uid}`, this.httpOptions );
  }

  getDataSupplierSuggestedProduct(uid: string): Observable<DataSupplierSuggestedProductModel> {
    return this.http.get<DataSupplierSuggestedProductModel>(`${this.apiUrl}datasupplier/suggested-products/${uid}`, this.httpOptions );
  }

  getDataSupplierSuggestedVariant(uid: string): Observable<DataSupplierSuggestedVariantModel> {
    return this.http.get<DataSupplierSuggestedVariantModel>(`${this.apiUrl}datasupplier/suggested-variants/${uid}`, this.httpOptions );
  }

  getImportRequestByMessageUid(uid: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}datasupplier/import-requests/${uid}`, this.httpOptions );
  }

  declineImportRequest(command: DeclineImportRequestCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/import-requests/decline`, command, this.httpOptions);
  }

  commitApprovedItemChangeRequests(command: CommitApprovedItemChangeRequestsCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/change-requests/approve`, command, this.httpOptions);
  }

  getLatestNotificationsForPIMManager(numToFetch: number): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${this.apiUrl}datasupplier/notifcations?numToFetch=${numToFetch}`, this.httpOptions );
  }

  getDataSupplierMessages(dataSupplierUid: string, pageSize: number, pageZeroIndexed: number): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(`${this.apiUrl}datasupplier/messages/${dataSupplierUid}?pageSize=${pageSize}&pageZeroIndexed=${pageZeroIndexed}`, this.httpOptions );
  }

  getDataSupplierMessageThreads(dataSupplierUid: string, pageSize: number, pageZeroIndexed: number): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(`${this.apiUrl}datasupplier/message-threads/${dataSupplierUid}?pageSize=${pageSize}&pageZeroIndexed=${pageZeroIndexed}`, this.httpOptions );
  }

  getDataSupplierImportRequestsMessageThreads(dataSupplierUid: string, pageSize: number, pageZeroIndexed: number): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(`${this.apiUrl}datasupplier/import-requests-message-threads/${dataSupplierUid}?pageSize=${pageSize}&pageZeroIndexed=${pageZeroIndexed}`, this.httpOptions );
  }

  getMessageThread(messageUid: string): Observable<MessageThread> {
    return this.http.get<MessageThread>(`${this.apiUrl}datasupplier/message-thread/${messageUid}`, this.httpOptions );
  }

  writeComment(command: CreateCommentCommand): Observable<MessageComment> {
    return this.http.post<MessageComment>(`${this.apiUrl}datasupplier/message/comment`, command, this.httpOptions);
  }

  writeMessage(command: CreateMessageCommand): Observable<TextMessage> {
    return this.http.post<TextMessage>(`${this.apiUrl}datasupplier/message`, command, this.httpOptions);
  }

  markMessageAsRead(messageUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/message/mark-as-read`, messageUid, this.httpOptions);
  }

  markCommentAsRead(commentUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/comment/mark-as-read`, commentUid, this.httpOptions);
  }

  markMessageAsUnread(messageUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/comment/mark-as-unread`, messageUid, this.httpOptions);
  }

  importFileFromImportRequest(model: ImportRequestCommitModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/import-requests/import`, model, this.httpOptions);
  }

  closeImportRequest(model: ImportRequestCloseModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/import-requests/close`, model, this.httpOptions);
  }

  commitApprovedSuggestedProductRequest(cmd: CommitApprovedSuggestedProductRequestCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/suggested-products/approve`, cmd, this.httpOptions);
  }

  commitApprovedSuggestedVariantRequest(cmd: CommitApprovedSuggestedProductRequestCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}datasupplier/suggested-variants/approve`, cmd, this.httpOptions);
  }
}

