// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataType } from "@struct/models/struct/shared/search/data-type";
import { DisplayType } from "@struct/models/struct/shared/search/display-type";
import { QueryOperator } from "@struct/models/struct/shared/search/query-operator";
import { QueryType } from "@struct/models/struct/shared/search/query-type";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDataField  {
  uid: string;
  name: string;
  dataFieldType: DataType;
  displayType: DisplayType;
  queryType: QueryType;
  supportedQueryOperators: QueryOperator[];
  options?: string[] | null;
  isMultiValue: boolean;
  supportsSorting: boolean;
  selectable: boolean;
  formatPattern: string;
  isAggregateValue: boolean;
  isReferenceValue: boolean;
}

export class DataField  implements IDataField {
  uid = "";

  name = "";

  dataFieldType: DataType = DataType.String;

  displayType: DisplayType = DisplayType.Text;

  queryType: QueryType = QueryType.Text;

  supportedQueryOperators: QueryOperator[] = [];

  options: string[] | null = null;

  isMultiValue = false;

  supportsSorting = false;

  selectable = false;

  formatPattern = "";

  isAggregateValue = false;

  isReferenceValue = false;


  constructor(data?: Partial<IDataField>) {
    Object.assign(this, data);
  }
}