<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/subscription-plans'">New subscription plan</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #subscriptionPlanForm="ngForm">

        <accounts-subscription-plan-details #subscriptionPlanDetails [subscriptionPlan]="subscriptionPlan"></accounts-subscription-plan-details>

      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button color="primary" (click)="submitSubscriptionPlan()">
      Create subscription plan
    </struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
