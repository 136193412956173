<accounts-header [goBackToRoute]="'/tenants'">
  Create tenant database template
</accounts-header>
  
  <form #tenantform="ngForm">
  
    <accounts-form>
      <accounts-form-section *ngIf="false">
        <accounts-form-section-title> Tenant status </accounts-form-section-title>
        <accounts-form-section-description> Manage tenant status </accounts-form-section-description>
        <accounts-form-section-content>
          <!-- <struct-simple-select name="Tenant status" [(ngModel)]="tenant.status" bindValue="key" bindLabel="value" [required]="true" placeholder="Tenant status" [options]="tenantStatuses"> </struct-simple-select> -->
        </accounts-form-section-content>
      </accounts-form-section>
      <accounts-form-section>
        <accounts-form-section-title> Database template details </accounts-form-section-title>
        <accounts-form-section-description> Add database template details </accounts-form-section-description>
        <accounts-form-section-content>
          <struct-input [name]="'Name'" [(value)]="name" [required]="true"></struct-input>
          <struct-input [name]="'Description'" [(value)]="description" [required]="true"></struct-input>
        </accounts-form-section-content>
      </accounts-form-section>
    </accounts-form>
     
    <div class="px-0 py-0 text-right mt-10">
      <div *ngIf="showError" class="text-red-400 font-semibold mb-3">One or more fields are not correctly filled</div>
      <struct-button buttonType="submit" (click)="cloneTenant()" color="primary">Clone tenant</struct-button>
    </div>
  </form>
  