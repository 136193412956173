
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateWorkflowCommand } from "@struct/models/struct/app/domain/workflows/commands/create-workflow-command";
import { UpdateEntityWorkflowStateCommand } from "@struct/models/struct/app/domain/workflows/commands/update-entity-workflow-state-command";
import { UpdateWorkflowCommand } from "@struct/models/struct/app/domain/workflows/commands/update-workflow-command";
import { WorkflowReference } from "@struct/models/struct/app/domain/workflows/workflow-reference";
import { WorkflowStateModel } from "@struct/models/struct/app/domain/workflows/workflow-state-model";
import { WorkflowSearchModel } from "@struct/models/struct/app/frontendapi/models/workflow/workflow-search-model";
import { WorkflowStateReference } from "@struct/models/struct/app/frontendapi/models/workflow/workflow-state-reference";

@Injectable({ providedIn: "root" })
export class WorkflowsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getWorkflows(): Observable<WorkflowSearchModel[]> {
    return this.http.get<WorkflowSearchModel[]>(`${this.apiUrl}workflows`, this.httpOptions );
  }

  getWorkflow(uid: string): Observable<WorkflowReference> {
    return this.http.get<WorkflowReference>(`${this.apiUrl}workflows/${uid}`, this.httpOptions );
  }

  createWorkflow(cmd: CreateWorkflowCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}workflows`, cmd, this.httpOptions);
  }

  updateWorkflow(cmd: UpdateWorkflowCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}workflows`, cmd, this.httpOptions);
  }

  deleteWorkflow(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}workflows/${uid}`, this.httpOptions );
  }

  getWorkflowStateReferences(): Observable<WorkflowStateReference[]> {
    return this.http.get<WorkflowStateReference[]>(`${this.apiUrl}workflowstatereferences`, this.httpOptions );
  }

  updateEntityWorkflowState(cmd: UpdateEntityWorkflowStateCommand): Observable<WorkflowStateModel> {
    return this.http.patch<WorkflowStateModel>(`${this.apiUrl}workflows/entityworkflowstate`, cmd, this.httpOptions);
  }
}

