
<mat-dialog-content class="h-full">
<section class="h-full flex items-center justify-center p-0 lg:p-4 xl:p-8" *ngIf="currentView === 'intro'">
  <div class="overflow-hidden h-full bg-gray-100 sm:grid sm:grid-cols-4 sm:gap-px">

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="copy" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('copy-product')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Copy product
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="quick_actions" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('change-product-structure')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Change product structure
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="archive" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('archive-product')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Archive product
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="move_down" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer"  (click)="changeView('move-product')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Move product
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-red-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-red-50 group-hover:text-red-600 ring-4 ring-white">
          <struct-icon icon="delete" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer"  (click)="changeView('delete-product')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Delete product
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="variant_add" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('create-variant')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Create variant
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="variants_generate" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('generate-variants')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Generate variants
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

    <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
      <div class="animate-in fade-in duration-500">
        <span class="rounded-full inline-flex p-4 bg-white -ml-4 text-gray-700 group-hover:bg-gray-50 group-hover:text-gray-700 ring-4 ring-white">
          <struct-icon icon="sort" size="32"></struct-icon>
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a class="focus:outline-none cursor-pointer" (click)="changeView('sort-variants')">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true"></span>
            Sort variants
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500">Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae.</p>
      </div>
      <span class="pointer-events-none absolute top-12 right-6 text-white group-hover:text-gray-400" aria-hidden="true">
        <struct-icon icon="chevron_right" size="32"></struct-icon>
      </span>
    </div>

  </div>
</section>

<section class="h-full flex items-center justify-center" *ngIf="currentView === 'copy-product'">
  <div class="h-full sm:grid sm:grid-cols-2">
    <div class="relative h-full flex items-center justify-center bg-white p-12">

      <div class="absolute top-0 left-0 right-0 h-1/2 bg-gray-50"></div>

      <div class="bg-white w-1/2 h-1/2 -mt-20 -mr-8 rounded-lg shadow-md z-10 m-2 p-4 lg:p-6 xl:p-8 grow-0 border-0 border-gray-200 animate-in slide-in-from-bottom-12 duration-500">
        <div class="w-full h-full flex flex-col justify-center space-y-4">
          <div class="flex grow items-center justify-center aspect-w-3 aspect-h-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="object-contain h-32 text-gray-200">
              <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                <circle cx="26" cy="23" r="5"></circle>
                <polyline points="11 44 20 35 28 43 44 27 53 36"></polyline>
              </g>
            </svg>
          </div>

          <div class="flex w-full items-start">
            <div class="flex grow flex-col space-y-2">
              <span class="block w-2/3 h-3 bg-gray-200"></span>
              <span class="block w-1/2 h-2 bg-gray-200"></span>
            </div>
            <div class="flex grow-0 flex-col space-y-2">
              <span class="block w-10 h-2 bg-gray-200"></span>
              <span class="block w-10 h-2 bg-gray-200"></span>
              <span class="block w-10 h-2 bg-gray-200"></span>
            </div>
          </div>
        </div>

      </div>

      <div class="bg-white border-2 border-dashed z-20 border-gray-200 ring-8 ring-gray-100/50 w-1/2 h-1/2 mt-20 -ml-8 rounded-lg shadow-xl m-2 p-4 lg:p-6 xl:p-8 flex grow-0 animate-in slide-in-from-bottom-12 duration-700">

        <div class="w-full h-full flex flex-col justify-center space-y-4">
          <div class="flex grow items-center justify-center aspect-w-3 aspect-h-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="object-contain h-32 text-gray-200">
              <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                <circle cx="26" cy="23" r="5"></circle>
                <polyline points="11 44 20 35 28 43 44 27 53 36"></polyline>
              </g>
            </svg>
          </div>

          <div class="flex w-full items-center">
            <div class="flex grow flex-col space-y-2">
              <span class="block w-2/3 h-3 bg-gray-200"></span>
              <span class="block w-1/2 h-2 bg-gray-200"></span>
            </div>
            <span class="flex w-10 h-10 bg-gray-200 rounded-full"></span>
          </div>
        </div>

      </div>


    </div>
    <div class="flex items-center p-12 bg-white">
      <form [formGroup]="productCopyOptions">
        <div class="flex flex-col">
          <h3 class="text-3xl font-medium mb-4">Create a copy of this product?</h3>
          <div class="text-xl text-gray-500 mb-8">The copy will be placed in the same categories as the one you are copying. Identifier fields will be cleared on the copy.</div>

          <h5 class="text-lg font-medium mb-4">This product has variants. Do you want to create copies of the variants as well?</h5>
          <mat-radio-group [formControl]="productCopyHasVariantsControl" aria-label="Select an option" color="primary" class="flex space-x-4 mb-8">
            <mat-radio-button value="1">Yes</mat-radio-button>
            <mat-radio-button value="2">No</mat-radio-button>
          </mat-radio-group>
          <!-- <struct-button color="primary" [disabled]="!productCopyOptions.valid">Copy product <struct-icon icon="chevron_right" size="12" class="ml-2"></struct-icon></struct-button> -->
        </div>
      </form>
    </div>

  </div>

</section>

<section class="h-full flex items-center justify-center overflow-hidden" *ngIf="currentView === 'change-product-structure'">
  <div class="relative h-full sm:grid sm:grid-cols-2 overflow-hidden">

    <div class="relative h-full flex items-center justify-center bg-white p-12 overflow-hidden">
      <div class="absolute top-0 left-0 right-0 h-1/2 bg-gray-50">
        <span class="flex h-5 w-5 relative m-8" #tooltip="matTooltip" matTooltip="Help" [matTooltipPosition]="'right'">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-300 opacity-10"></span>
          <struct-icon icon="help" size="16" class="text-gray-400"></struct-icon>

        </span>
      </div>

      <div class="bg-white border-0 z-30 border-gray-200 w-1/2 h-1/2 rounded-lg shadow-sm m-2 p-4 lg:p-6 xl:p-8 flex grow-0 animate-in slide-in-from-bottom-12 duration-700">

        <div class="w-full h-full flex space-x-4 items-start">
          <div class="flex flex-col space-y-2">
            <div class="flex grow-0 items-center justify-center p-3 rounded-full bg-gray-50 animate-in slide-in-from-bottom-12 duration-700">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="w-8 h-8 text-gray-200">
                <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                  <path d="M32,4c0,4.4-3.6,8-8,8 s-8-3.6-8-8h-6L2,8v12h8v24h28V20h8V8l-8-4H32z"></path>
                </g>
              </svg>
            </div>
            <div class="flex items-center justify-center space-x-1">
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
            </div>
          </div>


          <div class="flex h-full grow animate-in slide-in-from-bottom-12 duration-700">
            <div class="flex h-full grow flex-col space-y-2">
              <div class="flex w-full grow items-center space-x-2 justify-items-stretch animate-in slide-in-from-bottom-12 duration-500">
                <span class="h-2 flex-1 bg-gray-100 rounded"></span>
                <span class="h-2 flex-1 bg-gray-200 rounded"></span>
                <span class="h-2 flex-1 bg-gray-100 rounded"></span>
                <span class="h-2 flex-1 bg-gray-100 rounded"></span>
                <span class="h-2 flex-1 bg-gray-100 rounded"></span>
              </div>

              <div class="flex flex-col h-1/2 grow p-2 space-y-2 border-2 border-gray-100 rounded overflow-hidden animate-in slide-in-from-bottom-12 duration-1000">
                <div class="flex grow-0 w-full items-center">
                  <span class="h-1 w-1/3 bg-gray-200"></span>
                </div>

                <div class="flex flex-col space-y-2 grow w-full animate-in slide-in-from-bottom-12 duration-1000">
                  <span class="h-3 w-2/3 border-2 border-gray-100"></span>
                  <span class="h-6 w-2/3 border-2 border-gray-100"></span>
                </div>
              </div>

              <div class="flex flex-col h-1/2 grow p-2 space-y-2 border-2 border-gray-100 rounded overflow-hidden animate-in slide-in-from-bottom-12 duration-1000">
                <div class="flex grow-0 w-full items-center">
                  <span class="h-1 w-1/3 bg-gray-200"></span>
                </div>

                <div class="flex flex-col space-y-2 grow w-full animate-in slide-in-from-bottom-12 duration-1000">
                  <span class="h-3 w-2/3 border-2 border-gray-100"></span>
                  <span class="h-3 w-2/3 border-2 border-gray-100"></span>
                  <span class="h-3 w-2/3 border-2 border-gray-100"></span>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div class="bg-white scale-125 overflow-hidden -translate-x-24 border-0 z-30 border-gray-200 w-1/2 h-1/2 rounded-lg shadow-xl m-2 p-4 lg:p-6 xl:p-6 flex grow-0 animate-in slide-in-from-bottom-12 duration-700">

        <div class="w-full h-full flex space-x-4 items-start">
          <div class="flex flex-col space-y-2">
            <div class="flex grow-0 items-center justify-center p-3 rounded-full bg-gray-50 animate-in slide-in-from-bottom-12 duration-700">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="w-8 h-8 text-gray-700">
                <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
                  <path d="M12,26v16H6c0,0,0-7.3,0-12 c0-5.9-4-8.1-4-14C2,9.2,6,6,6,6l23.1,21.3c1.8,1.7,4.3,2.7,6.8,2.7H40c3.3,0,6,2.7,6,6v6c0,0-2,0-10,0S17.5,26,12,26z"></path>
                </g>
              </svg>


            </div>
            <div class="flex items-center justify-center space-x-1">
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
              <span class="h-2 w-2 bg-gray-100 rounded-full"></span>
            </div>
          </div>


          <div class="flex h-full grow animate-in slide-in-from-bottom-12 duration-700">
            <div class="flex h-full grow flex-col space-y-2">
              <div class="flex w-full grow items-center space-x-2 justify-items-stretch animate-in slide-in-from-bottom-12 duration-500">
                <span class="h-1 flex-1 bg-gray-600 rounded"></span>
                <span class="h-1 flex-1 bg-blue-500 rounded"></span>
                <span class="h-1 flex-1 bg-gray-600 rounded"></span>
                <span class="h-1 flex-1 bg-gray-600 rounded"></span>
                <span class="h-1 flex-1 bg-gray-600 rounded"></span>
              </div>

              <div class="flex flex-col h-1/2 grow p-2 space-y-2 border-0 border-gray-100 shadow rounded overflow-hidden animate-in slide-in-from-bottom-12 duration-1000">
                <div class="flex grow-0 w-full items-center">
                  <span class="h-1 w-1/3 bg-gray-700 rounded"></span>
                </div>

                <div class="flex flex-col space-y-2 grow w-full animate-in slide-in-from-bottom-12 duration-1000">
                  <span class="h-3 w-2/3 border border-gray-200"></span>
                  <span class="h-6 w-2/3 border border-gray-200"></span>
                  <div class="flex space-x-1 w-2/3">
                    <span class="h-2 flex-1 border border-gray-200"></span>
                    <span class="h-2 flex-1 border border-gray-200"></span>
                    <span class="h-2 flex-1 border border-gray-200"></span>
                  </div>
                </div>
              </div>

              <div class="flex flex-col h-1/2 grow p-2 space-y-2 border-0 border-gray-100 shadow rounded overflow-hidden animate-in slide-in-from-bottom-12 duration-1000">
                <div class="flex grow-0 w-full items-center">
                  <span class="h-1 w-1/3 bg-gray-700 rounded"></span>
                </div>

                <div class="flex flex-col space-y-2 grow w-full animate-in slide-in-from-bottom-12 duration-1000">
                  <span class="h-3 w-2/3 border border-gray-200"></span>
                  <span class="h-3 w-2/3 border border-gray-200"></span>
                  <span class="h-3 w-2/3 border border-gray-200"></span>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="flex items-center h-full p-12 bg-white overflow-y-auto struct-scrollbar">
      <div class="flex flex-col">
        <h3 class="text-2xl font-medium mb-2">Change product structure</h3>
        <div class="text-xl text-gray-500 mb-8">The copy will be placed in the same categories as the one you are copying. Identifier fields will be cleared on the copy.</div>

        <mat-selection-list #productStructuresList [multiple]="false">
          <mat-list-option *ngFor="let structure of productStructures" [value]="structure" [disableRipple]="true" [selected]="structure.selected">
            <div class="flex items-center space-x-4">
              <struct-icon icon="product_structure" size="16" class="grow-0" matListItemIcon></struct-icon>
              <div class="flex-1 flex flex-col">
                <div matListItemTitle class="font-medium text-md text-gray-900">{{structure.name}}</div>
                <div matListItemLine class="text-sm text-gray-500">Has variants: {{structure.variants}}</div>
              </div>

            </div>
          </mat-list-option>
        </mat-selection-list>

        <!-- <struct-button color="primary">Change product structure <struct-icon icon="chevron_right" size="12" class="ml-2"></struct-icon></struct-button> -->
      </div>
    </div>

  </div>

</section>

<section class="h-full w-full flex items-center justify-center" *ngIf="currentView === 'delete-product'">
  <div class="h-full w-full sm:grid sm:grid-cols-2 sm:gap-px bg-red-50">
    <div class="flex items-center justify-center p-12">

    </div>
    <div class="flex items-center p-12">
      <div class="flex flex-col">
        <h3 class="text-3xl font-medium mb-2 text-red-500">Are you sure you want to delete this product?</h3>
        <div class="text-xl text-red-500 mb-8">Are you sure you want to delete this product?</div>


        <struct-button color="warn">Delete product <struct-icon icon="chevron_right" size="16" class="ml-2"></struct-icon></struct-button>
      </div>
    </div>

  </div>

</section>

<section class="h-full w-full flex items-center justify-center" *ngIf="currentView === 'create-variant'">
  <div class="h-full w-full sm:grid sm:grid-cols-2">
    <div class="relative h-full w-full flex items-center justify-center bg-white p-12">
      <div class="absolute top-0 left-0 right-0 h-1/2 bg-gray-50"></div>

      <div class="bg-white border-0 z-10 border-gray-200 w-1/2 h-1/2 rounded-lg shadow-lg m-2 p-4 lg:p-6 xl:p-8 flex grow-0 animate-in slide-in-from-bottom-12 duration-700">

        <div class="w-full h-full flex flex-col justify-center space-y-4">
          <div class="flex grow items-center justify-center animate-in slide-in-from-bottom-12 duration-700">
            <struct-icon icon="variant" size="32" class="text-green-600"></struct-icon>
          </div>

          <div class="flex shrink-0 w-1/2 mx-auto items-center mb-4 animate-in slide-in-from-bottom-12 duration-1000">
            <div class="flex grow flex-col space-y-2">
              <div class="flex grow items-center space-x-2">
                <span class="grow h-2 bg-gray-100 rounded-full"></span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-green-50">
                  <struct-icon icon="check" size="16" class="text-green-600"></struct-icon>
                </span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-gray-50">

                </span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-gray-50">

                </span>
              </div>

              <div class="flex grow items-center space-x-2">
                <span class="grow h-2 bg-gray-100 rounded-full"></span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-gray-50">

                </span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-green-50">
                  <struct-icon icon="check" size="16" class="text-green-600"></struct-icon>
                </span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-gray-50">

                </span>
              </div>

              <div class="flex grow items-center space-x-2">
                <span class="grow h-2 bg-gray-100 rounded-full"></span>
                <span class="grow-0 flex items-center justify-center p-2 w-8 h-8 rounded-full bg-red-50">
                  <struct-icon icon="remove" size="16" class="text-red-600"></struct-icon>
                </span>
              </div>

            </div>
          </div>
        </div>

      </div>


    </div>

    <div class="flex w-full items-center p-12 bg-white">
      <div class="flex grow flex-col">
        <h3 class="text-3xl font-medium mb-4">Create variant</h3>
        <mat-stepper orientation="horizontal" #stepper class="min-h-1/2 w-full">
          <mat-step>
            <form>
              <ng-template matStepLabel>Basic information</ng-template>
              <struct-input name="Primary name" value="Nike Air Max 90" [required]="false"></struct-input>

              <div class="mt-6">
                <button mat-button matStepperNext>Reset</button>
              </div>
            </form>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p>
            <div class="mt-6">
              <struct-button mat-button matStepperNext><struct-icon icon="chevron_left" size="12" class="mr-2"></struct-icon> Back</struct-button>
              <!-- <struct-button mat-button color="primary" (click)="stepper.reset()">Create variant <struct-icon icon="chevron_right" size="12" class="ml-2"></struct-icon></struct-button> -->
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>

  </div>

</section>



</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex items-center justify-between">
    <div>
      <struct-button *ngIf="currentView !== 'intro'" (click)="changeView('intro')">Back</struct-button>
    </div>
    <div class="flex space-x-2 justify-end">

      <struct-button color="primary" *ngIf="currentView === 'copy-product'">Copy product</struct-button>
      <struct-button color="primary" *ngIf="currentView === 'change-product-structure'">Change structure</struct-button>
      <struct-button color="primary" *ngIf="currentView === 'create-variant'">Create variant</struct-button>

      <struct-button (click)="cancel()">Cancel</struct-button>
    </div>
  </div>
</mat-dialog-actions>
