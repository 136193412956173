// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeploymentInfo  {
  dbTemplateUid: string;
  deploymentStamp: string;
  adminUsersToInvite: string[];
}

export class DeploymentInfo  implements IDeploymentInfo {
  dbTemplateUid = "00000000-0000-0000-0000-000000000000";

  deploymentStamp = "00000000-0000-0000-0000-000000000000";

  adminUsersToInvite: string[] = [];


  constructor(data?: Partial<IDeploymentInfo>) {
    Object.assign(this, data);
  }
}