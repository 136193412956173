<struct-app-content-container>
  <accounts-header>Tenant log</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <table class="min-w-full divide-y divide-gray-300 text-sm">
        <tr>
              <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 pl-0">Timestamp</th>
              <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Message</th>
        </tr>
        <tr *ngFor="let logMessage of data">
              <td class="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                <div class="flex items-center gap-2">
                  <struct-icon icon="calendar_date" size="12"></struct-icon>
                  <time datetime="2022-01-07">{{logMessage.timestamp | date : "YYYY-MM-dd hh:mm:ss" }}</time>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{{logMessage.message}}</td>
        </tr>
      </table>
    </struct-app-content-workarea>
  </struct-app-content>
<struct-app-content-footer>
  <struct-button color="default" (click)="cancel()">Close</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
