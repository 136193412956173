<div class="relative w-10 h-10 animate-in fade-in duration-500" *ngIf="value === ''">
  <div class="absolute top-0 left-0 rounded-full w-10 h-10 ring-4 ring-inset ring-gray-100"></div>
  <span class="absolute inset-0 text-[8px] text-gray-700 font-semibold flex items-center justify-center z-10">n/a</span>
</div>

<div class="relative w-10 h-10 animate-in fade-in duration-500" *ngIf="type === 1 && value !== null && value !== ''">
  <div class="absolute top-0 left-0 rounded-full w-10 h-10 ring-4 ring-inset ring-gray-100"></div>

  <mat-progress-spinner
    class="w-10 h-10 rounded-full"
    [color]="color"
    mode="determinate"
    diameter="32"
    strokeWidth="3"
    [value]="value | number">
  </mat-progress-spinner>

  <span class="absolute inset-0 text-[8px] text-gray-700 font-semibold flex items-center justify-center z-10">{{value | number}}%</span>
</div>

<div class="relative flex flex-col w-full h-10 py-1 animate-in fade-in duration-500" *ngIf="type === 2 && value !== null && value !== ''">

  <mat-progress-bar
    [color]="color"
    mode="determinate"
    [value]="value | number">
  </mat-progress-bar>

  <span class="text-[8px] text-gray-700 font-semibold">{{value | number}}%</span>
</div>
