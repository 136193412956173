// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangePasswordCommand  {
  userId: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export class ChangePasswordCommand  implements IChangePasswordCommand {
  userId = "";

  currentPassword = "";

  newPassword = "";

  confirmNewPassword = "";


  constructor(data?: Partial<IChangePasswordCommand>) {
    Object.assign(this, data);
  }
}