// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum AccountRole {
  User = "User",
  Admin = "Admin",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace AccountRole {
  export function toString(value: AccountRole): string {
    return AccountRole[value];
  }

  export const values = (): AccountRole[] => [
    AccountRole.User,
    AccountRole.Admin,
  ];

  export function parse(value: string, ignoreCase?: boolean): AccountRole | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'AccountRole';
  }
}
