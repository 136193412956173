
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AsyncTaskInfo } from "@struct/models/struct/app/domain/infrastructure/asynctask/async-task-info";

@Injectable({ providedIn: "root" })
export class AsyncTaskApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAsyncTaskInfo(taskUid: string): Observable<AsyncTaskInfo | null> {
    return this.http.get<AsyncTaskInfo | null>(`${this.apiUrl}asynctask/${taskUid}`, this.httpOptions );
  }
}

