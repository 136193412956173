// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationUserInviteCommand  {
  organizationId: string;
  email: string;
  message: string;
  role: string;
  invitedBy: string;
}

export class OrganizationUserInviteCommand  implements IOrganizationUserInviteCommand {
  organizationId = "";

  email = "";

  message = "";

  role = "";

  invitedBy = "";


  constructor(data?: Partial<IOrganizationUserInviteCommand>) {
    Object.assign(this, data);
  }
}