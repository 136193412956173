import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { UiModule } from '@struct/ui/ui.module';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { AccountsFormContentComponent } from '../../accounts-ui/accounts-form/accounts-form-content/accounts-form-content.component';
import { AuthenticationApiService, UsersApiService } from '@struct/services/account-management';
import { ChangePasswordCommand } from '@struct/models/struct/usermanagementmodule/commands/change-password-command';
import { CommandResponse } from '@struct/models/struct/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordCommand } from '@struct/models/struct/usermanagementmodule/commands/forgot-password-command';
import { ChangeToPasswordDialogData } from './change-to-password-dialog-data';
import { ChangeToEmailAndPasswordSignInCommand } from '@struct/models/struct/usermanagementmodule/commands/change-to-email-and-password-sign-in-command';

@Component({
  selector: 'accounts-change-to-password-dialog',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, AccountsFormComponent, AccountsHeaderComponent, AccountsFormContentComponent, AccountsFormSectionContentComponent],
  templateUrl: './change-to-password-dialog.component.html',
})
export class ChangeToPasswordDialogComponent {
  @ViewChild('changePasswordForm') changePasswordForm!: NgForm;
  loaded = true;

  currentPassword!: string;
  newPassword!: string;
  verifyNewPassword!: string;
  showError = false;
  working = false;
  errorMsg = '';
  mode = 'change';

  constructor(
    private dialogRef: MatDialogRef<ChangeToPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ChangeToPasswordDialogData,
    private userApi: UsersApiService,
    private authApi: AuthenticationApiService
  ) {}

  changeToEmailAndPassword() {
    if (!this.changePasswordForm.valid) {
      return;
    }

    this.working = true;

    this.showError = false;
    if (this.verifyNewPassword !== this.newPassword) {
      this.showError = true;
      this.errorMsg = 'ChangePassword.Error_PasswordMismatch';
      this.working = false;
    } else {
      const command = new ChangeToEmailAndPasswordSignInCommand({
        userId: this.dialogData.userId,
        newPassword: this.newPassword,        
        confirmNewPassword: this.verifyNewPassword,
      });
      this.userApi.changeSignInMethodToEmailAndPassword(command).subscribe({
        next: (result: CommandResponse) => {
          this.working = false;
          if (result.succeeded) {
            this.dialogRef.close(true);
            window.location.reload();
          } else {
            this.showError = true;
            this.errorMsg = 'ChangePassword.Error_' + result.errors[0].code;
          }
        },
        error: error => {
          this.working = false;
          this.showError = true;
          this.errorMsg = 'An unknown error occured';
        },
      });
    }
  }

  forgotPassword() {
    this.mode = 'forgot';
  }

  sendResetEmail() {
    this.working = true;
    const command = new ForgotPasswordCommand({
      email: this.dialogData.email,
    });
    this.authApi.forgotPassword(command).subscribe({
      next: () => {
        this.mode = 'forgot-sent';
        this.working = false;
      },
      error: error => {
        this.showError = true;
        this.errorMsg = 'An unknown error occured';
        this.working = false;
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
