// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISort  {
  dataFieldUid: string;
  sortAsc: boolean;
}

export class Sort  implements ISort {
  dataFieldUid = "";

  sortAsc = false;


  constructor(data?: Partial<ISort>) {
    Object.assign(this, data);
  }
}