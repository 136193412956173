import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-mini-fab-button',
  templateUrl: './struct-mini-fab-button.component.html',
  styleUrls: ['./struct-mini-fab-button.component.scss'],
})
export class StructMiniFabButtonComponent {
  
  @Input() icon: string | null = null;
  @Input() color: string | null = null;
  @Input() disabled: boolean;
  @Input() buttonType: string;

  constructor() {
    this.disabled = false;
    this.buttonType = 'button';
  }
}
