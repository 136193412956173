<mat-accordion class="expansion-panel__sidebar" multi="true">
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>{{title}}</mat-panel-title>
        </mat-expansion-panel-header>
        <struct-app-content-metabar-info>
            <struct-app-content-metabar-info-row>
                <span info-name>{{'General.Id' | translate}}</span>
                <span info-value>{{id}}</span>
            </struct-app-content-metabar-info-row>
            <struct-app-content-metabar-info-row>
                <span info-name>{{'General.CreatedBy' | translate}}</span>
                <span info-value>{{createdBy}}</span>
                <struct-avatar info-icon size="xxsmall" [name]="createdBy ?? null" class="ml-2"></struct-avatar>
            </struct-app-content-metabar-info-row>
            <struct-app-content-metabar-info-row>
                <span info-name>{{'General.Created' | translate}}</span>
                <span info-value>{{created | date: 'medium'}}</span>
            </struct-app-content-metabar-info-row>
            <struct-app-content-metabar-info-row>
                <span info-name>{{'General.LastModifiedBy' | translate}}</span>
                <span info-value>{{lastModifiedBy}}</span>
                <struct-avatar info-icon size="xxsmall" [name]="lastModifiedBy ?? null" class="ml-2"></struct-avatar>
            </struct-app-content-metabar-info-row>
            <struct-app-content-metabar-info-row>
                <span info-name>{{'General.LastModified' | translate}}</span>
                <span info-value>{{lastModified | date: 'medium'}}</span>
            </struct-app-content-metabar-info-row>
        </struct-app-content-metabar-info>
    </mat-expansion-panel>
</mat-accordion>