
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProfileModel } from "@struct/models/struct/accountmanagement/api/models/account/profile-model";
import { RegisterWithMicrosoftRequest } from "@struct/models/struct/accountmanagement/api/models/account/register-with-microsoft-request";
import { AcceptInviteModel } from "@struct/models/struct/accountmanagement/api/models/users/accept-invite-model";
import { UserSearchModel } from "@struct/models/struct/accountmanagement/api/models/users/user-search-model";
import { CommandResponse } from "@struct/models/struct/shared/models/command-response";
import { AcceptInviteCommand } from "@struct/models/struct/usermanagementmodule/commands/accept-invite-command";
import { ChangePasswordCommand } from "@struct/models/struct/usermanagementmodule/commands/change-password-command";
import { ChangeToEmailAndPasswordSignInCommand } from "@struct/models/struct/usermanagementmodule/commands/change-to-email-and-password-sign-in-command";
import { ConfirmEmailCommand } from "@struct/models/struct/usermanagementmodule/commands/confirm-email-command";
import { DeclineInviteCommand } from "@struct/models/struct/usermanagementmodule/commands/decline-invite-command";
import { InviteAdminCommand } from "@struct/models/struct/usermanagementmodule/commands/invite-admin-command";
import { RegisterUserCommand } from "@struct/models/struct/usermanagementmodule/commands/register-user-command";
import { RegisterUserWithMicrosoftCommandResponse } from "@struct/models/struct/usermanagementmodule/commands/register-user-with-microsoft-command-response";
import { UpdateUserProfileCommand } from "@struct/models/struct/usermanagementmodule/commands/update-user-profile-command";

@Injectable({ providedIn: "root" })
export class UsersApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getInviteModel(inviteUid: string, token: string): Observable<AcceptInviteModel | null> {
    return this.http.get<AcceptInviteModel | null>(`${this.apiUrl}users/invite/${inviteUid}/${token}`, this.httpOptions );
  }

  getUsers(): Observable<UserSearchModel[]> {
    return this.http.get<UserSearchModel[]>(`${this.apiUrl}users`, this.httpOptions );
  }

  getUser(id: string): Observable<UserSearchModel> {
    return this.http.get<UserSearchModel>(`${this.apiUrl}users/${id}`, this.httpOptions );
  }

  registerUser(command: RegisterUserCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users`, command, this.httpOptions);
  }

  registerWithMicrosoft(request: RegisterWithMicrosoftRequest): Observable<RegisterUserWithMicrosoftCommandResponse> {
    return this.http.post<RegisterUserWithMicrosoftCommandResponse>(`${this.apiUrl}users/registerwithmicrosoft`, request, this.httpOptions);
  }

  changeSignInMethodToMicrosoft(): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/changesigninmethodtomicrosoft`, null, this.httpOptions);
  }

  changeSignInMethodToEmailAndPassword(command: ChangeToEmailAndPasswordSignInCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/changesigninmethodtoemailpassword`, command, this.httpOptions);
  }

  confirmEmail(command: ConfirmEmailCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/confirmemail`, command, this.httpOptions);
  }

  declineInvite(command: DeclineInviteCommand): Observable<CommandResponse> {
    return this.http.delete<CommandResponse>(`${this.apiUrl}users/invite`, this.httpOptions );
  }

  acceptInvite(command: AcceptInviteCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/invite`, command, this.httpOptions);
  }

  lockUser(userId: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/${userId}/lock`, null, this.httpOptions);
  }

  unlockUser(userId: string): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/${userId}/unlock`, null, this.httpOptions);
  }

  deleteUser(userUid: string): Observable<CommandResponse> {
    return this.http.delete<CommandResponse>(`${this.apiUrl}users/${userUid}`, this.httpOptions );
  }

  inviteAdmin(command: InviteAdminCommand): Observable<CommandResponse> {
    return this.http.post<CommandResponse>(`${this.apiUrl}users/inviteadmin`, command, this.httpOptions);
  }

  getMyProfile(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(`${this.apiUrl}users/myprofile`, this.httpOptions );
  }

  changePassword(command: ChangePasswordCommand): Observable<CommandResponse> {
    return this.http.put<CommandResponse>(`${this.apiUrl}users/changepassword`, command, this.httpOptions);
  }

  updateUserProfile(command: UpdateUserProfileCommand): Observable<CommandResponse> {
    return this.http.put<CommandResponse>(`${this.apiUrl}users/profile`, command, this.httpOptions);
  }
}

