
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateBusinessUnitCommand } from "@struct/models/struct/app/domain/businessunits/commands/create-business-unit-command";
import { UpdateBusinessUnitCommand } from "@struct/models/struct/app/domain/businessunits/commands/update-business-unit-command";
import { BusinessUnit } from "@struct/models/struct/app/domain/businessunits/business-unit";
import { BusinessUnitAliasModel } from "@struct/models/struct/app/frontendapi/models/businessunit/business-unit-alias-model";

@Injectable({ providedIn: "root" })
export class BusinessUnitsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAvailableBusinessUnits(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}user/businessunits`, this.httpOptions );
  }

  getBusinessUnits(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(`${this.apiUrl}businessunits`, this.httpOptions );
  }

  getAliases(): Observable<BusinessUnitAliasModel[]> {
    return this.http.get<BusinessUnitAliasModel[]>(`${this.apiUrl}businessunits/aliases`, this.httpOptions );
  }

  getBusinessUnit(uid: string): Observable<BusinessUnit> {
    return this.http.get<BusinessUnit>(`${this.apiUrl}businessunits/${uid}`, this.httpOptions );
  }

  deleteBusinessUnit(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}businessunits?uid=${uid}`, this.httpOptions );
  }

  createBusinessUnit(command: CreateBusinessUnitCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}businessunits`, command, this.httpOptions);
  }

  updateBusinessUnit(command: UpdateBusinessUnitCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}businessunits`, command, this.httpOptions);
  }
}

