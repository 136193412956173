// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommandError } from "@struct/models/struct/shared/models/command-error";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommandResponse  {
  succeeded: boolean;
  errors: CommandError[];
}

export class CommandResponse  implements ICommandResponse {
  succeeded = false;

  errors: CommandError[] = [];


  constructor(data?: Partial<ICommandResponse>) {
    Object.assign(this, data);
  }
}