import { SafeUrl } from "@angular/platform-browser";
import { FileHelper } from "./file-helper";
import { Asset } from "@struct/models/struct/app/domain/assets";

export class FileHandle {
    name: string;
    file: File;
    url: SafeUrl;
    size: string;
    type: string;
    progress: number = 0;
    uploadedAsset: Asset | null = null;
    bytesUploaded: number = 0;
    assetTypes: string[] = [];
    selectedAssetType: string = "";
  
    constructor(name: string, file: File, url: SafeUrl, size: string, type: string) {
      this.name = name;
      this.file = file;
      this.url = url;
      this.size = size;
      this.type = type;
    }
  
    isImage() : boolean{
      return this.type.startsWith('image');
    }
  
    isVideo() : boolean{
      return this.type.startsWith('video');
    }
  
    isAudio() : boolean{
      return this.type.startsWith('audio');
    }
  
    isDocument() : boolean { 
      return FileHelper.getDocumentMimeTypes().includes(this.type);
    }
  
    getFileExtension() : string{
      return this.name.split('.').pop() ?? "";
    }
  
    getFileType() : string {
      return this.type.split('/')[1];
    }
  }