import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'struct-flag-icon',
  templateUrl: './struct-flag-icon.component.html',
  styleUrls: ['./struct-flag-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructFlagIconComponent {
  @Input() public countryCode = "";
  @Input() public size: string;

  constructor(){
    this.size = "16px";
   }

}
