// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommandError  {
  message: string;
  code: string;
}

export class CommandError  implements ICommandError {
  message = "";

  code = "";


  constructor(data?: Partial<ICommandError>) {
    Object.assign(this, data);
  }
}