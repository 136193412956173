
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PropertySetup } from "@struct/models/struct/app/domain/dataconfiguration/base/property-setup";
import { SectionSetup } from "@struct/models/struct/app/domain/dataconfiguration/base/section-setup";
import { TabSetup } from "@struct/models/struct/app/domain/dataconfiguration/base/tab-setup";
import { WizardPropertySetup } from "@struct/models/struct/app/domain/dataconfiguration/base/wizard-property-setup";
import { PropertyTypeReference } from "@struct/models/struct/app/domain/dataconfiguration/property-type-reference";
import { SectionTypeReference } from "@struct/models/struct/app/domain/dataconfiguration/section-type-reference";
import { TabTypeReference } from "@struct/models/struct/app/domain/dataconfiguration/tab-type-reference";
import { WizardPropertyTypeReference } from "@struct/models/struct/app/domain/dataconfiguration/wizard-property-type-reference";
import { WizardStepTypeReference } from "@struct/models/struct/app/domain/dataconfiguration/wizard-step-type-reference";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";
import { HydrateWizardPropertyModel } from "@struct/models/struct/app/frontendapi/models/datamodelconfiguration/hydrate-wizard-property-model";

@Injectable({ providedIn: "root" })
export class DataModelConfigurationApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getTabTypes(entityType: StructEntityType): Observable<TabTypeReference[]> {
    return this.http.get<TabTypeReference[]>(`${this.apiUrl}datamodelconfiguration/tabs/types/${entityType}`, this.httpOptions );
  }

  getSectionTypes(entityType: StructEntityType): Observable<SectionTypeReference[]> {
    return this.http.get<SectionTypeReference[]>(`${this.apiUrl}datamodelconfiguration/sections/types/${entityType}`, this.httpOptions );
  }

  getPropertyTypes(entityType: StructEntityType): Observable<PropertyTypeReference[]> {
    return this.http.get<PropertyTypeReference[]>(`${this.apiUrl}datamodelconfiguration/properties/types/${entityType}`, this.httpOptions );
  }

  getWizardStepTypes(entityType: StructEntityType): Observable<WizardStepTypeReference[]> {
    return this.http.get<WizardStepTypeReference[]>(`${this.apiUrl}datamodelconfiguration/wizards/steptypes/${entityType}`, this.httpOptions );
  }

  getWizardPropertyTypes(entityType: StructEntityType): Observable<WizardPropertyTypeReference[]> {
    return this.http.get<WizardPropertyTypeReference[]>(`${this.apiUrl}datamodelconfiguration/wizards/propertytypes/${entityType}`, this.httpOptions );
  }

  hydrateTabSetup(tabSetup: TabSetup): Observable<TabSetup> {
    return this.http.post<TabSetup>(`${this.apiUrl}datamodelconfiguration/tabs/hydrate`, tabSetup, this.httpOptions);
  }

  hydrateSectionSetup(sectionSetup: SectionSetup): Observable<SectionSetup> {
    return this.http.post<SectionSetup>(`${this.apiUrl}datamodelconfiguration/sections/hydrate`, sectionSetup, this.httpOptions);
  }

  hydratePropertySetup(propertySetup: PropertySetup): Observable<PropertySetup> {
    return this.http.post<PropertySetup>(`${this.apiUrl}datamodelconfiguration/properties/hydrate`, propertySetup, this.httpOptions);
  }

  hydrateWizardPropertySetup(model: HydrateWizardPropertyModel): Observable<WizardPropertySetup> {
    return this.http.post<WizardPropertySetup>(`${this.apiUrl}datamodelconfiguration/wizards/hydrate`, model, this.httpOptions);
  }
}

