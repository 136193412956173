import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounceTime, map, of, switchMap } from 'rxjs';
import { EntityReference } from './EntityReference';
import { IEntitySearchDescriptor } from './IEntitySearchDescriptor';

@Component({
  selector: 'struct-entity-select',
  templateUrl: './struct-entity-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StructEntitySelectComponent,
    },
  ],
})
export class StructEntitySelectComponent implements ControlValueAccessor, OnInit {
  @Input({ required: true }) required: boolean = false;
  @Input({ required: true }) inline: boolean = false;
  @Input({ required: true }) placeholder: string = '';
  @Input({ required: true }) allowMultiselect: boolean = false;
  @Input({ required: true }) entitySearchDescriptor!: IEntitySearchDescriptor;
  @Input() showSelectedEntities = true;
  @Output() selectedEntitiesChange = new EventEmitter<EntityReference[]>();

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  ngForm: NgForm | null = null;
  selectGrid = new FormControl<EntityReference[]>([]);
  selectCtrl = new FormControl('');
  filteredOptions: EntityReference[] = [];

  selectedEntityIds: number[] = [];
  selectedEntities: EntityReference[] = [];
  searching = false;

  @ViewChild('selectInput') selectInput!: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.selectGrid = new FormControl(this.selectedEntities, { validators: this.required ? Validators.required : null });
  }

  removeSelectedOption(option: EntityReference): void {
    if (this.disabled) {
      return;
    }

    const index = this.selectedEntityIds.indexOf(option.id);
    this.selectedEntities.splice(index, 1);
    this.selectedEntityIds.splice(index, 1);
    this.onChange(this.selectedEntityIds);
    this.selectGrid.setValue(this.selectedEntities);
    this.markAsTouched();
    this.selectedEntitiesChange.emit(this.selectedEntities);
  }

  selectOption(event: MatAutocompleteSelectedEvent): void {
    if (this.disabled) {
      return;
    }
    if (event.option.value == null) {
      return;
    }
    this.selectInput.nativeElement.value = '';
    this.selectedEntities.push(event.option.value);
    this.selectedEntityIds.push(event.option.value.id);
    this.onChange(this.selectedEntityIds);
    this.selectGrid.setValue(this.selectedEntities);
    this.markAsTouched();
    this.selectedEntitiesChange.emit(this.selectedEntities);
  }

  drop(event: CdkDragDrop<EntityReference[]>) {
    moveItemInArray(this.selectedEntities, event.previousIndex, event.currentIndex);
    this.onChange(this.selectedEntities.map(e => e.id));
    this.selectGrid.setValue(this.selectedEntities);
    this.markAsTouched();
    this.selectedEntitiesChange.emit(this.selectedEntities);
  }

  openAdvancedSearchDialog(): void {
    this.entitySearchDescriptor.openAdvancedSearchDialog().pipe(
      switchMap(entityIds => {
        entityIds.forEach(entityId => {
          if(!this.selectedEntityIds.includes(entityId)) {
            this.selectedEntityIds.push(entityId);
          }
        });

        return this.entitySearchDescriptor.getEntities(this.selectedEntityIds);
      })
    ).subscribe(entities => {
      this.selectedEntities = entities;
      this.onChange(this.selectedEntityIds);
      this.selectGrid.setValue(this.selectedEntities);
      this.markAsTouched();
      this.selectedEntitiesChange.emit(this.selectedEntities);
    });
  }

  goToEntity(entityId: number): void {
    this.entitySearchDescriptor.goToEntity(entityId);
  }

  constructor(controlContainer: ControlContainer) {
    this.selectCtrl.valueChanges
      .pipe(
        map(value => {
          this.filteredOptions = [];
          if (typeof value !== 'object' && value !== null && value !== undefined && value.length > 0) {
            this.searching = true;
          }
          return value;
        }),
        debounceTime(200),
        switchMap((value: string | null) => {
          if (typeof value !== 'object') {
            this.filteredOptions = [];
            this.searching = true;
          }
          if (typeof value === 'object') {
            return of([]);
          }
          if (value === undefined || value === null || value.length === 0) {
            return of([]);
          }

          return this.entitySearchDescriptor.searchEntities(value, this.selectedEntityIds);
        })
      )
      .subscribe(result => {
        this.filteredOptions = result;
        this.searching = false;
      });

    //We need to replace the original markAllAsTouched function of the form with a new one that also marks the selectGrid as touched, so that the validation works correctly
    this.ngForm = controlContainer as NgForm;
    const originalFn = this.ngForm.form.markAllAsTouched;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.ngForm.form.markAllAsTouched = function () {
      originalFn.apply(this);
      that.selectGrid.markAsTouched();
      that.touched = true;
    };
  }

  //ControlValueAccessor implementation from here
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (s: number[]) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(selectedEntityIds: number[] | null) {
    const tmp = selectedEntityIds || [];
    if (this.selectedEntityIds.length === tmp.length && this.selectedEntityIds.every((v, i) => v === tmp[i])) {
      return;
    }
    this.selectedEntityIds = tmp;

    if(this.selectedEntityIds.length === 0){
      this.selectedEntities = [];
      return;
    }
    this.entitySearchDescriptor.getEntities(this.selectedEntityIds).subscribe(entities => {
      this.selectedEntities = entities;
    });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
