
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateAssetTypeCommand } from "@struct/models/struct/app/domain/assets/commands/create-asset-type-command";
import { UpdateAssetTypeCommand } from "@struct/models/struct/app/domain/assets/commands/update-asset-type-command";
import { AssetType } from "@struct/models/struct/app/domain/assets/asset-type";
import { AssetTypeReference } from "@struct/models/struct/app/domain/assets/asset-type-reference";

@Injectable({ providedIn: "root" })
export class AssetTypesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getAssetTypeReferences(): Observable<AssetTypeReference[]> {
    return this.http.get<AssetTypeReference[]>(`${this.apiUrl}assettypereferences`, this.httpOptions );
  }

  getAssetType(uid: string): Observable<AssetType> {
    return this.http.get<AssetType>(`${this.apiUrl}assettypes/${uid}`, this.httpOptions );
  }

  createAssetType(command: CreateAssetTypeCommand): Observable<AssetType> {
    return this.http.post<AssetType>(`${this.apiUrl}assettypes`, command, this.httpOptions);
  }

  updateAssetType(command: UpdateAssetTypeCommand): Observable<AssetType> {
    return this.http.put<AssetType>(`${this.apiUrl}assettypes`, command, this.httpOptions);
  }

  deleteAssetType(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}assettype/${uid}`, this.httpOptions );
  }
}

