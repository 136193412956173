<struct-app-content-container>
  <accounts-header [headerButtonText]="'Create subscription'" (headerButtonClicked)="createSubscription()">
    Subscriptions
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <accounts-list>

        <accounts-list-search [placeholder]="'Search subscriptions'" (searchChanged)="searchChanged($event)"></accounts-list-search>

        <accounts-list-content>

          <accounts-list-empty *ngIf="subscriptions?.length === 0 && searchTerm.length > 0">
            No subscriptions matched your search
          </accounts-list-empty>

          <accounts-list-empty *ngIf="subscriptions?.length === 0 && searchTerm.length === 0">
            No subscriptions have been created yet
          </accounts-list-empty>

          <accounts-list-item [iconName]="'subscription'" [routerLink]="'/subscriptions/' + subscription.uid" *ngFor="let subscription of subscriptions">
            {{subscription.name}}

            <accounts-list-item-badge *ngIf="subscription.status === subscriptionStatus.AwaitingApproval" [bgColorClass]="'bg-orange-400'">Awaiting approval</accounts-list-item-badge>
            <accounts-list-item-badge *ngIf="subscription.status === subscriptionStatus.Active" [bgColorClass]="'bg-green-400'">Active</accounts-list-item-badge>
            <accounts-list-item-badge *ngIf="subscription.status === subscriptionStatus.Suspended" [bgColorClass]="'bg-gray-400'">Suspended</accounts-list-item-badge>
            <accounts-list-item-badge *ngIf="subscription.status === subscriptionStatus.Terminated" [bgColorClass]="'bg-red-400'">Terminated</accounts-list-item-badge>

            <accounts-list-item-description>
              <div class="sm:flex">
                <p class="flex items-center text-sm text-gray-500">
                  <struct-icon icon="account_circle" size="12" class="mr-2"></struct-icon>
                  {{subscription.subscriptionPlan?.name}}
                </p>
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <struct-icon icon="calendar_month" size="12" class="mr-2"></struct-icon>
                <p>
                  Created
                  <time datetime="2022-01-07">{{subscription.created | date}}</time>
                </p>
              </div>
            </accounts-list-item-description>

          </accounts-list-item>

        </accounts-list-content>

      </accounts-list>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
