
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreatePermissionCommand } from "@struct/models/struct/app/domain/usermanagement/commands/create-permission-command";
import { CreatePermissionGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/create-permission-group-command";
import { UpdatePermissionCommand } from "@struct/models/struct/app/domain/usermanagement/commands/update-permission-command";
import { UpdatePermissionGroupCommand } from "@struct/models/struct/app/domain/usermanagement/commands/update-permission-group-command";
import { PermissionGroupReference } from "@struct/models/struct/app/domain/usermanagement/permissions/permission-group-reference";
import { PermissionReference } from "@struct/models/struct/app/domain/usermanagement/permissions/permission-reference";

@Injectable({ providedIn: "root" })
export class PermissionApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getPermissions(): Observable<PermissionReference[]> {
    return this.http.get<PermissionReference[]>(`${this.apiUrl}permissions`, this.httpOptions );
  }

  getPermission(uid: string): Observable<PermissionReference | null> {
    return this.http.get<PermissionReference | null>(`${this.apiUrl}permission/${uid}`, this.httpOptions );
  }

  createPermission(command: CreatePermissionCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}permission`, command, this.httpOptions);
  }

  updatePermission(command: UpdatePermissionCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}permission`, command, this.httpOptions);
  }

  deletePermission(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}permission/${uid}`, this.httpOptions );
  }

  getPermissionGroups(): Observable<PermissionGroupReference[]> {
    return this.http.get<PermissionGroupReference[]>(`${this.apiUrl}permissions/groups`, this.httpOptions );
  }

  getPermissionGroup(uid: string): Observable<PermissionGroupReference | null> {
    return this.http.get<PermissionGroupReference | null>(`${this.apiUrl}permissions/group/${uid}`, this.httpOptions );
  }

  createPermissionGroup(command: CreatePermissionGroupCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}permissions/group`, command, this.httpOptions);
  }

  updatePermissionGroup(command: UpdatePermissionGroupCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}permissions/group`, command, this.httpOptions);
  }

  deletePermissionGroup(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}permission/group/${uid}`, this.httpOptions );
  }
}

