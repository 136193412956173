<div class="struct-avatar" matTooltip="{{name}}" [matTooltipPosition]="'above'">
  <div class="struct-avatar-wrapper struct-avatar-wrapper-size-{{(size || 'large')}}" [ngClass]="{
    'bg-blue-800 text-white' : bgColor === bgColors.Blue,
    'bg-green-100 text-green-700' : bgColor === bgColors.Green,
    'bg-gray-200 text-gray-700' : bgColor === bgColors.Default,
    'w-4 h-4' : size === 'xxsmall',
    'w-5 h-5' : size === 'xsmall',
    'w-6 h-6' : size === 'small',
    'w-8 h-8' : size === 'medium',
    'w-10 h-10' : size === 'large',
    'w-12 h-12' : size === 'xlarge',
    'w-16 h-16' : size === 'xxlarge'
  }">
    <div class="struct-avatar-image" [hidden]="!src"></div>
    <div class="struct-avatar-initials" [hidden]="src || !name">
      <div *ngIf="size === 'xxsmall'" class="struct-avatar-initials-inner text-[8px]">
        {{ initials }}
      </div>
      <div *ngIf="size === 'xsmall'" class="struct-avatar-initials-inner  text-[9px]">
        {{ initials }}
      </div>
      <div *ngIf="size === 'small'" class="struct-avatar-initials-inner text-[10px]">
        {{ initials }}
      </div>
      <div *ngIf="size === 'medium'" class="struct-avatar-initials-inner text-sm">
        {{ initials }}
      </div>
      <div *ngIf="size === 'large'" class="struct-avatar-initials-inner text-base">
        {{ initials }}
      </div>
      <div *ngIf="size === 'xlarge'" class="struct-avatar-initials-inner text-lg">
        {{ initials }}
      </div>
      <div *ngIf="size === 'xxlarge'" class="struct-avatar-initials-inner text-xl">
        {{ initials }}
      </div>
    </div>
  </div>
</div>
