import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output} from '@angular/core';

@Directive({
  selector: '[structResizable]'
})
export class ResizableDirective{
  @Input() resizableElement!: HTMLElement;
  @Input() index!: number;
  @Input() minSize=0;
  @Output() resized = new EventEmitter<{index: number, width: number}>();
  resizing = false;
  startX!: number;
  startWidth!: number;
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.resizing = true;
    this.startX = event.clientX;
    this.startWidth = this.resizableElement.offsetWidth;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.resizing) {
      return;
    }
    let width = this.startWidth + (event.clientX - this.startX);
    if(width<=this.minSize){
      width=this.minSize;
    }
    this.resizableElement.style.flexBasis = `${width}px`;
    this.resized.emit({index: this.index,width});
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.resizing = false;
  }
}

// Write a unit test for the directive using jest
