
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ThumbnailInfo } from "@struct/models/struct/app/domain/editor/thumbnail-info";
import { StructEntityType } from "@struct/models/struct/app/domain/shared/struct-entity-type";

@Injectable({ providedIn: "root" })
export class ThumbnailsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getEntityThunbnails(entityIds: number[], entityType: StructEntityType): Observable<{ [key: number]: ThumbnailInfo }> {
    return this.http.post<{ [key: number]: ThumbnailInfo }>(`${this.apiUrl}entitythumbnails?entityType=${entityType}`, entityIds, this.httpOptions);
  }
}

