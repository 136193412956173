// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionPlanSetup } from "@struct/models/struct/shared/subscription/subscription-plan-setup";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscriptionPlan  {
  uid: string;
  name: string;
  setup: SubscriptionPlanSetup;
  description?: string | null;
  generallyAvailable: boolean;
}

export class SubscriptionPlan  implements ISubscriptionPlan {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  setup: SubscriptionPlanSetup = new SubscriptionPlanSetup();

  description: string | null = null;

  generallyAvailable = false;


  constructor(data?: Partial<ISubscriptionPlan>) {
    Object.assign(this, data);
  }
}