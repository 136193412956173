<struct-simple-select *ngIf="availableCultures" [ngModel]="value" [required]="required" [placeholder]="placeholder"
                      (ngModelChange)="cultureChanged($event)" [name]="name" [options]="availableCultures" bindLabel="name"
                      bindValue="cultureCode" [multiple]="multiple"
                      [customTemplate]="cultureTemplate"
                      data-cy="culture-select"
></struct-simple-select>

<ng-template #cultureTemplate let-option="option" let-bindValue="bindValue" let-bindLabel="bindLabel">
  <span class="fi fi-{{getFlag(option[bindValue])}}"></span>
  <span> {{ option[bindLabel] }} </span>
</ng-template>
