export interface IUserColumn {
    id : string;
    width : number;
}
export class UserColumn {
    id=''
    width =0;
  
    constructor(data?: Partial<IUserColumn>) {
        Object.assign(this, data);
      }
  }