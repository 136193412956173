export class Icon {
    name!: string;
    id!: string;
    type?: IconType;
}

export enum IconType {
    Struct,
    Material,

}
