<accounts-header [goBackToRoute]="'/organizations'">New organization</accounts-header>

<form #organizationForm="ngForm">

    <accounts-form>
        <accounts-form-section>
          <accounts-form-section-title> Organization Details </accounts-form-section-title>
          <accounts-form-section-description> Add organization details </accounts-form-section-description>
          <accounts-form-section-content>
            <struct-input [name]="'Organization Name'" [(value)]="organization.name" [required]="true"></struct-input>
          </accounts-form-section-content>
        </accounts-form-section>
      </accounts-form>
  

  <div class="px-0 py-0 bg-gray-50 text-right mt-10">
    <div *ngIf="showError" class="text-red-400 font-semibold mb-3">One or more fields are not correctly filled</div>
    <struct-button color="primary" buttonType="submit" (click)="submitOrganization()">Create organization</struct-button>
  </div>
</form>