import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, map, of, ReplaySubject, takeUntil } from 'rxjs';
import { UserModel } from '@struct/models/struct/authenticationmodule/models/user-model';
import { AuthenticationService } from './authentication/authentication.service';
import { MonitoringService } from './monitorService';

@Component({
  selector: 'accounts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Struct account';

  openSidenav = false;
  sidenavMode: MatDrawerMode = 'over';
  showSpinner = false;

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  @ViewChild(MatToolbar)
  toolbar!: MatToolbar;

  private destroyed$ = new ReplaySubject();
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);

  isAuthenticated: boolean = false;
  authenticatedUser: UserModel | null = null;
  openRoute = true;
  loaded = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth$.next(event.target.innerWidth);
  }

  constructor(
    private monitoringService: MonitoringService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url.indexOf('register-with-microsoft-callback') > -1 || 
        event.url.indexOf('sign-in-with-microsoft-callback') > -1 ||
        event.url.indexOf('change-sign-in-to-microsoft-callback') > -1 ||
        event.url.indexOf('accept-invite') > -1 ||
        event.url.indexOf('register-user') > -1 ||
        event.url.indexOf('reset-password') > -1 ? (this.openRoute = true) : (this.openRoute = false);
      }
    });

    this.screenWidth$
      .pipe(
        takeUntil(this.destroyed$),
        map(width => {
          if (width < 767) {
            this.openSidenav = false;
            this.sidenavMode = 'over';
          } else {
            this.sidenavMode = 'side';
            this.openSidenav = true;
          }
        })
      )
      .subscribe();

    this.authService.getAuthenticatedUser().subscribe(user => {
      this.isAuthenticated = user !== null;
      this.authenticatedUser = user;
    });
  }

  getUserRole(): string | null {
    return this.authenticatedUser?.role ?? null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
