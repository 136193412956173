import {Injectable} from '@angular/core';
import {Icon, IconType} from "./pack/icon";
import {MaterialIconsPack} from "./pack/material-icons-pack";
import {StructIconsPack} from "./pack/struct-icons.pack";

@Injectable({
    providedIn: 'root',
})
export class IconService {
    private matIconsPack: MaterialIconsPack = new MaterialIconsPack();
    private structIconsPack: StructIconsPack = new StructIconsPack();

    getIcons(ipIconPacks: string[]): Icon[] {
        const icons: Icon[] = [];
        ipIconPacks.forEach((ipIconPack) => {
            if (ipIconPack === 'mat' || ipIconPack === 'all') {
                const matIcons = this.matIconsPack.getIcons().map(icon => {
                    icon.type = IconType.Material;
                    return icon;
                });
                icons.push(...matIcons);
            }
            if(ipIconPack === 'struct' || ipIconPack === 'all') {
                const structIcons = this.structIconsPack.getIcons().map(icon => {
                    icon.type = IconType.Struct;
                    return icon;
                });
                icons.push(...structIcons);
            }
        });
        return icons;
    }
}


