import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'accounts-list-empty',
  templateUrl: './accounts-list-empty.component.html',
  styleUrls: ['./accounts-list-empty.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AccountsListEmptyComponent {

}
