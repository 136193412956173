<div class="flex gap-1 overflow-hidden animate-in fade-in duration-500">

  <ng-container *ngIf="isSimpleValueArray()">
    <ng-container *ngFor="let singleValue of getSimpleValueArray()">
      <div class="items-center gap-2 flex">
        <struct-avatar [name]="singleValue" [bgColor]="avatarColors.Blue"></struct-avatar> <span>{{ singleValue }}</span>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isSimpleValue()">
    <div class=" items-center gap-2 flex">
      <struct-avatar [name]="getSimpleValue()" [bgColor]="avatarColors.Blue"></struct-avatar> <span>{{ getSimpleValue() }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="isUserGroupModel()">
    <ng-container *ngFor="let singleValue of getUserGroupModels()">
      <div class="items-center flex overflow-hidden">
        <struct-avatar [name]="singleValue.value" [bgColor]="singleValue.isUser ? avatarColors.Blue : avatarColors.Green"></struct-avatar>
        <span class="sr-only">{{ singleValue.value }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
