import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-number-column',
  templateUrl: './struct-number-column.component.html',
  styleUrls: ['./struct-number-column.component.scss'],
})
export class StructNumberColumnComponent {
  @Input() value: number | null = null;
}
