// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
import { ValueReferenceQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/value-reference-query-operator";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IValueReferenceFieldQuery extends FieldQuery {
  filterValues: string[];
  queryOperator: ValueReferenceQueryOperator;
  fieldQueryType: string;
}

export class ValueReferenceFieldQuery extends FieldQuery implements IValueReferenceFieldQuery {
  filterValues: string[] = [];

  queryOperator: ValueReferenceQueryOperator = ValueReferenceQueryOperator.InList;

  override fieldQueryType = "ValueReferenceFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<IValueReferenceFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}