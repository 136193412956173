import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-button',
  templateUrl: './struct-button.component.html',
  styleUrls: ['./struct-button.component.scss'],
})
export class StructButtonComponent {
  @Input() color: string | null = 'default';
  @Input() disabled = false;
  @Input() buttonType = 'button';
  @Input() isStroked = false;
  @Input() working = false;
  @Input() pulsate = false;
}
