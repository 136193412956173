
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DatabaseTemplateModel } from "@struct/models/accountmanagement/api/models/tenant/database-template-model";
import { DeploymentStampModel } from "@struct/models/accountmanagement/api/models/tenant/deployment-stamp-model";
import { CloneTenantCommand } from "@struct/models/accountmanagement/domain/tenants/commands/clone-tenant-command";
import { CreateTenantCommand } from "@struct/models/accountmanagement/domain/tenants/commands/create-tenant-command";
import { Tenant } from "@struct/models/accountmanagement/domain/tenants/tenant";
import { AuditLogEntry } from "@struct/models/struct/shared/auditlogs/audit-log-entry";

@Injectable({ providedIn: "root" })
export class TenantApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getDeploymentStamps(): Observable<DeploymentStampModel[]> {
    return this.http.get<DeploymentStampModel[]>(`${this.apiUrl}deploymentstamps`, this.httpOptions );
  }

  getDatabaseTemplates(): Observable<DatabaseTemplateModel[]> {
    return this.http.get<DatabaseTemplateModel[]>(`${this.apiUrl}databasetemplates`, this.httpOptions );
  }

  deleteDatabaseTemplate(uid: string): Observable<DatabaseTemplateModel[]> {
    return this.http.delete<DatabaseTemplateModel[]>(`${this.apiUrl}databasetemplates/${uid}`, this.httpOptions );
  }

  getTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.apiUrl}tenants`, this.httpOptions );
  }

  getTenant(uid: string): Observable<Tenant> {
    return this.http.get<Tenant>(`${this.apiUrl}tenant/${uid}`, this.httpOptions );
  }

  getTenantAuditlog(uid: string): Observable<AuditLogEntry[]> {
    return this.http.get<AuditLogEntry[]>(`${this.apiUrl}tenant/${uid}/auditlog`, this.httpOptions );
  }

  cloneTenantToTemplate(cmd: CloneTenantCommand): Observable<AuditLogEntry[]> {
    return this.http.post<AuditLogEntry[]>(`${this.apiUrl}tenant/clone`, cmd, this.httpOptions);
  }

  createTenant(cmd: CreateTenantCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}tenant`, cmd, this.httpOptions);
  }

  deleteTenant(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}tenant/${uid}`, this.httpOptions );
  }
}

