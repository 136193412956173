// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionSetup } from "@struct/models/struct/shared/subscription/subscription-setup";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateSubscriptionCommand  {
  uid: string;
  name: string;
  subscriptionPlanUid: string;
  companyName: string;
  vatNumber: string;
  postal: string;
  city: string;
  address: string;
  address2?: string | null;
  country: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  invoiceEmail: string;
  firstInvoiceDate: Date | string;
  paymentCurrency: string;
  partnerUid?: string | null;
  setup?: SubscriptionSetup | null;
}

export class CreateSubscriptionCommand  implements ICreateSubscriptionCommand {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  subscriptionPlanUid = "00000000-0000-0000-0000-000000000000";

  companyName = "";

  vatNumber = "";

  postal = "";

  city = "";

  address = "";

  address2: string | null = null;

  country = "";

  contactPerson = "";

  contactPhone = "";

  contactEmail = "";

  invoiceEmail = "";

  firstInvoiceDate: Date | string = new Date(0);

  paymentCurrency = "";

  partnerUid: string | null = null;

  setup: SubscriptionSetup | null = null;


  constructor(data?: Partial<ICreateSubscriptionCommand>) {
    Object.assign(this, data);
  }
}