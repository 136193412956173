<mat-form-field>
  <mat-label>{{column.title}}</mat-label>
  <input *ngIf="inputType === dateInputTypes.Date" [placeholder]="'dd/MM/yyyy'" matInput #item [ngModel]="value" (ngModelChange)="onValueChanged($event)">

  <input *ngIf="inputType === dateInputTypes.DateOffset" [placeholder]="'>-14d'" matInput #item [(ngModel)]="column.selectedDate">

  <!-- <mat-label>{{column.title}}</mat-label>
  <input matInput [matDatepicker]="picker" #item [(ngModel)]="column.selectedDate" (keydown)="onKeyDown($event)" (dateChange)="onSearchColumnChange();">
  <mat-datepicker-toggle matSuffix [for]="picker">
    <struct-icon size="12" #tooltip="matTooltip" matTooltip="Choose date" icon="calendar" matDatepickerToggleIcon></struct-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker> -->

  <div matPrefix>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Select query operator" class="mr-2">
        <struct-icon size="12" #tooltip="matTooltip" matTooltip="Equals" icon="calendar" *ngIf="inputType === dateInputTypes.Date"></struct-icon>
        <struct-icon size="12" #tooltip="matTooltip" matTooltip="Equals" icon="priority_high" *ngIf="inputType === dateInputTypes.DateOffset"></struct-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button class="flex items-center text-sm" mat-menu-item (click)="inputType = dateInputTypes.Date">
          <struct-icon size="12" icon="calendar"></struct-icon>
          <span>Date search</span>
        </button>
        <button class="flex items-center text-sm" mat-menu-item (click)="inputType = dateInputTypes.DateOffset">
          <struct-icon size="12" icon="priority_high"></struct-icon>
          <span>Date offset search</span>
        </button>
      </mat-menu>
  </div>


  <struct-icon-button size="small" [useMaterialIcons]="true" matSuffix *ngIf="sortField !== column.id" class="text-gray-400" icon="unfold_more" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
  <struct-icon-button size="small" [useMaterialIcons]="true" matSuffix *ngIf="sortField === column.id && sortAsc === true" icon="keyboard_arrow_up" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
  <struct-icon-button size="small" [useMaterialIcons]="true" matSuffix *ngIf="sortField === column.id && sortAsc === false" icon="keyboard_arrow_down" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
</mat-form-field>
