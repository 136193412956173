// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum NumberQueryOperator {
  Equals = "Equals",
  NotEquals = "NotEquals",
  LargerThan = "LargerThan",
  SmallerThan = "SmallerThan",
  LargerThanOrEqualTo = "LargerThanOrEqualTo",
  SmallerThanOrEqualTo = "SmallerThanOrEqualTo",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
  InList = "InList",
  NotInList = "NotInList",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace NumberQueryOperator {
  export function toString(value: NumberQueryOperator): string {
    return NumberQueryOperator[value];
  }

  export const values = (): NumberQueryOperator[] => [
    NumberQueryOperator.Equals,
    NumberQueryOperator.NotEquals,
    NumberQueryOperator.LargerThan,
    NumberQueryOperator.SmallerThan,
    NumberQueryOperator.LargerThanOrEqualTo,
    NumberQueryOperator.SmallerThanOrEqualTo,
    NumberQueryOperator.IsEmpty,
    NumberQueryOperator.IsNotEmpty,
    NumberQueryOperator.InList,
    NumberQueryOperator.NotInList,
  ];

  export function parse(value: string, ignoreCase?: boolean): NumberQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'NumberQueryOperator';
  }
}
