import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { UiModule } from "@struct/ui/ui.module";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { Component, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Organization } from "@struct/models/struct/usermanagementmodule";
import { OrganizationsApiService } from "@struct/services/account-management";
import { StructSpinnerService } from "@struct/ui/struct-spinner";
import { cryptoHelper } from "@struct/helpers/lib/helpers/crypto-helper";
import { CreateOrganizationCommand } from "@struct/models/accountmanagement/domain/organizations/commands/create-organization-command";
import { AccountsFormComponent } from "../../accounts-ui/accounts-form/accounts-form/accounts-form.component";
import { AccountsFormSectionComponent } from "../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component";
import { AccountsFormSectionTitleComponent } from "../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component";
import { AccountsFormSectionDescriptionComponent } from "../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component";
import { AccountsFormSectionContentComponent } from "../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component";

@Component({
    selector: 'accounts-create-organization',
    templateUrl: 'create-organization.component.html',
    standalone: true,
    imports: [CommonModule, UiModule, RouterModule, AccountsHeaderComponent,AccountsFormComponent, AccountsFormSectionComponent, AccountsFormSectionTitleComponent, AccountsFormSectionDescriptionComponent,AccountsFormSectionContentComponent,AccountsFormSectionComponent],
  })

  

  export class CreateOrganizationComponent {
    constructor(private organizationApi: OrganizationsApiService, private spinnerService: StructSpinnerService, private router: Router) {
    }

    destroyed$: any;
    @ViewChild('organizationForm') organizationForm!: NgForm;
    showError = false;
    organization: Organization = new Organization();
    submitOrganization() {
        if (this.organizationForm.valid) {
            //this.spinnerService.changeShowSpinner(true);

            const command = new CreateOrganizationCommand({
                name: this.organization.name,
                uid: cryptoHelper.randomUUID(),
            });

            this.organizationApi
            .createOrganization(command)
            .subscribe(() => {
                //this.spinnerService.changeShowSpinner(false);
                this.router.navigateByUrl('/organizations');
            });
           
          } else {
            for (const i in this.organizationForm.controls) {
              this.organizationForm.controls[i].markAsTouched();
              this.showError = true;
            }
          }
    }
    
  }

  
