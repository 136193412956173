// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMyTenantSetStateCommand  {
  tenantUid: string;
  newState: string;
}

export class MyTenantSetStateCommand  implements IMyTenantSetStateCommand {
  tenantUid = "00000000-0000-0000-0000-000000000000";

  newState = "";


  constructor(data?: Partial<IMyTenantSetStateCommand>) {
    Object.assign(this, data);
  }
}