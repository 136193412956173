<struct-input [name]="column.title" [value]="column.searchText" (valueChange)="onValueChanged($event)" [floatLabelValue]="true" appearance="fill"
              data-cy="struct-valuereference-header.input" [attr.data-cy-value-selector]="column.title">
    <struct-input-suffix>
      <struct-icon-button [useMaterialIcons]="false" *ngIf="sortField !== column.id" class="text-gray-400" icon="enlarge" (click)="sortByMe($event)"></struct-icon-button>
      <struct-icon-button [useMaterialIcons]="false" *ngIf="sortField === column.id && sortAsc === true" icon="arrow_sm_up" (click)="sortByMe($event)"></struct-icon-button>
      <struct-icon-button [useMaterialIcons]="false" *ngIf="sortField === column.id && sortAsc === false" icon="arrow_sm_down" (click)="sortByMe($event)"></struct-icon-button>
    </struct-input-suffix>


  </struct-input>
