// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeclineInviteCommand  {
  inviteUid: string;
  token: string;
}

export class DeclineInviteCommand  implements IDeclineInviteCommand {
  inviteUid = "00000000-0000-0000-0000-000000000000";

  token = "";


  constructor(data?: Partial<IDeclineInviteCommand>) {
    Object.assign(this, data);
  }
}