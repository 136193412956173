<struct-app-content-container *ngIf="loaded">
  <accounts-header *ngIf="mode === 'change'">{{'ChangePassword.Title' | translate}}</accounts-header>
  <!-- <accounts-header *ngIf="mode === 'forgot'">{{'ChangePassword.ForgotPassword' | translate}}</accounts-header> -->
  <!-- <accounts-header *ngIf="mode === 'forgot-sent'">{{'ChangePassword.ForgotPasswordSent' | translate}}</accounts-header> -->
  <struct-app-content>
    <struct-app-content-workarea [noBottomPadding]="true" *ngIf="mode === 'change'">
      <ng-container *ngIf="working">
        <div class="absolute left-0 top-0 w-full h-full bg-white z-40 opacity-50"></div>
      </ng-container>
      <form #changePasswordForm="ngForm">
        <accounts-form>
          <accounts-form-content>
            <struct-form-layout>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline> {{'ChangePassword.CurrentPassword' | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{'ChangePassword.CurrentPasswordDescription' | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="6">
                    <struct-input [name]="'Current password'" [required]="true" [(value)]="currentPassword" inputType="password"></struct-input>
                    <div class="text-blue-800 mt-2">
                      <a class="cursor-pointer text-sm" (click)="forgotPassword()">Forgot password</a>
                    </div>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline>{{'ChangePassword.NewPassword' | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{'ChangePassword.NewPasswordDescription' | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="6">
                    <struct-input [name]="'New password'" [required]="true" [(value)]="newPassword" inputType="password"></struct-input>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>
              <struct-form-row [borderBelow]="false">
                <struct-form-row-headline>{{'ChangePassword.VerifyPassword' | translate}}</struct-form-row-headline>
                <struct-form-row-description>{{'ChangePassword.VerifyPasswordDescription' | translate}}</struct-form-row-description>
                <struct-form-row-grid>
                  <struct-form-row-grid-tile data-cols="6">
                    <struct-input [name]="'Verify new password'" [required]="true" [(value)]="verifyNewPassword" inputType="password"></struct-input>
                  </struct-form-row-grid-tile>
                </struct-form-row-grid>
              </struct-form-row>
            </struct-form-layout>
          </accounts-form-content>
        </accounts-form>
      </form>
    </struct-app-content-workarea>
    <struct-app-content-workarea [noBottomPadding]="true" *ngIf="mode === 'forgot'">
      <ng-container *ngIf="working">
        <div class="absolute left-0 top-0 w-full h-full bg-white z-40 opacity-50"></div>
      </ng-container>
      <div class="flex items-center gap-4">
        <div class="w-1/2 border-r h-full flex items-center p-4 justify-center">
          <div class="w-32 h-32 relative flex-grow-0 flex items-center justify-center animate-in slide-in-from-bottom-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-24 h-24"><g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10">
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,22 2,27.7999992 2,62 62,62 62,27.7999992 52,22 " stroke-linecap="butt"></polyline>
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,33.4000015 12,2 52,2 52,33.2000008 " stroke-linecap="butt"></polyline>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="2" y1="27.7999992" x2="62" y2="62" stroke-linecap="butt"></line>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="62" y1="27.7999992" x2="31.2000008" y2="44.4000015" stroke-linecap="butt"></line>
            </g></svg>
          </div>
        </div>

        <div class="flex flex-col gap-2 p-4">
          <h3 class="text-xl font-medium animate-in slide-in-from-bottom-4">{{'ChangePassword.ForgotPassword' | translate}}</h3>
          <div class="text-sm animate-in slide-in-from-bottom-4">
            {{'ChangePassword.SendResetPasswordMailDescription' | translate}}
          </div>
        </div>
      </div>
    </struct-app-content-workarea>
    <struct-app-content-workarea [noBottomPadding]="true" *ngIf="mode === 'forgot-sent'">
      <ng-container *ngIf="working">
        <div class="absolute left-0 top-0 w-full h-full bg-white z-40 opacity-50"></div>
      </ng-container>
      <div class="flex items-center gap-4">
        <div class="w-1/2 border-r h-full flex items-center p-4 justify-center">
          <div class="w-32 h-32 relative flex-grow-0 flex items-center justify-center animate-in slide-in-from-bottom-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-24 h-24"><g fill="currentColor" stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" stroke-miterlimit="10">
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,22 2,27.7999992 2,62 62,62 62,27.7999992 52,22 " stroke-linecap="butt"></polyline>
              <polyline fill="none" stroke="currentColor" stroke-width="3" points="12,33.4000015 12,2 52,2 52,33.2000008 " stroke-linecap="butt"></polyline>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="2" y1="27.7999992" x2="62" y2="62" stroke-linecap="butt"></line>
              <line fill="none" stroke="currentColor" stroke-width="3" x1="62" y1="27.7999992" x2="31.2000008" y2="44.4000015" stroke-linecap="butt"></line>
            </g></svg>
            <span class="absolute -bottom-1 -right-1 w-12 h-12 rounded-full ring-4 ring-gray-50 bg-green-600 text-white flex items-center justify-center">
              <struct-icon icon="check" class="w-6 h-6"></struct-icon>
            </span>
          </div>
        </div>

        <div class="flex flex-col gap-2 p-4">
          <h3 class="text-xl font-medium animate-in slide-in-from-bottom-4">{{'ChangePassword.ForgotPasswordSent' | translate}}</h3>
          <div class="text-sm animate-in slide-in-from-bottom-4">
            {{'ChangePassword.ForgotPasswordSentDescription' | translate}}
          </div>
        </div>
      </div>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div class="struct-app-footer-left">
      <button mat-button *ngIf="mode === 'forgot'" (click)="mode = 'change'">
        <struct-icon icon="arrow_left"></struct-icon>
        <span>Go back</span>
      </button>
    </div>
    <div *ngIf="showError" class="text-red-400 text-sm">{{errorMsg | translate}}</div>
    <struct-button buttonType="button" *ngIf="!working && mode !== 'forgot-sent'" (click)="cancel()" color="default">{{'General.Cancel' | translate}}</struct-button>
    <struct-button buttonType="button" *ngIf="!working && mode === 'forgot-sent'" (click)="cancel()" color="default">{{'General.Close' | translate}}</struct-button>
    <struct-button buttonType="button" *ngIf="mode === 'change'" [working]="working" (click)="changePassword()" color="primary" [disabled]="(changePasswordForm !== undefined && !changePasswordForm.valid)">{{'ChangePassword.ChangePassword' | translate}}</struct-button>
    <struct-button buttonType="button" *ngIf="mode === 'forgot'" [working]="working" (click)="sendResetEmail()" color="primary">{{'ChangePassword.SendResetMail' | translate}}</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
