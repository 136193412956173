import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {StructColumnHeaderComponent} from '../struct-column-header';
import {TextQueryOperator} from "@struct/models/struct/shared/search/datafieldqueries";

@Component({
  selector: 'struct-culture-header',
  templateUrl: './struct-culture-header.component.html',
  styleUrls: ['./struct-culture-header.component.scss'],
})
export class StructCultureHeaderComponent extends StructColumnHeaderComponent<TextQueryOperator> {
  @Input() sortField: string | null = null;
  @Input() sortAsc: boolean | null = null;

  @Output() sortingChanged = new EventEmitter<string>();

  TextQueryOperatorEnum = TextQueryOperator;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  get cdr() {
    return this._cdr;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortByMe(event: any): void {
    this.sortingChanged.emit(this.column.id);
    event.stopPropagation();
  }

  getDefaultQueryOperator(): TextQueryOperator {
    return TextQueryOperator.StartsWith;
  }

  getCurrentColumOperator(): TextQueryOperator {
    return this.column.textQueryOperator;
  }

  setColumnQueryOperator(queryOperator: TextQueryOperator): void {
    this.column.textQueryOperator = queryOperator;
  }

  shouldFloatLabelValue(queryOperator: TextQueryOperator): boolean {
    return queryOperator === TextQueryOperator.StartsWith;
  }
}

