
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferencedItemType } from "@struct/models/struct/app/domain/attributes/referenced-item-type";
import { AddInternalNoteCommand } from "@struct/models/struct/app/domain/internalnote/commands/add-internal-note-command";
import { InternalNote } from "@struct/models/struct/app/domain/internalnote/internal-note";

@Injectable({ providedIn: "root" })
export class InternalNotesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getInternalNotes(entityType: ReferencedItemType, entityId: number): Observable<InternalNote[]> {
    return this.http.get<InternalNote[]>(`${this.apiUrl}internalnotes/${entityType}/${entityId}`, this.httpOptions );
  }

  addNote(command: AddInternalNoteCommand): Observable<InternalNote> {
    return this.http.post<InternalNote>(`${this.apiUrl}internalnotes`, command, this.httpOptions);
  }
}

