// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModulePrices } from "@struct/models/struct/shared/subscription/module-prices";
import { OptionalFeatures } from "@struct/models/struct/shared/subscription/optional-features";
import { Price } from "@struct/models/struct/shared/subscription/price";
import { Quotas } from "@struct/models/struct/shared/subscription/quotas";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISubscriptionPlanSetup  {
  price: Price;
  optionalFeatures: OptionalFeatures;
  quotas: Quotas;
  modulePrices: ModulePrices;
}

export class SubscriptionPlanSetup  implements ISubscriptionPlanSetup {
  price: Price = new Price();

  optionalFeatures: OptionalFeatures = new OptionalFeatures();

  quotas: Quotas = new Quotas();

  modulePrices: ModulePrices = new ModulePrices();


  constructor(data?: Partial<ISubscriptionPlanSetup>) {
    Object.assign(this, data);
  }
}