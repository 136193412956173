// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ValueReferenceQueryOperator {
  InList = "InList",
  NotInList = "NotInList",
  IsEmpty = "IsEmpty",
  IsNotEmpty = "IsNotEmpty",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace ValueReferenceQueryOperator {
  export function toString(value: ValueReferenceQueryOperator): string {
    return ValueReferenceQueryOperator[value];
  }

  export const values = (): ValueReferenceQueryOperator[] => [
    ValueReferenceQueryOperator.InList,
    ValueReferenceQueryOperator.NotInList,
    ValueReferenceQueryOperator.IsEmpty,
    ValueReferenceQueryOperator.IsNotEmpty,
  ];

  export function parse(value: string, ignoreCase?: boolean): ValueReferenceQueryOperator | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'ValueReferenceQueryOperator';
  }
}
