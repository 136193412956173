// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProvisionStatusMessage } from "@struct/models/accountmanagement/domain/tenants/provision-status-message";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeProvisioningStatus  {
  publicAssetContainerDeleted: boolean;
  privateAssetContainerDeleted: boolean;
  appDataContainerDeleted: boolean;
  keyVaultEntryDeleted: boolean;
  databaseDeleted: boolean;
  deProvisionStatusMessages: ProvisionStatusMessage[];
}

export class DeProvisioningStatus  implements IDeProvisioningStatus {
  publicAssetContainerDeleted = false;

  privateAssetContainerDeleted = false;

  appDataContainerDeleted = false;

  keyVaultEntryDeleted = false;

  databaseDeleted = false;

  deProvisionStatusMessages: ProvisionStatusMessage[] = [];


  constructor(data?: Partial<IDeProvisioningStatus>) {
    Object.assign(this, data);
  }
}