// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMyTenantCloneTenantCommand  {
  name: string;
  description: string;
  tenantUid: string;
  organizationUid: string;
}

export class MyTenantCloneTenantCommand  implements IMyTenantCloneTenantCommand {
  name = "";

  description = "";

  tenantUid = "00000000-0000-0000-0000-000000000000";

  organizationUid = "00000000-0000-0000-0000-000000000000";


  constructor(data?: Partial<IMyTenantCloneTenantCommand>) {
    Object.assign(this, data);
  }
}