
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export const nameof = <T>(name: keyof T): string => name as string;

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function notString<T>(value: T | string): value is T {
  return typeof value !== "string";
}
export function isString<T>(value: T | string): value is string {
  return typeof value === "string";
}

export function isEmpty<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

export function isNull<T>(value: T | null): value is null {
  return value === null;
}

export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return value === undefined;
}

export function getKeyValue<T extends object, U extends keyof T>(key: U) {
  return (obj: T): T[U] => obj[key];
}

export function cmpBy<T>(a: T, b: T, by: (e: T) => number): number {
  return by(a) - by(b);
}

export function deepCopy<T>(value: T): T {
  return JSON.parse(JSON.stringify(value)) as T;
}
export const isNumber = (value: string | number | null): value is number => {
  return typeof value === "number" || Boolean(value && (Number(value) || value === "0"));
};

export const deepFlatten: (arr: any[]) => any[] = (arr: any[]) => [].concat(...arr.map(v => (Array.isArray(v) ? deepFlatten(v) : v)));

export function hasOwnProperty<X extends {}, Y extends PropertyKey>
(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}


export function camelize(str: string):string{
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export function randomString() {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-";
  let text = "";
  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
