export class RowActionResult {
  reloadSearch = false;
  navigateToRoute: string | null = null;

  getRoute(rowId: string): string | null {
    if (this.navigateToRoute === null) {
      return null;
    }
    return this.navigateToRoute.replace('{id}', rowId).replace('{rowId}', rowId).replace('{0}', rowId);
  }

  constructor(reloadSearch: boolean = false, navigateToRoute: string | null = null) {
    this.reloadSearch = reloadSearch;
    this.navigateToRoute = navigateToRoute;
  }
}
