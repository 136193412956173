// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DeploymentStatus {
  NotProvisioned = "NotProvisioned",
  Provisioning = "Provisioning",
  Provisioned = "Provisioned",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DeploymentStatus {
  export function toString(value: DeploymentStatus): string {
    return DeploymentStatus[value];
  }

  export const values = (): DeploymentStatus[] => [
    DeploymentStatus.NotProvisioned,
    DeploymentStatus.Provisioning,
    DeploymentStatus.Provisioned,
  ];

  export function parse(value: string, ignoreCase?: boolean): DeploymentStatus | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DeploymentStatus';
  }
}
