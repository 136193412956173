import { Component, Input } from '@angular/core';

@Component({
  selector: 'struct-media-column',
  templateUrl: './struct-media-column.component.html',
  styleUrls: ['./struct-media-column.component.scss'],
})
export class StructMediaColumnComponent {
  @Input() value? : string;
}
