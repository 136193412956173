<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/subscriptions'">New subscription</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #subscriptionform="ngForm">
        <accounts-subscription-details #subscriptionDetails [subscription]="subscription"></accounts-subscription-details>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button color="primary" buttonType="submit" (click)="submitSubscription()">Create subscription</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
