
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AttributeRevisionGraph } from "@struct/models/struct/app/domain/transaction/attribute-revision-graph";
import { ChangelogEntry } from "@struct/models/struct/app/domain/transaction/changelog-entry";
import { ChangelogSearchModel } from "@struct/models/struct/app/domain/transaction/changelog-search-model";
import { ItemRevisionHistory } from "@struct/models/struct/app/domain/transaction/item-revision-history";
import { PIMTransaction } from "@struct/models/struct/app/domain/transaction/pIM-transaction";
import { PIMTransactionItem } from "@struct/models/struct/app/domain/transaction/pIM-transaction-item";
import { PIMTransactionToItem } from "@struct/models/struct/app/domain/transaction/pIM-transaction-to-item";
import { RevisionChange } from "@struct/models/struct/app/domain/transaction/revision-change";
import { RevisionGraph } from "@struct/models/struct/app/domain/transaction/revision-graph";
import { ChangeResponseModel } from "@struct/models/struct/app/frontendapi/models/changelog/change-response-model";

@Injectable({ providedIn: "root" })
export class TransactionApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getNumberOfRevisions(itemId: string, itemType: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}transactions/number-of-revisions?itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}`, this.httpOptions );
  }

  getRevisionChangesComparedToCurrentValue(itemId: string, itemType: string, revisionNo: number): Observable<RevisionChange[]> {
    return this.http.get<RevisionChange[]>(`${this.apiUrl}transactions/revision-changes?itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}&revisionNo=${revisionNo}`, this.httpOptions );
  }

  getRevisionGraph(itemId: string, itemType: string, fromRevision: number, toRevision: number): Observable<RevisionGraph> {
    return this.http.get<RevisionGraph>(`${this.apiUrl}transactions/revision-graph?itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}&fromRevision=${fromRevision}&toRevision=${toRevision}`, this.httpOptions );
  }

  getAttributeRevisionGraph(attributeUid: string, itemId: string, itemType: string, skip: number, take: number): Observable<AttributeRevisionGraph> {
    return this.http.get<AttributeRevisionGraph>(`${this.apiUrl}transactions/field-revision-graph?attributeUid=${attributeUid}&itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}&skip=${skip}&take=${take}`, this.httpOptions );
  }

  rollbackRevision(itemId: string, itemType: string, revisionNo: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}transactions/rollback-revision?itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}&revisionNo=${revisionNo}`, null, this.httpOptions);
  }

  getRevisionData(itemId: string, itemType: string): Observable<ItemRevisionHistory[]> {
    return this.http.get<ItemRevisionHistory[]>(`${this.apiUrl}transactions/revision-data?itemId=${encodeURIComponent(itemId)}&itemType=${encodeURIComponent(itemType)}`, this.httpOptions );
  }

  getTransactions(): Observable<PIMTransactionItem[]> {
    return this.http.get<PIMTransactionItem[]>(`${this.apiUrl}transactions`, this.httpOptions );
  }

  getTransaction(uid: string): Observable<PIMTransactionItem> {
    return this.http.get<PIMTransactionItem>(`${this.apiUrl}transactions/${uid}`, this.httpOptions );
  }

  search(model: ChangelogSearchModel): Observable<PIMTransaction> {
    return this.http.post<PIMTransaction>(`${this.apiUrl}transactions/search`, model, this.httpOptions);
  }

  getAllUidsInSearchResult(model: ChangelogSearchModel): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiUrl}transactions/search/uids`, model, this.httpOptions);
  }

  getChangedEntities(transactionUid: string): Observable<ChangeResponseModel[]> {
    return this.http.get<ChangeResponseModel[]>(`${this.apiUrl}transactions/${transactionUid}/changed-entities`, this.httpOptions );
  }

  rollbackRevisions(transactionUid: string, model: PIMTransactionToItem[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}transactions/${transactionUid}/rollback-revisions`, model, this.httpOptions);
  }

  exportTransactions(transactionUids: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}transactions/export-transactions`, transactionUids, {responseType:'blob' as 'json', withCredentials: true});
  }

  exportTransactionChanges(transactionUid: string, model: ChangelogEntry[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}transactions/${transactionUid}/export-transaction-changes`, model, {responseType:'blob' as 'json', withCredentials: true});
  }

  exportTransaction(listItems: ChangeResponseModel[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}transactions/export-transaction`, listItems, {responseType:'blob' as 'json', withCredentials: true});
  }
}

