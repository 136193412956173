<struct-input [name]="column.title" [value]="column.searchText" (valueChange)="onValueChanged($event)" [floatLabelValue]="true" appearance="fill" class="cursort-default"
              data-cy="struct-text-header.input" [attr.data-cy-value-selector]="column.title">
    <struct-input-suffix>
      <struct-icon-button size="small" [useMaterialIcons]="false" *ngIf="sortField !== column.id" class="text-gray-400" icon="enlarge" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
      <struct-icon-button size="small" [useMaterialIcons]="false" *ngIf="sortField === column.id && sortAsc === true" icon="arrow_sm_up" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
      <struct-icon-button size="small" [useMaterialIcons]="false" *ngIf="sortField === column.id && sortAsc === false" icon="arrow_sm_down" iconSize="12" (click)="sortByMe($event)"></struct-icon-button>
    </struct-input-suffix>


  </struct-input>
