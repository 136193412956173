import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UserGroupSearchModel } from '@struct/models/struct/app/frontendapi/models/usergroups/user-group-search-model';
import { UserSearchModel } from '@struct/models/struct/app/frontendapi/models/users/user-search-model';
import { UserGroupsApiService, UsersApiService } from '@struct/services/frontend-api';
import { StructAvatarInitialsColor } from '@struct/ui/struct-avatar/struct-avatar-initials-color';
import { ReplaySubject, forkJoin, map, takeUntil } from 'rxjs';

export class UserUserGroupOptions {
    name: string = '';
    uid: string = '';
    isUser: boolean = false;
}

@Component({
  selector: 'struct-user-user-group-picker',
  templateUrl: './struct-user-user-group-picker.component.html',
  styleUrls: ['./struct-user-user-group-picker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class StructUserUserGroupPickerComponent implements OnInit
{
  @Input() multiple: boolean = false;
  @Input() valueUsers: string[] = [];
  @Input() valueUserGroups: string[] = [];
  @Input() required: boolean = false;
  @Output() valueUsersChange = new EventEmitter<string[]>();
  @Output() valueUserGroupsChange = new EventEmitter<string[]>();
  @Input({required:true}) name = 'usergroup';
  @Input() placeholder: string = 'placeholder';

  options: UserUserGroupOptions[] = [];
  users: UserSearchModel[] = [];
  userGroups: UserGroupSearchModel[] = [];

  avatarColors = StructAvatarInitialsColor;

  value: string[] = []

  private destroyed$ = new ReplaySubject();

  constructor(private userApiService: UsersApiService, private userGroupApiService: UserGroupsApiService, cdr: ChangeDetectorRef){
    forkJoin([this.userApiService.getUsers(), this.userGroupApiService.getUserGroups()])
    .pipe(
      takeUntil(this.destroyed$),
      map(([users, userGroups]) => {
        this.users = users ?? [];
        this.userGroups = userGroups ?? [];
        this.options = this.users.map(user => {
          const option: UserUserGroupOptions = new UserUserGroupOptions();
          option.name = user.name;
          option.uid = user.uid;
          option.isUser = true;
          return option;
      }).concat(this.userGroups.map(userGroup => {
          const option: UserUserGroupOptions = new UserUserGroupOptions();
          option.name = userGroup.name;
          option.uid = userGroup.uid;
          option.isUser = false;
          return option;
        }));
      })
    ).subscribe();
  }

  ngOnInit()
  {
    this.value = (this.valueUsers ?? []).concat(this.valueUserGroups ?? []);
  }

  userChanged(event: string[]){
    const assignedUsers = this.users.filter(user => event.some(id => id === user.uid)).map(u => u.uid);
    const assignedUserGroups = this.userGroups.filter(userGroup => event.some(id => id === userGroup.uid)).map(u => u.uid);
    console.log(assignedUsers)
    console.log(assignedUserGroups)


    this.valueUsersChange.emit(assignedUsers);
    this.valueUserGroupsChange.emit(assignedUserGroups);
  }
}
