import { Observable, of, switchMap } from 'rxjs';
import { IRowAction } from './models/row-action';
import { TableColumn } from './models/table-column';
import { ITableDefinition } from './models/table-definition';
import { ITableRow, Row } from './models/row';
import { BooleanOperator, BooleanQuery, DisplayType, Query, SearchResult, SimpleQuery } from '@struct/models/struct/shared/search';
import { SearchDefinitionModel } from '@struct/models/struct/shared/models';
import {
  BooleanFieldQuery,
  DateFieldQuery,
  DateOffsetFieldQuery,
  DateQueryOperator,
  FieldQuery,
  NumberFieldQuery,
  NumberQueryOperator,
  TextFieldQuery,
  TextQueryOperator,
} from '@struct/models/struct/shared/search/datafieldqueries';
import { StructUserUsergroupModel } from './struct-column-renderers/struct-user-usergroup-column/struct-user-usergroup-model';
import { StructBadgeItemModel } from './struct-column-renderers/struct-badge-item-column/struct-badge-item-model';
import { TableFilterModel } from './models/table-filter-model';


export class ClientSideSearchTableDefinition implements ITableDefinition {
  filter: TableFilterModel | null;
  items: any[] | null = new Array<any>();
  public isLoaded = false;

  constructor(
    public tableScope: string,
    public tableColumns: TableColumn[],
    public rowActions: IRowAction[],
    private idColumn: string,
    public icon: string,
    public rowRouteTemplate: string | null,
    public dataProvider: ClientSideSearchDataProvider,
    public defaultSearchFields: string[] = []
  ) {
    this.filter = null;
  }

  getAvailableColumns(): Observable<TableColumn[]> {
    return of(this.tableColumns);
  }

  getAllIds(args: Query | SimpleQuery | null): Observable<string[]> {
    return of(this.queryItems(this.items ?? [], args).map(x => x.uid));
  }

  getItems(): Observable<object[]> {
    if (this.isLoaded) {
      return of(Object.assign([], this.items));
    }
    return this.dataProvider.getData().pipe(
      switchMap(x => {
        this.items = x;
        this.isLoaded = true;
        return of([...(this.items ?? [])]);
      })
    );
  }

  getSearchResult(args: SearchDefinitionModel, fieldUids: string[], fromRefresh: boolean): Observable<SearchResult<ITableRow>> {
    if (fromRefresh) {
      this.isLoaded = false;
    }
    return this.getItems().pipe(
      switchMap(x => {
        const allHits = this.queryItems(x, args.searchQuery);
        return of(
          new SearchResult({
            hits: this.getSortedPagedHits(allHits, args.pageIndex, args.pageSize, args.sortField, args.sortAsc).map(
              x => new Row({ id: x[this.idColumn], data: x, icon: this.icon, thumbnailUrl: x.image, thumbnailLabel: x.imageLabel, thumbnailLabelBgColor: x.imageLabelBgColor, thumbnailLabelTextColor: x.imageLabelTextColor })
            ),
            totalHits: allHits.length,
          })
        );
      })
    );
  }

  private compare(sortField: string, a: any, b: any): number {
    if (a[sortField] < b[sortField]) {
      return -1;
    }
    if (a[sortField] > b[sortField]) {
      return 1;
    }
    return 0;
  }

  private getSortedPagedHits(hits: any[], pageIndex: number, pageSize: number, sortField: string | null, sortAsc: boolean): any[] {
    if (sortField != null) {
      hits = hits.sort((a, b) => this.compare(sortField, a, b));
      if (!sortAsc) {
        hits = hits.reverse();
      }
    }
    const start = pageIndex * pageSize;
    hits = hits.slice(start, start + pageSize);
    return hits;
  }

  private getFixedListQueryResult(allItems: any[], f: TextFieldQuery): any[] {
    let fieldResult: any[] = [];

    if (!allItems || allItems.length === 0) {
      return [];
    }

    const firstItem = allItems[0];
    const isArray = Array.isArray(firstItem[f.fieldUid]);

    if (!isArray) {
      switch (f.queryOperator) {
        case TextQueryOperator.StartsWith:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase().startsWith(f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.Contains:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase().includes(f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.Equals:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() == f.filterValue?.toLowerCase());
          break;
        case TextQueryOperator.IsEmpty:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() == null || x[f.fieldUid]?.toLowerCase() == '');
          break;
        case TextQueryOperator.IsNotEmpty:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() != null && x[f.fieldUid]?.toLowerCase() != '');
          break;
        case TextQueryOperator.NotContains:
          fieldResult = allItems.filter(x => !x[f.fieldUid]?.toLowerCase().includes(f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.NotEquals:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() != f.filterValue?.toLowerCase());
          break;
        case TextQueryOperator.NotStartsWith:
          fieldResult = allItems.filter(x => !x[f.fieldUid]?.toLowerCase().startsWith(f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.InList:
          //Not supported
          fieldResult = [];
          break;
        case TextQueryOperator.NotInList:
          //Not supported
          fieldResult = [];
          break;
        default:
          fieldResult = [];
      }
      return fieldResult;
    } else {
      switch (f.queryOperator) {
        case TextQueryOperator.StartsWith:
          fieldResult = allItems.filter(x => x[f.fieldUid].some((item : string) => item?.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
          break;
        case TextQueryOperator.Contains:
          fieldResult = allItems.filter(x => x[f.fieldUid].some((item : string) => item?.toLowerCase().includes(f.filterValue?.toLowerCase())));
          break;
        case TextQueryOperator.Equals:
          fieldResult = allItems.filter(x => x[f.fieldUid].some((item : string) => item?.toLowerCase() == f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.IsEmpty:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.length === 0);
          break;
        case TextQueryOperator.IsNotEmpty:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.length > 0);
          break;
        case TextQueryOperator.NotContains:
          fieldResult = allItems.filter(x => !x[f.fieldUid]?.some((item : string) => item?.toLowerCase().includes(f.filterValue?.toLowerCase())));
          break;
        case TextQueryOperator.NotEquals:
          fieldResult = allItems.filter(x => x[f.fieldUid]?.some((item : string) => item?.toLowerCase() != f.filterValue?.toLowerCase()));
          break;
        case TextQueryOperator.NotStartsWith:
          fieldResult = allItems.filter(x => !x[f.fieldUid]?.some((item : string) => item?.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
          break;
        case TextQueryOperator.InList:
          //Not supported
          fieldResult = [];
          break;
        case TextQueryOperator.NotInList:
          //Not supported
          fieldResult = [];
          break;
        default:
          fieldResult = [];
      }
      return fieldResult;
    }
  }

  private getTextQueryResult(allItems: any[], f: TextFieldQuery): any[] {
    let fieldResult: any[] = [];

    switch (f.queryOperator) {
      case TextQueryOperator.StartsWith:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase().startsWith(f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.Contains:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase().includes(f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.Equals:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() == f.filterValue?.toLowerCase());
        break;
      case TextQueryOperator.IsEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() == null || x[f.fieldUid]?.toLowerCase() == '');
        break;
      case TextQueryOperator.IsNotEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() != null && x[f.fieldUid]?.toLowerCase() != '');
        break;
      case TextQueryOperator.NotContains:
        fieldResult = allItems.filter(x => !x[f.fieldUid]?.toLowerCase().includes(f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.NotEquals:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.toLowerCase() != f.filterValue?.toLowerCase());
        break;
      case TextQueryOperator.NotStartsWith:
        fieldResult = allItems.filter(x => !x[f.fieldUid]?.toLowerCase().startsWith(f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.InList:
        //Not supported
        fieldResult = [];
        break;
      case TextQueryOperator.NotInList:
        //Not supported
        fieldResult = [];
        break;
      default:
        fieldResult = [];
    }
    return fieldResult;
  }

  private getBadgeItemQueryResult(allItems: any[], f: TextFieldQuery): any[] {
    let fieldResult: any[] = [];

    switch (f.queryOperator) {
      case TextQueryOperator.StartsWith:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.Contains:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase().includes(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.Equals:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase() == f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.IsEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.length === 0);
        break;
      case TextQueryOperator.IsNotEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.length > 0);
        break;
      case TextQueryOperator.NotContains:
        fieldResult = allItems.filter(x => !x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase().includes(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.NotEquals:
        fieldResult = allItems.filter(x => x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase() != f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.NotStartsWith:
        fieldResult = allItems.filter(x => !x[f.fieldUid]?.some((y: StructBadgeItemModel) => y.value?.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.InList:
        //Not supported
        fieldResult = [];
        break;
      case TextQueryOperator.NotInList:
        //Not supported
        fieldResult = [];
        break;
      default:
        fieldResult = [];
    }
    return fieldResult;
  }

  private getUserGroupQueryResult(allItems: any[], f: TextFieldQuery): any[] {
    let fieldResult: any[] = [];

    switch (f.queryOperator) {
      case TextQueryOperator.StartsWith:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.Contains:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase().includes(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.Equals:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase() === f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.IsEmpty:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).length === 0);
        break;
      case TextQueryOperator.IsNotEmpty:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).length > 0);
        break;
      case TextQueryOperator.NotContains:
        fieldResult = allItems.filter(x => !this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase().includes(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.NotEquals:
        fieldResult = allItems.filter(x => this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase() != f.filterValue?.toLowerCase()));
        break;
      case TextQueryOperator.NotStartsWith:
        fieldResult = allItems.filter(x => !this.mapUserUserGroups(x[f.fieldUid]).some(y => y.toLowerCase().startsWith(f.filterValue?.toLowerCase())));
        break;
      case TextQueryOperator.InList:
        //Not supported
        fieldResult = [];
        break;
      case TextQueryOperator.NotInList:
        //Not supported
        fieldResult = [];
        break;
      default:
        fieldResult = [];
    }
    return fieldResult;
  }

  private getNumberQueryResult(allItems: any[], f: NumberFieldQuery): any[] {
    let fieldResult: any[] = [];

    switch (f.queryOperator) {
      case NumberQueryOperator.Equals:
        fieldResult = allItems.filter(x => x[f.fieldUid] == f.filterValue);
        break;
      case NumberQueryOperator.IsEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid] == null || x[f.fieldUid] == '');
        break;
      case NumberQueryOperator.IsNotEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid] != null && x[f.fieldUid] != '');
        break;
      case NumberQueryOperator.NotEquals:
        fieldResult = allItems.filter(x => x[f.fieldUid] != f.filterValue);
        break;
      case NumberQueryOperator.InList:
        fieldResult = allItems.filter(() => f.filterValues?.some(x => x == f.filterValue));
        break;
      case NumberQueryOperator.NotInList:
        fieldResult = allItems.filter(() => !f.filterValues?.some(x => x == f.filterValue));
        break;
      case NumberQueryOperator.LargerThan:
        fieldResult = allItems.filter(x => x[f.fieldUid] > f.filterValue);
        break;
      case NumberQueryOperator.LargerThanOrEqualTo:
        fieldResult = allItems.filter(x => x[f.fieldUid] >= f.filterValue);
        break;
      case NumberQueryOperator.SmallerThan:
        fieldResult = allItems.filter(x => x[f.fieldUid] < f.filterValue);
        break;
      case NumberQueryOperator.SmallerThanOrEqualTo:
        fieldResult = allItems.filter(x => x[f.fieldUid] <= f.filterValue);
        break;
      default:
        fieldResult = [];
    }
    return fieldResult;
  }

  parseDate(date: Date | string | null): Date | null {
    if (date === null || date === undefined) {
      return null;
    }
    if (date instanceof Date) {
      return date;
    }
    return new Date(Date.parse(date));
  }

  private getDateQueryResult(allItems: any[], f: DateFieldQuery): any[] {
    let fieldResult: any[] = [];

    const filterDate = this.parseDate(f.filterValue);

    switch (f.queryOperator) {
      case DateQueryOperator.Equals:
        fieldResult = allItems.filter(x => this.parseDate(x[f.fieldUid])?.getTime() == filterDate?.getTime());
        break;
      case DateQueryOperator.IsEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid] == null || x[f.fieldUid] == '');
        break;
      case DateQueryOperator.IsNotEmpty:
        fieldResult = allItems.filter(x => x[f.fieldUid] != null && x[f.fieldUid] != '');
        break;
      case DateQueryOperator.NotEquals:
        fieldResult = allItems.filter(x => this.parseDate(x[f.fieldUid])?.getTime() != filterDate?.getTime());
        break;
      case DateQueryOperator.LargerThan:
        fieldResult = allItems.filter(x => {
          const parsed = this.parseDate(x[f.fieldUid]);
          return filterDate !== null && parsed !== null && parsed?.getTime() > filterDate?.getTime();
        });
        break;
      case DateQueryOperator.LargerThanOrEqualTo:
        fieldResult = allItems.filter(x => {
          const parsed = this.parseDate(x[f.fieldUid]);
          return filterDate !== null && parsed !== null && parsed?.getTime() >= filterDate?.getTime();
        });
        break;
      case DateQueryOperator.SmallerThan:
        fieldResult = allItems.filter(x => {
          const parsed = this.parseDate(x[f.fieldUid]);
          return filterDate !== null && parsed !== null && parsed?.getTime() < filterDate?.getTime();
        });
        break;
      case DateQueryOperator.SmallerThanOrEqualTo:
        fieldResult = allItems.filter(x => {
          const parsed = this.parseDate(x[f.fieldUid]);
          return filterDate !== null && parsed !== null && parsed?.getTime() <= filterDate?.getTime();
        });
        break;
      default:
        fieldResult = [];
    }
    return fieldResult;
  }

  private mapUserUserGroups(obj: any): string[] {
    if (obj instanceof StructUserUsergroupModel) {
      if (obj.value === null) {
        return [];
      }
      return [obj.value];
    }

    if (typeof obj === 'string') {
      if (obj === null || obj === undefined) {
        return [];
      }
      return [obj];
    }

    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        return [];
      }
      if (obj[0] instanceof StructUserUsergroupModel) {
        const result: string[] = [];
        obj.forEach(x => {
          const tmp = x as StructUserUsergroupModel;
          if (tmp.value !== null) {
            result.push(tmp.value);
          }
        });
        return result;
      }

      if (typeof obj[0] === 'string') {
        const result: string[] = [];
        obj.forEach(x => {
          const tmp = x as string;
          if (tmp !== null && tmp !== undefined) {
            result.push(tmp);
          }
        });
        return result;
      }
    }
    return [];
  }

  private getTableColumn(f: FieldQuery): TableColumn | null {
    const tableColumn = this.tableColumns.find(x => x.id === f.fieldUid);
    return tableColumn ?? null;
  }

  private queryItems(allItems: any[], query: Query | null): any[] {
    if (query === null) {
      return allItems;
    }

    if (query instanceof SimpleQuery) {
      let tmpResult = query.booleanOperator === BooleanOperator.And ? allItems : [];
      query.fieldQueries.forEach(f => {
        const tableColumn = this.getTableColumn(f);
        let fieldResult: any[] = [];

        if (f instanceof TextFieldQuery && tableColumn?.type === DisplayType.Text) {
          fieldResult = this.getTextQueryResult(allItems, f);
        } else if (f instanceof TextFieldQuery && tableColumn?.type === DisplayType.FixedList) {
          fieldResult = this.getFixedListQueryResult(allItems, f);
        } else if (f instanceof TextFieldQuery && tableColumn?.type === DisplayType.UserUserGroup) {
          fieldResult = this.getUserGroupQueryResult(allItems, f);
        } else if (f instanceof TextFieldQuery && tableColumn?.type === DisplayType.BadgeItem) {
          fieldResult = this.getBadgeItemQueryResult(allItems, f);
        } else if (f instanceof NumberFieldQuery) {
          fieldResult = this.getNumberQueryResult(allItems, f);
        } else if (f instanceof BooleanFieldQuery) {
          fieldResult = allItems.filter(x => x[f.fieldUid] == f.filterValue);
        } else if (f instanceof DateFieldQuery) {
          fieldResult = this.getDateQueryResult(allItems, f);
        } else if (f instanceof DateOffsetFieldQuery) {
          fieldResult = allItems;
        }

        if (query.booleanOperator === BooleanOperator.And) {
          tmpResult = tmpResult.filter(value => fieldResult.includes(value));
        } else {
          tmpResult = tmpResult.concat(fieldResult).filter(this.distinct);
        }
      });
      return tmpResult;
    } else if (query instanceof BooleanQuery) {
      let tmpResult = query.booleanOperator === BooleanOperator.And ? allItems : [];
      query.subQueries.forEach(b => {
        let tmpResultInner = b.booleanOperator === BooleanOperator.And ? tmpResult : [];
        tmpResultInner = this.queryItems(tmpResult, b);
        if (query.booleanOperator === BooleanOperator.And) {
          tmpResult = tmpResult.filter(value => tmpResultInner.includes(value));
        } else {
          tmpResult = tmpResult.concat(tmpResultInner).filter(this.distinct);
        }
      });
      return tmpResult;
    } else {
      return allItems;
    }
  }

  distinct(value: any, index: number, array: any[]): boolean {
    return array.indexOf(value) === index;
  }
}

export interface ClientSideSearchDataProvider {
  getData(): Observable<any[] | null>;
}
