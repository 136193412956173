// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/date-query-operator";
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDateFieldQuery extends FieldQuery {
  filterValue?: Date | string | null;
  queryOperator: DateQueryOperator;
  fieldQueryType: string;
}

export class DateFieldQuery extends FieldQuery implements IDateFieldQuery {
  filterValue: Date | string | null = null;

  queryOperator: DateQueryOperator = DateQueryOperator.Equals;

  override fieldQueryType = "DateFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<IDateFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}