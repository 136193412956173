// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIdsResult  {
  itemIds: string[];
}

export class IdsResult  implements IIdsResult {
  itemIds: string[] = [];


  constructor(data?: Partial<IIdsResult>) {
    Object.assign(this, data);
  }
}