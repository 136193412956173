<div class="flex justify-between py-2 text-sm font-medium">
    <dt class="text-gray-500">
        <ng-content select="[info-name]"></ng-content>
    </dt>
    <dd class="whitespace-nowrap text-gray-900">
        <div class="flex items-center">
            <p class="text-sm font-medium text-gray-900">
                <ng-content select="[info-value]"></ng-content>
            </p>
            <ng-content select="[info-icon]"></ng-content>
        </div>
    </dd>
</div>