<struct-app-content-container>
  <accounts-header>Create database template</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #inviteForm="ngForm">
        <struct-form-layout>
          <struct-form-row [borderBelow]="false" [stack]="true">
            <struct-form-row-headline>
              Name
            </struct-form-row-headline>
            <struct-form-row-description>
              Please enter a name
            </struct-form-row-description>
            <struct-form-row-grid>
              <struct-form-row-grid-tile data-cols="6">
                <struct-input [name]="'Name'"  [required]="true" [(value)]="command.name"></struct-input>
              </struct-form-row-grid-tile>
            </struct-form-row-grid>
          </struct-form-row>

          <struct-form-row [borderBelow]="false" [stack]="true">
            <struct-form-row-headline>
              Description
            </struct-form-row-headline>
            <struct-form-row-description>
              Please enter a description
            </struct-form-row-description>
            <struct-form-row-grid>
              <struct-form-row-grid-tile data-cols="6">
                <struct-input [name]="'Description'"  [required]="true" [(value)]="command.description"></struct-input>
              </struct-form-row-grid-tile>
            </struct-form-row-grid>
          </struct-form-row>

        </struct-form-layout>
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
      <struct-button color="default" (click)="cancel()">Cancel</struct-button>
      <struct-button color="primary" [disabled]="inviteForm.invalid ?? true" (click)="cloneTenant()">Create template</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
