// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeProvisioningStatus } from "@struct/models/accountmanagement/domain/tenants/de-provisioning-status";
import { DeploymentInfo } from "@struct/models/accountmanagement/domain/tenants/deployment-info";
import { ProvisioningStatus } from "@struct/models/accountmanagement/domain/tenants/provisioning-status";
import { Tenant } from "@struct/models/accountmanagement/domain/tenants/tenant";
import { TenantStatus } from "@struct/models/accountmanagement/domain/tenants/tenant-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDetailedTenant extends Tenant {
}

export class DetailedTenant extends Tenant implements IDetailedTenant {
  override uid = "00000000-0000-0000-0000-000000000000";

  override subscriptionUid = "00000000-0000-0000-0000-000000000000";

  override name = "";

  override slug = "";

  override status: TenantStatus = TenantStatus.AwaitingProvisioning;

  override created: Date | string = new Date(0);

  override createdBy = "";

  override deploymentInfo: DeploymentInfo = new DeploymentInfo();

  override provisioningStatus: ProvisioningStatus | null = null;

  override deProvisioningStatus: DeProvisioningStatus | null = null;

  override isEarlyAdopter = false;


  constructor(data?: Partial<IDetailedTenant>) {
    super(data);
    Object.assign(this, data);
  }
}