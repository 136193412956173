import { Injectable, Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';

type AbstractType<T> = abstract new (...args: any[]) => T;

@Injectable()
@Pipe({
  name: 'instanceofclass',
  standalone: true,
})
export class InstanceofclassPipe implements PipeTransform {
  public transform<T>(value: any, type: AbstractType<T>): T | undefined {

    if (value instanceof type) { 
      return value as T;
    }
    return undefined;
  }
}

@Injectable()
@Pipe({
  name: 'instanceof',
  standalone: true,
})
export class InstanceofPipe implements PipeTransform {
  public transform<T>(value: any, type: AbstractType<T>, discriminator: string): T | undefined {

    if (discriminator === undefined) {
      discriminator = value['type']
    }

    if (type.name === discriminator) { 
      return value as T;
    }
    return undefined;
  }
}