<struct-app-content-container>
  <accounts-header [headerButtonText]="'Create tenant'" (headerButtonClicked)="createTenant()">
    Tenants
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <struct-table *ngIf="tableDefinition !== null"
      [tableDefinition]="tableDefinition"
      [defaultPageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
      [showFirstLastPageButton]="false"
      [showPageSizeSelector]="true"
      [defaultColumnIds]="['name','slug','deploymentStamp']"
      >
      <struct-selected-items-actions>
        <struct-icon-button icon="edit" #tooltip="matTooltip" matTooltip="Change status" [matTooltipPosition]="'above'"></struct-icon-button>
        <struct-icon-button icon="delete" #tooltip="matTooltip" matTooltip="Delete tenants" [matTooltipPosition]="'above'"></struct-icon-button>
      </struct-selected-items-actions>
      </struct-table>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
