// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISearchResult<T>  {
  totalHits: number;
  hits: T[];
}

export class SearchResult<T>  implements ISearchResult<T> {
  totalHits = 0;

  hits: T[] = [];


  constructor(data?: Partial<ISearchResult<T>>) {
    Object.assign(this, data);
  }
}