import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UiModule } from "@struct/ui/ui.module";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountsListComponent } from "../../accounts-ui/accounts-list/accounts-list/accounts-list.component";
import { AccountsListContentComponent } from "../../accounts-ui/accounts-list/accounts-list-content/accounts-list-content.component";
import { SubscriptionModel } from "../../subscription/models/subscription-model";
import { MyTenantApiService, TenantApiService } from "@struct/services/account-management";
import { MyTenantsModel } from "@struct/models/accountmanagement/domain/mytenants/my-tenants-model";
import { AccountsListItemComponent } from "../../accounts-ui/accounts-list/accounts-list-item/accounts-list-item.component";
import { AccountsListItemDescriptionComponent } from "../../accounts-ui/accounts-list/accounts-list-item-description/accounts-list-item-description.component";
import { AccountsListItemBadgeComponent } from "../../accounts-ui/accounts-list/accounts-list-item-badge/accounts-list-item-badge.component";
import { environment } from "apps/accounts/src/environments/environment";
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from "@struct/ui/struct-dialog/StructDialogConfig";
import { MyTenantsCloneTenantDialogComponent } from "../mytenants-clone-dialog/mytenants-clone-dialog.component";
import { ProvisionStatusMessage, TenantStatus } from "@struct/models/accountmanagement/domain/tenants";
import { MyTenantsLogDialogComponent } from "../mytenants-log-dialog.component/mytenants-log-dialog.component";
import { ConfirmDialogData, StructConfirmDialogService } from "@struct/ui/struct-confirm-dialog";
import { map, of, switchMap } from "rxjs";
import { C } from "@angular/cdk/keycodes";
import { MyTenantSetStateCommand } from "@struct/models/struct/accountmanagement/domain/mytenants/commands/my-tenant-set-state-command";
import { StructNotificationService } from "@struct/ui/struct-notification";
import { RowActionResult } from "@struct/ui/struct-table";

@Component({
    selector: 'accounts-mytenants-list',
    templateUrl: 'mytenants-list.component.html',
    standalone: true,
    imports: [CommonModule, UiModule, AccountsHeaderComponent, AccountsListComponent, AccountsListContentComponent, AccountsListItemComponent, AccountsListItemDescriptionComponent, AccountsListItemBadgeComponent],
  })



  export class MyTenantsListComponent implements OnInit {
    subscriptions: MyTenantsModel[];
    subscriptionStatus: string[] = [];

    constructor(private myTenantsService : MyTenantApiService, 
        private tr : TranslateService, 
        private dialogService: MatDialog,
        private router : Router, 
        private currentRoute: ActivatedRoute,
        private notificationService: StructNotificationService,
        private confirmDialogService: StructConfirmDialogService,
    ) {
        this.subscriptions = [];
    }

    ngOnInit(): void {



        this.myTenantsService.getTenants().subscribe(subs => {
                this.subscriptions = subs;
        })

        
    }

    showLog(provisionStatusMessages: ProvisionStatusMessage[]) {

        const dialogConfig = new StructDialogConfig();
        dialogConfig.position = StructDialogPosition.Center;
        dialogConfig.width = StructDialogWidth.Quarter;

        const config = new MatDialogConfig();
        config.width = '900px';
        config.data = provisionStatusMessages;
        config.height = '900px';
        this.dialogService.open(MyTenantsLogDialogComponent, config).afterClosed().subscribe(x => console.log(x));
    }

    createTenant(subscriptionUid: string): void {
        this.router.navigateByUrl('/mytenants/create/'+subscriptionUid);
    }

    openInstance(slug?: string): void {
        let iSlug = '';

        if(slug)
            iSlug = slug;

        window.open(environment.tenantAppUrl.replace('{tenant}', iSlug), '_blank');
    }

    cloneTenant(tenantId: string): void {
        const dialogConfig = new StructDialogConfig();
        dialogConfig.position = StructDialogPosition.Center;
        dialogConfig.width = StructDialogWidth.Quarter;

        const config = new MatDialogConfig();
        config.width = '500px';
        config.data = tenantId;
        config.height = '500px';
        this.dialogService.open(MyTenantsCloneTenantDialogComponent, config).afterClosed().subscribe(x => console.log(x));
      }

      setState(tenantUid: string, newState: string):void {
        if(confirm('Are you sure you want to change state of this tenant?')) {

        
            const cmd = new MyTenantSetStateCommand();
            cmd.newState = newState;
            cmd.tenantUid = tenantUid;
            this.myTenantsService.setState(cmd).subscribe(response=> {
                if(!response.succeeded) {
                
                    this.notificationService.showErrorNotification(of(response.errors[0].message));
                } else {
                    window.location.href = '/mytenants';
                }
            })
        }
       
    }
  }
