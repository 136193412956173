import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { Router, RouterModule } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Invite } from '@struct/models/struct/usermanagementmodule/invite';
import { RegisterUserCommand } from '@struct/models/struct/usermanagementmodule/commands/register-user-command';
import { PasswordConfirmationValidatorService } from '../passwordConfirmValidator.service';
import { UsersApiService } from '@struct/services/account-management';
import { CommandError } from '@struct/models/struct/shared/models';
import { AuthenticationService } from '../authentication.service';
import { SignInRequest } from '@struct/models/struct/authenticationmodule/models/sign-in-request';
import { TenantInvite } from '@struct/models/struct/usermanagementmodule';

@Component({
  selector: 'accounts-register-user',
  standalone: true,
  imports: [CommonModule, UiModule, TranslateModule, RouterModule],
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit {
  @Input({required:true}) invite! : Invite;
  userForm: FormGroup
  errors: CommandError[] | null = null;
  showMarketing = false;
  userCreated: boolean = false;
  showError: boolean = false;
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;
  working = false;
  signUpType: string | null = null;

  constructor(private userApi: UsersApiService, private router: Router, private authService: AuthenticationService, private passConfValidator: PasswordConfirmationValidatorService) {
    this.userForm = new FormGroup({
      email: new FormControl({ value: "", disabled: true}, [Validators.required]),
      phoneNumber: new FormControl(""),
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      company: new FormControl(""),
      password: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("")
    });

    this.userForm.controls["confirmPassword"].setValidators([Validators.required, this.passConfValidator.validateConfirmPassword(this.userForm.controls["password"])]);
  }

  createWithMicrosoft() {
    this.authService.createWithEntraId(this.invite.email, this.invite.uid, this.invite.inviteToken);
  }

  createWithEmail() {
    this.signUpType = 'email';
  }

  ngOnInit(): void {
      this.userForm.controls["email"].setValue(this.invite.email);
      console.log(this.invite);
      console.log(this.invite.type === TenantInvite.name);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  togglePasswordConfirmVisibility() {
    this.passwordConfirmVisible = !this.passwordConfirmVisible;
  }

  toggleMarketingColumn() {
    this.showMarketing = !this.showMarketing;
  }

  public createUser() : void {
    this.working = true;
    if (!this.userForm.valid) {
      return;
    }
    const command = new RegisterUserCommand({
      inviteToken: this.invite.inviteToken,
      inviteUid: this.invite.uid,
      email: this.userForm.getRawValue().email,
      phoneNumber: this.userForm.value.phoneNumber,
      firstName: this.userForm.value.firstname,
      lastName: this.userForm.value.lastname,
      organization: this.userForm.value.company,
      password: this.userForm.value.password,
      confirmPassword: this.userForm.value.confirmPassword,
    });

    this.userApi.registerUser(command).subscribe(x => {
      if(x.succeeded){
        this.userCreated = true;
        const signInRequest = new SignInRequest({ email: this.userForm.getRawValue().email, password: this.userForm.value.password });
        this.authService.signIn(signInRequest).subscribe(x => {
          if(x.isSuccess && this.invite.type === TenantInvite.name){
            const t = this.invite as TenantInvite;
            window.location.href = t.tenantUrl;
          }
          else if(x.isSuccess){
            this.router.navigate(['/']);
          }
        });
      }
      else {
        this.errors = x.errors;
        this.showError = true;
      }
      this.working = false;
    });
  }
}
