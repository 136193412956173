// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum DateOffsetUnit {
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
  Years = "Years",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace DateOffsetUnit {
  export function toString(value: DateOffsetUnit): string {
    return DateOffsetUnit[value];
  }

  export const values = (): DateOffsetUnit[] => [
    DateOffsetUnit.Days,
    DateOffsetUnit.Weeks,
    DateOffsetUnit.Months,
    DateOffsetUnit.Years,
  ];

  export function parse(value: string, ignoreCase?: boolean): DateOffsetUnit | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'DateOffsetUnit';
  }
}
