// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommand } from "@struct/models/struct/shared/cqrs/i-command";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateUserProfileCommand  {
  id: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string | null;
  organization?: string | null;
  timeZoneId?: string | null;
  cultureCode?: string | null;
}

export class UpdateUserProfileCommand  implements IUpdateUserProfileCommand {
  id = "";

  firstname = "";

  lastname = "";

  phoneNumber: string | null = null;

  organization: string | null = null;

  timeZoneId: string | null = null;

  cultureCode: string | null = null;


  constructor(data?: Partial<IUpdateUserProfileCommand>) {
    Object.assign(this, data);
  }
}