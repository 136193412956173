import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'struct-culture-column',
  templateUrl: './struct-culture-column.component.html',
  styleUrls: ['./struct-culture-column.component.scss'],
})
export class StructCultureColumnComponent implements OnInit {
  @Input() value?: string;
  cultureIso = '';

  ngOnInit(): void {
    if(this.value) {
      this.cultureIso = this.value.split('-')[1].toLowerCase();
    }
  }
}
