// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFieldQuery  {
  fieldUid: string;
  fieldQueryType: string;
}

export class FieldQuery  implements IFieldQuery {
  fieldUid = "";

  fieldQueryType = "";


  constructor(data?: Partial<IFieldQuery>) {
    Object.assign(this, data);
  }
}