// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloneTenantCommand  {
  uid: string;
  name: string;
  description: string;
  organizationUid?: string | null;
}

export class CloneTenantCommand  implements ICloneTenantCommand {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  description = "";

  organizationUid: string | null = null;


  constructor(data?: Partial<ICloneTenantCommand>) {
    Object.assign(this, data);
  }
}