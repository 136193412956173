// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TenantStatus {
  AwaitingProvisioning = "AwaitingProvisioning",
  Provisioning = "Provisioning",
  ProvisioningFailed = "ProvisioningFailed",
  Active = "Active",
  Inactive = "Inactive",
  InMaintenance = "InMaintenance",
  AwaitingDeprovisioning = "AwaitingDeprovisioning",
  Deprovisioning = "Deprovisioning",
  DeprovisioningFailed = "DeprovisioningFailed",
  Deprovisioned = "Deprovisioned",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace TenantStatus {
  export function toString(value: TenantStatus): string {
    return TenantStatus[value];
  }

  export const values = (): TenantStatus[] => [
    TenantStatus.AwaitingProvisioning,
    TenantStatus.Provisioning,
    TenantStatus.ProvisioningFailed,
    TenantStatus.Active,
    TenantStatus.Inactive,
    TenantStatus.InMaintenance,
    TenantStatus.AwaitingDeprovisioning,
    TenantStatus.Deprovisioning,
    TenantStatus.DeprovisioningFailed,
    TenantStatus.Deprovisioned,
  ];

  export function parse(value: string, ignoreCase?: boolean): TenantStatus | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'TenantStatus';
  }
}
