<div class="relative">
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{placeholder}}</mat-label>
    <!-- Single chip row -->
    <div *ngIf="!multiple">

      <mat-chip-grid #chipGrid [ariaLabel]="placeholder" [required]="required" [formControl]="selectGrid">
        <mat-chip-row class="struct-simple-select__single-chip-row" (removed)="removeSelectedOption(singleSelectedOption)" (click)="focusOnInput()" *ngIf="singleSelectedOption !== null">
          <ng-container
            [ngTemplateOutlet]="currentTemplate"
            [ngTemplateOutletContext]="{ option: singleSelectedOption, bindValue: bindValue, bindLabel: bindLabel}">
          </ng-container>
        </mat-chip-row>
  
        <input
          [tabIndex]="tabIndex"
          #selectInput
          (keydown)="keyDown($event)"
          placeholder="{{singleSelectedOption !== null ? '' : ('General.Search' | translate)}}"
          (blur)="inputBlur($event)"
          (focus)="inputFocused()"
          [matChipInputAddOnBlur]="false"
          [formControl]="selectCtrl"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [attr.data-cy]="'struct-simple-select-input.single.search'"
        />
  
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption hideSingleSelectionIndicator (optionSelected)="selectOption($event)">
          <mat-option *ngFor="let option of renderedOptions" [value]="option">
            <ng-container
              [ngTemplateOutlet]="currentTemplate"
              [ngTemplateOutletContext]="{ option: option, bindValue: bindValue, bindLabel: bindLabel}">
            </ng-container>
          </mat-option>
          <div *ngIf="filteredOptions.length > pageSize && renderedOptions.length < filteredOptions.length" class="flex justify-center">
            <button type="button" mat-button color="primary" (click)="loadMore(); $event.stopPropagation()">{{"General.LoadMore" | translate}}</button>
          </div>
          <mat-option [disabled]="true" *ngIf="filteredOptions.length === 0 ?? true" [value]="null">
            <span>{{"General.NoResultsFound" | translate}}</span>
          </mat-option>
        </mat-autocomplete>
        <div class="flex gap-2 absolute right-0 top-0 h-full">
          <struct-icon icon="remove_12" size="10" class="text-gray-500 cursor-pointer"
            #tooltip="matTooltip" matTooltip="{{'General.Clear' | translate}}" [matTooltipPosition]="'below'"
            (click)="removeSelectedOption(singleSelectedOption)"
            *ngIf="!disabled && singleSelectedOption !== null">
          </struct-icon>
          <struct-icon icon="down_arrow_12" size="10" (click)="focusOnInput()" class="text-gray-500 cursor-pointer"></struct-icon>
        </div>
  
      </mat-chip-grid>
    </div>
    <!-- Multi chip row -->
    <div *ngIf="multiple">

      <mat-chip-grid class="struct-simple-select__multiple-chip-row" #chipGrid [ariaLabel]="placeholder" [required]="required" [formControl]="selectGrid"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)">
        <mat-chip-row cdkDrag *ngFor="let selectedOption of selectedOptions" (removed)="removeSelectedOption(selectedOption)">
          <ng-container
            [ngTemplateOutlet]="currentTemplate"
            [ngTemplateOutletContext]="{ option: selectedOption, bindValue: bindValue, bindLabel: bindLabel}">
          </ng-container>
          <button matChipRemove [attr.aria-label]="('General.Remove' | translate) + ' ' + selectedOption[bindLabel]">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>

        <input
          #selectInput
          placeholder="{{'General.Search' | translate}}"
          (blur)="inputBlur($event)"
          (focus)="inputFocused()"
          [matChipInputAddOnBlur]="false"
          [formControl]="selectCtrl"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [attr.data-cy]="'struct-simple-select-input.multiple.search'"
      />
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption hideSingleSelectionIndicator (optionSelected)="selectOption($event)">
          <mat-option *ngFor="let option of renderedOptions" [value]="option">
            <ng-container
              [ngTemplateOutlet]="currentTemplate"
              [ngTemplateOutletContext]="{ option: option, bindValue: bindValue, bindLabel: bindLabel}">
            </ng-container>
          </mat-option>
          <div *ngIf="filteredOptions.length > pageSize && renderedOptions.length < filteredOptions.length" class="flex justify-center">
            <button #loadMoreBtn type="button" mat-button color="primary" (click)="loadMore(); $event.stopPropagation()">{{"General.LoadMore" | translate}}</button>
          </div>
          <mat-option [disabled]="true" *ngIf="filteredOptions.length === 0 ?? true" [value]="null">
            <span>{{"General.NoResultsFound" | translate}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-chip-grid>
      
    </div>
    <div #matSuffixRef matSuffix *ngIf="showSuffix">
      <ng-content select="[selectSuffix]"></ng-content>
    </div>
    <mat-error *ngIf="required && selectedOptions.length === 0 && touched">{{"General.FieldRequired" | translate}}</mat-error>
  </mat-form-field>
</div>

<ng-template #defaultTemplate let-bindLabel="bindLabel" let-bindValue="bindValue" let-option="option">
  {{option[bindLabel]}}
</ng-template>
