// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountRole } from "@struct/models/struct/shared/models/account-role";
import { AuthenticationMethod } from "@struct/models/struct/usermanagementmodule/authentication-method";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileModel  {
  id: string;
  email: string;
  authenticationMethod: AuthenticationMethod;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  organization?: string | null;
  cultureCode?: string | null;
  timezoneId?: string | null;
  role: AccountRole;
}

export class ProfileModel  implements IProfileModel {
  id = "";

  email = "";

  authenticationMethod: AuthenticationMethod = AuthenticationMethod.UsernamePassword;

  firstName: string | null = null;

  lastName: string | null = null;

  phoneNumber: string | null = null;

  organization: string | null = null;

  cultureCode: string | null = null;

  timezoneId: string | null = null;

  role: AccountRole = AccountRole.User;


  constructor(data?: Partial<IProfileModel>) {
    Object.assign(this, data);
  }
}