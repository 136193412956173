<struct-app-content-container>
<accounts-header [goBackToRoute]="'/tenants'">New tenant</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
    <form #tenantForm="ngForm">
      <accounts-tenant-details #tenantDetails [tenant]="tenant"></accounts-tenant-details>
    </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold mb-3">One or more fields are not correctly filled</div>
    <struct-button color="primary" buttonType="submit" (click)="submitTenant()">Create tenant</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
