// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateOffsetQueryOperator } from "@struct/models/struct/shared/search/datafieldqueries/date-offset-query-operator";
import { DateOffsetUnit } from "@struct/models/struct/shared/search/datafieldqueries/date-offset-unit";
import { FieldQuery } from "@struct/models/struct/shared/search/datafieldqueries/field-query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDateOffsetFieldQuery extends FieldQuery {
  offset: number;
  offsetUnit: DateOffsetUnit;
  queryOperator: DateOffsetQueryOperator;
  fieldQueryType: string;
}

export class DateOffsetFieldQuery extends FieldQuery implements IDateOffsetFieldQuery {
  offset = 0;

  offsetUnit: DateOffsetUnit = DateOffsetUnit.Days;

  queryOperator: DateOffsetQueryOperator = DateOffsetQueryOperator.LargerThan;

  override fieldQueryType = "DateOffsetFieldQuery";

  override fieldUid = "";


  constructor(data?: Partial<IDateOffsetFieldQuery>) {
    super(data);
    Object.assign(this, data);
  }
}