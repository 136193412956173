// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanOperator } from "@struct/models/struct/shared/search/boolean-operator";
import { Query } from "@struct/models/struct/shared/search/query";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBooleanQuery extends Query {
  subQueries: Query[];
  queryType: string;
}

export class BooleanQuery extends Query implements IBooleanQuery {
  subQueries: Query[] = [];

  override queryType = "BooleanQuery";

  override booleanOperator: BooleanOperator = BooleanOperator.And;

  override includeArchived = false;


  constructor(data?: Partial<IBooleanQuery>) {
    super(data);
    Object.assign(this, data);
  }
}