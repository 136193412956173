import {ComponentType} from "@angular/cdk/overlay";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from "./StructDialogConfig";

@Injectable({
  providedIn: 'root',
})
export class StructDialogService {
  constructor(private dialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public open<TOut>(component: ComponentType<any>, data: any, structDialogConfig?: StructDialogConfig): Observable<TOut>  {

    const panelClass = ['struct-dialog', 'struct-dialog-current'];
    let position = { top: '0', right: '0', bottom: '0', left: 'auto' };
    let dialogTypeClass = '';
    let positionClass = '';
    let widthClass = '';

    // Positions
    if(structDialogConfig !== undefined && structDialogConfig.position !== null){
      if(structDialogConfig.position === StructDialogPosition.Left){
        position = { top: '0', right: 'auto', bottom: '0', left: '0' };
        positionClass = 'struct-sidebar-dialog-position-left';
        dialogTypeClass = 'struct-sidebar-dialog';
      }
      if(structDialogConfig.position === StructDialogPosition.Right){
        position = { top: '0', right: '0', bottom: '0', left: 'auto' };
        positionClass = 'struct-sidebar-dialog-position-right';
        dialogTypeClass = 'struct-sidebar-dialog';
      }
      if(structDialogConfig.position === StructDialogPosition.Center){
        position = { top: 'auto', right: 'auto', bottom: 'auto', left: 'auto' };
        positionClass = 'struct-sidebar-dialog-position-center';
      }
    } else {
      positionClass = 'struct-sidebar-dialog-position-right';
      dialogTypeClass = 'struct-sidebar-dialog';
    }

    // Width
    if(structDialogConfig !== undefined && structDialogConfig.width !== null){
      if(structDialogConfig.width === StructDialogWidth.Quarter){
        widthClass = 'struct-sidebar-dialog-width-quarter';
      }
      if(structDialogConfig.width === StructDialogWidth.Half){
        widthClass = 'struct-sidebar-dialog-width-half';
      }
      if(structDialogConfig.width === StructDialogWidth.Full){
        widthClass = 'struct-sidebar-dialog-width-full';
      }
      if(structDialogConfig.width === StructDialogWidth.Auto){
        widthClass = 'struct-sidebar-dialog-width-auto';
      }
    } else {
      widthClass = 'struct-sidebar-dialog-width-half';
    }

    panelClass.push(positionClass);
    panelClass.push(dialogTypeClass);
    panelClass.push(widthClass);

    const dialogConfig = <MatDialogConfig>{
      panelClass: panelClass,
      position: position,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      maxWidth: 'unset',
      hasBackdrop: this.dialog.openDialogs.length === 0,
      data: data,
      autoFocus: false,
    };

    let index = 1;
    this.dialog.openDialogs
      .sort(this.sortById)
      .reverse()
      .forEach(d => {
        this.removeAllPanelClasses(d);
        d.addPanelClass('struct-sidebar-dialog-level-' + index);
        index++;
      });

    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.disableClose = true;
    dialogRef.keydownEvents().subscribe(e => {
      if (e.key === 'Escape') {
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if(this.dialog.openDialogs.length > 0){

        let index = 0;

        this.dialog.openDialogs
          .sort(this.sortById)
          .reverse()
          .forEach(d => {
            this.removeAllPanelClasses(d);
            if (index !== 0) {
              d.addPanelClass('struct-sidebar-dialog-level-' + index);
            } else {
              d.addPanelClass('struct-sidebar-dialog-current');
            }
            index++;
          });
      }
    });

    return dialogRef.afterClosed();
  }

  private removeAllPanelClasses(dialogRef: MatDialogRef<any, any>): void {
    dialogRef.removePanelClass('struct-sidebar-dialog-current');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-1');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-2');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-3');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-4');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-5');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-6');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-7');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-8');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-9');
    dialogRef.removePanelClass('struct-sidebar-dialog-level-10');
  }

  public sortById(a: MatDialogRef<any, any>, b: MatDialogRef<any, any>): number {
    if(a.id < b.id){
      return -1;
    }
    if(a.id > b.id){
      return 1;
    }
    return 0;
  }
}
