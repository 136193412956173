// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvite  {
  uid: string;
  email: string;
  created: Date | string;
  expires: Date | string;
  invitedBy: string;
  inviteToken: string;
  accepted?: boolean | null;
  type: string;
}

export class Invite  implements IInvite {
  uid = "00000000-0000-0000-0000-000000000000";

  email = "";

  created: Date | string = new Date(0);

  expires: Date | string = new Date(0);

  invitedBy = "";

  inviteToken = "";

  accepted: boolean | null = null;

  type = "";


  constructor(data?: Partial<IInvite>) {
    Object.assign(this, data);
  }
}