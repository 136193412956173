// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionPlanSetup } from "@struct/models/struct/shared/subscription/subscription-plan-setup";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateSubscriptionPlanCommand  {
  uid: string;
  name: string;
  description?: string | null;
  sortOrder: number;
  generallyAvailable: boolean;
  setup?: SubscriptionPlanSetup | null;
}

export class UpdateSubscriptionPlanCommand  implements IUpdateSubscriptionPlanCommand {
  uid = "00000000-0000-0000-0000-000000000000";

  name = "";

  description: string | null = null;

  sortOrder = 0;

  generallyAvailable = false;

  setup: SubscriptionPlanSetup | null = null;


  constructor(data?: Partial<IUpdateSubscriptionPlanCommand>) {
    Object.assign(this, data);
  }
}