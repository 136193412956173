
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AttributeEditorModel } from "@struct/models/struct/app/domain/dataconfiguration/implementation/properties/attribute-editor-model";
import { DeleteGlobalListValuesCommand } from "@struct/models/struct/app/domain/globallists/commands/delete-global-list-values-command";
import { GlobalListValueReferenceModel } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-value-reference-model";
import { GlobalListValueSortingModel } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-value-sorting-model";
import { GlobalListValueTemplateModel } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-value-template-model";
import { GlobalListValueWrapper } from "@struct/models/struct/app/frontendapi/models/globallist/global-list-value-wrapper";

@Injectable({ providedIn: "root" })
export class GlobalListValuesApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getGlobalLisValueReferences(globalListUid: string): Observable<GlobalListValueReferenceModel[]> {
    return this.http.get<GlobalListValueReferenceModel[]>(`${this.apiUrl}globallistvalues/${globalListUid}/referencemodels`, this.httpOptions );
  }

  getGlobalListValue(uid: string): Observable<GlobalListValueWrapper> {
    return this.http.get<GlobalListValueWrapper>(`${this.apiUrl}globallistvalues/${uid}`, this.httpOptions );
  }

  getGlobalListValueTemplate(globalListUid: string): Observable<GlobalListValueTemplateModel> {
    return this.http.get<GlobalListValueTemplateModel>(`${this.apiUrl}globallistvalues/template/${globalListUid}`, this.httpOptions );
  }

  createGlobalListValue(model: AttributeEditorModel): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}globallistvalues`, model, this.httpOptions);
  }

  updateGlobalListValue(model: GlobalListValueWrapper): Observable<GlobalListValueWrapper> {
    return this.http.patch<GlobalListValueWrapper>(`${this.apiUrl}globallistvalues`, model, this.httpOptions);
  }

  deleteGlobalListValue(command: DeleteGlobalListValuesCommand): Observable<GlobalListValueWrapper> {
    return this.http.put<GlobalListValueWrapper>(`${this.apiUrl}globallistvalues/delete`, command, this.httpOptions);
  }

  saveGlobalListValueSorting(globalListValueSort: GlobalListValueSortingModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}globallistvalues/sorting`, globalListValueSort, this.httpOptions);
  }
}

