
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateSubscriptionPlanCommand } from "@struct/models/struct/shared/subscription/commands/create-subscription-plan-command";
import { UpdateSubscriptionPlanCommand } from "@struct/models/struct/shared/subscription/commands/update-subscription-plan-command";
import { SubscriptionPlan } from "@struct/models/struct/shared/subscription/subscription-plan";

@Injectable({ providedIn: "root" })
export class SubscriptionPlanApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) { }


  getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(`${this.apiUrl}subscriptionPlans`, this.httpOptions );
  }

  getSubscriptionPlan(uid: string): Observable<SubscriptionPlan> {
    return this.http.get<SubscriptionPlan>(`${this.apiUrl}subscriptionPlans/${uid}`, this.httpOptions );
  }

  createSubscriptionPlan(cmd: CreateSubscriptionPlanCommand | null): Observable<SubscriptionPlan> {
    return this.http.post<SubscriptionPlan>(`${this.apiUrl}subscriptionPlans`, cmd, this.httpOptions);
  }

  updateSubscriptionPlan(cmd: UpdateSubscriptionPlanCommand | null): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}subscriptionPlans`, cmd, this.httpOptions);
  }

  deletedSubscriptionPlan(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}subscriptionPlans/${uid}`, this.httpOptions );
  }
}

