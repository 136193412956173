// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomExtensionQuota  {
  numCustomExtensionsIncluded: number;
}

export class CustomExtensionQuota  implements ICustomExtensionQuota {
  numCustomExtensionsIncluded = 0;


  constructor(data?: Partial<ICustomExtensionQuota>) {
    Object.assign(this, data);
  }
}