// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOptionalFeatures  {
  advancedMediaLibrary: boolean;
  businessRules: boolean;
  dynamicCategories: boolean;
  dimensionedAttributes: boolean;
  businessUnits: boolean;
  enrichmentInsights: boolean;
  workflows: boolean;
  userTasks: boolean;
}

export class OptionalFeatures  implements IOptionalFeatures {
  advancedMediaLibrary = false;

  businessRules = false;

  dynamicCategories = false;

  dimensionedAttributes = false;

  businessUnits = false;

  enrichmentInsights = false;

  workflows = false;

  userTasks = false;


  constructor(data?: Partial<IOptionalFeatures>) {
    Object.assign(this, data);
  }
}