// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangelogRetentionQuota  {
  numDaysRetention: number;
}

export class ChangelogRetentionQuota  implements IChangelogRetentionQuota {
  numDaysRetention = 0;


  constructor(data?: Partial<IChangelogRetentionQuota>) {
    Object.assign(this, data);
  }
}