// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum TenantUserStatus {
  Active = "Active",
  Suspended = "Suspended",
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-namespace
export namespace TenantUserStatus {
  export function toString(value: TenantUserStatus): string {
    return TenantUserStatus[value];
  }

  export const values = (): TenantUserStatus[] => [
    TenantUserStatus.Active,
    TenantUserStatus.Suspended,
  ];

  export function parse(value: string, ignoreCase?: boolean): TenantUserStatus | undefined {
      const search = ignoreCase ? value.toLowerCase() : value;
      return values().find(v => (ignoreCase ? toString(v).toLowerCase() : toString(v)) === search);
  }

  export function typeString(): string {
      return 'TenantUserStatus';
  }
}
