<img src="/assets/filetypes/file-{{filetype}}.svg" [ngStyle]="{'width': size + 'px', 'height': size + 'px'}" *ngIf="filetype !== null">
<img src="/assets/filetypes/file-unknown.svg" [ngStyle]="{'width': size + 'px', 'height': size + 'px'}" *ngIf="filetype === null">
<!--
<svg xmlns="http://www.w3.org/2000/svg" attr.height="{{size}}" attr.width="{{size}}" viewBox="0 0 48 48" *ngIf="filetype !== null">
  <title>file {{filetype}}</title>

  <g class="" *ngIf="filetype === 'pdf'">
    <polygon class="fill-red-800" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
    <path class="struct-filetype-page" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
    <path class="struct-filetype-shadow" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
    <path class="fill-red-700" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
    <path class="struct-filetype-name" d="M20.071,30.645c0,0.878-0.259,1.556-0.776,2.033c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122 v-7.853h2.718c0.992,0,1.737,0.217,2.234,0.649C19.822,29.23,20.071,29.847,20.071,30.645z M16.494,31.666h0.387 c0.319,0,0.572-0.09,0.76-0.27c0.188-0.179,0.282-0.426,0.282-0.74c0-0.53-0.293-0.795-0.881-0.795h-0.548V31.666z"></path>
    <path class="struct-filetype-name" d="M27.988,31.907c0,1.311-0.361,2.32-1.083,3.029C26.184,35.646,25.17,36,23.863,36h-2.541v-7.853h2.718 c1.26,0,2.233,0.322,2.919,0.967C27.645,29.759,27.988,30.689,27.988,31.907z M25.786,31.982c0-0.72-0.142-1.253-0.427-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S25.786,32.752,25.786,31.982z"></path>
    <path class="struct-filetype-name" d="M31.484,36h-2.089v-7.853h4.641v1.702h-2.551v1.499h2.353v1.702h-2.353V36z"></path>
  </g>

  <g class="" *ngIf="filetype === 'xlsx'">
    <polygon class="fill-green-800" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
    <path class="struct-filetype-page" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
    <path class="struct-filetype-shadow" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
    <path class="fill-green-700" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
    <path class="struct-filetype-name" d="M17.592,36h-2.455l-1.531-2.455L12.092,36H9.691l2.605-4.018l-2.449-3.835H12.2l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L17.592,36z"></path>
    <path class="struct-filetype-name" d="M18.473,36v-7.853h2.122v6.14h3.024V36H18.473z"></path>
    <path class="struct-filetype-name" d="M30.01,33.615c0,0.487-0.124,0.919-0.371,1.297s-0.604,0.672-1.069,0.881 c-0.465,0.21-1.012,0.314-1.638,0.314c-0.523,0-0.961-0.037-1.316-0.11s-0.723-0.201-1.106-0.384v-1.891 c0.405,0.208,0.825,0.37,1.262,0.486s0.838,0.174,1.203,0.174c0.315,0,0.546-0.054,0.693-0.163s0.22-0.25,0.22-0.422 c0-0.107-0.03-0.201-0.088-0.282c-0.06-0.08-0.154-0.162-0.285-0.244c-0.13-0.082-0.479-0.251-1.044-0.505 c-0.512-0.232-0.896-0.458-1.152-0.677c-0.256-0.219-0.446-0.469-0.569-0.752c-0.124-0.283-0.186-0.617-0.186-1.004 c0-0.724,0.263-1.288,0.79-1.692s1.25-0.606,2.17-0.606c0.813,0,1.642,0.188,2.487,0.563l-0.65,1.638 c-0.734-0.336-1.368-0.504-1.901-0.504c-0.276,0-0.476,0.048-0.602,0.145s-0.188,0.217-0.188,0.359c0,0.154,0.08,0.292,0.239,0.414 c0.159,0.122,0.591,0.344,1.297,0.666c0.677,0.305,1.146,0.631,1.41,0.98C29.878,32.646,30.01,33.085,30.01,33.615z"></path>
    <path class="struct-filetype-name" d="M38.298,36h-2.455l-1.531-2.455L32.798,36h-2.401l2.605-4.018l-2.449-3.835h2.353l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L38.298,36z"></path>
  </g>

  <g class="" *ngIf="filetype === 'doc'">
    <polygon class="fill-blue-800" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
    <path class="struct-filetype-page" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
    <path class="struct-filetype-shadow" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
    <path class="fill-blue-700" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
    <path class="struct-filetype-name" d="M19.525,31.907c0,1.311-0.36,2.32-1.082,3.029C17.723,35.646,16.708,36,15.4,36h-2.54v-7.853h2.718 c1.26,0,2.233,0.322,2.919,0.967C19.184,29.759,19.525,30.689,19.525,31.907z M17.324,31.982c0-0.72-0.143-1.253-0.428-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.617v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S17.324,32.752,17.324,31.982z"></path>
    <path class="struct-filetype-name" d="M28.281,32.062c0,1.322-0.324,2.326-0.973,3.014s-1.598,1.031-2.847,1.031c-1.231,0-2.177-0.346-2.833-1.037 c-0.657-0.69-0.986-1.697-0.986-3.018c0-1.308,0.327-2.306,0.98-2.995c0.654-0.689,1.604-1.034,2.85-1.034 c1.25,0,2.197,0.343,2.842,1.026S28.281,30.738,28.281,32.062z M22.877,32.062c0,1.519,0.529,2.277,1.585,2.277 c0.537,0,0.936-0.184,1.194-0.553c0.26-0.369,0.391-0.943,0.391-1.725c0-0.783-0.133-1.363-0.395-1.737 c-0.264-0.374-0.656-0.562-1.18-0.562C23.409,29.764,22.877,30.53,22.877,32.062z"></path>
    <path class="struct-filetype-name" d="M33.195,29.775c-0.505,0-0.898,0.206-1.182,0.62c-0.283,0.413-0.424,0.983-0.424,1.71 c0,1.512,0.574,2.268,1.724,2.268c0.347,0,0.685-0.049,1.01-0.146s0.653-0.213,0.983-0.349v1.793 c-0.656,0.291-1.396,0.436-2.225,0.436c-1.185,0-2.094-0.344-2.725-1.031c-0.633-0.688-0.949-1.682-0.949-2.98 c0-0.813,0.154-1.527,0.459-2.144c0.307-0.616,0.748-1.09,1.322-1.421s1.25-0.496,2.027-0.496c0.849,0,1.66,0.184,2.434,0.553 L35,30.258c-0.29-0.136-0.58-0.25-0.87-0.344C33.84,29.821,33.528,29.775,33.195,29.775z"></path>
  </g>

  <g class="" *ngIf="filetype === 'docx'">
    <polygon class="fill-blue-800" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
    <path class="struct-filetype-page" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
    <path class="struct-filetype-shadow" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
    <path class="fill-blue-700" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
    <path class="struct-filetype-name" d="M15.549,31.907c0,1.311-0.361,2.32-1.083,3.029C13.744,35.646,12.73,36,11.424,36H8.883v-7.853h2.718 c1.261,0,2.233,0.322,2.919,0.967S15.549,30.689,15.549,31.907z M13.346,31.982c0-0.72-0.142-1.253-0.427-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S13.346,32.752,13.346,31.982z"></path>
    <path class="struct-filetype-name" d="M24.303,32.062c0,1.322-0.324,2.326-0.972,3.014s-1.597,1.031-2.847,1.031c-1.232,0-2.176-0.346-2.834-1.037 c-0.656-0.69-0.984-1.697-0.984-3.018c0-1.308,0.326-2.306,0.98-2.995c0.652-0.689,1.603-1.034,2.849-1.034 c1.249,0,2.196,0.343,2.841,1.026S24.303,30.738,24.303,32.062z M18.9,32.062c0,1.519,0.527,2.277,1.584,2.277 c0.537,0,0.936-0.184,1.195-0.553s0.389-0.943,0.389-1.725c0-0.783-0.131-1.363-0.395-1.737c-0.264-0.374-0.656-0.562-1.179-0.562 C19.432,29.764,18.9,30.53,18.9,32.062z"></path>
    <path class="struct-filetype-name" d="M29.218,29.775c-0.505,0-0.899,0.206-1.182,0.62c-0.282,0.413-0.425,0.983-0.425,1.71 c0,1.512,0.575,2.268,1.725,2.268c0.348,0,0.684-0.049,1.01-0.146s0.653-0.213,0.982-0.349v1.793 c-0.654,0.291-1.396,0.436-2.223,0.436c-1.186,0-2.094-0.344-2.727-1.031c-0.632-0.688-0.947-1.682-0.947-2.98 c0-0.813,0.152-1.527,0.459-2.144s0.746-1.09,1.32-1.421c0.576-0.331,1.252-0.496,2.028-0.496c0.849,0,1.659,0.184,2.433,0.553 l-0.649,1.67c-0.29-0.136-0.58-0.25-0.87-0.344C29.862,29.821,29.551,29.775,29.218,29.775z"></path>
    <path class="struct-filetype-name" d="M39.934,36h-2.455l-1.531-2.455L34.434,36h-2.401l2.604-4.018l-2.449-3.835h2.354l1.418,2.428l1.363-2.428 h2.423l-2.487,4.001L39.934,36z"></path>
  </g>

</svg> -->
