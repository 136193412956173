
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateEnrichmentInsightSetupCommand } from "@struct/models/struct/app/domain/enrichmentinsights/commands/create-enrichment-insight-setup-command";
import { ToggleEntitiesEnrichmentInsightCommand } from "@struct/models/struct/app/domain/enrichmentinsights/commands/toggle-entities-enrichment-insight-command";
import { UpdateEnrichmentInsightCommand } from "@struct/models/struct/app/domain/enrichmentinsights/commands/update-enrichment-insight-command";
import { EnrichmentInsightSetup } from "@struct/models/struct/app/domain/enrichmentinsights/enrichment-insight-setup";
import { InsightsDataReferenceModel } from "@struct/models/struct/app/domain/enrichmentinsights/insights-data-reference-model";
import { EnrichmentInsightListItem } from "@struct/models/struct/app/frontendapi/models/enrichmentinsight/enrichment-insight-list-item";
import { EnrichmentInsightSetupModel } from "@struct/models/struct/app/frontendapi/models/enrichmentinsight/enrichment-insight-setup-model";
import { GetAvailableDataReferencesRequest } from "@struct/models/struct/app/frontendapi/models/enrichmentinsight/get-available-data-references-request";

@Injectable({ providedIn: "root" })
export class EnrichmentInsightSetupsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getEnrichmentInsights(): Observable<EnrichmentInsightSetup[]> {
    return this.http.get<EnrichmentInsightSetup[]>(`${this.apiUrl}enrichmentinsightsetups`, this.httpOptions );
  }

  createEnrichmentInsight(cmd: CreateEnrichmentInsightSetupCommand): Observable<EnrichmentInsightSetup> {
    return this.http.post<EnrichmentInsightSetup>(`${this.apiUrl}enrichmentinsightsetups`, cmd, this.httpOptions);
  }

  updateEnrichmentInsight(cmd: UpdateEnrichmentInsightCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}enrichmentinsightsetups`, cmd, this.httpOptions);
  }

  deleteEnrichmentInsight(uid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}enrichmentinsightsetups/${uid}`, this.httpOptions );
  }

  getAvailableDataReferences(request: GetAvailableDataReferencesRequest): Observable<InsightsDataReferenceModel[]> {
    return this.http.post<InsightsDataReferenceModel[]>(`${this.apiUrl}enrichmentinsightsetups/GetAvailableDataReferences`, request, this.httpOptions);
  }

  getEnrichmentInsightSetupReferences(): Observable<EnrichmentInsightListItem[]> {
    return this.http.get<EnrichmentInsightListItem[]>(`${this.apiUrl}enrichmentinsightsetups/GetEnrichmentInsightSetupReferences`, this.httpOptions );
  }

  getEnrichmentInsightSetup(uid: string): Observable<EnrichmentInsightSetupModel> {
    return this.http.get<EnrichmentInsightSetupModel>(`${this.apiUrl}enrichmentinsightsetups/GetEnrichmentInsightSetup?uid=${uid}`, this.httpOptions );
  }

  toggleEntityEnrichmentInsight(command: ToggleEntitiesEnrichmentInsightCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}enrichmentinsightsetups/ToggleEntityEnrichmentInsight`, command, this.httpOptions);
  }
}

