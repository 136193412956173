import { ComponentType } from "@angular/cdk/overlay";
import { ISidebarMenuComponent } from "../struct-app-sidebar-menu/ISidebarMenuComponent";

export class MenuSidebarItem {
  icon: string;
  label: string;
  activeOnRoute: string[];
  sidebarMenuComponent: ComponentType<ISidebarMenuComponent>;
  useMaterialIcons = false;

  constructor(icon: string, label: string, activeOnRoute: string | string[], sidebarMenuComponent: ComponentType<ISidebarMenuComponent>, useMaterialIcons = false) {
    this.icon = icon;
    this.label = label;
    this.sidebarMenuComponent = sidebarMenuComponent;
    this.useMaterialIcons = useMaterialIcons;

    if(activeOnRoute instanceof Array){
      this.activeOnRoute = activeOnRoute;
    }
    else{
      this.activeOnRoute = [activeOnRoute];
    }
  }
}