// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRemoveSubscriptionToUserCommand  {
  subscriptionUid: string;
  userUid: string;
}

export class RemoveSubscriptionToUserCommand  implements IRemoveSubscriptionToUserCommand {
  subscriptionUid = "00000000-0000-0000-0000-000000000000";

  userUid = "";


  constructor(data?: Partial<IRemoveSubscriptionToUserCommand>) {
    Object.assign(this, data);
  }
}