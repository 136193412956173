<accounts-form>
  <accounts-form-section *ngIf="tenant && tenant.status !== null">
    <accounts-form-section-title> Tenant status </accounts-form-section-title>
    <accounts-form-section-description> Manage tenant status </accounts-form-section-description>
    <accounts-form-section-content>
      <!-- <struct-simple-select name="Tenant status" [(ngModel)]="tenant.status" bindValue="key" bindLabel="value" [required]="true" placeholder="Tenant status" [options]="tenantStatuses"> </struct-simple-select> -->
    </accounts-form-section-content>
  </accounts-form-section>
  <accounts-form-section>
    <accounts-form-section-title> Tenant Details </accounts-form-section-title>
    <accounts-form-section-description> Add the tenant details </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Tenant name
          </struct-form-row-headline>
          <struct-form-row-description>
            Tenant name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Tenant Name'" [(value)]="tenant.name" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Tenant slug
          </struct-form-row-headline>
          <struct-form-row-description>
            Tenant slug description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Slug'" [(value)]="tenant.slug" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Deployment stamp
          </struct-form-row-headline>
          <struct-form-row-description>
            Deployment stamp description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select
                [(ngModel)]="tenant.deploymentInfo.deploymentStamp"
                name="Deployment stamp"
                [required]="true"
                bindValue="uid"
                bindLabel="name"
                placeholder="Deployment stamp"
                [options]="(deploymentStamps$ | async) ?? []"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select
                [(ngModel)]="tenant.subscriptionUid"
                name="Subscription"
                [required]="true"
                bindValue="uid"
                bindLabel="name"
                placeholder="Subscription"
                [options]="(subscriptions$ | async) ?? []"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="true">
          <struct-form-row-headline>
            Database template
          </struct-form-row-headline>
          <struct-form-row-description>
            Database template description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select
                [(ngModel)]="tenant.deploymentInfo.dbTemplateUid"
                name="DBTemplate"
                [required]="true"
                bindValue="uid"
                bindLabel="name"
                placeholder="DB template"
                [options]="(dbTemplates$ | async) ?? []"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Invite admin users
          </struct-form-row-headline>
          <struct-form-row-description>
            Invite admin users description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <mat-chip-grid #chipGrid aria-label="Enter invite emails">
                <mat-chip-row
                  (removed)="removeInvite(invite)"
                  [aria-description]="'press enter to edit ' + invite"
                  *ngFor="let invite of tenant.deploymentInfo.adminUsersToInvite; track invite">
                    {{invite}}
                  <button matChipRemove [attr.aria-label]="'remove ' + invite">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>

                <input placeholder="New invite"
                     [matChipInputFor]="chipGrid"
                     (matChipInputTokenEnd)="addInvite($event)"/>
            </mat-chip-grid>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>
</accounts-form>
