<struct-app-content-container>
    <accounts-header [goBackToRoute]="'/mytenants'">New tenant</accounts-header>
    <struct-app-content>
        <struct-app-content-workarea>
            <form #mytenantNewTenantForm="ngForm">

                <accounts-form>
                    <accounts-form-section>
                        <accounts-form-section-title> Tenant Details </accounts-form-section-title>
                        <accounts-form-section-description> Add tenant details </accounts-form-section-description>
                        <accounts-form-section-content>
                          <struct-form-layout>
                            <struct-form-row [borderBelow]="false">
                              <struct-form-row-headline>
                                Name
                              </struct-form-row-headline>
                              <struct-form-row-description>
                                Please enter tenant name
                              </struct-form-row-description>
                              <struct-form-row-grid>
                                <struct-form-row-grid-tile data-cols="6">
                                  <struct-input [name]="'Tenant Name'" [(value)]="tenantCommand.name" [required]="true"></struct-input>
                                </struct-form-row-grid-tile>
                              </struct-form-row-grid>
                            </struct-form-row>

                            <struct-form-row [borderBelow]="false">
                              <struct-form-row-headline>
                                Slug
                              </struct-form-row-headline>
                              <struct-form-row-description>
                                Please enter slug
                              </struct-form-row-description>
                              <struct-form-row-grid>
                                <struct-form-row-grid-tile data-cols="6">
                                  <struct-input [name]="'Tenant Slug'" [(value)]="tenantCommand.slug" [required]="true"></struct-input>
                                </struct-form-row-grid-tile>
                              </struct-form-row-grid>
                            </struct-form-row>

                            <struct-form-row [borderBelow]="false">
                              <struct-form-row-headline>
                                Template
                              </struct-form-row-headline>
                              <struct-form-row-description>
                                Please select a template
                              </struct-form-row-description>
                              <struct-form-row-grid>
                                <struct-form-row-grid-tile data-cols="6">
                                  <struct-simple-select
                                      name="Database Template"
                                      [(ngModel)]="tenantCommand.databaseTemplateUid"
                                      bindValue="id"
                                      bindLabel="name"
                                      [required]="true"
                                      placeholder="Database Template"
                                      [options]="dbTemplates">
                                  </struct-simple-select>
                                </struct-form-row-grid-tile>
                              </struct-form-row-grid>
                            </struct-form-row>

                          </struct-form-layout>
                        </accounts-form-section-content>
                    </accounts-form-section>
                </accounts-form>
            </form>
        </struct-app-content-workarea>
    </struct-app-content>

    <struct-app-content-footer>
        <div class="px-0 py-0 bg-gray-50 text-right mt-10">
            <!-- <div *ngIf="showError" class="text-red-400 font-semibold mb-3">One or more fields are not correctly filled</div> -->
            <struct-button color="primary" buttonType="submit" (click)="createTenant()">Create tenant</struct-button>
        </div>
    </struct-app-content-footer>
</struct-app-content-container>
