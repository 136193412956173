<mat-form-field [appearance]="getApperance()" [floatLabel]="floatLabelValue ? 'auto' : 'always'" class="w-full">
  <mat-label>{{name}}</mat-label>
  <input #inputField tabindex="{{tabIndex}}"
        (mousedown)="$event.stopPropagation()"
         matInput
         type="{{inputType}}" name="{{name}}-{{random}}"
         [ngClass]="{'text-right': textAlign === 'right'}"
         #elm="ngModel"
         [disabled]="disabled" [required]="required"
         placeholder="{{placeholder}}"
         [ngModel]="_value"
         (ngModelChange)="onNgModelChange($event)"
         (keydown)="onKeyDown($event)"
         (click)="onKeyDown($event)"
         [value]="_value?.toString()"
         class=""
         data-cy="struct-input.input"
  >

  <mat-error>{{"General.FieldRequired" | translate}}  </mat-error>
  <!-- <mat-error *ngIf="required && elm.invalid && _value?.toString()?.length === 0">{{name}} is required</mat-error> -->
  <mat-hint align="start" *ngIf="hintStart !== null">{{hintStart}}</mat-hint>
  <mat-hint align="end" *ngIf="hintEnd !== null">{{hintEnd}}</mat-hint>



  <div #matPrefixRef matPrefix *ngIf="showPrefix">
    <ng-content select="struct-input-prefix"></ng-content>
  </div>
  <div #matSuffixRef matSuffix *ngIf="showSuffix" class="pr-4 text-xs font-medium">
    <ng-content select="struct-input-suffix"></ng-content>
  </div>

</mat-form-field>
