<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/subscriptions'" [headerButtonText]="'Delete subscription'" [headerButtonIsDelete]="true" (headerButtonClicked)="deleteSubscription()">
    Edit subscription
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #subscriptionForm="ngForm">
        <accounts-subscription-details #subscriptionDetails [subscription]="subscription"></accounts-subscription-details>
        <!-- <accounts-form-danger-zone
        [buttonText]="'Delete subscription'"
        [title]="'Delete subscription'"
        [description]="'Deleting a subscription cannot be undone'"
        (deleteClicked)="deleteSubscription()"
        ></accounts-form-danger-zone> -->
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button buttonType="submit" (click)="submitSubscription()" color="primary">Update subscription</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
