<struct-app-content-container>
  <accounts-header>Invite user</accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
    <form #inviteForm="ngForm">
      <struct-form-layout>
        <struct-form-row [borderBelow]="false" [stack]="true">
          <struct-form-row-headline>
            Email
          </struct-form-row-headline>
          <struct-form-row-description>
            Please enter a email
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Email'" [inputType]="'email'" [required]="true" [(value)]="command.email"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false" [stack]="true">
          <struct-form-row-headline>
            Role
          </struct-form-row-headline>
          <struct-form-row-description>
            Please select role(s)
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Subscription role" [(ngModel)]="command.role" bindValue="id" bindLabel="name" [required]="true" placeholder="Role" [options]="subscriptionsRoles"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false" [stack]="true">
          <struct-form-row-headline>
            Message
          </struct-form-row-headline>
          <struct-form-row-description>
            Please enter a message
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-textarea [name]="'Message'" [(value)]="command.message"></struct-textarea>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>

    </form>
  </struct-app-content-workarea>
</struct-app-content>
<struct-app-content-footer>
    <struct-button color="default" (click)="cancel()">Cancel</struct-button>
    <struct-button color="primary" [disabled]="inviteForm.invalid ?? true" (click)="sendInvite()">Send invite</struct-button>
</struct-app-content-footer>
</struct-app-content-container>
