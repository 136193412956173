import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, map, of, switchMap, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog/struct-confirm-dialog.component';
import { StructSpinnerService } from '@struct/ui/struct-spinner/struct-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { AccountsFormDangerZoneComponent } from '../../accounts-ui/accounts-form/accounts-form-danger-zone/accounts-form-danger-zone.component';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { UsersApiService } from '@struct/services/account-management';
import { UserSearchModel } from '@struct/models/struct/accountmanagement/api/models/users/user-search-model';

@Component({
  selector: 'accounts-edit-tenant',
  templateUrl: './user-edit.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
    AccountsFormDangerZoneComponent,
    AccountsFormComponent,
    AccountsFormSectionComponent,
    AccountsFormSectionTitleComponent,
    AccountsFormSectionDescriptionComponent,
    AccountsFormSectionContentComponent,
    AccountsFormSectionComponent,
  ],
})
export class UserEditComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();
  user: UserSearchModel = new UserSearchModel();
  tenantName: string | null = null;
  showError = false;

  @ViewChild('userform') tenantform: NgForm | null = null;

  constructor(private userApi: UsersApiService, private currentRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, private spinnerService: StructSpinnerService) {}

  ngOnInit(): void {
    this.currentRoute.paramMap
      .pipe(
        takeUntil(this.destroyed$),
        map(params => params.get('uid') ?? ''),
        switchMap(uid => this.userApi.getUser(uid))
      )
      .subscribe(user => {
        this.user = user;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  deleteUser(): void {
    const dialogRef = this.dialog.open(StructConfirmDialogComponent, {
      data: <ConfirmDialogData>{
        userValueConfirm: true,
        userValueConfirmString: this.user?.firstName,
        description: of('Are you sure you want to delete this user?')
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResult) => {
      if (result?.confirmed) {
        const uid = this.currentRoute.snapshot.paramMap.get('uid');
        if (uid !== null) {
          this.userApi.deleteUser(uid).subscribe(() => {
            this.router.navigateByUrl('/users');
          });
        }
      }
    });
  }

  lockUser(): void {
    this.userApi
      .lockUser(this.user.id)
      .pipe(switchMap(() => this.userApi.getUser(this.user.id)))
      .subscribe(user => {
        this.user = user;
      });
  }

  unlockUser(): void {
    this.userApi
      .unlockUser(this.user.id)
      .pipe(switchMap(() => this.userApi.getUser(this.user.id)))
      .subscribe(user => {
        this.user = user;
      });
  }
}
