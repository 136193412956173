export class StructDialogConfig {
  width: StructDialogWidth | null = null;
  position: StructDialogPosition | null = null;

  constructor(init?: Partial<IStructDialogConfig>){
    Object.assign(this, init);
  }
}

export interface IStructDialogConfig{
  width: StructDialogWidth | null;
  position: StructDialogPosition | null;
}

export enum StructDialogPosition{
  Left,
  Right,
  Center
}

export enum StructDialogWidth{
  Quarter,
  Half,
  Full,
  Auto
}
