<struct-app-content-container>
  <struct-app-content-header [title]="'UIComponents.StructMediaPicker.MaximizeFileDialog.Title' | translate">
  </struct-app-content-header>
  <struct-app-content>
      <struct-app-content-workarea>


      </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <button mat-flat-button color="default" mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary">Next</button>
  </struct-app-content-footer>
</struct-app-content-container>
