<div class="flex h-full flex-col overflow-hidden absolute top-0 left-0 w-full">
  <!-- Top nav-->
  <header
    class="relative flex h-20 flex-shrink-0 items-center bg-white border-b border-gray-200 z-40"
  >
    <!-- Desktop nav area -->
    <div
      class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-2 md:px-4"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <struct-icon-button
          icon="chevron_left"
          #tooltip="matTooltip"
          matTooltip="Go back"
          [matTooltipPosition]="'below'"
        ></struct-icon-button>
        <h1 class="text-4xl font-medium">Create attribute</h1>
      </div>
      <div class="md:ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
        <div class="flex items-center space-x-4">
          <span class="inline-flex">
            <struct-icon-button
              icon="bookmark_add"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Add bookmark"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="actions"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Actions"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="revision_log"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Revision log"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>

          <span class="inline-flex">
            <struct-icon-button
              icon="quick_actions"
              iconSize="16"
              #tooltip="matTooltip"
              matTooltip="Quick actions"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </span>
        </div>
      </div>
    </div>
  </header>

  <!-- Bottom section -->
  <div class="flex min-h-0 flex-1 overflow-hidden">
    <!-- Main area -->
    <main class="relative min-w-0 flex-1 bg-gray-50 lg:flex">
      <!-- Primary column -->
      <section
        aria-labelledby="primary-heading"
        class="flex h-full min-w-0 flex-1 flex-col lg:order-last"
      >
        <div
          class="relative struct-scrollbar flex-grow overflow-y-auto">
          <div class="p-2 sm:p-4 lg:p-8">
            <mat-accordion multi>
              <mat-expansion-panel
                expanded="true"
                hideToggle
                id="section_attribute-settings"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> General settings </mat-panel-title>
                  <mat-panel-description></mat-panel-description>
                </mat-expansion-panel-header>
                <form action="#" method="POST">
                  <section class="flex flex-col space-y-4">
                    <!-- Backoffice name -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Backoffice name</span>
                            <struct-icon
                              icon="info_outline"
                              size="12"
                              #tooltip="matTooltip"
                              matTooltip="This value is inherited to all variants of this product"
                              [matTooltipPosition]="'below'"
                            ></struct-icon>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Enter the name this attribute should be displayed by
                            within the backoffice
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-input
                            name="Backoffice name"
                            value="Blade material"
                            [required]="false"
                          ></struct-input>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Alias -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Alias</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Add a unique alias to the attribute, which can be
                            used to reference the list elsewhere in the system
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6 relative"
                        >
                          <struct-input
                            name="Alias"
                            value="BladeMaterial"
                            [required]="true"
                            [disabled]="aliasDisabled"
                          >
                          </struct-input>
                          <struct-icon
                            icon="lock"
                            size="16"
                            class="absolute right-4 top-4 cursor-pointer"
                            (click)="toggleAlias()"
                            *ngIf="aliasDisabled"
                          ></struct-icon>
                          <struct-icon
                            icon="unlocked"
                            size="16"
                            class="absolute right-4 top-4 cursor-pointer"
                            (click)="toggleAlias()"
                            *ngIf="!aliasDisabled"
                          ></struct-icon>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Backoffice description -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Backoffice description</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Description to display along with the attribute when
                            shown in the backoffice
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-textarea
                            name="description"
                            value=""
                            [rows]="4"
                            [required]="false"
                          ></struct-textarea>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Data type -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Data type</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Select the data type of the attribute
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-simple-select
                            [(ngModel)]="activeDataType"
                            bindValue="id"
                            bindLabel="name"
                            [required]="true"
                            placeholder="Data type"
                            name="Data type"
                            [options]="dataTypes"
                          >
                          </struct-simple-select>
                        </div>
                      </div>
                    </div>

                    <!-- Global list -->
                    <div class="" *ngIf="activeDataType === 'Global list'">
                      <div class="grid gap-4 grid-cols-12">
                        <div class="col-span-12 md:col-span-3 lg:col-span-2">
                          <div class="py-2">
                            <h3
                              class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                            >
                              <span>Global list</span>
                            </h3>
                            <p class="mt-1 text-sm text-gray-600">
                              Select the global list which contains the values
                              assignable to this attribute
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                        >
                          <div
                            class="relative col-span-full lg:col-span-9 xl:col-span-6"
                          >
                            <struct-simple-select
                              [(ngModel)]="activeDataType"
                              bindValue="id"
                              bindLabel="name"
                              [required]="true"
                              name="Global list"
                              placeholder="Global list"
                              [options]="dataTypes"
                            >
                            </struct-simple-select>
                          </div>
                        </div>
                      </div>

                      <div class="grid gap-4 grid-cols-12">
                        <div class="col-span-12 md:col-span-3 lg:col-span-2">
                          <div class="py-2">
                            <h3
                              class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                            >
                              <span>Allow multiple values</span>
                            </h3>
                            <p class="mt-1 text-sm text-gray-600">
                              Allow selection of multiple values from the global
                              list
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                        >
                          <div
                            class="relative col-span-full lg:col-span-9 xl:col-span-6"
                          >
                            <mat-slide-toggle
                              color="primary"
                              checked="true"
                              disabled="false"
                            ></mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      href="javascript:;"
                      (click)="toggleDataTypeAdvanced()"
                      *ngIf="!dataTypeAdvanced"
                      class="text-blue-500 flex items-center gap-2"
                    >
                      <struct-icon icon="plus" size="12"></struct-icon>
                      <span>Show advanced data type settings</span>
                    </a>
                    <a
                      href="javascript:;"
                      (click)="toggleDataTypeAdvanced()"
                      *ngIf="dataTypeAdvanced"
                      class="text-blue-500 flex items-center gap-2"
                    >
                      <struct-icon icon="minus" size="12"></struct-icon>
                      <span>Hide advanced data type settings</span>
                    </a>

                    <div class="" *ngIf="dataTypeAdvanced">
                      <!-- Localize data -->
                      <div class="grid gap-4 grid-cols-12">
                        <div class="col-span-12 md:col-span-3 lg:col-span-2">
                          <div class="py-2">
                            <h3
                              class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                            >
                              <span>Localize data</span>
                            </h3>
                            <p class="mt-1 text-sm text-gray-600">
                              Select if data for this attribute are localized
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                        >
                          <div
                            class="relative col-span-full lg:col-span-9 xl:col-span-6 flex gap-4 w-full items-center"
                          >
                            <div class="grow-0">
                              <mat-slide-toggle
                                [(ngModel)]="isLocalizedData"
                                color="primary"
                              ></mat-slide-toggle>
                            </div>
                            <div class="grow-1 flex-1">
                              <struct-simple-select
                                bindValue="id"
                                bindLabel="name"
                                [required]="false"
                                placeholder="Fallback language"
                                name="Fallback language"
                                [(ngModel)]="isLocalizedData"
                                [options]="fallbackLanguages"
                              >
                              </struct-simple-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Segment data -->
                      <div class="grid gap-4 grid-cols-12">
                        <div class="col-span-12 md:col-span-3 lg:col-span-2">
                          <div class="py-2">
                            <h3
                              class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                            >
                              <span>Segment data</span>
                            </h3>
                            <p class="mt-1 text-sm text-gray-600">
                              Select if data for this attribute are segmented
                              based on a dimension
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                        >
                          <div
                            class="relative col-span-full lg:col-span-9 xl:col-span-6 flex gap-4 w-full items-center"
                          >
                            <div class="grow-0">
                              <mat-slide-toggle
                                [(ngModel)]="isSegmentData"
                                color="primary"
                              ></mat-slide-toggle>
                            </div>
                            <div class="grow-1 flex-1">
                              <struct-simple-select
                                bindValue="id"
                                bindLabel="name"
                                name="Segment"
                                [required]="false"
                                placeholder="Fallback segment"
                                [options]="fallbackSegments"
                              >
                              </struct-simple-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </mat-expansion-panel>

              <mat-expansion-panel
                expanded="false"
                hideToggle
                id="section_attribute-advanced"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>Advanced </mat-panel-title>
                  <mat-panel-description></mat-panel-description>
                </mat-expansion-panel-header>
                <form action="#" method="POST">
                  <section class="flex flex-col space-y-4">
                    <!-- Display name -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Display name</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Add a display name to use when this attribute is
                            displayed in any channel outside of PIM
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-input
                            name="Display name"
                            value=""
                            [required]="false"
                          ></struct-input>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Scopes -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Scopes</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Select one or more scopes for the attribute
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-simple-select
                            [(ngModel)]="scopes"
                            name="Scopes"
                            bindValue="id"
                            bindLabel="name"
                            [required]="true"
                            placeholder="Scopes"
                            [options]="scopes"
                          >
                          </struct-simple-select>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Attribute group -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Attribute group</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Choose if the attribute should be connected to an
                            attribute group
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <struct-simple-select
                            [(ngModel)]="attributeGroups"
                            name="Attribute groups"
                            bindValue="id"
                            bindLabel="name"
                            [required]="true"
                            placeholder="Attribute groups"
                            [options]="attributeGroups"
                          >
                          </struct-simple-select>
                        </div>
                      </div>
                    </div>

                    <hr class="border-gray-50" />

                    <!-- Revision logging -->
                    <div class="grid gap-4 grid-cols-12">
                      <div class="col-span-12 md:col-span-3 lg:col-span-2">
                        <div class="py-2">
                          <h3
                            class="text-base font-medium leading-6 text-gray-900 flex items-center space-x-2"
                          >
                            <span>Disable revision logging</span>
                          </h3>
                          <p class="mt-1 text-sm text-gray-600">
                            Revision logging takes some time each time an item
                            is saved. If revisions are not used at all, disable
                            revision logging for improved performance
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-span-12 md:col-span-9 lg:col-span-10 relative p-2 grid gap-2 grid-cols-12"
                      >
                        <div
                          class="relative col-span-full lg:col-span-9 xl:col-span-6"
                        >
                          <mat-slide-toggle
                            color="primary"
                            checked="false"
                            disabled="false"
                          ></mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <!-- Bottom action bar begin -->
        <div
          class="grow-0 shrink-0 h-16 lg:h-20 border-t border-gray-200 bg-white flex items-center px-6 z-10 justify-between"
        >
          <div>
            <nav class="flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center space-x-2">
                <li>
                  <div class="flex items-center">
                    <a
                      href="#"
                      class="ml-2 flex items-center text-sm text-gray-500 hover:text-gray-700"
                    >
                      <struct-icon
                        icon="folder_filled"
                        size="8"
                        class="mr-1"
                      ></struct-icon>
                      <span>Product model setup</span>
                    </a>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <struct-icon icon="chevron_right" size="12"></struct-icon>
                    <a
                      href="#"
                      class="ml-2 flex items-center text-sm text-gray-500 hover:text-gray-700"
                      aria-current="page"
                    >
                      <struct-icon
                        icon="folder_filled"
                        size="8"
                        class="mr-1"
                      ></struct-icon>
                      <span>Attributes</span>
                    </a>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <struct-icon icon="chevron_right" size="12"></struct-icon>
                    <a
                      href="#"
                      class="ml-2 text-sm text-gray-500 hover:text-gray-700"
                      aria-current="page"
                      >Create attribute</a
                    >
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="flex items-center space-x-4">
            <struct-button buttonType="submit" color="accent"
              >Save attribute</struct-button
            >
            <struct-button buttonType="submit">Cancel</struct-button>
          </div>
        </div>
        <!-- Bottom action bar end -->
      </section>

      <!-- Meta information column (hidden on smaller screens) -->
      <aside
        class="hidden flex-col w-96 overflow-hidden border-r border-gray-200 bg-gray-50 lg:flex lg:order-first z-10"
      >
        <div class="flex flex-col flex-grow h-full">
          <section class="">
            <div
              class="w-full h-64 block overflow-hidden aspect-w-3 aspect-h-3 border-b border-gray-100 relative"
            >
              <!-- <struct-icon icon="product_model_setup_32" size="64"></struct-icon> -->
              <img src="/assets/images/illustrations/note-taking.svg" />





            </div>
          </section>
          <section class="overflow-y-auto p-4 flex-1">
            <div class="space-y-6 pb-16">
              <mat-accordion class="expansion-panel__sidebar">
                <mat-expansion-panel
                  class="relative mat-elevation-z0"
                  expanded="true"
                >
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title> Attribute status </mat-panel-title>
                  </mat-expansion-panel-header>
                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">UId</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        4d5af664-509c-4f9b-b217-0270c364f7ab
                      </dd>
                    </div>
                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Created by</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <p class="mr-2 text-sm font-medium text-gray-900">
                            Simon Lyder
                          </p>
                          <img
                            src="/assets/images/avatars/avatar-simon.jpg"
                            alt=""
                            class="h-5 w-5 rounded-full"
                          />
                        </div>
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Creation date</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        August 12, 2022
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Last modified</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        December 8, 2022
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Last modified by</dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <p class="mr-2 text-sm font-medium text-gray-900">
                            Lasse Nørby
                          </p>
                          <img
                            src="/assets/images/avatars/avatar-lasse.jpg"
                            alt=""
                            class="h-5 w-5 rounded-full"
                          />
                        </div>
                      </dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Revision</dt>
                      <dd class="whitespace-nowrap text-gray-900">Rev. 2</dd>
                    </div>

                    <div class="flex justify-between py-3 text-sm font-medium">
                      <dt class="text-gray-500">Attribute Group</dt>
                      <dd class="whitespace-nowrap text-gray-900">General</dd>
                    </div>
                  </dl>
                </mat-expansion-panel>
                <mat-expansion-panel class="relative mat-elevation-z0">
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title>
                      Categories using this attribute
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="w-full flex items-center">
                    <div class="grow">
                      <label for="search" class="sr-only">Search</label>
                      <div
                        class="relative text-gray-400 focus-within:text-gray-600"
                      >
                        <div
                          class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                        >
                          <struct-icon icon="search" size="16"></struct-icon>
                        </div>
                        <input
                          id="search-field"
                          class="block h-full w-full bg-transparent border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </div>
                    <struct-icon-button
                      icon="add"
                      iconSize="12"
                      (click)="isMetaDrawerActive = !isMetaDrawerActive"
                    ></struct-icon-button>
                  </div>

                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Clothing</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Power Tools</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Phones</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Computers</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Tools</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="folder_filled"
                            class="text-yellow-500"
                            size="16"
                          ></struct-icon>
                          <span>Laptops</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <button
                      class="flex w-full items-center justify-center px-2 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-50"
                    >
                      View all (87)
                    </button>
                  </dl>
                </mat-expansion-panel>
                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header class="disable_ripple">
                    <mat-panel-title>
                      Models using this attribute
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="w-full flex items-center">
                    <div class="grow">
                      <label for="search" class="sr-only">Search</label>
                      <div
                        class="relative text-gray-400 focus-within:text-gray-600"
                      >
                        <div
                          class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                        >
                          <struct-icon icon="search" size="16"></struct-icon>
                        </div>
                        <input
                          id="search-field"
                          class="block h-full w-full bg-transparent border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </div>
                    <struct-icon-button
                      icon="add"
                      iconSize="12"
                      (click)="isMetaDrawerActive = !isMetaDrawerActive"
                    ></struct-icon-button>
                  </div>
                  <dl
                    class="mt-2 divide-y divide-gray-100 border-t border-b border-gray-100"
                  >
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="product_model_setup"
                            size="16"
                          ></struct-icon>
                          <span>Non-food</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                    <div
                      class="flex justify-between items-center text-sm font-medium"
                    >
                      <dt class="text-gray-500">
                        <div class="flex items-center gap-2">
                          <struct-icon
                            icon="product_model_setup"
                            size="16"
                          ></struct-icon>
                          <span>Wine</span>
                        </div>
                      </dt>
                      <dd class="whitespace-nowrap text-gray-900">
                        <div class="flex items-center">
                          <struct-icon-button
                            icon="remove"
                            iconSize="12"
                          ></struct-icon-button>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </section>
          <div
            class="h-16 lg:h-20 flex items-center justify-end px-4 space-x-4"
          >
            <struct-icon-button
              icon="move_left"
              #tooltip="matTooltip"
              matTooltip="Close meta sidebar"
              [matTooltipPosition]="'below'"
            ></struct-icon-button>
          </div>
        </div>
      </aside>
      <div
        class="absolute top-0 right-0 bottom-0 left-96 bg-gray-500 bg-opacity-25 transition-opacity"
        *ngIf="isMetaDrawerActive"
        (click)="isMetaDrawerActive = !isMetaDrawerActive"
      ></div>
      <aside
        class="hidden flex-col w-96 overflow-hidden border-r border-gray-200 shadow-lg bg-gray-50 lg:flex absolute h-full ml-96"
        *ngIf="isMetaDrawerActive"
      >
        <div
          class="flex h-full flex-col flex-grow overflow-y-auto bg-gray-50 py-6"
        >
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                class="text-base font-semibold leading-6 text-gray-900"
                id="slide-over-title"
              >
                Add category
              </h2>
              <div class="ml-3 flex h-7 items-center">
                <struct-icon-button
                  icon="remove"
                  #tooltip="matTooltip"
                  matTooltip="Close"
                  [matTooltipPosition]="'below'"
                  (click)="isMetaDrawerActive = !isMetaDrawerActive"
                ></struct-icon-button>
              </div>
            </div>
          </div>
          <div class="relative mt-6 flex-1 px-4">
            <div
              class="h-10 flex items-center px-2 py-2 sm:px-2 border-b border-gray-100 sticky top-0 z-10"
            >
              <form class="flex w-full lg:ml-0" action="#" method="GET">
                <label for="search-field" class="sr-only">Search</label>
                <div
                  class="relative w-full text-gray-400 focus-within:text-gray-600"
                >
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  >
                    <struct-icon
                      icon="search"
                      size="16"
                      class="text-gray-400"
                    ></struct-icon>
                  </div>
                  <input
                    id="search-field"
                    class="block h-full w-full bg-transparent border-transparent py-2 pl-6 pr-2 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-md"
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
            <form action="#" method="POST">
              <mat-tree
                [dataSource]="dataSource"
                [treeControl]="treeControl"
                class="bg-transparent"
              >
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <div
                    class="w-full px-2 flex space-x-2 items-center relative group"
                  >
                    <button mat-icon-button disabled></button>
                    <struct-checkbox></struct-checkbox>
                    <struct-icon
                      icon="folder_filled_open"
                      size="16"
                      class="text-yellow-500"
                      *ngIf="treeControl.isExpanded(node)"
                    ></struct-icon>
                    <struct-icon
                      icon="folder_filled"
                      size="16"
                      class="text-yellow-500"
                      *ngIf="!treeControl.isExpanded(node)"
                    ></struct-icon>
                    <span class="text-md flex-grow truncate">{{
                      node.item
                    }}</span>
                    <struct-icon-button
                      icon="more_vert"
                      [matMenuTriggerFor]="menu_tree_node_options"
                      class="opacity-0 group-hover:opacity-100"
                    ></struct-icon-button>
                  </div>
                </mat-tree-node>

                <mat-tree-node
                  *matTreeNodeDef="let node; when: hasChild"
                  matTreeNodePadding
                >
                  <div
                    class="w-full px-2 flex space-x-2 items-center relative group"
                  >
                    <button
                      mat-icon-button
                      [attr.aria-label]="'Toggle ' + node.item"
                      matTreeNodeToggle
                    >
                      <struct-icon
                        icon="minus"
                        size="12"
                        class="text-gray-500"
                        *ngIf="treeControl.isExpanded(node)"
                      ></struct-icon>
                      <struct-icon
                        icon="plus"
                        size="12"
                        class="text-gray-500"
                        *ngIf="!treeControl.isExpanded(node)"
                      ></struct-icon>
                    </button>
                    <a
                      href="javascript:;"
                      class="flex-1 flex items-center space-x-2"
                    >
                      <struct-checkbox></struct-checkbox>
                      <struct-icon
                        icon="folder_filled_open"
                        size="16"
                        class="text-yellow-500"
                        *ngIf="treeControl.isExpanded(node)"
                      ></struct-icon>
                      <struct-icon
                        icon="folder_filled"
                        size="16"
                        class="text-yellow-500"
                        *ngIf="!treeControl.isExpanded(node)"
                      ></struct-icon>
                      <span class="text-md flex-grow truncate">{{
                        node.item
                      }}</span>
                      <struct-icon-button
                        icon="more_vert"
                        [matMenuTriggerFor]="menu_tree_node_options"
                        class="opacity-0 group-hover:opacity-100"
                      ></struct-icon-button>
                    </a>
                  </div>
                  <mat-progress-bar
                    *ngIf="node.isLoading"
                    mode="indeterminate"
                    class="struct-tree-progress-bar absolute bottom-0 left-0 w-full"
                  ></mat-progress-bar>
                </mat-tree-node>
              </mat-tree>

              <mat-menu #menu_tree_node_options="matMenu" class="text-sm">
                <button
                  mat-menu-item
                  class="text-sm flex items-center h-10 leading-tight"
                >
                  <struct-icon icon="content_copy" size="16"></struct-icon>
                  <span>Copy</span>
                </button>

                <button
                  mat-menu-item
                  class="text-sm flex items-center h-10 leading-tight"
                >
                  <struct-icon icon="move_down" size="16"></struct-icon>
                  <span>Move</span>
                </button>
                <button
                  mat-menu-item
                  class="text-sm flex items-center h-10 leading-tight"
                >
                  <struct-icon icon="delete" size="16"></struct-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </form>
          </div>
        </div>
      </aside>
    </main>
  </div>
</div>
