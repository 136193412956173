import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SignInRequest } from '@struct/models/accountmanagement/api/models/authentication/sign-in-request';
import { SignInResponse } from '@struct/models/accountmanagement/api/models/authentication/sign-in-response';
import { UserModel } from '@struct/models/accountmanagement/api/models/authentication/user-model';
import { AuthenticationApiService } from '@struct/services/account-management';
import { BehaviorSubject, Observable, catchError, concat, map, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { RegisterUserWithMicrosoftCommandResponse } from '@struct/models/struct/usermanagementmodule/commands/register-user-with-microsoft-command-response';
import { RegisterWithMicrosoftRequest } from '@struct/models/struct/accountmanagement/api/models/account/register-with-microsoft-request';
import { CommandResponse } from '@struct/models/struct/shared/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userIsFetched = false;
  private authenticatedUser$: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);
  constructor(private authApi: AuthenticationApiService, private http: HttpClient, @Inject('accountManagementApiUrl') private apiUrl: string) {}

  public isSignedIn(): Observable<boolean> {
    return this.getAuthenticatedUser().pipe(
      map(user => {
        return user !== null;
      })
    );
  }

  private generateCodeVerifier(): string {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    return this.base64UrlEncode(hash);
  }

  private base64UrlEncode(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  private generateRandomString(length: number): string {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
  }

  async createWithEntraId(email: string, inviteUid: string, inviteToken: string): Promise<void> {
    const scope = 'user.read openid profile email';
    const userState = this.generateRandomString(16); // Generate a random user state
    const state = `userState=${userState}&inviteUid=${inviteUid}&inviteToken=${inviteToken}`;
    const responseType = 'code';
    const responseMode = 'query';
    const prompt = 'select_account';

    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier);
    sessionStorage.setItem('user_state', userState); // Store the user state

    const url = `https://login.microsoftonline.com/${environment.entraId.tenantId}/oauth2/v2.0/authorize?` +
                `client_id=${environment.entraId.clientId}&` +
                `response_type=${responseType}&` +
                `redirect_uri=${encodeURIComponent(environment.entraId.createAccountRedirectUrl)}&` +
                `response_mode=${responseMode}&` +
                `scope=${scope}&` +
                `state=${encodeURIComponent(state)}&` +
                `prompt=${prompt}&` +
                `login_hint=${email}&` +
                `code_challenge=${codeChallenge}&` +
                `code_challenge_method=S256`;

    window.location.href = url;
  }

  async redirectToEntraIdLogin(tenant: string | null, redirectUri: string | null = null): Promise<void> {
    const scope = 'user.read openid profile email';
    const userState = this.generateRandomString(16); // Generate a random user state
    const state = `tenant=${tenant === null ? '' : tenant}&userState=${userState}`;
    const responseType = 'code';
    const responseMode = 'query';
    const prompt = 'select_account';

    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier);
    sessionStorage.setItem('user_state', userState); // Store the user state

    redirectUri = redirectUri ?? environment.entraId.redirectUrl;

    const url = `https://login.microsoftonline.com/${environment.entraId.tenantId}/oauth2/v2.0/authorize?` +
                `client_id=${environment.entraId.clientId}&` +
                `response_type=${responseType}&` +
                `redirect_uri=${encodeURIComponent(redirectUri)}&` +
                `response_mode=${responseMode}&` +
                `scope=${scope}&` +
                `state=${encodeURIComponent(state)}&` +
                `prompt=${prompt}&` +
                `code_challenge=${codeChallenge}&` +
                `code_challenge_method=S256`;

    window.location.href = url;
  }

  handleEntraIdLoginCallback(): Observable<MicrosoftSignInResponse> {
    return this.handleEntraIdCallback(environment.entraId.redirectUrl);
  }

  handleEntraIdChangeToMicrosoftCallback(): Observable<MicrosoftSignInResponse> {
    return this.handleEntraIdCallback(environment.entraId.changeSignInRedirectUrl);
  }

  handleEntraIdRegisterCallback(): Observable<MicrosoftSignInResponse> {
    return this.handleEntraIdCallback(environment.entraId.createAccountRedirectUrl);
  }

  private handleEntraIdCallback(redirectUri: string): Observable<MicrosoftSignInResponse> {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const codeVerifier = sessionStorage.getItem('pkce_code_verifier');
    const storedUserState = sessionStorage.getItem('user_state');

    if (!code) {
      console.error('Authorization code not found in the callback URL');
      return of(new MicrosoftSignInResponse(null, '', false, 'Authorization code not found in the callback URL'));
    }

    if (!codeVerifier) {
      console.error('Code verifier not found in session storage');
      return of(new MicrosoftSignInResponse(null, '', false, 'Code verifier not found in session storage'));
    }

    if (!state) {
      console.error('State not found in the callback URL');
      return of(new MicrosoftSignInResponse(null, '', false, 'State not found in the callback URL'));
    }

    const stateParams = new URLSearchParams(state);
    const returnedUserState = stateParams.get('userState');

    if (!storedUserState || storedUserState !== returnedUserState) {
      console.error('User state validation failed');
      return of(new MicrosoftSignInResponse(null, '', false, 'User state validation failed'));
    }

    const body = new HttpParams()
      .set('client_id', environment.entraId.clientId)
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', redirectUri)
      .set('code_verifier', codeVerifier)
      .set('scope', environment.entraId.scope);

    return this.http.post<MicrosoftAccessTokenResponse>(environment.entraId.tokenEndpoint, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).pipe(
      map((accessToken: MicrosoftAccessTokenResponse) => {
        return new MicrosoftSignInResponse(stateParams, accessToken.access_token, true, null);
      }),
      catchError(error => {
        return of(new MicrosoftSignInResponse(null, '', false, error));
      })
    );
  }

  public signInWithMicrosoft(authCode: string): Observable<SignInResponse>{
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Authorization": 'Bearer ' + authCode }),
      withCredentials: true
    };

    return this.http.post<SignInResponse>(`${this.apiUrl}auth/signinwithmicrosoft`, null, httpOptions);
  }

  public changeSignInToMicrosoft(authCode: string): Observable<CommandResponse>{
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Authorization": 'Bearer ' + authCode }),
      withCredentials: true
    };

    return this.http.post<CommandResponse>(`${this.apiUrl}users/changesigninmethodtomicrosoft`, null, httpOptions);
  }

  public registerWithMicrosoft(authCode: string, inviteUid: string, inviteToken: string): Observable<RegisterUserWithMicrosoftCommandResponse>{
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Authorization": 'Bearer ' + authCode }),
      withCredentials: true
    };

    const request = new RegisterWithMicrosoftRequest({
      inviteToken: inviteToken,
      inviteUid: inviteUid
    })

    return this.http.post<RegisterUserWithMicrosoftCommandResponse>(`${this.apiUrl}users/registerwithmicrosoft`, request, httpOptions);
  }

  public getAuthenticatedUser(): Observable<UserModel | null> {
    if (!this.userIsFetched) {
      this.userIsFetched = true;
      return this.authApi.getUser().pipe(
        switchMap(user => {
          this.authenticatedUser$.next(user);
          return this.authenticatedUser$.asObservable();
        }),
        catchError(error => {
          this.authenticatedUser$.next(null);
          return of(null);
        })
      );
    } else {
      return this.authenticatedUser$;
    }
  }

  public signIn(signInRequest: SignInRequest): Observable<SignInResponse> {
    return this.authApi.signIn(signInRequest).pipe(
      map(response => {
        if (response.isSuccess && response.authenticatedUser) {
          this.authenticatedUser$.next(response.authenticatedUser);
        }
        return response;
      }),
      switchMap(response => {
        if (response.isSuccess) {
          return this.authApi.getAntiforgeryToken().pipe(
            map(token => {
              return response;
            })
          );
        } else {
          return of(response);
        }
      })
    );
  }

  public removeAuthenticatedUser(): void {
    this.authenticatedUser$.next(null);
  }

  public signOut(): Observable<boolean> {
    return concat(this.authApi.signOut(), this.authApi.getAntiforgeryToken()).pipe(
      map(response => {
        this.authenticatedUser$.next(null);
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }
}

export class MicrosoftAccessTokenResponse {
  access_token: string = '';
  expires_in: number = 0;
  ext_expires_in: number = 0;
  id_token: string = '';
  refresh_token: string = '';
  scope: string = '';
  token_type: string = '';
}

export class MicrosoftSignInResponse {
  stateParams: URLSearchParams | null;
  accessToken: string;
  success: boolean;
  error: string | null;

  public tenant(): string | null{
    return this.stateParams?.get('tenant') ?? null;
  }

  public inviteToken() : string | null{
    return this.stateParams?.get('inviteToken') ?? null;
  }

  public inviteUid() : string | null{
    return this.stateParams?.get('inviteUid') ?? null;
  }

  constructor(stateParams: URLSearchParams | null, accessToken: string, success: boolean, error: string | null) {
    this.stateParams = stateParams;
    this.accessToken = accessToken;
    this.success = success;
    this.error = error;
  }
}