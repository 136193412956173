// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TenantUserStatus } from "@struct/models/accountmanagement/domain/tenants/tenant-user-status";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITenantUser  {
  userId: string;
  tenantUid: string;
  status: TenantUserStatus;
}

export class TenantUser  implements ITenantUser {
  userId = "";

  tenantUid = "00000000-0000-0000-0000-000000000000";

  status: TenantUserStatus = TenantUserStatus.Active;


  constructor(data?: Partial<ITenantUser>) {
    Object.assign(this, data);
  }
}