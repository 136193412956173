import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from "./struct-confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root',
  })
  export class StructConfirmDialogService {

    constructor(private dialog : MatDialog) { }

    public open(data : ConfirmDialogData) : Observable<ConfirmDialogResult>{
        const dialogRef = this.dialog.open(StructConfirmDialogComponent, {
          panelClass: ['struct-confirm-dialog'],
          data: data,
        });

        return dialogRef.afterClosed();
    }
  }
