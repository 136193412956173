// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserSearchModel  {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organization?: string | null;
  twoFactorEnabled: boolean;
  lockedOut: boolean;
  lockoutEnd?: Date | string | null;
  emailConfirmed: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed: boolean;
  timeZoneId?: string | null;
  cultureCode?: string | null;
}

export class UserSearchModel  implements IUserSearchModel {
  id = "";

  firstName = "";

  lastName = "";

  email = "";

  role = "";

  organization: string | null = null;

  twoFactorEnabled = false;

  lockedOut = false;

  lockoutEnd: Date | string | null = null;

  emailConfirmed = false;

  phoneNumber: string | null = null;

  phoneNumberConfirmed = false;

  timeZoneId: string | null = null;

  cultureCode: string | null = null;


  constructor(data?: Partial<IUserSearchModel>) {
    Object.assign(this, data);
  }
}