<li class="flex items-start">
  <a [routerLink]="[routerLink]" href="#" class="flex items-center w-full h-full px-4 py-5 sm:px-5 hover:bg-gray-100">

    <div type="button" class="items-center p-2 pr-4 border-r border-gray-200 h-full">
      <struct-icon icon="{{iconName}}" size="16"></struct-icon>
    </div>


    <div class="ml-5 flex-grow">
      <div class="flex items-center justify-between">
        <div class="w-full">
          <p class="font-medium truncate leading-tight">
            <ng-content></ng-content>
          </p>
          <ng-content select="accounts-list-item-description"></ng-content>
        </div>
        <!-- <div class="ml-2 flex-shrink-0 flex">
          <ng-content select="accounts-list-item-badge"></ng-content>
        </div> -->
      </div>


    </div>

    <div class="px-5">
      <struct-icon-button *ngIf="routerLink !== null" icon="chevron_right"></struct-icon-button>
    </div>
  </a>
</li>
