<!-- <div class="flex flex-col items-center animate-in slide-in-from-bottom-4 duration-800">
  <img src="/assets/images/illustrations/note-taking.svg" class="w-64 h-auto" />
  <mat-spinner diameter="40" color="primary"></mat-spinner>
</div> -->

<!-- <div class="gooey">
  <span class="dot"></span>
  <div class="dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div> -->
<div class="flex flex-col gap-2 items-center">
  <!-- <p class="text-xs text-gray-400 font-medium">Loading</p> -->
  <div class="dot-falling"></div>
</div>

