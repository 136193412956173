
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MaintenanceTaskStatus } from "@struct/models/struct/app/frontendapi/models/maintenance/maintenance-task-status";

@Injectable({ providedIn: "root" })
export class MaintenanceApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getActiveTasks(): Observable<MaintenanceTaskStatus> {
    return this.http.get<MaintenanceTaskStatus>(`${this.apiUrl}maintenance/activetasks`, this.httpOptions );
  }

  rebuildPimReferences(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildpimreferences`, null, this.httpOptions);
  }

  rebuildGlobalListIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildgloballistindex`, null, this.httpOptions);
  }

  rebuildSingleGlobalListIndex(globalListUid: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}maintenance/rebuildsinglegloballistindex/${globalListUid}`, null, this.httpOptions);
  }

  rebuildCategoryIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildcategoryindex`, null, this.httpOptions);
  }

  rebuildAssetIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildassetindex`, null, this.httpOptions);
  }

  rebuildProductIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildproductindex`, null, this.httpOptions);
  }

  rebuildVariantIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantindex`, null, this.httpOptions);
  }

  rebuildVariantGroupIndex(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantgroupindex`, null, this.httpOptions);
  }

  rebuildProductIdentifiers(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildproductidentifiers`, null, this.httpOptions);
  }

  rebuildVariantIdentifiers(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantidentifiers`, null, this.httpOptions);
  }

  rebuildVariantGroupIdentifiers(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantgroupidentifiers`, null, this.httpOptions);
  }

  rebuildCategoryIdentifiers(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildcategoryidentifiers`, null, this.httpOptions);
  }

  rebuildProductEnrichmentInsights(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildproductenrichmentinsights`, null, this.httpOptions);
  }

  rebuildVariantEnrichmentInsights(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantenrichmentinsights`, null, this.httpOptions);
  }

  rebuildVariantGroupEnrichmentInsights(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildvariantgroupenrichmentinsights`, null, this.httpOptions);
  }

  rebuildCategoryEnrichmentInsights(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildcategoryenrichmentinsights`, null, this.httpOptions);
  }

  rebuildEntityWorkflowStates(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuildentityworkflowstates`, null, this.httpOptions);
  }

  rebuildDynamicCategories(): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}maintenance/rebuilddynamiccategories`, null, this.httpOptions);
  }
}

